import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './converters';

@JsonObject('UserTravelPolicyModel')
export default class UserTravelPolicyModel {
  @JsonProperty('approval_manager_ids', [Number]) public approvalManagerIds: number[] = [];
  @JsonProperty('budget_per_trip', Number) public budgetPerTrip: number = -1;
  @JsonProperty('created_at', DateConverter) public createdAt: Date = undefined;
  @JsonProperty('flight_cabin_class_long_haul', String)
  public flightCabinClassLongHaul: string = '';
  @JsonProperty('flight_cabin_class_short_medium_haul', String)
  public flightCabinClassShortMediumHaul: string = '';
  @JsonProperty('flight_long_haul', Boolean) public flightLongHaul: boolean = false;
  @JsonProperty('flight_short_medium_haul', Boolean) public flightShortMediumHaul: boolean = false;
  @JsonProperty('hotel', Boolean) public hotel: boolean = false;
  @JsonProperty('hotel_stars', Number) public hotelStars: number = -1;
  @JsonProperty('id', Number) public id: number = -1;
  @JsonProperty('monthly_budget', Number) public monthlyBudget: number = -1;
  @JsonProperty('rental_car', Boolean) public rentalCar: boolean = false;
  @JsonProperty('rental_car_class', String) public rentalCarClass: string = '';
  @JsonProperty('shuttle', Boolean) public shuttle: boolean = false;
  @JsonProperty('shuttle_type', String) public shuttleType: string = '';
  @JsonProperty('train', Boolean) public train: boolean = false;
  @JsonProperty('train_comfort_class', Number) public trainComfortClass: number = -1;
  @JsonProperty('updated_at', DateConverter) public updatedAt: Date = undefined;
  @JsonProperty('user_id', Number) public userId: number = -1;
}
