import { createSelector } from 'reselect';

// Utils
import { verifyProperty } from '../util/general';
// Constants
import { ROLES } from '../const/app';
// Actions
// Models
import { UserModel } from '../models';
// Interfaces
import { IAdminUserState } from '../reducers';

export const currentUser = (state:IAdminUserState):UserModel => state.profile;

export const isCurrentUserAdmin = createSelector(
  currentUser,
  (user:UserModel) => {
    const role = verifyProperty(null, user, ['role']);
    return role === ROLES.ADMIN;
  }
);

export const isCurrentUserTravelAssistant = createSelector(
  currentUser,
  (user:UserModel) => {
    const role = verifyProperty(null, user, ['role']);
    const canBookForMyReports = verifyProperty(null, user, ['bookForMyReportsPermission']);
    return (role === ROLES.TRAVEL_ASSISTANT || canBookForMyReports);
  }
);

export const isCurrentUserManager = createSelector(
  currentUser,
  (user:UserModel) => {
    const role = verifyProperty(null, user, ['role']);
    return role === ROLES.MANAGER;
  }
);

export const isCurrentUserNotTraveller = createSelector(
  currentUser,
  (user:UserModel) => {
    const role = verifyProperty(null, user, ['role']);
    const canBookForMyReports = verifyProperty(null, user, ['bookForMyReportsPermission']);
    return (
      role === ROLES.ADMIN ||
      role === ROLES.MANAGER ||
      role === ROLES.TRAVEL_ASSISTANT ||
      role === ROLES.READ_ONLY_ADMIN ||
      role === ROLES.ACCOUNTANT ||
      canBookForMyReports
    );
  }
);

export const isCurrentUserROTraveller = createSelector(
  currentUser,
  (user:UserModel) => {
    const role = verifyProperty(null, user, ['role']);
    return role === ROLES.READ_ONLY_TRAVELLER;
  }
);

export const costCenterRequired = createSelector(currentUser, (user) => user.costCenterRequired);

export const costUnitRequired = createSelector(currentUser, (user) => user.costUnitRequired);

export const justificationRequired = createSelector(
  currentUser,
  (user) => user.requireJustificationForPolicyViolation
);

export const isCurrentUserTraveller = createSelector(
  currentUser,
  (user:UserModel) => {
    const role = verifyProperty(null, user, ['role']);
    return role === ROLES.TRAVELLER;
  }
);

export const currentUserId = createSelector(
  currentUser,
  (user:UserModel) => verifyProperty(null, user, ['id'])
);
