import * as React from 'react';
import { SEARCH_TYPE } from '@src/shared/src/const/app';
import SearchBarVehicles from './SearchBarVehicles';
import { SearchBarCabinClassSelector } from './SearchBarCabinClassSelector';
import { SearchBarRoomsSelector } from './SearchBarRoomsSelector';
// Styles
import '../styles/SearchBarFilter.scss';

type Props = {
  searchType: SEARCH_TYPE;
  onSetSearchHotelRoomsType: (userId: number) => void;
  currentRoomType: number;
};

const SearchBarFilter: React.FC<Props> = ({ searchType }) => {
  return (
    <div className="searchbar-filter">
      {(searchType === SEARCH_TYPE.ALL || searchType === SEARCH_TYPE.OUTBOUND) && (
        <SearchBarVehicles />
      )}
      {searchType !== SEARCH_TYPE.RENTAL && searchType !== SEARCH_TYPE.HOTEL && (
        <>
          <SearchBarCabinClassSelector />
        </>
      )}
      {searchType !== SEARCH_TYPE.RENTAL && (
        <>
          <SearchBarRoomsSelector alignRight={searchType === SEARCH_TYPE.ALL}/>
        </>
      )}
    </div>
  );
};

export default SearchBarFilter;
