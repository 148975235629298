import * as React from 'react';

import '../styles/SearchBarSlideOut.scss';

type Props = {
  isOpen: boolean;
  children: any;
  className?: string;
};

const SearchBarSlideOut: React.FC<Props> = (props) => {
  const { isOpen, className, children } = props;

  return (
    <aside className={`searchbar-slide-out ${isOpen ? 'is--open' : ''} ${className}`}>
      {children}
    </aside>
  );
};

export default SearchBarSlideOut;
