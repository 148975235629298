import * as React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'react-final-form';

// Utils
import { partial } from '@src/shared/src/util/general';
import { maxLen, required, composeValidators } from '@toolkit/util/formsValidation';
import { t } from '@toolkit/util/i18n';
// Constants
import { COST_CENTER_CATEGORIES } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { IRootState } from '@src/store';
import { AccountingInvoiceProfileModel } from '@src/shared/src/models';
// Components
import { SelectInput, FormInput } from '@src/toolkit/ui';
import { CostCentersFormField } from '.';
// Styles
import '../styles/FormGroup.scss';

type Props = {
  costCenterRequired: boolean;
  costUnitRequired: boolean;
  purposeOfTripRequired: boolean;
  referenceCodeRequired: boolean;
  accountingInvoiceProfiles: AccountingInvoiceProfileModel[];
};
const maxLenValidate = partial(maxLen, [50]);

const CheckoutPaymentAccountingForm: React.FC<Props> = (props: Props) => {
  const organization = useSelector((state: IRootState) => state.organization.org);

  return (
    <div className="checkout-box checkout-payment box">
      <h2 className="checkout-title hidden-xs-down">{t('checkoutPaymentInfo.label.accounting')}</h2>
      <div className="checkout-box-content">
        {props.accountingInvoiceProfiles.length > 1 && (
          <div className="tcp-form-group">
            <label htmlFor="accountingInvoiceProfile" className="tcp-form-group-label">
              {t('checkoutPaymentAccountingForm.label.accountingInvoiceProfile')}
            </label>
            <div className="tcp-form-group-field tcp-form-group-field-full">
              <Field
                name="accountingInvoiceProfile"
                options={props.accountingInvoiceProfiles.map((aip) => ({
                  label: aip.name,
                  value: aip.id,
                }))}
                validate={required}
                parse={(val) => val?.value}
                component={SelectInput}
              />
            </div>
          </div>
        )}
        <CostCentersFormField
          category={COST_CENTER_CATEGORIES.COST_CENTER}
          formFieldName="costCenters"
          organizationId={organization.id}
          multiValuesEnabled={organization.multiCostCentersEnabled}
          valueRequired={props.costCenterRequired}
        />
        {organization.costUnitsEnabled && (
          <CostCentersFormField
            category={COST_CENTER_CATEGORIES.COST_UNIT}
            formFieldName="costUnits"
            organizationId={organization.id}
            multiValuesEnabled={organization.multiCostUnitsEnabled}
            valueRequired={props.costUnitRequired}
          />
        )}
        <div className="checkout-form-row">
          <div className="tcp-form-group tcp-form-group-6">
            <label htmlFor="referenceCode" className="tcp-form-group-label">
              {t('checkoutPaymentAccountingForm.label.referenceCode')}
            </label>
            <div className="tcp-form-group-field">
              <Field
                name="referenceCode"
                component={FormInput}
                validate={
                  props.referenceCodeRequired
                    ? composeValidators(maxLenValidate, required)
                    : maxLenValidate
                }
              />
            </div>
          </div>
        </div>
        <div className="tcp-form-group">
          <label htmlFor="purposeOfTrip" className="tcp-form-group-label">
            {t('checkoutPaymentAccountingForm.label.purposeOfTrip')}
          </label>
          <div className="tcp-form-group-field tcp-form-group-field-full">
            <Field
              name="purposeOfTrip"
              type="textarea"
              component={FormInput}
              validate={props.purposeOfTripRequired ? required : undefined}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutPaymentAccountingForm;
