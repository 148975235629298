import * as React from 'react';

// Utils
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import './styles/LoaderOverlay.scss';

type Props = {
  isOpen:boolean;
  wrapperCSS?:string;
  outerCSS?:string;
  innerCSS?:string;
  icon?:string;
  title?:string;
  body?:any;
  buttonText?:string;
  showButton?:boolean;
  onButtonClicked?:() => void;
};
type State = {
  isOpen:boolean;
};

export default class InformationOverlay extends React.PureComponent<Props, State> {
  readonly state:State = {
    isOpen: this.props.isOpen,
  };

  private onButtonClicked = () => {
    if (this.props.onButtonClicked) {
      this.props.onButtonClicked();
    } else {
      // Close it on button clicked otherwise
      this.setState({
        isOpen: false,
      });
    }
  }

  render() {
    const {
      wrapperCSS,
      outerCSS,
      icon,
      title,
      body,
      innerCSS,
      showButton,
      buttonText,
    } = this.props;

    if (!this.state.isOpen) {
      return null;
    }

    return (
      <div className={`tcp-loader-overlay`}>
        <div className="tcp-loader-overlay-content">
          <div className={wrapperCSS}>
            <div className={outerCSS}>
              <div className="tcp-loader-overlay-icon">
                {icon &&
                  <i className={`is-size-giant ${icon}`}/>}
              </div>
              {title &&
                <h2 className="tcp-loader-overlay-content-title">{title}</h2>}
              <div className={innerCSS || ''}>
                {body}
              </div>
              <div>
                {showButton &&
                  <button className="tcp-loader-overlay-button button" onClick={this.onButtonClicked}>
                    {buttonText || ''}
                  </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
