// Utils
import { getDerivedHook } from '@toolkit/util/state-utils/state.util';
// Constants
import { DIRECTION } from '@src/shared/src/const/app';
// Actions
// Models
import { TripModel } from '@src/shared/src/models';
// Interfaces
// Subscriptions
import * as tripSubscriptions from './trip.subscriptions';
// Queries

export const useAllowanceMatchingTrip = (direction: DIRECTION) =>
  getDerivedHook<TripModel>(() => tripSubscriptions.allowanceMatchingTrip(direction));

export const useTripsCount = (direction: DIRECTION) =>
  getDerivedHook<number>(() => tripSubscriptions.tripsCount(direction));

export const useFilteredTripsCount = (direction: DIRECTION) =>
  getDerivedHook<number>(() => tripSubscriptions.filteredTripsCount(direction));
