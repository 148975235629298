// Utils
import {
  contains,
  filter,
  flatten,
  innerJoin,
  map,
  partial,
  pipe,
  pluck,
  uniq,
  values,
} from '../util/general';
import { getMainLegsHeadTS, getMainLegsTS } from '../util/trips';
// Constants
import { VEHICLE_TYPES } from '../const/app';
// Actions
// Models
import { TripModel, OptionModel } from '../models';
// Interfaces
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';

export default class TripTransportationFilter implements IFilter<TripModel, OptionModel[], OptionModel[]> {
  private constructor() {}

  private static instance:TripTransportationFilter;

  static getInstance = () => {
    if (!TripTransportationFilter.instance) {
      TripTransportationFilter.instance = new TripTransportationFilter();
    }
    return TripTransportationFilter.instance;
  }

  public id:string = 'TripTransportationFilter';

  private getVehicles = pipe(
    values,
    pluck('legs'),
    flatten,
    getMainLegsTS,
    pluck('vehicles'),
    flatten,
    uniq,
    filter((vehicle:string) => vehicle !== VEHICLE_TYPES.FOOT)
  )

  public getConstraints = (
    opts:ConstraintsOpts<TripModel, OptionModel[], OptionModel[]>
  ):OptionModel[] => {
    const availableVehicles:OptionModel[] = map(
      (vehicle:string) => ({ value: true, label: vehicle }), this.getVehicles(opts.values));

    if(opts.reinit) {
      if (opts.onlyUpdateConstraint) {
        return opts.constraint;
      }
      return availableVehicles;
    }

    return innerJoin(
      (opt:OptionModel, vehicle:OptionModel) => opt.label === vehicle.label,
      opts.userConstraint,
      availableVehicles,
    );
  }

  public process = (constraint:OptionModel[], trips:TripModel[]) =>
    filter(partial(this.match, [constraint]), trips);

  private match = (constraint:OptionModel[], trip:TripModel) =>
    contains(
      getMainLegsHeadTS(trip.legs).vehicles[0],
      pluck('label', constraint.filter((opt) => opt.value))
    );
}
