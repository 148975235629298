import * as React from 'react';
// Utils
import { t } from '@toolkit/util/i18n';

// Constants
import { SEARCH_BAR_RENTAL_LINK_TYPE } from '@toolkit/const/app';

// Actions
// Models
import { UserModel } from '@src/shared/src/models';
// Interfaces
// Components
import { Modal, Tab } from '@toolkit/ui';
// Styles
import '../styles/SearchBarConcierge.scss';
import {
  AirportShuttleIcon,
  CarRentalIcon,
  ConnectingAirportsIcon,
  DirectionsBoatIcon,
  GroupAddIcon,
  KingBedIcon,
  LocalParkingIcon,
  SearchIcon,
} from '@toolkit/ui/icons';

declare const window: any;

type Props = {
  uiSearchConciergeOverlay: boolean;
  onSetUiSearchConciergeOverlay: (value: string, type?: boolean) => void;
  profile: UserModel;
};

const SearchBarConcierge: React.FC<Props> = (props) => {
  const sendLoadingMessage = () => {
    if ((window as any).__IS_WEBVIEW) {
      window.isMobileWebView.postMessage('concierge_loading');
    }
  };

  const services = [
    {
      url: t('searchBarConcierge.link', {
        email: props.profile.email,
        type: SEARCH_BAR_RENTAL_LINK_TYPE.MULTI_STOP,
      }),
      label: t('searchBarConcierge.title.multi_stop'),
      icon: <ConnectingAirportsIcon />,
      desc: t('searchBarConcierge.label.multi_stop'),
    },
    {
      url: t('searchBarConcierge.link', {
        email: props.profile.email,
        type: SEARCH_BAR_RENTAL_LINK_TYPE.SHUTTEL,
      }),
      label: t('searchBarConcierge.title.transit'),
      icon: <AirportShuttleIcon />,
      desc: t('searchBarConcierge.label.transit'),
    },
    {
      url: t('searchBarConcierge.link', {
        email: props.profile.email,
        type: SEARCH_BAR_RENTAL_LINK_TYPE.SPECIALHOTELS,
      }),
      label: t('searchBarConcierge.title.special_hotel'),
      icon: <KingBedIcon />,
      desc: t('searchBarConcierge.label.special_hotel'),
    },
    {
      url: t('searchBarConcierge.link', {
        email: props.profile.email,
        type: SEARCH_BAR_RENTAL_LINK_TYPE.AIRPORTPARKING,
      }),
      label: t('searchBarConcierge.title.airport_parking'),
      icon: <LocalParkingIcon />,
      desc: t('searchBarConcierge.label.airport_parking'),
    },
    {
      url: t('searchBarConcierge.link', {
        email: props.profile.email,
        type: SEARCH_BAR_RENTAL_LINK_TYPE.RENTAL,
      }),
      label: t('searchBarConcierge.title.rental'),
      icon: <CarRentalIcon />,
      desc: t('searchBarConcierge.label.rental'),
    },
    {
      url: t('searchBarConcierge.link', {
        email: props.profile.email,
        type: SEARCH_BAR_RENTAL_LINK_TYPE.FERRY,
      }),
      label: t('searchBarConcierge.title.ferry'),
      icon: <DirectionsBoatIcon />,
      desc: t('searchBarConcierge.label.ferry'),
    },
    {
      url: t('searchBarConcierge.link', {
        email: props.profile.email,
        type: SEARCH_BAR_RENTAL_LINK_TYPE.GROUPTRAVEL,
      }),
      label: t('searchBarConcierge.title.group_travel'),
      icon: <GroupAddIcon />,
      desc: t('searchBarConcierge.label.group_travel'),
    },
    {
      url: t('searchBarConcierge.link', {
        email: props.profile.email,
        type: SEARCH_BAR_RENTAL_LINK_TYPE.OTHER,
      }),
      label: t('searchBarConcierge.title.other'),
      icon: <SearchIcon />,
      desc: t('searchBarConcierge.label.other'),
    },
  ];

  return (
    <>
      <div
        className="lp-search-bar-concierge"
        onClick={() => props.onSetUiSearchConciergeOverlay('concierge')}>
        <div className="lp-search-bar-concierge-image" />
        <div className="lp-search-bar-concierge-inner">
          <Tab
            label={t('searchBarConcierge.title.service')}
            onClick={() => props.onSetUiSearchConciergeOverlay('concierge')}
          />
          <div className="lp-search-bar-concierge-desc">{t('searchBarConcierge.description')}</div>
        </div>
      </div>
      <Modal
        isOpen={props.uiSearchConciergeOverlay}
        onClose={() => props.onSetUiSearchConciergeOverlay('concierge', false)}
        title={t('searchBarConcierge.title.service')}>
        {props.profile && (
          <div className="lp-search-bar-concierge-services">
            {services.map((service) => (
              <a
                className="lp-search-bar-concierge-item"
                target="_blank"
                onClick={() => sendLoadingMessage()}
                href={service.url}
                key={service.url}>
                <div className="lp-search-bar-concierge-icon">{service.icon}</div>
                <div>
                  <div className="lp-search-bar-concierge-title">{service.label}</div>
                  {service.desc}
                </div>
              </a>
            ))}
          </div>
        )}
      </Modal>
    </>
  );
};
export default SearchBarConcierge;
