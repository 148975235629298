import * as i18n from 'i18next';
import * as LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import { errorLogger } from '@src/shared/src/util/errors';
const resources = require('../../locales');

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: {
      'de_de_all4one': ['de', 'en'],
      'default': ['en'],
    },
    appendNamespaceToCIMode: true,
    saveMissing: true,
    missingKeyHandler: (lng:string[], ns:string, key:string, fallbackValue:string) => {
      errorLogger('MissingTranslationKey', new Error(key), null, key);
    },

    // have a common namespace used around the full app
    ns: ['main'],
    defaultNS: 'main',

    keySeparator: false,
    nsSeparator: false,

    debug: false,

    react: {
      wait: true,
      nsMode: 'default',
    },
  });


export const t = i18n.t;

export const nsValue = '';

export default i18n;
