import { isSameDay } from 'date-fns';

// Utils
// Constants
import { SEARCH_TYPE } from "../const/app";
// Actions
// Models
import { SearchModel } from "../models";
// Interfaces

export const isRentalAndPickupSame = (search:SearchModel) =>
  search.searchType === SEARCH_TYPE.RENTAL && search.rentalReturnAtPickup;

export const getSearchTypesForBE = (search: SearchModel) => {
  // TODO: put this in search.subscriptions or search.queries when we remove 'shared'
  const sameDay = isSameDay(search.depAt as Date, search.arrAt as Date);
  switch (search.searchType) {
    case SEARCH_TYPE.ALL: return sameDay ? ['transport'] : ['transport', 'hotel'];
    case SEARCH_TYPE.HOTEL: return ['hotel'];
    case SEARCH_TYPE.OUTBOUND: return ['transport'];
    case SEARCH_TYPE.RENTAL: return ['rental'];
    default: return ['transport'];
  }
};
