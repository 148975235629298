import useMediaQuery from "./useMediaQuery";
import {mediaBreakpoints} from "./style";
/**
 * Get a set of boolean representing which breakpoint is active
 * and which breakpoints are inactive.
 *
 * Inspired by: https://github.com/contra/react-responsive/issues/162#issuecomment-592082035
 */
export default function useBreakpoints() {
  const breakpoints = {
    isMd: useMediaQuery(`(max-width: ${mediaBreakpoints.md}px)`),
    isLg: useMediaQuery(`(max-width: ${mediaBreakpoints.lg}px)`),
    isXl: useMediaQuery(`(max-width: ${mediaBreakpoints.xl}px)`),
    active: "md"
  };
  if (breakpoints.isMd) breakpoints.active = "md";
  if (breakpoints.isLg) breakpoints.active = "lg";
  if (breakpoints.isXl) breakpoints.active = "xl";
  return breakpoints;
}
