import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
// Interfaces
// Components
import { WarningBanner } from '@toolkit/ui';

type Props = {
  requiresAllowance:boolean;
}

export const RequiresAllowanceWarning:React.FC<Props> = (props) => {
  if (props.requiresAllowance) {
   return (
    <WarningBanner
        icon="lp-icon-help"
        isInfo={true}
        label={
          <div>
            <p>
              <strong>{t('requiresAllowanceWarning.requestAllownace.title')}</strong>
            </p>
            <p>
              {t('requiresAllowanceWarning.requestAllowance.message')}
            </p>
          </div>
        }
      />
   );
  } else {
    return null;
  }
}
