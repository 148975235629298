import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { getSelectedTariff } from '@src/shared/src/util/trips';
import {
  cond,
  equals,
  verifyProperty,
} from '@src/shared/src/util/general';
import { baseErrorCond } from '@toolkit/util/app';
import { getTariffRequestSeatReservation, getTariffBasketItemId } from '@src/shared/src/util/tariffs';
// Constants
import {
  ENVIRONMENT,
  VEHICLE_TYPES,
} from '@src/shared/src/const/app';
// Actions
import { requestSeatReservationAsync } from '@src/shared/src/actions/basketActions';
import { changeTariffAsync } from '@src/shared/src/actions/tripsActions';
import { setUiTripChangingTariffsloader } from '@pod/ui/actions';
import { selectors } from '@src/shared/src';
// Models
import { TariffModel, BasketItemModel } from '@src/shared/src/models';
// Interfaces
import {
  ConnectedRedux,
  IRootState,
} from '@src/store';
// Components
import TariffOptions from './TariffOptions';
import TariffFareTerms from './TariffFareTerms';
import { Spinner } from '@toolkit/ui';
// Styles

type Props = ConnectedRedux<IRootState> & {
  tripId:number;
  env:ENVIRONMENT;
  changeable:boolean;
  vehicle:VEHICLE_TYPES;
  tariffs:TariffModel[];
  uiTripChangingTariffsloader:boolean;
  profileLocale:string;
  isBasketEditable:boolean;
  basketItems: BasketItemModel[];
};

class TariffDetails extends React.PureComponent<Props> {

  public componentDidUpdate(prevProps:Props) {
    if (!equals(prevProps.tariffs, this.props.tariffs)) {
      this.props.dispatch(setUiTripChangingTariffsloader(false));
    }
  }

  private onHandleChangeTariff = (tariffId:string) => {
    this.props.dispatch(setUiTripChangingTariffsloader(true));
    this.props.dispatch(changeTariffAsync.request({
      onError: cond(baseErrorCond),
      tariffId,
      tripId: this.props.tripId,
    }));
  }

  private setRequestSeatReservation = (value: boolean, basketItemId: number) => {
    this.props.dispatch(requestSeatReservationAsync.request({
      basketItemId,
      requestSeatReservation: value,
      onError: cond(baseErrorCond),
    }))
  }

  private getTariffFareTermsMarkup = (selectedTariff:TariffModel, vehicle:VEHICLE_TYPES) => {
    if (this.props.uiTripChangingTariffsloader) {
      return <Spinner />;
    } else {
      const basketItemId = getTariffBasketItemId(this.props.basketItems, selectedTariff.id);
      const requestSeatReservation = getTariffRequestSeatReservation(this.props.basketItems, selectedTariff.id);
      return (
        <TariffFareTerms
          env={this.props.env}
          profileLocale={this.props.profileLocale}
          fareName={selectedTariff.fareName}
          fareTerms={selectedTariff.fareTerms}
          vehicle={vehicle}
          basketItemId={basketItemId}
          isBasketEditable={this.props.isBasketEditable}
          requestSeatReservation={requestSeatReservation}
          setRequestSeatReservation={(value) => this.setRequestSeatReservation(value, basketItemId)}/>
      );
    }
  }

  render() {
    const { env, changeable, tariffs, vehicle } = this.props;
    const selectedTariff = getSelectedTariff(tariffs);
    return (
      <React.Fragment>
        <TariffOptions
          env={env}
          changeable={changeable}
          tariffs={tariffs}
          selectedTariff={selectedTariff}
          onChangeTariff={this.onHandleChangeTariff}/>
        {selectedTariff && this.getTariffFareTermsMarkup(selectedTariff, vehicle)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state:IRootState) => ({
  uiTripChangingTariffsloader: state.ui.uiTripChangingTariffsloader,
  profileLocale: verifyProperty('', state.adminUser.profile, ['preference', 'language']),
  isBasketEditable: selectors.checkout.isBasketEditable(state.checkout),
  basketItems: selectors.checkout.basketItems(state.checkout),
});
export default connect(mapStateToProps)(TariffDetails);
