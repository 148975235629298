// Utils
import { filter, partial } from '../util/general';
// Constants
// Actions, Models & Interfaces
import { HotelModel } from '../models';
import { IHotelFilter } from '../interfaces';

export type HotelNameFilterConstraint = {
  current:string;
};

export default class HotelNameFilter implements IHotelFilter<HotelNameFilterConstraint> {
  private constructor() {}

  private static instance:HotelNameFilter;

  static getInstance = () => {
    if (!HotelNameFilter.instance) {
      HotelNameFilter.instance = new HotelNameFilter();
    }
    return HotelNameFilter.instance;
  }

  public id:string = 'HotelNameFilter';

  public getConstraints = (_:HotelModel[]):HotelNameFilterConstraint => ({
    current: '',
  })

  public process = (constraint:HotelNameFilterConstraint, hotels:HotelModel[]) =>
    constraint.current === '' ? hotels : filter(partial(this.match, [constraint]), hotels)

  private match = (constraint:HotelNameFilterConstraint, hotel:HotelModel) =>
    hotel.name.toLowerCase().includes(constraint.current.toLowerCase())
}
