import * as React from 'react';
import '../styles/TripPrice.scss';

// Utils
import { formatCents } from '@src/shared/src/util/general';
// Constants
import { ENVIRONMENT, CENTS_CONVERSION_TYPES } from '@src/shared/src/const/app';
// Services
// Models, Stores & Interfaces
// Components
interface IProps {
  tripBaseTotal:number;
  env: ENVIRONMENT;
}

const TripPrice: React.FC<IProps> = (props) => {
  if (props.env === ENVIRONMENT.SEARCH) {
    return (
      <strong>
        {
          formatCents(
            props.tripBaseTotal,
            props.env === ENVIRONMENT.SEARCH ? CENTS_CONVERSION_TYPES.FLOOR : CENTS_CONVERSION_TYPES.NONE
          )
        }
      </strong>
    );
  } else {
    return null;
  }
};

export default TripPrice;
