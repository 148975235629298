import * as React from 'react';

import { ACCOMMODATION_TYPES, CENTS_CONVERSION_TYPES, ENVIRONMENT } from '@src/shared/src/const/app';
import { formatCents } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import { isEnvSearch } from '@src/util/app';
import { useSelector } from 'react-redux';
import { isAnyBookingItemAirbnb } from '@src/shared/src/selectors/checkoutSelectors';

type Props = {
  price: number;
  pricePerNight?: number;
  env: ENVIRONMENT;
  accommodationType: ACCOMMODATION_TYPES;
  currencyChanged?: boolean;
};

export const HotelPrice: React.FC<Props> = ({ price, pricePerNight, env, accommodationType, currencyChanged }) => {
  const bookingHasAirbnb = useSelector(isAnyBookingItemAirbnb)

  // Do not round off when in search
  const hotelPrice = formatCents(price, isEnvSearch(env) ? CENTS_CONVERSION_TYPES.FLOOR : CENTS_CONVERSION_TYPES.NONE)
  const hotelPriceTooltip = accommodationType === ACCOMMODATION_TYPES.VACATION_RENTAL ? t('hotel-price.tooltip.vacation_rental') : null;
  const showPricePerNight = isEnvSearch(env) && pricePerNight;
  const showCurrencyChanged = !isEnvSearch(env) && currencyChanged && !bookingHasAirbnb

  return (
    <>
      <div>
        <strong>{hotelPrice}</strong>
        &nbsp;{hotelPriceTooltip && <i data-rh={hotelPriceTooltip} data-rh-at="left" className="icon-info_outline" />}
      </div>
      {showPricePerNight &&
        <div className="is-font-medium">
          {t('hotelPrice.label.pricePerNight',
            { price: formatCents(pricePerNight, CENTS_CONVERSION_TYPES.FLOOR) })}
        </div>}
      {showCurrencyChanged &&
        <span data-rh={t('hotel.tooltip.body.text.differentCurrency')} data-rh-at="left" className="is-font-small tcp-tooltip-header-crossed">EUR</span>
      }
    </>
  )
}
