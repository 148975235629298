import * as React from 'react';
import reduxForm from 'redux-form/es/reduxForm';
import Field from 'redux-form/es/Field';
import { FormProps } from 'redux-form/es/Form';


// Utils
import { t } from '@toolkit/util/i18n';
import {
  required,
} from '@toolkit/util/formsValidation';
// Constants
// Actions
// Models
// Interfaces
// Components
import {
  Button,
  FormInput,
} from '@toolkit/ui';
// Styles
import '../styles/LoginForm.scss';

type Props = FormProps & {
  onEditEmail: () => void;
};

const PasswordForm: React.FC<Props> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="tcp-login-form-field">
        <Field
          className="tcp-login-form-text"
          name="password"
          component={FormInput}
          validate={required}
          type="password"
          placeholder={t('loginForm.form.label.password')}/>
      </div>
      <div className="tcp-login-form-field tcp-login-form-field-group">
        <div className="tcp-login-edit-email-link is-underlined" onClick={props.onEditEmail}>
          <i className="icon-arrow_back"/> Edit Email
        </div>
        <label>
          <Field className="" id="remember_me" name="remember_me" component="input" type="checkbox"/>
          {t('loginForm.form.label.rememberMe')}
        </label>
      </div>
      <Button type="submit" className="button">{t('loginForm.form.button.login')}</Button>
    </form>
  );
};

export default reduxForm({
  form: 'loginPwd',
})(PasswordForm);
