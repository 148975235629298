// Utils
import {
  find,
  pathOr,
  propEq,
} from './general';
// Constants
// Actions, Models & Interfaces
import { BasketItemModel } from '../models';

const findRentalBaksetItem = (tariffId:number) => find(propEq('rentalFareId', tariffId));

export const getRentalTariffDocuments = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['travelBookingItem', 'visibleDocuments'], findRentalBaksetItem(tariffId)(basketItems));

export const getRentalTariffOrderNumber = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['travelBookingItem', 'orderNumber'], findRentalBaksetItem(tariffId)(basketItems));
