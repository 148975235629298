import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('RentalVehicleModel')
export default class RentalVehicleModel {
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('air_condition', Boolean) airCondition:boolean = false;
  @JsonProperty('automatic_transmission', Boolean) automaticTransmission:boolean = false;
  @JsonProperty('baggage_big', Number) baggageBig:number = -1;
  @JsonProperty('baggage_small', Number) baggageSmall:number = -1;
  @JsonProperty('body_style', String) bodyStyle:string = undefined;
  @JsonProperty('doors_count', Number) doorsCount:number = -1;
  @JsonProperty('group', String) group:string = undefined;
  @JsonProperty('navigation_system_available', Boolean) navigationSystemAvailable:boolean = false;
  @JsonProperty('navigation_system_included', Boolean) navigationSystemIncluded:boolean = false;
  @JsonProperty('passenger_count', Number) passengerCount:number = -1;
}
