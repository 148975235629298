import { getType } from 'typesafe-actions';

// Utils
// Constants
// Actions
import { AppSettingsActions, appSettingsActions } from '../actions';
// Models
import { AppSettingsModel } from '../models';
// Interfaces
// Components
// Styles

export interface IAppSettingsState {
  config:AppSettingsModel;
}

const initialState:IAppSettingsState = {
  config:undefined,
};

export default (state:IAppSettingsState = initialState, action:AppSettingsActions) => {
  switch (action.type) {
    case getType(appSettingsActions.setAppSettings):
      return { ...state, config: action.payload };
    default:
      return state;
  }
};