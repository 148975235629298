declare const __RELEASE_STAGE__: string;
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';

// Utils
import { isNil, isNotNilOrEmpty } from '@src/shared/src/util/general';
import { hashHistory, notifyError } from '@toolkit/util/app';

// Constants
import { ROUTES } from '@toolkit/const/app';
import { LANG } from '@src/shared/src/const/app';
import { API_URL, ZENDESK_MESSENGER_KEYS } from '@src/shared/src/const/api';
// Actions
import { appSettingsActions, adminUserActions } from '@src/shared/src/actions';
// Models
// Interfaces
import { IRootState } from '@src/store';
// Components
import { AuthRouter, SessionRouter } from '@src/routes';
import { LoaderOverlay } from '@toolkit/ui';
// Styles

const App: React.FC = () => {
  const dispatch = useDispatch();

  const config = useSelector((state: IRootState) => state.appSettings.config);
  const profile = useSelector((state: IRootState) => state.adminUser?.profile);

  React.useEffect(() => {
    if (isNil(config)) {
      dispatch(
        appSettingsActions.fetchAppSettingsAsync.request({
          onError: () => notifyError(['notifyError.fetchAppSettings.text']),
        }),
      );
    }
  }, []);

  React.useEffect(() => {
    // Load Zendesk Messenger
    if (profile) {
      const scriptId = 'ze-snippet';
      const scriptElement = document.getElementById(scriptId);
      const zendeskInstance = __RELEASE_STAGE__ === 'production' ? 'production' : 'sandbox';
      const locale = profile.preference.language === LANG.DE_DE ? LANG.DE_DE : LANG.EN_US;
      const widgetKey = ZENDESK_MESSENGER_KEYS[zendeskInstance][locale];
      // Do not load the widget if it's already loaded or if the widget key is missing
      if (isNotNilOrEmpty(widgetKey) && !scriptElement) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src = `${API_URL.ZENDESK_MESSENGER_SNIPPET}?key=${widgetKey}`;
        document.body.appendChild(script);
        dispatch(adminUserActions.zendeskWidgetLoaded(profile.zendeskToken, locale));
      }
    }
  }, [profile]);

  if (isNil(config)) {
    return (
      <div>
        <LoaderOverlay title="Loading..." showSpinner={true} />
      </div>
    );
  }

  return (
    <Router history={hashHistory}>
      <Switch>
        <Route path={ROUTES.AUTH.DEFAULT} component={AuthRouter} />
        <Route path={ROUTES.HOME} component={SessionRouter} />
      </Switch>
    </Router>
  );
};

export default App;
