import * as React from 'react';

// Utils
import {
  find,
  pipe,
} from '@src/shared/src/util/general';
import { getUserFullname } from '@src/shared/src/util/users';
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
import { PassengerModel } from '@src/shared/src/models';
// Interfaces
// Interfaces
// Components
// Styles

type Props = {
  passengers:PassengerModel[]
}

const RentalDriversInfo:React.FC<Props> = (props:Props) => {
  return (
    <div>
      <div className="lp-rental-details-inner-title">
        {t('RentalDescription.label.drivers')}
      </div>
      <div className="lp-rental-details-inner-car_option">
        {pipe(
          find((pass:PassengerModel) => pass.isFirst),
          getUserFullname,
        )(props.passengers)
        }
      </div>
    </div>
  );
}

export default RentalDriversInfo;
