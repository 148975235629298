import * as React from 'react';
import { GoogleMap } from '@react-google-maps/api';

// Utils
import { cond, gte, T, always, partial } from '@src/shared/src/util/general';
import { getHotelsResultsByRating } from '@toolkit/util/app';
// Constants
// Actions, Models & Interfaces
import { HotelModel, CoordinatesModel } from '@src/shared/src/models';
// Components
import HotelMapMarker from './HotelMapMarker';
import HotelDestMarker from './HotelDestMarker';
// Styles
import '../styles/HotelMap.scss';

type Props = {
  handleViewOffer:(hotelId:string) => void;
  showHotelsList:() => void;
  hotels:HotelModel[];
  center:CoordinatesModel;
  location:CoordinatesModel;
};

type State = {
  map:google.maps.Map;
  filteredHotels:HotelModel[];
  displayHotelTooltip:string;
};

class HotelsMap extends React.Component<Props, State> {
  readonly state:State = {
    map: null,
    filteredHotels: [],
    displayHotelTooltip: null
  };

  private filterHotelsbyRatingAndZoomLevel = (hotels:HotelModel[]) => {
    const currentZoom = this.state.map?.getZoom?.();
    // Zoom : Out(8) => In(16)
    const getHotelsByZoom = cond([
      [gte(8), partial(getHotelsResultsByRating, [hotels, 10])],
      [gte(9), partial(getHotelsResultsByRating, [hotels, 9])],
      [gte(10), partial(getHotelsResultsByRating, [hotels,8 ])],
      [gte(11), partial(getHotelsResultsByRating, [hotels, 7])],
      [gte(12), partial(getHotelsResultsByRating, [hotels, 6])],
      [gte(13), partial(getHotelsResultsByRating, [hotels, 5])],
      [gte(14), partial(getHotelsResultsByRating, [hotels, 4])],
      [gte(15), partial(getHotelsResultsByRating, [hotels, 3])],
      [T, always(hotels)],
    ]);
    return getHotelsByZoom(currentZoom);
  }

  public componentDidMount() {
    this.setState({
      filteredHotels: this.filterHotelsbyRatingAndZoomLevel(this.props.hotels),
    });
  }

  public componentWillReceiveProps(nextProps:Props) {
    if (this.props.hotels !== nextProps.hotels) {
      this.setState({
        filteredHotels: this.filterHotelsbyRatingAndZoomLevel(nextProps.hotels),
      });
    }
  }

  private onZoomChanged = () => {
    if (this.state.map)
      this.setState({
        filteredHotels: this.filterHotelsbyRatingAndZoomLevel(this.props.hotels),
      });
  };

  private handleViewOffer = (hotelId:string) => {
    this.props.handleViewOffer(hotelId);
    this.props.showHotelsList();
  }

  private handleTooltip = (hotelId:string) => {
    this.setState({ displayHotelTooltip: this.state.displayHotelTooltip === hotelId ? null : hotelId });
  }

  public render() {
    const mapOptions = {
      minZoom: 8,
      maxZoom: 17,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    };
    return (
      <div className="map-container">
        <div className="map-element">
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            zoom={12}
            clickableIcons={false}
            center={this.props.center}
            options={mapOptions}
            onZoomChanged={this.onZoomChanged}
            onLoad={(map) => this.setState({ map })}
            onUnmount={() => this.setState({ map: null })}>
            {this.state.filteredHotels.map((hotel) => (
              <HotelMapMarker key={hotel.id} hotel={hotel} onShowDetails={this.handleViewOffer} onHandelTooltip={this.handleTooltip} isTooltipOpen={this.state.displayHotelTooltip === hotel.id}/>
            ))}
            <HotelDestMarker position={this.props.location} />
          </GoogleMap>
        </div>
      </div>
    );
  }
}

export default HotelsMap;
