import * as React from 'react';
import { t } from '@toolkit/util/i18n';

export interface IProps {
  title:string;
  currentValue:string;
  onChange:(value:string) => void;
}

export interface IState {
  isOpen:boolean;
  currentValue:string;
}

export default class InputFilter extends React.Component<IProps, IState> {
  public state: IState = {
    isOpen: true,
    currentValue: this.props.currentValue
  };

  private toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  private onChangeInput = (e:any) => {
    this.setState({
      currentValue: e.target.value
    });
    this.props.onChange(e.target.value);
  }

  public render() {
    return (
      <div className={`sidebar-section ${this.state.isOpen ? 'is-open' : ''}`}>
        <button className="sidebar-section-title" onClick={this.toggleOpen}>
          {this.props.title}
          <i className="sidebar-section-indicator" aria-hidden="true" />
        </button>
        <div className="sidebar-section-inner">
          <div className="search-field">
              <input
                type="text"
                value={this.state.currentValue}
                onChange={this.onChangeInput}
                placeholder={t('Hotel.InputFilter.placeholder')}/>
          </div>
        </div>
      </div>
    );
  }
}
