import * as React from 'react';
import classNames from 'classnames';
import Select from 'react-select';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNil } from '@src/shared/src/util/general';
import { getShuttleIcon } from '@toolkit/util/app';
// Constants
import { TRANSIT_TYPES } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { OptionModel } from '@src/shared/src/models';
// Components
// Styles
import '../styles/TripShuttleTypeSelector.scss';

type Props = {
  onSelectDep: any;
  onSelectArr: any;
  arrivalShuttle: string;
  departureShuttle: string;
  isTripsLoading: boolean;
  isDisabled: boolean;
};
type State = {
  isOpen: boolean;
};

export default class TripShuttleTypeSelector extends React.PureComponent<Props, State> {
  readonly state: State = {
    isOpen: isNil(this.props.isTripsLoading) ? true : this.props.isTripsLoading,
  };

  shuttlesOptions = (): OptionModel[] => [
    { value: TRANSIT_TYPES.PUBLIC_TRANSIT, label: t('TripShuttleTypeSelector.select.option.publicTransit') },
    { value: TRANSIT_TYPES.TAXI, label: t('TripShuttleTypeSelector.select.option.taxi') },
    { value: TRANSIT_TYPES.NONE, label: t('TripShuttleTypeSelector.select.option.none') },
  ];

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  getOptionMarkup = (opt: OptionModel) => (
    <div>
      <i className={`tcp-sidebar-route-icon ${getShuttleIcon(opt.value)}`} />
      {opt.label}
    </div>
  );

  render() {
    const { isDisabled } = this.props;
    const isOpen = this.props.isTripsLoading ? false : this.state.isOpen;

    const sidebarSectionClass = classNames('tcp-trip-shuttle-type-selector', {
      'sidebar-section': true,
      'is-open': isOpen,
    });
    const indicatorStyle = classNames('sidebar-section-indicator', {
      'sidebar-section-indicator-disabled': isDisabled,
    });

    return (
      <div className={sidebarSectionClass}>
        <button className="sidebar-section-title" onClick={isDisabled ? null : this.toggleOpen}>
          {t('tripShuttleTypeSelector.title')}
          <i className={indicatorStyle} aria-hidden="true" />
        </button>
        <div className="sidebar-section-inner">
          <div className="tcp-trip-shuttle-type-selector">
            <span className="tcp-trip-shuttle-type-selector-transit-title">
              {t('tripShuttleTypeSelector.select.label.atDeparture')}
            </span>
            <Select
              clearable={false}
              backspaceRemoves={false}
              deleteRemoves={false}
              className="tcp-trip-shuttle-type-selector-select"
              valueRenderer={this.getOptionMarkup}
              optionRenderer={this.getOptionMarkup}
              options={this.shuttlesOptions()}
              value={this.props.departureShuttle}
              onChange={nOpt => {
                if (this.props.departureShuttle !== nOpt.value) {
                  this.props.onSelectDep(nOpt.value);
                }
              }}
            />
          </div>
          <div className="tcp-trip-shuttle-type-selector">
            <span className="tcp-trip-shuttle-type-selector-transit-title">
              {t('tripShuttleTypeSelector.select.label.atArrival')}
            </span>
            <Select
              clearable={false}
              backspaceRemoves={false}
              deleteRemoves={false}
              className="tcp-trip-shuttle-type-selector-select"
              valueRenderer={this.getOptionMarkup}
              optionRenderer={this.getOptionMarkup}
              options={this.shuttlesOptions()}
              value={this.props.arrivalShuttle}
              onChange={nOpt => {
                if (this.props.arrivalShuttle !== nOpt.value) {
                  this.props.onSelectArr(nOpt.value);
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
