import * as React from 'react';

// Utils
import {
  formatCents,
} from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import '../styles/CheckoutPrice.scss';

type Props = {
  label:string;
  price:number;
  children?:any;
};

const ItemPrice:React.FC<Props> = (props) =>
  <div className="lp-checkout-price">
    <div className="lp-checkout-price-label">{props.label}</div>
    <div className="lp-checkout-price-number">{formatCents(props.price)}</div>
  </div>

export default ItemPrice;
