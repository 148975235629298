import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { findBySelected, pathOr } from '@src/shared/src/util/general';
import {
  getHotelTariffDocuments,
  getHotelTariffOrderNumber,
} from '@src/shared/src/util/hotels';

// Constants
import { ENVIRONMENT, STATUS } from '@src/shared/src/const/app';

// Models
import { HotelModel, BasketItemModel } from '@src/shared/src/models';

// Actions
import { selectors } from '@src/shared/src';

// Components
import { BookedItemInfo } from '@src/pod/checkout/components';

// Interfaces
import { IRootState, ConnectedRedux } from '@src/store';


type Props = ConnectedRedux<IRootState> & {
  hotel:HotelModel;
  env:ENVIRONMENT;
  basketItems:BasketItemModel[];
  basketStatus:STATUS;
};


class BookedHotelItemInfo extends React.PureComponent<Props> {
  render ()
  {
    const { env, basketStatus, basketItems, hotel } = this.props;
    const hotelSelectedFareId:number = Number(pathOr(-1, ['id'], findBySelected(hotel.fares)));
    if (basketStatus === STATUS.CONFIRM_FINISHED) {
      return (
        <BookedItemInfo
          env={env}
          bookingNumbers={getHotelTariffOrderNumber(basketItems, hotelSelectedFareId)}
          documents={getHotelTariffDocuments(basketItems, hotelSelectedFareId)}/>
      );
    } else {
      return '';
    }
  }
}

const mapState = (state:IRootState) => ({
  basketItems: selectors.checkout.basketItems(state.checkout),
  basketStatus: selectors.checkout.basketStatus(state),
});

export default connect(mapState)(BookedHotelItemInfo);
