// Utils
import { cond } from '@src/shared/src/util/general';
import { store } from '@src/store/store.config';
import { baseErrorCond, navigate } from '@toolkit/util/app';
// Constants
import { ROUTES } from '@toolkit/const/app';
// Actions
import {
  hotelActions,
} from '@src/shared/src/actions';
import { uiActions } from '@pod/ui';
// Models
// Interfaces
// Subscriptions
// Queries

const { dispatch } = store;

export const fetchHotelDetails = (hotelId: string) => {
  dispatch(hotelActions.fetchHotelDetailsAsync.request({
    onError: cond(baseErrorCond),
    hotelId,
  }));
};

export const selectHotel = (hotelId: string, hotelFareIds: string[]) => {
  const searchId = store.getState().search.id;

  dispatch(uiActions.setUiIsSelectingHotel(true));
  dispatch(hotelActions.addHotelFaresToBasketAsync.request({
    onSuccess: () => {
      dispatch(hotelActions.setSelectedHotelId(hotelId));
      dispatch(uiActions.setUiMobileNoScrolling(false));
      navigate(`${ROUTES.BOOKING.RESERVE}${searchId}`);
    },
    onError: (err) => {
      dispatch(hotelActions.setSelectedHotelId(hotelId));
      return cond(baseErrorCond)(err);
    },
    hotelFareIds,
  }));
};
