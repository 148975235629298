import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { findBySelected, pathOr } from '@src/shared/src/util/general';
import {
  getRentalTariffOrderNumber,
  getRentalTariffDocuments,
} from '@src/shared/src/util/rentals';
// Constants
import { ENVIRONMENT, STATUS } from '@src/shared/src/const/app';
// Models
import { RentalModel, BasketItemModel } from '@src/shared/src/models';
// Actions
import { selectors } from '@src/shared/src';
// Components
import { BookedItemInfo } from '@src/pod/checkout/components';
// Interfaces
import { IRootState, ConnectedRedux } from '@src/store';


type Props = ConnectedRedux<IRootState> & {
  rental:RentalModel;
  env:ENVIRONMENT;
  basketItems:BasketItemModel[];
  basketStatus:STATUS;
};


class BookedHotelItemInfo extends React.PureComponent<Props> {
  render () {
    const { rental, env, basketStatus, basketItems } = this.props;
    const rentalSelectedFareId:number = Number(pathOr(-1, ['id'], findBySelected(rental.fares)));
    if (basketStatus === STATUS.CONFIRM_FINISHED) {
      return (
        <BookedItemInfo
          env={env}bookingNumbers={getRentalTariffOrderNumber(basketItems, rentalSelectedFareId)}
          documents={getRentalTariffDocuments(basketItems, rentalSelectedFareId)}/>
      );
    } else {
      return '';
    }
  }
}

const mapState = (state:IRootState) => ({
  basketItems: selectors.checkout.basketItems(state.checkout),
  basketStatus: selectors.checkout.basketStatus(state),
});

export default connect(mapState)(BookedHotelItemInfo);
