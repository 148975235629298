import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

// Utils
import {
  path
} from '@src/shared/src/util/general';
// Constants
// Actions
import { checkoutActions } from '@src/shared/src/actions';
// Models
import { TravelBookingModel } from '@src/shared/src/models';
// Interfaces
import { IConnectedRedux, IRootState } from '@src/store';
// Components
import { CheckoutConn } from '@pod/checkout';
// Styles

type Props = IConnectedRedux<IRootState> & {
  travelBooking:TravelBookingModel;
};

class CheckoutRouter extends React.Component<Props> {
  componentDidMount() {
    this.initCheckout();
  }

  componentDidUpdate(prevProps:Props) {
    if (path(['travelBooking', 'id'], prevProps) !== path(['travelBooking', 'id'], this.props)) {
      this.initCheckout();
    }
  }

  private initCheckout = () => {
    if (this.props.travelBooking) {
      this.props.dispatch(
        checkoutActions.initBookings(this.props.travelBooking.id)
      );
    }
  }

  render() {
    return (
      <div>
        <Route render={(props: any) => <CheckoutConn {...props}/>}/>
      </div>
    );
  }
}
const mapState = (state:IRootState) => ({
  travelBooking: state.checkout.travelBooking,
});
export default connect(mapState)(CheckoutRouter);
