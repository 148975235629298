import * as React from 'react';
import { useSelector } from 'react-redux';

import { TariffFareTermsModel } from '@src/shared/src/models';
import { LANG } from '@src/shared/src/const/app';
import { RootState } from '@src/shared/src/interfaces';
import { Tooltip } from '@toolkit/ui';
import { t } from '@toolkit/util/i18n';

type Props = {
  tariffFareTerms: TariffFareTermsModel;
}

const renderCityTicket = (iconClass: string, header: string, description: string) =>
  <Tooltip
    icon={iconClass}
    bodyLabel={header}
    alignLeft={true}
    bodyTextFirst={description}/>;

const getCityTicketDescription = (tariffFareTerms: TariffFareTermsModel, language: LANG, descriptionDirection: string) => {
  switch(language) {
    case LANG.EN_US: return tariffFareTerms[`en${descriptionDirection}CityTicketDescription`];
    default: return tariffFareTerms[`de${descriptionDirection}CityTicketDescription`];
  }
}

export const CityTicketTerms: React.FC<Props> = ({ tariffFareTerms }) => {
  if (tariffFareTerms.depCityTicket || tariffFareTerms.arrCityTicket) {

    const language = useSelector<RootState, string>(state => state.adminUser.profile.preference?.language) as LANG;

    return (
      <div className="city-ticket-indicator mt-1">
        <div className="city-ticket-indicator-tooltip">
          {tariffFareTerms.depCityTicket &&
            renderCityTicket('lp-icon-terms lp-icon-terms-city-ticket-dep', t('cityTicketTerms.header.dep') , getCityTicketDescription(tariffFareTerms, language, 'Dep'))}
          
          {tariffFareTerms.arrCityTicket &&
            renderCityTicket('lp-icon-terms lp-icon-terms-city-ticket-arr', t('cityTicketTerms.header.arr') , getCityTicketDescription(tariffFareTerms, language, 'Arr'))}
        </div>
      </div>
    )
  } else {
    return null;
  }
}
