import * as actioncable from '@rails/actioncable';
import { Observable } from 'rxjs';

// Utils
import {
  head,
  map,
  consoleLog
} from './general';
// Constants
import { API_URL, WS_CHANNELS, WS_ACTIONS } from '../const/api';
// Actions
// Models
// Interfaces
import { IACSubscription, IACAction } from '../interfaces';

actioncable.logger.enabled = true;
const actionCable = actioncable.createConsumer(API_URL.WEBSOCKET);

export const unsubscribeActionCable = () => {
  // Goes through each subscription and unsubscribes to it
  map((subscription:any) => {
    subscription.unsubscribe();
    consoleLog(`Unsubscribed to:${subscription.identifier}`);
  }, actionCable.subscriptions.subscriptions);
};

export const getWSStatus = () => actionCable.connection.isOpen();

export const ActionCableObservable = (sub:IACSubscription, act:IACAction) => {
  // Check if we already have a subscription with that identifier
  const ident = JSON.stringify(sub);
  const prevSubscription = actionCable.subscriptions.findAll(ident);
  if (prevSubscription.length > 0) { // Found a previous subscription with same identifier
    return new Observable((observer) => {
      head(prevSubscription).perform(act.action, act);
      head(prevSubscription).received = (val:any) => {
        observer.next(val);
      };
    });
  } else { // Return a new subscription
    return new Observable((observer) => {
      const subscription = actionCable.subscriptions.create(
        sub,
        {
          connected: () => {
            subscription.perform(act.action, act);
          },
          received: (val:any) => {
            observer.next(val);
          },
        }
      );
    });
  }
};

/**
 * Returns the ActionCable custom observable
 * @param {WS_CHANNELS} channel The websocket channel to subscribe
 * @param {number}           id The websocket channel subscription id
 * @param {WS_ACTIONS}   action The websocket channel action to perfrom after subscription
 * @param {string}     language Optional language param to get results in specific language
 * @param {string}    direction Optional direction param to send with subscription and action
 */
export const getACObservable = (channel:WS_CHANNELS, id:number, action:WS_ACTIONS, language?:string, direction?: string) =>
  ActionCableObservable(
    {
      channel,
      id,
      direction,
      language,
    },
    {
      action,
      id,
      direction,
    }
  );