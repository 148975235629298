import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { formatCents, isNil } from '@src/shared/src/util/general';
// Constants
import { ENVIRONMENT, SEAT_RES_STATUS } from '@src/shared/src/const/app';
// Actions
// Models
import { TariffFareTermsModel } from '@src/shared/src/models';
// Interfaces
// Components
import { Tooltip } from '@toolkit/ui';
import Switch from '@pod/filters/components/Switch';
// Styles

type Props = {
  fareTerms: TariffFareTermsModel;
  env: ENVIRONMENT;
  basketItemId: number;
  requestSeatReservation: boolean;
  setRequestSeatReservation: (value: boolean) => void;
  isBasketEditable: boolean;
};

const getSeatReservationLabel = (sr: string, srPrice: number) => {
  switch (sr) {
    case SEAT_RES_STATUS.INCLUDED:
      return (
        <div className="train-seat-res-value">
          {t('tariffFareTerms.label.seatReservation.included')}
        </div>
      );
    case SEAT_RES_STATUS.NOT_AVAILABLE:
      return (
        <div className="train-seat-res-value">
          <span className="train-seat-res-value-not_available">
            <i className="icon-warning" />
            {t('tariffFareTerms.label.seatReservation.notAvailable')}
          </span>
        </div>
      );
    case SEAT_RES_STATUS.AVAILABLE:
      return <div className="train-seat-res-value">{`+${formatCents(srPrice)}`}</div>;
  }
};

export const TrainSeatReservation: React.FC<Props> = (props: Props) => {
  if (isNil(props.fareTerms)) {
    return null;
  }

  const { seatReservation, seatReservationPrice } = props.fareTerms;
  if (
    seatReservation === SEAT_RES_STATUS.FRESH ||
    (props.env === ENVIRONMENT.SEARCH && seatReservation === SEAT_RES_STATUS.NOT_REQUESTED)
  ) {
    return null;
  }

  return (
    <div className="train-seat-res">
      <div className="train-seat-res-tooltip">
        <Tooltip
          icon="extra-icon icon-seating"
          alignLeft={true}
          label={t('tariffFareTerms.tooltip.title.seatReservation')}
          labelCSS="train-seat-res-label"
          bodyTextFirst={t('trainSeatReservation.tooltip.body.seatReservation')}
          isDisabled={
            props.env === ENVIRONMENT.CHECKOUT ||
            seatReservation === SEAT_RES_STATUS.INCLUDED ||
            seatReservation === SEAT_RES_STATUS.NOT_AVAILABLE ||
            !props.isBasketEditable
          }
        />
      </div>
      {getSeatReservationLabel(seatReservation, seatReservationPrice)}
      {props.env === ENVIRONMENT.CHECKOUT &&
        (seatReservation === SEAT_RES_STATUS.NOT_REQUESTED ||
          seatReservation === SEAT_RES_STATUS.AVAILABLE) && (
          <Switch
            isChecked={props.requestSeatReservation}
            onChange={props.setRequestSeatReservation}
            isDisabled={!props.isBasketEditable}
          />
        )}
    </div>
  );
};
