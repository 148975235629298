import * as React from 'react';
import { useState } from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
// Interfaces
// Components
// Styles

type Props = {
  id: string;
  label: string;
  isChecked: boolean;
  onChange: (value: boolean) => void;
  onChangeOnly?: (value: string) => void;
};
export const CheckboxFilter: React.FC<Props> = props => {
  const [showLinkOnly, setShowLinkOnly] = useState(false);
  return (
    <div>
      <div
        className="fac fac-checkbox-o fac-default"
        onMouseEnter={() => setShowLinkOnly(true)}
        onMouseLeave={() => setShowLinkOnly(false)}>
        <span/>
        <input
          id={props.id}
          type="checkbox"
          checked={props.isChecked}
          onChange={event => props.onChange(event.target.checked)}
        />
        <label htmlFor={props.id}>{props.label}</label>
        {showLinkOnly && (
          <button className="sidebar-filter-only" onClick={() => props.onChangeOnly(props.label)}>
            {t('checkboxFilter.btn.only')}
          </button>
        )}
      </div>
    </div>
  );
};
