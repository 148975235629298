import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { cond, verifyProperty } from '@src/shared/src/util/general';
import { clearLS } from '@toolkit/util/general';
import { navigate, baseErrorCond } from '@toolkit/util/app';
// Constants
// Actions
import { usersActions } from '@src/shared/src/actions';
// Models
import { UserModel, OrganizationModel, BasketModel } from '@src/shared/src/models';
// Interfaces
import { IRootState, ConnectedRedux } from '@src/store';
// Components
import { Header, HeaderMobile, Breadcrumbs } from '@pod/header/components';
// Styles
import '../styles/Navbar.scss';
import '../styles/Intercom.scss';
import { adminUserSelectors } from '@src/shared/src/selectors';
import useBreakpoints from '@toolkit/util/useBreakpoints';

type Props = ConnectedRedux<IRootState> & {
  profile: UserModel;
  organization: OrganizationModel;
  searchId: number;
  isSearchingForOnlyHotel: boolean;
  isSearchingForOnlyOutbound: boolean;
  isSearchingForOnlyRental: boolean;
  isSearchingForOnlyTransport: boolean;
  isCurrentUserNotTraveller: boolean;
  basket: BasketModel;
};

const HeaderConn: React.FC<Props> = (props) => {
  const { isMd } = useBreakpoints();

  const onLogout = () => {
    props.dispatch(
      usersActions.doLogoutAsync.request({
        onSuccess: () => {
          clearLS();
          navigate('/auth');
        },
        onError: cond(baseErrorCond),
      }),
    );
  };

  const {
    profile,
    organization,
    searchId,
    isSearchingForOnlyHotel,
    isSearchingForOnlyOutbound,
    isSearchingForOnlyRental,
    isSearchingForOnlyTransport,
    basket,
    isCurrentUserNotTraveller,
  } = props;

  return (
    <div>
      {isMd ? (
        <HeaderMobile
          currentUser={profile}
          onLogout={onLogout}
          isCurrentUserAdmin={isCurrentUserNotTraveller}
          organization={organization}
          breadcrumbs={
            <Breadcrumbs
              searchId={searchId}
              isBasketEditable={verifyProperty(true, basket, ['editable'])}
              isSearchingForOnlyTransport={isSearchingForOnlyTransport}
              isSearchingForOnlyRental={isSearchingForOnlyRental}
              isSearchingForOnlyOutbound={isSearchingForOnlyOutbound}
              isSearchingForOnlyHotel={isSearchingForOnlyHotel}
            />
          }
        />
      ) : (
        <Header
          profile={profile}
          organization={organization}
          isCurrentUserNotTraveller={isCurrentUserNotTraveller}
          breadcrumbs={
            <Breadcrumbs
              searchId={searchId}
              isBasketEditable={verifyProperty(true, basket, ['editable'])}
              isSearchingForOnlyTransport={isSearchingForOnlyTransport}
              isSearchingForOnlyRental={isSearchingForOnlyRental}
              isSearchingForOnlyOutbound={isSearchingForOnlyOutbound}
              isSearchingForOnlyHotel={isSearchingForOnlyHotel}
            />
          }
          onLogout={onLogout}
        />
      )}
    </div>
  );
};

const mapState = (state: IRootState) => ({
  profile: state.adminUser.profile,
  organization: state.organization.org,
  searchId: state.search.currentSearch.id,
  isSearchingForOnlyHotel: state.settings.isSearchingForOnlyHotel,
  isSearchingForOnlyOutbound: state.settings.isSearchingForOnlyOutbound,
  isSearchingForOnlyRental: state.settings.isSearchingForOnlyRental,
  isSearchingForOnlyTransport: state.settings.isSearchingForOnlyTransport,
  isCurrentUserNotTraveller: adminUserSelectors.isCurrentUserNotTraveller(state.adminUser),
  basket: state.checkout.basket,
});
export default connect(mapState)(HeaderConn);
