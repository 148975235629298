import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './converters';

@JsonObject('UserPassportModel')
export default class UserPassportModel {
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('user_id', Number) public userId:number = -1;
  @JsonProperty('birthday', DateConverter) public birthday:Date = undefined;
  @JsonProperty('birthplace', String) public birthplace:string = '';
  @JsonProperty('country_of_residence_id', Number) public countryOfResidenceId:number = 82;
  @JsonProperty('document_number', String) public documentNumber:string = '';
  @JsonProperty('document_type', String) public documentType:string = '';
  @JsonProperty('expiration_date', DateConverter) public expirationDate:Date = undefined;
  @JsonProperty('issuing_country_id', Number) public issuingCountryId:number = 82;
  @JsonProperty('issuing_date', DateConverter) public issuingDate:Date = undefined;
  @JsonProperty('nationality_id', Number) public nationalityId:number = -1;
  @JsonProperty('passport_type', String) public passportType:string = '';
  @JsonProperty('created_at', DateConverter, true) public createdAt:Date = undefined;
  @JsonProperty('updated_at', DateConverter, true) public updatedAt:Date = undefined;
}
