import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
import { isEmpty } from '@src/shared/src/util/general';
import { searchHooks } from '@src/services';
// Constants
import { SEARCH_TYPE } from '@src/shared/src/const/app';
// Actions
import {
  setIsSearchingForOnlyHotel,
  setIsSearchingForOnlyOutbound,
} from '@src/shared/src/actions/settingsActions';
import { searchActions } from '@src/shared/src/actions';
import { IRootState } from '@src/store';
// Models
// Interfaces
// Components
// Styles
import '../styles/SearchBarOptions.scss';
import { Tab } from '@toolkit/ui';

export const SearchOptions: React.FC = () => {
  const dispatch = useDispatch();
  const [currentSearchType] = searchHooks.useSearchState<SEARCH_TYPE>([
    'currentSearch',
    'searchType',
  ]);
  const [currentSearchDep] = searchHooks.useSearchState<String>(['currentSearch', 'depName']);
  const [currentSearchArr] = searchHooks.useSearchState<String>(['currentSearch', 'arrName']);
  const rentalEnabled = useSelector((state: IRootState) => state.organization?.org?.rentalEnabled);

  const isOptionActive = (option: SEARCH_TYPE) =>
    (option === SEARCH_TYPE.ALL && currentSearchType === SEARCH_TYPE.OUTBOUND) ||
    option === currentSearchType;

  // TODO: remove this method once we are using `searchType` only
  const _dispatchSearchType = (type: SEARCH_TYPE) => {
    switch (type) {
      case SEARCH_TYPE.ALL:
        dispatch(searchActions.setSearchType(SEARCH_TYPE.ALL));
        dispatch(setIsSearchingForOnlyHotel(false));
        dispatch(setIsSearchingForOnlyOutbound(false));
        if (!isEmpty(currentSearchDep) && currentSearchDep === currentSearchArr) {
          dispatch(searchActions.setSearchDep(''));
        }
        break;
      case SEARCH_TYPE.HOTEL:
        dispatch(searchActions.setSearchType(SEARCH_TYPE.HOTEL));
        dispatch(setIsSearchingForOnlyHotel(true));
        dispatch(setIsSearchingForOnlyOutbound(false));
        break;
      case SEARCH_TYPE.RENTAL:
        dispatch(searchActions.setSearchType(SEARCH_TYPE.RENTAL));
        dispatch(setIsSearchingForOnlyHotel(false));
        dispatch(setIsSearchingForOnlyOutbound(false));
        dispatch(searchActions.setSearchDep(''));
        dispatch(searchActions.setSearchDepCoor(-1, -1));
        dispatch(searchActions.setSearchArr(''));
        dispatch(searchActions.setSearchArrCoor(-1, -1));
        break;
    }
  };

  return (
    <div className="search-bar-options">
      <Tab
        label={t('search.bar.option.label.all_in_one')}
        onClick={() => _dispatchSearchType(SEARCH_TYPE.ALL)}
        active={isOptionActive(SEARCH_TYPE.ALL)}
      />
      <Tab
        label={t('search.bar.option.label.hotel_only')}
        onClick={() => _dispatchSearchType(SEARCH_TYPE.HOTEL)}
        active={isOptionActive(SEARCH_TYPE.HOTEL)}
      />
      {rentalEnabled && (
        <Tab
          label={t('search.bar.option.label.rental_car')}
          onClick={() => _dispatchSearchType(SEARCH_TYPE.RENTAL)}
          active={isOptionActive(SEARCH_TYPE.RENTAL)}
        />
      )}
    </div>
  );
};
