import { JsonObject, JsonProperty } from 'json2typescript';
import AppSettingsPassportModel from './AppSettingsPassportModel';
import AppSettingsUserTrainSeatPreferencesModel from './AppSettingsUserTrainSeatPreferencesModel';
import AppSettingsUserFlightSeatPreferencesModel from './AppSettingsUserFlightSeatPreferencesModel';
import CountriesModel from './CountriesModel';
import SettingsModel from './SettingsModel';
import BENotificationMessageModel from './BENotificationMessageModel';

@JsonObject('AppSettingsModel')
export default class AppSettingsModel {
  @JsonProperty('user_train_seat_preferences', AppSettingsUserTrainSeatPreferencesModel)
  public userTrainSeatPreferences:AppSettingsUserTrainSeatPreferencesModel =
    new AppSettingsUserTrainSeatPreferencesModel();
  @JsonProperty('user_flight_seat_preferences', AppSettingsUserFlightSeatPreferencesModel)
  public userFlightSeatPreferences:AppSettingsUserFlightSeatPreferencesModel =
    new AppSettingsUserFlightSeatPreferencesModel();
  @JsonProperty('user_titles', [SettingsModel]) public userTitles:SettingsModel[] = [];
  @JsonProperty('user_genders', [SettingsModel]) public userGenders:SettingsModel[] = [];
  @JsonProperty('travel_regions', [SettingsModel]) public travelRegions:SettingsModel[] = [];
  @JsonProperty('train_loyalty_cards', [SettingsModel]) public trainLoyaltyCards:SettingsModel[] = [];
  @JsonProperty('receipt', [SettingsModel]) public receipt:SettingsModel[] = [];
  @JsonProperty('flight_loyalty_cards', [SettingsModel]) public flightLoyaltyCards:SettingsModel[] = [];
  @JsonProperty('currencies', [SettingsModel]) public currencies:SettingsModel[] = [];
  @JsonProperty('comfort_class', [SettingsModel]) public comfortClass:SettingsModel[] = [];
  @JsonProperty('address_categories', [SettingsModel]) public addressCategories:SettingsModel[] = [];
  @JsonProperty('passport', AppSettingsPassportModel)
  public passport:AppSettingsPassportModel = new AppSettingsPassportModel();
  @JsonProperty('countries', [CountriesModel]) public countries:CountriesModel[] = [];
  @JsonProperty('notifications', [BENotificationMessageModel], true)
  public notifications:BENotificationMessageModel[] = [];
  @JsonProperty('rental_loyalty_cards', [SettingsModel]) public rentalLoyaltyCards:SettingsModel[] = [];
  @JsonProperty('flight_cabin_classes', [SettingsModel]) public flightCabinClasses:SettingsModel[] = [];
}
