import * as React from 'react';
import { useState, useEffect } from 'react';
// Utils
import { t } from '@toolkit/util/i18n';
import { Trans } from 'react-i18next';

// Constants
import { CONFIRM_TIMEOUT } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
// Components
import SkeletonSpinner from './SkeletonSpinner';
import Spinner from './Spinner';
import LoaderProgress from './LoaderProgress';
// Styles
import './styles/LoaderConfirm.scss';


const LoaderConfirm: React.FC = ({ }) => {
  const [isDelayed, setIsDelayed] = useState(false);

  useEffect(() => {
    const delayedInterval = setTimeout(() => {
      setIsDelayed(true);
    }, CONFIRM_TIMEOUT.DELAYED_CONFIRMATION);
    return () => clearTimeout(delayedInterval);

  }, []);


  return (
    <div className="loader-confirm">
      <SkeletonSpinner />
      <div className="loader-confirm-title">
        {t('loaderConfirm.title')}
      </div>
      <div className="loader-confirm-content">
        <span className={`loader-confirm-text ${isDelayed ? 'is--hidden' : ''}`}>
          <Trans i18nKey="loaderConfirm.text">
            <br />
          </Trans>
        </span>
        {isDelayed &&
          <span className="loader-confirm-text-delayed">{t('loaderConfirm.text_delayed')}</span>
        }
      </div>
      <div className="loader-confirm-footer">
        <Spinner /> {t('loaderConfirm.loading')} ...
      </div>
      <LoaderProgress finished={false} duration={CONFIRM_TIMEOUT.DELAYED_CONFIRMATION} repeat={isDelayed} />
    </div>
  );
};

export default LoaderConfirm;
