import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

// Utils
// Constants
// Actions
// Models
// Interfaces
import { IConnectedRedux, IRootState } from '@src/store';
// Components
import { HotelsConn } from '@pod/hotels';
// Styles

type Props = IConnectedRedux<IRootState> & {
  searchId:number;
};

class HotelsRouter extends React.Component<Props> {

  render() {
    return (
      <div>
        <Route render={() => <HotelsConn />}/>
      </div>
    );
  }
}
const mapState = (state:IRootState) => ({
  searchId: state.search.currentSearch.id,
});
export default connect(mapState)(HotelsRouter);