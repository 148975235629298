export function generateAction<T>(type: any, payload: T) {
  return {
    type,
    payload,
  };
}

export function generateActionKeys<T>(stateObject: T, actionPrefix: string) {
  return Object.keys(stateObject).
    reduce((accObj, newObj) => {
      accObj[`${newObj}`] = `@@${actionPrefix}/${newObj}`;
      return accObj;
    }, {} as { [K in keyof T]: string});
}
