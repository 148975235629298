import { ofType, Epic } from 'redux-observable';
import { isActionOf, getType } from 'typesafe-actions';
import { mergeMap, switchMap, debounceTime, map, filter } from 'rxjs/operators';
import { of, empty } from 'rxjs';

// Utils
import * as filters from '../filters';
import { merge, prop, pipe, partial, pluck } from '../util/general';
import { getMinInboundDepTime } from '../util/filters';
// Constants
import { DEBOUNCE_TIME, DIRECTION } from '../const/app';
// Actions
import {
  tripsActions,
  hotelActions,
  filterActions,
} from '../actions';
// Models
import {
  TripModel,
  HotelModel,
  OptionModel,
} from '../models';
// Interfaces
import { IFilterState } from '../reducers';
import {
  IFilter,
  RangeFilterConstraint,
  RootAction,
  RootState,
  SingleFilterConstraint,
  StationsFilterConstraint,
  TimeWindowFilterConstraint,
  ObjectArray,
} from '../interfaces';
import { tripSelectors } from '../selectors';

type initFilterOptions = {
  trips:ObjectArray<TripModel>,
  direction:DIRECTION,
  filterStore?:IFilterState,
  selectedOutbound?:TripModel,
  reinit:boolean,
  onlyUpdateConstraint:boolean,
};

const getSetFilterParams = <T, K, L>(
  filterId:string,
  values:T,
  constraint:K,
  reinit:boolean,
  direction?:DIRECTION,
  userConstraint?:L,
  onlyUpdateConstraint?:boolean,
) => ({
  constraint: (prop(filterId, filters).getInstance() as IFilter<T, K, L>).getConstraints({
    values,
    constraint,
    reinit,
    onlyUpdateConstraint,
    userConstraint,
  }),
  onlyUpdateConstraint: reinit,
  direction,
});

const getSetTripFilterParams = <T, K = any>(
  filterId:string,
  opts:initFilterOptions,
  constraint:T,
  userConstraint?:K,
  onlyUpdateConstraint?:boolean) =>
  getSetFilterParams<ObjectArray<TripModel>, T, K>(
    filterId,
    opts.trips,
    constraint,
    opts.reinit,
    opts.direction,
    userConstraint,
    onlyUpdateConstraint,
  );

const initTripFilters = (opts:initFilterOptions) => {
  const { filterStore, selectedOutbound } = opts;
  const userConstraintTimeWindow = opts.direction === DIRECTION.OUTWARD ?
    filterStore.tripTimeWindowUserFilter.outward : filterStore.tripTimeWindowUserFilter.inbound;

  return [
    filterActions.setTripDepTimeFilter(
      getSetTripFilterParams<RangeFilterConstraint, TimeWindowFilterConstraint>(
        filters.TripDepTimeFilter.getInstance().id,
        opts,
        filterStore.tripDepTimeFilter,
        userConstraintTimeWindow.dep,
        opts.onlyUpdateConstraint,
      )
    ),
    filterActions.setTripArrTimeFilter(
      getSetTripFilterParams<RangeFilterConstraint, TimeWindowFilterConstraint>(
        filters.TripArrTimeFilter.getInstance().id,
        opts,
        filterStore.tripArrTimeFilter,
        userConstraintTimeWindow.arr,
        opts.onlyUpdateConstraint,
      )
    ),
    filterActions.setTripDurationFilter(
      getSetTripFilterParams<SingleFilterConstraint>(
        filters.TripDurationFilter.getInstance().id,
        opts,
        filterStore.tripDurationFilter,
        null,
        opts.onlyUpdateConstraint,
      )
    ),
    filterActions.setTripPriceFilter(
      getSetTripFilterParams<SingleFilterConstraint>(
        filters.TripPriceFilter.getInstance().id,
        opts,
        filterStore.tripPriceFilter,
        null,
        opts.onlyUpdateConstraint,
      )
    ),
    filterActions.setTripStopsFilter(
      getSetTripFilterParams<SingleFilterConstraint>(
        filters.TripStopsFilter.getInstance().id,
        opts,
        filterStore.tripStopsFilter,
        null,
        opts.onlyUpdateConstraint,
      )
    ),
    filterActions.setTripTransportationFilter(
      getSetTripFilterParams<OptionModel[], OptionModel[]>(
        filters.TripTransportationFilter.getInstance().id,
        opts,
        filterStore.tripTransportationFilter,
        filterStore.tripTransportationUserFilter,
        opts.onlyUpdateConstraint,
      )
    ),
    filterActions.setTripSupplierFilter(
      getSetTripFilterParams<OptionModel[]>(
        filters.TripSupplierFilter.getInstance().id,
        opts,
        filterStore.tripSupplierFilter,
        null,
        opts.onlyUpdateConstraint,
      )
    ),
    filterActions.setTripStationsFilter(
      getSetTripFilterParams<StationsFilterConstraint>(
        filters.TripStationsFilter.getInstance().id,
        opts,
        filterStore.tripStationsFilter,
        null,
        opts.onlyUpdateConstraint,
      )
    ),
    filterActions.setTripInvalidInboundsFilter(
      getSetTripFilterParams<filters.TripInvalidInboundsFilterConstraint>(
        filters.TripInvalidInboundsFilter.getInstance().id,
        opts,
        getMinInboundDepTime(selectedOutbound, opts.direction),
      )
    ),
    filterActions.setTripSortingFilter({
      // Not using getSetFilterParams because it sets onlyUpdateConstraint: reinit
      // Which is breaking reset filters
      constraint: filters.TripSortingFilter.getInstance().getConstraints({
        values: opts.trips as any,
        constraint: filterStore.tripSortingFilter,
        reinit: opts.reinit,
        onlyUpdateConstraint: opts.onlyUpdateConstraint,
        userConstraint: filterStore.tripSortingUserFilter,
      }),
      onlyUpdateConstraint: opts.onlyUpdateConstraint,
      direction: opts.direction,
    }),
  ];
};

export const initTripFilterEpic:Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(filterActions.initializeTripFilters)),
    mergeMap((action) => {
      const { trips } = store$.value;
      return initTripFilters({
        trips: action.payload.direction === DIRECTION.OUTWARD ? trips.outward: trips.inbound,
        direction: action.payload.direction as DIRECTION,
        filterStore: store$.value.filters,
        selectedOutbound: tripSelectors.selectedOutbound(store$.value.trips),
        reinit: action.payload.reinit,
        onlyUpdateConstraint: action.payload.onlyUpdateConstraint,
      });
    })
  );

export const applyTripFilterEpic = (action$:any, store$:any) =>
  action$.pipe(
    ofType(
      getType(filterActions.setTripArrTimeFilter),
      getType(filterActions.setTripDepTimeFilter),
      getType(filterActions.setTripDurationFilter),
      getType(filterActions.setTripPriceFilter),
      getType(filterActions.setTripStopsFilter),
      getType(filterActions.setTripTransportationFilter),
      getType(filterActions.setTripStationsFilter),
      getType(filterActions.setTripSupplierFilter),
      getType(filterActions.setTripInvalidInboundsFilter),
      getType(filterActions.setTripSortingFilter)
    ),
    debounceTime(DEBOUNCE_TIME.APPLY_FILTER),
    switchMap((action:any) => {
      if (!action.payload.onlyUpdateConstraint && !action.payload.reinit) {
        const allTrips = action.payload.direction === DIRECTION.OUTWARD ?
          store$.value.trips.outward : store$.value.trips.inbound;

        const allNewTrips = pipe(
          partial(
            filters.TripInvalidInboundsFilter.getInstance().process,
            [store$.value.filters.tripInvalidInboundsFilter]),
          partial(
            filters.TripPriceFilter.getInstance().process,
            [store$.value.filters.tripPriceFilter]),
          partial(
            filters.TripArrTimeFilter.getInstance().process,
            [store$.value.filters.tripArrTimeFilter]),
          partial(
            filters.TripDepTimeFilter.getInstance().process,
            [store$.value.filters.tripDepTimeFilter]),
          partial(
            filters.TripDurationFilter.getInstance().process,
            [store$.value.filters.tripDurationFilter]),
          partial(
            filters.TripStopsFilter.getInstance().process,
            [store$.value.filters.tripStopsFilter]),
          partial(
            filters.TripTransportationFilter.getInstance().process,
            [store$.value.filters.tripTransportationFilter]),
          partial(
            filters.TripSupplierFilter.getInstance().process,
            [store$.value.filters.tripSupplierFilter]),
          partial(
            filters.TripStationsFilter.getInstance().process,
            [store$.value.filters.tripStationsFilter]),
          partial(
            filters.TripSortingFilter.getInstance().process,
            [store$.value.filters.tripSortingFilter])
        )(allTrips);

        return [tripsActions.setFilteredTripIds(pluck('id', allNewTrips), action.payload.direction)] as any;

      } else {
        return empty();
      }
    })
  );

// Hotel Filter Epics
const initHotelFilters = (hotels:HotelModel[], filterStore:IFilterState) => {
  return [
    filterActions.setHotelNameFilter({
      constraint: filters.HotelNameFilter.getInstance().getConstraints(hotels),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelPriceFilter({
      constraint: filters.HotelPriceFilter.getInstance().getConstraints(hotels),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelPricePerNightFilter({
      constraint: filters.HotelPricePerNightFilter.getInstance().getConstraints(hotels),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelRatingFilter({
      constraint: filters.HotelRatingFilter.getInstance().getConstraints({
        values: hotels as any,
        constraint: filterStore.hotelRatingFilter,
        userConstraint: filterStore.hotelRatingUserFilter,
      }),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelStarFilter({
      constraint: filters.HotelStarFilter.getInstance().getConstraints(hotels),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelDistanceFilter({
      constraint: filters.HotelDistanceFilter.getInstance().getConstraints(hotels),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelSortingFilter({
      constraint: filters.HotelSortingFilter.getInstance().getConstraints({
        values: hotels as any,
        constraint: filterStore.hotelSortingFilter,
        userConstraint: filterStore.hotelSortingUserFilter,
      }),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelFeatureFilter({
      constraint: filters.HotelFeatureFilter.getInstance().getConstraints(hotels),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelAccommodationFilter({
      constraint: filters.HotelAccommodationFilter.getInstance().getConstraints({
        values: hotels as any,
        constraint: filterStore.hotelAccommodationFilter,
        userConstraint: filterStore.hotelAccommodationUserFilter,
      }),
      onlyUpdateConstraint: false,
    }),
  ];
};

const reinitHotelFilters = (hotels:HotelModel[], filterStore:IFilterState) => {
  return [
    filterActions.setHotelNameFilter({
      constraint: merge(filters.HotelNameFilter.getInstance().getConstraints(hotels), {
        current: filterStore.hotelNameFilter.current,
      }),
      onlyUpdateConstraint: true,
    }),
    filterActions.setHotelPriceFilter({
      constraint: merge(filters.HotelPriceFilter.getInstance().getConstraints(hotels), {
        currentMin: filterStore.hotelPriceFilter.currentMin,
        currentMax: filterStore.hotelPriceFilter.currentMax,
      }),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelPricePerNightFilter({
      constraint: merge(filters.HotelPricePerNightFilter.getInstance().getConstraints(hotels), {
        currentMin: filterStore.hotelPricePerNightFilter.currentMin,
        currentMax: filterStore.hotelPricePerNightFilter.currentMax,
      }),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelRatingFilter({
      constraint: merge(filters.HotelRatingFilter.getInstance().getConstraints({
        values: hotels as any,
        constraint: filterStore.hotelRatingFilter,
        userConstraint: filterStore.hotelRatingUserFilter,
      }), {
        currentMin: filterStore.hotelRatingFilter.currentMin,
        currentMax: filterStore.hotelRatingFilter.currentMax,
      }),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelStarFilter({
      constraint: merge(filters.HotelStarFilter.getInstance().getConstraints(hotels), {
        currentMin: filterStore.hotelStarFilter.currentMin,
        currentMax: filterStore.hotelStarFilter.currentMax,
      }),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelDistanceFilter({
      constraint: merge(filters.HotelDistanceFilter.getInstance().getConstraints(hotels), {
        current: filterStore.hotelDistanceFilter.current,
      }),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelSortingFilter({
      constraint: merge(filters.HotelSortingFilter.getInstance().getConstraints({
        values: hotels as any,
        constraint: filterStore.hotelSortingFilter,
        userConstraint: filterStore.hotelSortingUserFilter,
      }), {
        current: filterStore.hotelSortingFilter.current,
      }),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelFeatureFilter({
      constraint: merge(filters.HotelFeatureFilter.getInstance().getConstraints(hotels), {
        current: filterStore.hotelFeatureFilter.current,
      }),
      onlyUpdateConstraint: false,
    }),
    filterActions.setHotelAccommodationFilter({
      constraint: merge(filters.HotelAccommodationFilter.getInstance().getConstraints({
        // TODO: change IFilter and remove the array expectation from the generic and make that as a part
        // of the param
        values: hotels as any,
        constraint: filterStore.hotelAccommodationFilter,
        userConstraint: filterStore.hotelAccommodationUserFilter,
      }), {
        current: filterStore.hotelAccommodationFilter.current,
      }),
      onlyUpdateConstraint: false,
    }),
  ];
};

export const initHotelFilterEpic:Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(filterActions.initializeHotelFilters)),
    debounceTime(DEBOUNCE_TIME.INIT_FILTER),
    mergeMap(() => {
      return initHotelFilters(store$.value.hotels.hotels, store$.value.filters);
    })
  );

export const reinitHotelFilterEpic:Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(hotelActions.addFilteredHotel)),
    mergeMap(() => {
      return reinitHotelFilters(
        store$.value.hotels.hotels,
        store$.value.filters
      );
    })
  );

export const applyHotelFilterEpic = (action$:any, store$:any) =>
  action$.pipe(
    ofType(
      getType(filterActions.setHotelNameFilter),
      getType(filterActions.setHotelPriceFilter),
      getType(filterActions.setHotelPricePerNightFilter),
      getType(filterActions.setHotelRatingFilter),
      getType(filterActions.setHotelStarFilter),
      getType(filterActions.setHotelDistanceFilter),
      getType(filterActions.setHotelSortingFilter),
      getType(filterActions.setHotelFeatureFilter),
      getType(filterActions.setHotelAccommodationFilter),
    ),
    debounceTime(DEBOUNCE_TIME.APPLY_FILTER),
    mergeMap((action:any) => {
      if (!action.payload.onlyUpdateConstraint) {
        return of(store$.value.hotels.hotels).pipe(
          map((hotels:HotelModel[]) =>
            filters.HotelNameFilter.getInstance().process(
              store$.value.filters.hotelNameFilter, hotels)),
          map((hotels:HotelModel[]) =>
            filters.HotelPriceFilter.getInstance().process(
              store$.value.filters.hotelPriceFilter, hotels)),
          map((hotels:HotelModel[]) =>
            filters.HotelPricePerNightFilter.getInstance().process(
              store$.value.filters.hotelPricePerNightFilter, hotels)),
          map((hotels:HotelModel[]) =>
            filters.HotelRatingFilter.getInstance().process(
              store$.value.filters.hotelRatingFilter, hotels)),
          map((hotels:HotelModel[]) =>
            filters.HotelStarFilter.getInstance().process(
              store$.value.filters.hotelStarFilter, hotels)),
          map((hotels:HotelModel[]) =>
            filters.HotelDistanceFilter.getInstance().process(
              store$.value.filters.hotelDistanceFilter, hotels)),
          map((hotels:HotelModel[]) =>
            filters.HotelSortingFilter.getInstance().process(
              store$.value.filters.hotelSortingFilter, hotels)),
          map((hotels:HotelModel[]) => filters.HotelFeatureFilter.getInstance().process(store$.value.filters.hotelFeatureFilter, hotels)),
          map((hotels:HotelModel[]) => filters.HotelAccommodationFilter.getInstance().process(store$.value.filters.hotelAccommodationFilter, hotels)),
          map((hotels:any) =>
            hotelActions.setFilteredHotels(hotels))
        );
      } else {
        return empty();
      }
    })
  );
