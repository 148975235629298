import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Models
import { OptionModel } from '@src/shared/src/models';
// Interfaces
// Components
// Styles
import '../styles/SortBar.scss';

type Props = {
  options: OptionModel[];
  value:string;
  onChange:(val:OptionModel) => void;
}

export default class SortBar extends React.PureComponent<Props> {

  private renderButton = (option:OptionModel) =>
    <button key={option.value} onClick={() => this.props.onChange(option)} className={`lp-sortbar-button ${this.props.value === option.value ? 'is--active' : ''}`}>
      {option.label}
    </button>

  public render() {
    return (
      <div className="lp-sortbar">
        <label className="lp-sortbar-label">
          {t('SortBar.label.sort')}
        </label>
        {this.props.options.map((option:OptionModel) =>
           this.renderButton(option)
          )
        }
      </div>
    );
  }
}
