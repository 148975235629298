// Utils
import { map, filter, partial } from '../util/general';
// Constants
// Actions, Models & Interfaces
import { HotelModel } from '../models';
import { IHotelFilter } from '../interfaces';

export type HotelDistanceFilterConstraint = {
  min:number;
  max:number;
  current:number;
}

export default class HotelDistanceFilter implements IHotelFilter<HotelDistanceFilterConstraint> {
  private constructor() {}

  private static instance:HotelDistanceFilter;

  static getInstance = () => {
    if (!HotelDistanceFilter.instance) {
      HotelDistanceFilter.instance = new HotelDistanceFilter();
    }
    return HotelDistanceFilter.instance;
  }

  public id:string = 'HotelDistanceFilter';

  public getConstraints = (hotels:HotelModel[]):HotelDistanceFilterConstraint => {
    const distances = map((hotel:HotelModel) => hotel.distance, hotels);

    return {
      min: Math.min(...distances),
      max: Math.max(...distances),
      current: Math.max(...distances),
    }
  }

  public process = (constraint:HotelDistanceFilterConstraint, hotels:HotelModel[]) =>
    filter(partial(this.match, [constraint]), hotels);

  private match = (constraint:HotelDistanceFilterConstraint, hotel:HotelModel) =>
    hotel.distance <= constraint.current;
}