import { JsonObject, JsonProperty } from 'json2typescript';

import PolicyViolationTransportDataModel from './PolicyViolationTransportDataModel';
import PolicyViolationHotelDataModel from './PolicyViolationHotelDataModel';
import PolicyViolationRentalDataModel from './PolicyViolationRentalDataModel';
import PolicyModel from './PolicyModel';

@JsonObject('PolicyViolationModel')
export default class PolicyViolationModel {
  @JsonProperty('booking_item_id', Number) public bookingItemId:number = undefined;
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('justification', String) public justification:string = undefined;
  @JsonProperty('policy', PolicyModel) public policy:PolicyModel = undefined;
  @JsonProperty('policy_id', Number) public policyId:number = -1;
  @JsonProperty('policy_type', String) public policyType:string = undefined;
  @JsonProperty('policy_type_name', String)
  public policyTypeName:string = 'transport';
  @JsonProperty('transport_data', PolicyViolationTransportDataModel)
  public transportData:PolicyViolationTransportDataModel = undefined;
  @JsonProperty('hotel_data', PolicyViolationHotelDataModel)
  public hotelData:PolicyViolationHotelDataModel = undefined;
  @JsonProperty('vehicle', String) public vehicle:string = undefined;
  @JsonProperty('rental_data', PolicyViolationRentalDataModel)
  public rentalData:PolicyViolationRentalDataModel = undefined;
}
