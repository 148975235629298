import * as React from 'react';
import classNames from 'classnames';
import './styles/WarningBanner.scss';

interface Props {
  label:any;
  icon?:string;
  showCloseBtn?:boolean;
  isInfo?:boolean;
}

type State = {
  isVisible:boolean;
};

class WarningBanner extends React.PureComponent<Props, State> {

  readonly state:State = {
    isVisible: true,
  };

  private setIsVisible = (val:boolean) => this.setState({ isVisible: val });

  public render() {
    if (!this.state.isVisible) {
      return null;
    }

    const wrapperCls = classNames(
      'lp-warning-banner',
      { 'lp-warning-banner-info': this.props.isInfo }
    );

    return (
      <div className={wrapperCls}>
        {this.props.icon && <i className={`lp-warning-banner-icon ${this.props.icon}`} />}
        <div className="lp-warning-banner-warning-text">
          {this.props.label}
        </div>
        {this.props.showCloseBtn &&
          <div className="lp-warning-banner-close" onClick={() => this.setIsVisible(false)} >
            <i className="icon-close2"/>
          </div>
        }
      </div>);
  }
}

export default WarningBanner;
