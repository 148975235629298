import * as React from 'react';
import { useState, useEffect } from 'react';

// Utils
import classnames from 'classnames';
import { getUserFullname } from '@src/shared/src/util/users';
import { t } from '@toolkit/util/i18n';
// Interfaces
import { PassengerModel } from '@src/shared/src/models';
// Constants
import { ROLES } from '@src/shared/src/const/app';
// Components
import { CancelIcon, SearchIcon } from './icons';
import TextField from './TextField';
// Styles
import './styles/PassengersSelect.scss';
import '@pod/search/styles/ResultsList.scss';

type Props = {
  onChange: (inputValue: PassengerModel[]) => void;
  onInputChange: (searchTerm: string) => void;
  placeholder: string;
  options: PassengerModel[];
  selectedValues: PassengerModel[];
};

const PassengersSelect: React.FC<Props> = (props) => {
  const { onChange, onInputChange, placeholder, selectedValues, options } = props;
  const [selectedOption, setSelectedOption] = useState('');
  const [listOpen, setListOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<PassengerModel[]>(null);

  useEffect(() => {
    if (options?.length) {
      // exclude already selected passengers (selectedValues)
      setFilteredOptions(
        options.filter(
          (passenger) => !selectedValues.map((item) => item.userId).includes(passenger.userId),
        ),
      );
    }
  }, [options]);

  const clear = () => {
    setListOpen(false);
    setSelectedOption('');
  };

  const remove = (passenger) => {
    onChange(
      selectedValues.filter((selectedPassenger) => selectedPassenger.userId !== passenger.userId),
    );
  };

  const add = (passenger) => {
    onChange([...selectedValues, passenger]);
    clear();
  };

  const onSearchChange = (searchTerm) => {
    onInputChange(searchTerm);
    setSelectedOption(searchTerm);

    if (searchTerm.length < 3) {
      setFilteredOptions(null);
      setListOpen(false);
    } else {
      setListOpen(true);
    }
  };

  const getPassengerLabel = (passenger: PassengerModel, showRoleInfo: boolean = false) => {
    return `${getUserFullname(passenger)}
    ${
      showRoleInfo && passenger.role === ROLES.GUEST
        ? t('searchBarPassengersSelector.label.guest')
        : ''
    }`;
  };

  return (
    <div className="search-select">
      <TextField
        onChange={onSearchChange}
        autoComplete={false}
        placeholder={placeholder}
        onBlur={clear}
        value={selectedOption}
        icon={<SearchIcon />}
      />
      <ul className={classnames('results-list is--dropdown', { 'is--open': listOpen })}>
        {filteredOptions ? (
          filteredOptions.map((passenger) => {
            return (
              <li key={passenger.userId}>
                <button className="results-list-item" onClick={() => add(passenger)}>
                  {getPassengerLabel(passenger, true)}
                </button>
              </li>
            );
          })
        ) : (
          <li key="no-results">
            <span className="results-list-item">{t('passengers.select.noResults')} </span>
          </li>
        )}
      </ul>
      {selectedValues && (
        <ul className="search-select-selected">
          {selectedValues.map((passenger) => {
            return (
              <li key={passenger.userId}>
                {getPassengerLabel(passenger)}{' '}
                <button className="search-select-remove" onClick={() => remove(passenger)}>
                  <CancelIcon />
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default PassengersSelect;
