import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { tripSelectors } from '@src/shared/src/selectors';
import i18n from '@src/toolkit/util/i18n';
import { navigate } from '@toolkit/util/app';
// Constants
import {
  DIRECTION,
} from '@src/shared/src/const/app';
import { ROUTES } from '@toolkit/const/app';
// Actions
import { filterActions } from '@src/shared/src/actions';
// Models
// Interfaces
import { IRootState, ConnectedRedux } from '@src/store';
// Components
import {
  NoResultsWarning
} from '@toolkit/ui';
// Styles

type Props = ConnectedRedux<IRootState> & {
  direction:DIRECTION;
  tripsCount:number;
  filteredTripsCount:number;
  isTripsLoading:boolean;
}

class TripNoResults extends React.PureComponent<Props> {

  private initTripFilters = () => {
    this.props.dispatch(
      filterActions.initializeTripFilters(this.props.direction, true, false)
    )
  }

  render() {
    const { tripsCount, filteredTripsCount, isTripsLoading  } = this.props;
    if (!isTripsLoading) {
      if (tripsCount > 0) {
        if (filteredTripsCount === 0) {
          return (
            <NoResultsWarning
              title={i18n.t('tripsConn.title.noFilteredResults')}
              bodyText={i18n.t('tripsConn.text.noFilteredResults')}
              buttonText={i18n.t('tripsConn.button.noFilteredResults.clickHere')}
              onClick={this.initTripFilters}
              withBackground={true}/>
          );
        }
        return null;
      } else {
        return (
          <NoResultsWarning
            title={i18n.t('tripsConn.title.noResults')}
            bodyText={i18n.t('tripsConn.text.noResults')}
            buttonText={i18n.t('tripsConn.button.noResults.here')}
            onClick={() => navigate(ROUTES.HOME)}
            withBackground={true}/>
        );
      }
    } else {
      return null;
    }
  }

}
const mapStateToProps = (state:IRootState, props:any) => ({
  tripsCount: props.direction === DIRECTION.OUTWARD
    ? tripSelectors.outwardTripsCount(state.trips) : tripSelectors.inboundTripsCount(state.trips),
  filteredTripsCount: props.direction === DIRECTION.OUTWARD
    ? tripSelectors.outwardFilteredTripsCount(state.trips) : tripSelectors.inboundFilteredTripsCount(state.trips),
});
export default connect(mapStateToProps)(TripNoResults);
