import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
// Interfaces
// Components
import { Button } from '@toolkit/ui';
// Styles

type Props = {
  onPrepareBasket:() => void;
  isPreparingBooking:boolean;
};

const CheckoutHeaderReserve:React.FC<Props> = (props) => {
  return (
        <Button onClick={props.onPrepareBasket} isLoading={props.isPreparingBooking} className="button">
          {t('CheckoutHeader.button.reserveBooking')}
            <i className="icon-arrow_forward"/>
        </Button>
  );
};

export default CheckoutHeaderReserve;