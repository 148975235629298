declare const __APP_VERSION__: string;
declare const __RELEASE_STAGE__: string;

import Bugsnag, { Event } from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { AjaxError } from 'rxjs/ajax';

import { omit, pathOr } from '@src/shared/src/util/general';
import { store } from '@src/store/store.config';
import { ERROR_SEVERITY } from '@src/shared/src/const/app';

export const ajaxErrorSeverity = (err: AjaxError) => {
  if (err.status >= 400 && err.status < 500) {
    return ERROR_SEVERITY.WARNING;
  } else if (err.status >= 500) {
    return ERROR_SEVERITY.ERROR;
  }

  return ERROR_SEVERITY.INFO;
};

const injectStoreMetadata = (event: Event, store) => {
  event.addMetadata('appState', {
    basket: store.getState().checkout.basket
      ? omit(['travelPolicyViolations', 'updatedAt', 'userId'], store.getState().checkout.basket)
      : null,
    currentSearch: store.getState().search.currentSearch
      ? omit(['creator'], store.getState().search.currentSearch)
      : null,
    settings: store.getState().settings,
  });

  if (store.getState().organization.org) {
    event.addMetadata('user', 'organizationId', store.getState().organization.org.id)
  }

  if (store.getState().adminUser.profile) {
    event.addMetadata('user', 'userId', store.getState().adminUser.profile.id)
  }
}

const injectAjaxMetadata = (event: Event) => {
  const ajaxError = event.originalError;
  if (ajaxError instanceof AjaxError) {
    event.severity = ajaxErrorSeverity(ajaxError);
    const ajaxLog: any  = {
      ajax: {
        request: ajaxError.request,
        response: ajaxError.response,
        status: ajaxError.status,
        message: ajaxError.message,
        name: ajaxError.name,
      },
    };
    // Redact sensitive information
    ajaxLog.ajax.request.headers.Authorization = '[REDACTED]';
    const includesBody = pathOr(false, ['body'], ajaxLog.ajax.request);

    if (includesBody) {
      try {
        const parsedBody = JSON.parse(ajaxLog.ajax.request.body);
        if (parsedBody.password || pathOr(false, ['user', 'password'], parsedBody)) {
          ajaxLog.ajax.request.body = '[REDACTED]';
        }
      } catch (err) {
        event.errors[0].errorMessage = 'ajax parsing body error';
      }
    }

    event.addMetadata('ajaxState', ajaxLog.ajax)
  }
}

Bugsnag.start({
  apiKey: 'c9898f58c0f7a04466fb370bf2fcff7e',
  releaseStage: __RELEASE_STAGE__,
  enabledReleaseStages: ['staging', 'demo', 'production'],
  plugins: [
    new BugsnagPluginReact()
  ],
  onError: (event) => {
    injectStoreMetadata(event, store);
    injectAjaxMetadata(event);
  }
});
