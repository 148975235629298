import * as React from 'react';
import { useState } from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNilOrEmpty } from '@src/shared/src/util/general';
// Constants
// Actions
// Models
// Interfaces
// Components
// Styles
import '../styles/HotelRoomDetails.scss';

type Props = {
  roomDetails: string;
};

const HotelRoomDetails: React.FC<Props> = props => {
  const [showRoomDetails, setShowRoomDetails] = useState(false);
  const toggleShowRoom = () => setShowRoomDetails(!showRoomDetails);

  if (!isNilOrEmpty(props.roomDetails)) {
    return (
      <React.Fragment>
        <div className="tcp-hotel-room-details">{showRoomDetails && props.roomDetails}</div>
        <button className="tcp-link tcp-link-icon-right" onClick={toggleShowRoom}>
          <span>
            {t('hotelRoomDetails.button.roomDetails')}
            <i
              className={showRoomDetails ? 'icon-keyboard_arrow_up' : 'icon-keyboard_arrow_down'}
            />
          </span>
        </button>
      </React.Fragment>
    );
  }

  return null;
};

export default HotelRoomDetails;
