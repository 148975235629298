import * as React from 'react';
import { InfoBox } from '@react-google-maps/api';

// Utils
import { equals, isNotNil } from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
import { CoordinatesModel } from '@src/shared/src/models';
// Components
// Styles

interface IProps {
  position: CoordinatesModel;
}

class HotelDestMarker extends React.Component<IProps> {
  public shouldComponentUpdate(nextProps: IProps) {
    return !equals(this.props.position, nextProps.position);
  }

  public render() {
    if (isNotNil(this.props.position)) {
      return (
        <InfoBox
          position={new google.maps.LatLng(this.props.position.lat, this.props.position.lng)}
          options={{ closeBoxURL: ``, enableEventPropagation: true }}>
          <div className="icon-flag map-marker-dest" />
        </InfoBox>
      );
    } else {
      return null;
    }
  }
}

export default HotelDestMarker;
