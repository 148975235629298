export const enum FARE_PAYMENT_TYPE {
  PREPAY = 'PrePay'
}

export const HOTEL_PROVIDERS = [
  'Booking.com',
  'Expedia',
  'HRS',
  'Hotel Direct Rates',
  'Booking.com GENIUS',
  'Amadeus',
  'Ebookers',
  'Otel',
  'Orbitz',
  'TravelGuru',
  '+ 60 more',
];
