import * as React from 'react';
import classNames from 'classnames';

// Utils
import { isNil } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
import { OptionModel } from '@src/shared/src/models';
// Interfaces
// Components
import Switch from './Switch';
// Styles

type Props = {
  title:string;
  vehicles:OptionModel[];
  isOpen?:boolean;
  isDisabled?:boolean;
  onChange:(vehicles:OptionModel[]) => void;
};

type State = {
  isOpen:boolean;
};
export default class TransportationFilter extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: isNil(props.isOpen) ? true : props.isOpen,
    };
  }

  private toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  public render() {
    const { title, vehicles, isDisabled } = this.props;
    const indicatorStyle = classNames('sidebar-section-indicator', {
      'sidebar-section-indicator-disabled': isDisabled,
    });
    return (
      <div className={`sidebar-section ${this.state.isOpen ? 'is-open' : ''}`}>
        <button
          className="sidebar-section-title"
          onClick={isDisabled ? null : this.toggleOpen}>
          {title}
          <i className={indicatorStyle} aria-hidden="true" />
        </button>
        <div className="sidebar-section-inner">
        {
          vehicles.map((vehicle:OptionModel) =>
            <Switch
              key={`kid-${vehicle.label}`}
              label={t(`TransportationFilter.switch.label.${vehicle.label.toLowerCase()}`)}
              icon={`icon-${vehicle.label.toLowerCase()}`}
              isChecked={Boolean(vehicle.value)}
              onChange={(val) => {
                const nVehicles:any = vehicles.map((veh:OptionModel) =>
                  (veh.label === vehicle.label) ?
                    {
                      label: veh.label,
                      value: val,
                    }
                    :
                    veh
                );
                this.props.onChange(nVehicles);
              }
            }/>
          )
        }
        </div>
      </div>
    );
  }
}
