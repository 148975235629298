import * as React from 'react';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { FormProps } from 'redux-form/es/Form';


// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Services
// Models, Stores & Interfaces
import { UserModel, SettingsModel } from '@src/shared/src/models';
// Components
import { Button } from '@toolkit/ui';

type Props = FormProps & {
  profile:UserModel;
  userTitles:SettingsModel[];
  countries:SettingsModel[];
  hasOmniAuth:boolean;
};

const CheaperCombineableFlightForm: React.FC<Props> = (props) => {
  return (
    <form onSubmit={props.handleSubmit} className="tcp-form-group tcp-form-group-vertical">
      <h2>{t('cheaperCombineableFlightForm.header.additionalComment')}:</h2>
      <div>
        <Field
          name="userComment"
          className="form-textarea form-textarea-full"
          placeholder={t('cheaperCombineableFlightForm.placeholder.description')}
          component="textarea" />
      </div>

      <div className="form-submit">
        <Button type="submit" className="button">
          {t('checkoutPrepare.button.CheckOffer')}
        </Button>
      </div>
    </form>
  )
};

export default reduxForm({
  form: 'cheaperCombineableFlight'
})(CheaperCombineableFlightForm);