import * as React from 'react';

// Utils
// Constants
// Actions
// Models
// Interfaces
// Components
// Styles
type Props ={
  isReverse?:boolean;
  children: React.ReactNode;
};

const CheckoutHeaderConfirm:React.FC<Props> = (props) => {
  return (
    <div className={`content-header ${props.isReverse ? 'content-header-reverse' : ''}`}>
      {props.children}
    </div>
  );
};
export default CheckoutHeaderConfirm;
