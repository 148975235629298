import * as React from 'react';
import reduxForm from 'redux-form/es/reduxForm';
import Field from 'redux-form/es/Field';
import { FormProps } from 'redux-form/es/Form';

// Utils
import { t } from '@toolkit/util/i18n';
import {
  required,
} from '@toolkit/util/formsValidation';
// Constants
// Actions
// Models
// Interfaces
// Components
import {
  Button,
  FormInput,
} from '@toolkit/ui';
// Styles
import '../styles/LoginForm.scss';

const EmailForm: React.FC<FormProps> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <div className="tcp-login-form-field">
        <Field
          className="tcp-login-form-text"
          name="email"
          component={FormInput}
          validate={required}
          type="email"
          placeholder={t('loginForm.form.label.email')}/>
      </div>
      <Button type="submit" className="button">{t('loginForm.form.next')}</Button>
    </form>
  );
};

export default reduxForm({
  form: 'lginEmail',
})(EmailForm);
