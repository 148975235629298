import * as React from 'react';

// Utils
import { tripHooks, tripActions } from '@src/services';
import { searchHooks } from '@src/services';
import { t } from '@toolkit/util/i18n';
import { isNil } from '@src/shared/src/util/general';
// Constants
import { DIRECTION, ENVIRONMENT } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Components
import Trip from './Trip';
// Styles

type Props = {
  direction: DIRECTION;
};
export const SelectedTrip: React.FC<Props> = props => {
  const matchingTrip = tripHooks.useAllowanceMatchingTrip(props.direction);
  const searchDate = searchHooks.useSearchDate(props.direction);
  if (isNil(matchingTrip)) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="content-header">
        <h2 className="content-title">{t('BookedTrip.title.bookedTrip')}</h2>
      </div>
      <Trip
        direction={props.direction}
        trip={matchingTrip}
        env={ENVIRONMENT.SEARCH}
        searchDate={searchDate}
        isSelectingTrip={false}
        isRoundtrip={false}
        detailsOpened={false}
        onFetchDetail={() => tripActions.fetchTripDetails(matchingTrip.id, props.direction)}
        onSelectTrip={tripActions.selectTrip}
        onCloseDetail={() => {}}
        isLoading={false}
      />
    </React.Fragment>
  );
};
