import { Epic } from 'redux-observable';
import { isActionOf, getType } from 'typesafe-actions';
import { map, mergeMap, filter, switchMap, mapTo } from 'rxjs/operators';
import { timer } from 'rxjs';

// Utils
import { applyExtAction } from '../util/app';
import { getWSStatus } from '../util/ActionCableObservable';
import { errorLogger } from '../util/errors';
import { pathOr, getWSCallKey } from '../util/general';
// Constants
import {
  ERRORS,
  ERROR_SEVERITY,
  SERVER_TIMEOUT,
  SERVER_TIMEOUT_MAX,
  WS_STATUS_CHECK_INTERVAL,
} from '../const/app';
// Actions
import {
  basketActions,
  checkoutActions,
  generalActions,
  hotelActions,
  searchActions,
  tripsActions,
  settingsActions,
} from '../actions';
// Models
// Interfaces
import { RootAction, WSCall } from '../interfaces';

const wsActions = [
  basketActions.addTripTariffsToBasketAsync.request,
  basketActions.removeItemFromBasketAsync.request,
  checkoutActions.confirmBookingAsync.request,
  checkoutActions.initBasket,
  checkoutActions.initBookings,
  checkoutActions.prepareBookingAsync.request,
  hotelActions.addHotelFaresToBasketAsync.request,
  hotelActions.fetchHotelDetailsAsync.request,
  hotelActions.fetchHotelsAsync.request,
  searchActions.initSearch,
  tripsActions.changeShuttleTimeAsync.request,
  tripsActions.changeShuttleTypeAsync.request,
  tripsActions.changeTariffAsync.request,
  tripsActions.fetchTripDetailsAsync.request,
  tripsActions.fetchTripsAsync.request,
] as any;

const getActionIdKey = (actionType:string) => {
  switch(actionType) {
    case getType(searchActions.initSearch):
    case getType(tripsActions.fetchTripsAsync.request):
    case getType(hotelActions.fetchHotelsAsync.request):
      return 'searchId';
    case getType(tripsActions.changeTariffAsync.request):
    case getType(tripsActions.fetchTripDetailsAsync.request):
    case getType(tripsActions.changeShuttleTimeAsync.request):
      return 'tripId';
    case getType(hotelActions.fetchHotelDetailsAsync.request):
      return 'hotelId';
    case getType(basketActions.removeItemFromBasketAsync.request):
      return 'itemType';
    case getType(checkoutActions.confirmBookingAsync.request):
    case getType(checkoutActions.prepareBookingAsync.request):
    case getType(checkoutActions.initBasket):
      return 'basketId';
    case getType(checkoutActions.initBookings):
      return 'bookingId';
    default:
      return null;
  }
}

const isServerTimedout = (state:boolean, startTime:number, endTime:number) => {
  if (!state) {
    if (endTime && endTime - startTime > SERVER_TIMEOUT_MAX) { // false positive
      return false;
    } else {
      return true;
    }
  }
  return false;
}

export const applyExtActionEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(generalActions.applyExtActionAsync.request)),
    map((action) => {
      if (action.payload.callback) {
        applyExtAction(action.payload.callback, action.payload.param);
      }
      return generalActions.applyExtActionAsync.success(action.payload);
    })
  );

export const serverTimeoutEpic = (action$:any, store$:any) =>
  action$.pipe(
    filter(isActionOf(wsActions)),
    mergeMap((action:any) => {
      const timerSearchId = store$.value.search.id;
      return timer(SERVER_TIMEOUT).pipe(
        map(() => {
          const currentSearchId = store$.value.search.id;
          if (timerSearchId === currentSearchId) {
            const actionIdKey = getActionIdKey(action.type);
            const wsCallKey = actionIdKey
              ? getWSCallKey(action.type, action.payload[actionIdKey])
              : getWSCallKey(action.type);
            const wsCall = store$.value.settings.wsCalls[wsCallKey] as WSCall;
            if (wsCall) {
              const isTimedOut = isServerTimedout(wsCall.state, wsCall.startTime, wsCall.endTime);

              if (isTimedOut) {
                const timeoutError = {
                  action: action.type,
                  currentSearchId,
                  ...(pathOr(false, ['payload', 'tripId'], action) && { tripId: action.payload.tripId }),
                  ...(pathOr(false, ['payload', 'hotelId'], action) && { hotelId: action.payload.hotelId }),
                };
                errorLogger('TimeoutError', new Error(JSON.stringify(timeoutError)), ERROR_SEVERITY.WARNING, action.type);
                return generalActions.applyExtActionAsync.request({
                  callback: (action.payload.onError ? action.payload.onError : null),
                  param: ERRORS.TIMEOUT,
                });
              }
            } else {
              errorLogger(
                'WSCallKeyError', new Error(`Could not find state for wsCallKey:${wsCallKey}`), ERROR_SEVERITY.WARNING);
              return generalActions.noOp();
            }

          }
          return generalActions.noOp();
        })
      );
    }
    )
  );

export const wsCallEpic = (action$:any) =>
  action$.pipe(
    filter(isActionOf(wsActions)),
    map((action:any) => {
      const actionIdKey = getActionIdKey(action.type);
      const wsCallKey = actionIdKey
        ? getWSCallKey(action.type, action.payload[actionIdKey])
        : getWSCallKey(action.type);

      return settingsActions.setWSCallStart(wsCallKey);
    })
  );

export const wsStatusCheckEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(settingsActions.startWSCheck)),
    switchMap(() =>
      timer(WS_STATUS_CHECK_INTERVAL).pipe(
        mapTo(settingsActions.setWSStatus(getWSStatus()))
      )
    )
  );