export default class NumberFormatter {
  private constructor() {}

  private static instance:NumberFormatter;

  private fmtNumber:Intl.NumberFormat;

  private setFmtNumber = (locale:string) =>
    NumberFormatter.instance.fmtNumber = new Intl.NumberFormat(locale)

  static getInstance = () => {
    if (!NumberFormatter.instance) {
      NumberFormatter.instance = new NumberFormatter();
    }
    return NumberFormatter.instance.fmtNumber;
  }

  static initFormatter = (locale:string) => {
    NumberFormatter.instance = new NumberFormatter();
    NumberFormatter.instance.setFmtNumber(locale);
  }
}