import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { formatDate, formatDateRange } from '@src/shared/src/util/date';
import { navigate } from '@toolkit/util/app';
import { t } from '@toolkit/util/i18n';
// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions
import { selectors } from '@src/shared/src';
// Models
import { PassengerModel, SettingsModel, RedeemingAllowanceModel } from '@src/shared/src/models';
// Interfaces
import { IRootState } from '@src/store';
// Components
import { LabelButton, SidebarRoute } from '@toolkit/ui';
import SearchSideBarPassengerInfo from './SearchSideBarPassengerInfo';
// Styles

type Props = {
  to:string;
  depAt:Date;
  arrAt:Date;
  loyaltyCards:SettingsModel[];
  passengers:PassengerModel[];
  from?:string;
  hideResetButton?:boolean;
  isSearchingForOnlyHotel?:boolean;
  redeemingAllowance?:RedeemingAllowanceModel;
};

const SearchSideBarInfo = (props:Props) => {
  const hideResetBtn = props.hideResetButton || props.redeemingAllowance;
  return (
    <div className="sidebar-section hidden-xs-down is-open">
      <div className="sidebar-section-inner">
        <div className="sidebar-trip-details">
            <div>
              {props.from && !props.isSearchingForOnlyHotel &&
                <SidebarRoute
                  iconClass="icon-from"
                  label={t('TripSideBarInfo.label.from')}>
                  {props.from}
                </SidebarRoute>
              }
              {props.to &&
                <SidebarRoute
                  iconClass="icon-to"
                  label={
                    props.isSearchingForOnlyHotel ?
                      t('TripSideBarInfo.label.in') : t('TripSideBarInfo.label.to')}
                  hasSpacing={true}>
                  {props.to}
                </SidebarRoute>
              }
              {props.depAt &&
                <SidebarRoute iconClass="icon-calendar">
                  {props.arrAt
                    ? formatDateRange(props.depAt, props.arrAt, '-', DATE_FORMAT_TYPES.DAY_SHORT_DATE)
                    : formatDate(props.depAt, DATE_FORMAT_TYPES.DAY_SHORT_DATE)
                  }
                </SidebarRoute>
              }
              <SearchSideBarPassengerInfo
                passengers={props.passengers}
                loyaltyCards={props.loyaltyCards}/>
              {!hideResetBtn &&
                <LabelButton
                  onClick={() => navigate('/')}
                  className="sidebar-reset">
                  <i className="icon-reset"/>{t('TripSideBarInfo.button.reset')}
                </LabelButton>
              }
            </div>
        </div>
      </div>
    </div>
  );
};
const mapState = (state:IRootState) => ({
  passengers: selectors.search.searchPassengers(state.search),
  redeemingAllowance: state.checkout.redeemingAllowance,
});
export default connect(mapState)(SearchSideBarInfo);
