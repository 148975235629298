import * as React from 'react';
import { Observable, Subscription } from 'rxjs';

export const withObservableStream = <T extends {}>(observable: Observable<T>, initialState?: T) => Component => {
  return class extends React.Component {
    subscription: Subscription;
    state = {
      emittedValues: initialState,
    };

    componentDidMount() {
      this.subscription = observable.subscribe(emittedValues => this.setState({ emittedValues }));
    }

    componentWillUnmount() {
      this.subscription.unsubscribe();
    }

    render() {
      return <Component {...this.props} {...this.state} />;
    }
  };
};
