import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import {
  getTariffBookedStatus,
  getTariffBookedTravelClass,
  getTariffSeatReservation,
  getTariffDocuments,
  getTariffOrderNumber,
} from '@src/shared/src/util/tariffs';
import {
  getMainLegs,
  findBySelected,
} from '@src/shared/src/util/trips';
import {
  map,
  pipe,
  flatten,
 } from '@src/shared/src/util/general';
// Constants
import { ENVIRONMENT, STATUS } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import {
  TariffModel,
  TripModel,
  LegModel,
  BasketItemModel
} from '@src/shared/src/models';
import { ITariffBooking } from '@src/shared/src/interfaces';
import { selectors } from '@src/shared/src';
import { ConnectedRedux, IRootState } from '@src/store';
// Components
import { BookedItemInfo } from '@src/pod/checkout/components';
// Styles

type Props = ConnectedRedux<IRootState> & {
  trip:TripModel;
  basketStatus:STATUS;
  env:ENVIRONMENT;
  basketItems:BasketItemModel[];
};

const BookedItemsInfo = ({ trip, basketStatus, env, basketItems }:Props) => {

  /**
   * This method returns the booking details for all selected tariffs
   * @param {TripModel} tripA
   * @return {ITariffBooking[]}
   */
  const getTariffsBooking = (trip:TripModel) => {
    if (env === ENVIRONMENT.CONFIRMATION || env === ENVIRONMENT.BOOKED) {
      const tariffBookings = pipe(
        getMainLegs,
        map((tripLeg:LegModel) => findBySelected(tripLeg.legOption.tariffs)),
        map((selectedTariff:TariffModel) => selectedTariff.id),
        map((tariffId:number) => {
          const tariffBooking:ITariffBooking = {
            bookedStatus: getTariffBookedStatus(basketItems, tariffId),
            bookedTravelClass: getTariffBookedTravelClass(basketItems, tariffId),
            seatReservation: getTariffSeatReservation(basketItems, tariffId),
            documents: getTariffDocuments(basketItems, tariffId),
            orderNumber: getTariffOrderNumber(basketItems, tariffId)
          };
          return tariffBooking;
        })
      )(trip.legs);
      return tariffBookings;
    } else {
      return [];
    }
  };


  if (basketStatus === STATUS.CONFIRM_FINISHED) {
    const tariffBookings = getTariffsBooking(trip);
    return (
      <BookedItemInfo
        env={env}
        bookingNumbers={
          map((tariffBooking:ITariffBooking) => tariffBooking.orderNumber, tariffBookings)
        }
        documents={
          flatten(
            map((tariffBooking:ITariffBooking) => tariffBooking.documents, tariffBookings)
          )
        }/>
    );
  }

  return (<span></span>) ;
};

const mapState = (state:IRootState) => ({
  basketItems: selectors.checkout.basketItems(state.checkout),
  basketStatus: selectors.checkout.basketStatus(state),
  uiTripChangingTariffsloader: state.ui.uiTripChangingTariffsloader,
});

export default connect(mapState)(BookedItemsInfo);
