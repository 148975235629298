import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter, NumberToStringConverter } from './converters';
import HotelModel from './HotelModel';

@JsonObject('BookingHotelFareModel')
export default class BookingHotelFareModel {
  @JsonProperty('id', NumberToStringConverter) public id:string = undefined;
  @JsonProperty('breakfast', Boolean) public breakfast:boolean = undefined;
  @JsonProperty('conditions', String) public conditions:string = undefined;
  @JsonProperty('created_at', DateConverter) public createdAt:Date = undefined;
  @JsonProperty('currency', String, true) public currency:string = undefined;
  @JsonProperty('price', Number, true) public price:number = undefined;
  @JsonProperty('room_type', String) public roomType:string = undefined;
  @JsonProperty('payment_type', ) public paymentType:string = undefined;
  @JsonProperty('hotel', ) public hotel:HotelModel = undefined;
}
