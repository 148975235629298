import { AjaxError } from 'rxjs/ajax';

export class BaseError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class BaseAjaxError extends AjaxError {
  constructor(err: AjaxError, name: string, message = '') {
    super(message, err.xhr, err.request);
    this.name = name;
  }
}

export const buildBaseAjaxError = (err: AjaxError, name: string, message?: string) =>
  new BaseAjaxError(err, name, message || err.response);
