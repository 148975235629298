import * as React from 'react';
import { useDispatch } from 'react-redux';

// Utils
import { cond, equals, propEq, sortBy } from '@src/shared/src/util/general';
import { baseErrorCond, navigate } from '@toolkit/util/app';
// Constants
import { ENVIRONMENT, ERRORS, STATUS } from '@src/shared/src/const/app';
import { ROUTES } from '@toolkit/const/app';
// Actions
import { hotelActions } from '@src/shared/src/actions';
import { setUiMobileNoScrolling } from '@pod/ui/actions';
// Models
import { HotelModel } from '@src/shared/src/models';
// Interfaces
// Components
import Hotel from './Hotel';
import { ScrollList } from '@toolkit/ui';
// Styles

type Props = {
  searchId: number;
  filteredHotels: HotelModel[];
  hotelOpenedDetailsId: string;
  showBasketLockedOverlay: () => void;
  hotelRoomCount?: number;
  onShowMore?: (itemCount: number) => void;
};

const HotelsList: React.FC<Props> = ({
  filteredHotels,
  hotelRoomCount,
  hotelOpenedDetailsId,
  searchId,
  showBasketLockedOverlay,
  onShowMore,
}) => {
  const dispatch = useDispatch();
  const [isSelectingHotel, setIsSelectingHotel] = React.useState<boolean>(false);

  const onFetchDetail = (hotelId: string) => {
    dispatch(
      hotelActions.fetchHotelDetailsAsync.request({
        onError: cond(baseErrorCond),
        hotelId,
      }),
    );
  };

  const onSelectHotel = (hotelId: string, hotelFareIds: string[]) => {
    setIsSelectingHotel(true);
    dispatch(
      hotelActions.addHotelFaresToBasketAsync.request({
        onSuccess: () => {
          dispatch(hotelActions.setSelectedHotelId(hotelId));
          dispatch(setUiMobileNoScrolling(false));
          navigate(`${ROUTES.BOOKING.RESERVE}${searchId}`);
        },
        onError: (err) => {
          setIsSelectingHotel(false);
          return cond([
            [equals(ERRORS.UNPROCESSABLE_ENTITY), showBasketLockedOverlay],
            ...baseErrorCond,
          ])(err);
        },
        hotelFareIds,
      }),
    );
  };

  // show failed hotels first
  const hotelsByStatus: HotelModel[] = sortBy(propEq(STATUS.FAILED, 'detailsStatus'))(
    filteredHotels,
  );

  return (
    <div className="hotels-list">
      <ScrollList
        items={hotelsByStatus}
        render={(hotel) => (
          <Hotel
            onFetchDetail={onFetchDetail}
            onSelectHotel={(hotelFareIds) => onSelectHotel(hotel.id, hotelFareIds)}
            hotel={hotel}
            env={ENVIRONMENT.SEARCH}
            isSelectingHotel={isSelectingHotel}
            hotelRoomCount={hotelRoomCount}
            detailsOpened={hotel.id === hotelOpenedDetailsId}
            onCloseDetail={() => {}}
          />
        )}
        targetItemId={hotelOpenedDetailsId}
        fetchDetails={onFetchDetail}
        onShowMore={onShowMore}
      />
    </div>
  );
};

export default HotelsList;
