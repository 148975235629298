import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export default class StringToNumberConverter implements JsonCustomConvert<number> {
  public serialize(num:number): any {
    return num;
  }

  public deserialize(str:string): number {
    return Number(str);
  }
}