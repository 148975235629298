import * as React from 'react';
import Select from 'react-select';
import { t } from '@toolkit/util/i18n';

export default (props) => (
  <div>
    <Select
      {...props}
      value={props.input.value}
      onChange={(value) => props.input.onChange(value)}
      onBlur={() => props.input.onBlur(props.input.value)}
      options={props.options}
      placeholder={props.placeholder ? props.placeholder : t('global.select')}
    />
    {props.meta.touched && props.meta.error && (
      <div className="form-error-message">{props.meta.error}</div>
    )}
  </div>
);
