// Utils
import { store } from '@src/store/store.config';
// Constants
// Actions
// Models
import { HotelModel } from '@src/shared/src/models';
// Interfaces
// Subscriptions
// Queries

export const findHotelById = (hotelId: number) =>
  store.getState().hotels.hotels.find((hotel: HotelModel) => Number(hotel.id) === hotelId);
