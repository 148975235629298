import { JsonObject, JsonProperty} from 'json2typescript';

import LegModel from './LegModel';
import { CeilConverter } from './converters';

@JsonObject('TripModel')
export default class TripModel {
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('base_total', Number) public baseTotal:number = -1;
  @JsonProperty('duration', CeilConverter) public duration:number = -1;
  @JsonProperty('legs', [LegModel]) public legs:LegModel[] = [];
  @JsonProperty('expanded', Boolean) public expanded:boolean = false;
  @JsonProperty('status', String) public status:string = undefined;
  @JsonProperty('changeovers', Number) public changeovers:number = -1;

  // Custom
  public isRefreshing:boolean = false;
}
