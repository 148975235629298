import * as React from 'react';

import { t } from '@toolkit/util/i18n';
import '../styles/CheckoutPrice.scss';

type Props = {
  costCenter: string;
  costUnit: string;
  referenceCode: string;
  remarks: string;
};

export const CheckoutFooterConfirm: React.FC<Props> = (props) => {
  const checkoutFooterContent = [];
  props.costCenter &&
    checkoutFooterContent.push(
      <p key="costCenter">
        {t('CheckoutFooter.label.costCenter')}: &nbsp;<strong>{props.costCenter}</strong>
      </p>,
    );
  props.costUnit &&
    checkoutFooterContent.push(
      <p key="costUnit">
        {t('CheckoutFooter.label.costUnit')}: &nbsp;<strong>{props.costUnit}</strong>
      </p>,
    );
  props.referenceCode &&
    checkoutFooterContent.push(
      <p key="referenceCode">
        {t('CheckoutFooter.label.referenceCode')}:&nbsp; <strong>{props.referenceCode}</strong>
      </p>,
    );
  props.remarks &&
    checkoutFooterContent.push(
      <p key="remarks">
        {t('checkout-footer.label.remarks')}:&nbsp; <strong>{props.remarks}</strong>
      </p>,
    );

  if (checkoutFooterContent.length !== 0) {
    return <div className="lp-checkout-price lp-checkout-price-info">{checkoutFooterContent}</div>;
  } else {
    return null;
  }
};
