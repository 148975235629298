import { JsonObject, JsonProperty } from 'json2typescript';
import {
  DateConverter
} from './converters';

@JsonObject('EventModel')
export default class EventModel {
  @JsonProperty('id', Number) public id: number = undefined;
  @JsonProperty('name', String) public name: string = undefined;
  @JsonProperty('description', String) public description: string = undefined;
  @JsonProperty('start_on', DateConverter) public startOn: Date = undefined;
  @JsonProperty('end_on', DateConverter) public endOn: Date = undefined;
  @JsonProperty('created_at', DateConverter) public createdAt: Date = undefined;
  @JsonProperty('updated_at', DateConverter) public updatedAt: Date = undefined;
}
