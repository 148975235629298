import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import { switchMap, mergeMap, catchError, filter } from 'rxjs/operators';
import { format } from 'date-fns';

// Utils
import { jsonConvert } from '../util/general';
import {
  apiRequest,
  apiRequestError,
} from '../util/api';
// Constants
import { API_URL } from '../const/api';
// Actions
import {
  appSettingsActions,
  generalActions,
} from '../actions';
// Models
import { AppSettingsModel } from '../models';
// Interfaces
import { RootAction } from '../interfaces';

export const fetchAppSettingsEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(appSettingsActions.fetchAppSettingsAsync.request)),
    switchMap((action) =>
      apiRequest(`${API_URL.SETTINGS}?_=${format(new Date(), 'yyyyMMddHHmm')}`).pipe(
        mergeMap((result:any) =>
          [
            appSettingsActions.setAppSettings(jsonConvert.deserializeObject(result.response, AppSettingsModel)),
            generalActions.applyExtActionAsync.request({
              callback: action.payload.onSuccess, param: null,
            }),
          ]
        ),
        catchError((err) =>  {
          return apiRequestError(action.payload.onError, 'FetchAppSettingsError', err);
         }
        )
      )
    ));
