import * as React from 'react';

// Utils
import { isNilOrEmpty } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
import {
  TravelSearchAggregatorModel,
} from '@src/shared/src/models';
// Interfaces
// Components
import {
  WarningBanner
} from '@toolkit/ui';
// Styles

type Props = {
  searchWarnings:TravelSearchAggregatorModel[];
};

class AggregatorWarnings extends React.Component<Props> {
  private getAggregatorName = (aggregatorName:string) => {
    switch (aggregatorName) {
      case 'TaxiCalculator':
        return 'Taxi';
      case 'Aeroplan':
        return t('global.flight');
      default:
        return aggregatorName;
    }
  }

  public render() {
    if (!isNilOrEmpty(this.props.searchWarnings)) {
      const body = this.props.searchWarnings.map((warning, idx) => {
        return (
          <p key={`warning-${idx}`}>
            {t(`tripsConn.warning.text.${warning.lastErrorStatus}`, {
              aggregatorName: this.getAggregatorName(warning.aggregatorName),
            })}
          </p>
        );
      });
      return <WarningBanner label={body} showCloseBtn={true}/>;
    }

    return null;
  }
}

export default AggregatorWarnings;
