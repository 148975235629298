import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { notify, navigate } from '@toolkit/util/app';
// Constants
import { NOTIF_TYPE } from '@src/shared/src/const/app';
// Actions
import { usersActions } from '@src/shared/src/actions';
// Models
// Interfaces
import {
  IConnectedRedux,
  IRootState
} from '@src/store';
// Components
import { ForgotPasswordForm } from '@pod/admin_user/components';
// Styles

type Props = IConnectedRedux<IRootState>;
class ForgotPasswordConn extends React.Component<Props> {
  private handleSubmintForgotPass = (values) => {
    this.props.dispatch(
      usersActions.requestResetPasswordAsync.request({
        onSuccess: () => {
          notify('Please check your email inbox. We have sent you an email.', NOTIF_TYPE.SUCCESS);
          navigate('/auth/login');
        },
        onError: () => {
          notify('Email address is wrong', NOTIF_TYPE.ERROR);
        },
        email: values.email,
      })
    );
  }

  render() {
    return (
      <div>
        <ForgotPasswordForm onSubmit={this.handleSubmintForgotPass}/>
      </div>
    );
  }
}
export default connect()(ForgotPasswordConn);