import { combineReducers } from 'redux';
import { reducers } from '@src/shared/src';

import { ui, IUIState } from '@pod/ui';
// import { default as formReducer } from 'redux-form/es/reducer';
const formReducer = require('redux-form').reducer;

export interface IRootState {
  search: reducers.ISearchState;
  trips: reducers.ITripsState;
  hotels: reducers.IHotelState;
  settings: reducers.ISettingsState;
  ui: IUIState;
  appSettings: reducers.IAppSettingsState;
  adminUser: reducers.IAdminUserState;
  filters: reducers.IFilterState;
  checkout: reducers.ICheckoutState;
  organization: reducers.IOrganizationState;
  form: any;
}

export const rootReducer = combineReducers<IRootState>({
  search: reducers.search,
  trips: reducers.trips,
  hotels: reducers.hotels,
  settings: reducers.settings,
  ui,
  appSettings: reducers.appSettings,
  adminUser: reducers.adminUser,
  filters: reducers.filters,
  checkout: reducers.checkout,
  organization: reducers.organization,
  form: formReducer,
});
