import * as React from 'react';
import classnames from 'classnames';
// Utils
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import './styles/Tab.scss';

type Props = {
  label: string;
  onClick: () => void;
  active?: boolean;
};

const Tab: React.FC<Props> = (props) => (
  <button className={classnames('tab', { 'is--active': props.active })} onClick={props.onClick}>
    {props.label}
  </button>
);

export default Tab;
