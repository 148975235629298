import {
  addMinutes,
  getTime,
  setHours,
  setMinutes,
} from 'date-fns';

// Utils
import {
  partialRight,
  path,
  pipe,
  verifyProperty,
} from './general';
import { getTripLastTS } from './trips';
// Constants
import {
  DIRECTION,
  INBOUND_MIN_DEP_INVALID_INBOUNDS_FILTER,
  VEHICLE_TYPES,
  TIME_WINDOW_TYPE,
} from '../const/app';
// Actions, Models & Interfaces
import {
  TimeWindowFilterConstraint,
} from '../interfaces';
import {
  OptionModel,
  TripModel,
  UserModel,
} from '../models';
import { FilterOption } from '@src/models';

export const setTimeToDate = (hours: number, minutes: number = 0) =>
  pipe(partialRight(setHours, [hours]), partialRight(setMinutes, [minutes]));

export const getTimeWindow = (date:Date, timeWindow:string):TimeWindowFilterConstraint => {
  switch (timeWindow) {
    case TIME_WINDOW_TYPE.EARLY:
      return {
        currentMin: getTime(setTimeToDate(4)(date)),
        currentMax: getTime(setTimeToDate(8)(date)),
        timeWindow: TIME_WINDOW_TYPE.EARLY,
      };
    case TIME_WINDOW_TYPE.MORNING:
      return {
        currentMin: getTime(setTimeToDate(8)(date)),
        currentMax: getTime(setTimeToDate(12)(date)),
        timeWindow: TIME_WINDOW_TYPE.MORNING,
      };
    case TIME_WINDOW_TYPE.AFTERNOON:
      return {
        currentMin: getTime(setTimeToDate(12)(date)),
        currentMax: getTime(setTimeToDate(17)(date)),
        timeWindow: TIME_WINDOW_TYPE.AFTERNOON,
      };
    case TIME_WINDOW_TYPE.EVENING:
      return {
        currentMin: getTime(setTimeToDate(17)(date)),
        currentMax: getTime(setTimeToDate(21)(date)),
        timeWindow: TIME_WINDOW_TYPE.EVENING,
      };
    case TIME_WINDOW_TYPE.NIGHT:
      return {
        currentMin: getTime(setTimeToDate(21)(date)),
        currentMax: getTime(setTimeToDate(24)(date)),
        timeWindow: TIME_WINDOW_TYPE.NIGHT,
      };
    default:
      return {
        currentMin: -1,
        currentMax: -1,
        timeWindow: TIME_WINDOW_TYPE.ANYTIME,
      };
  }
};

export const getMinInboundDepTime = (selectedOutbound:TripModel, direction:DIRECTION) => {
  if (selectedOutbound && direction === DIRECTION.INBOUND) {
    const outboundArrTime = path(['arrAt'], getTripLastTS(selectedOutbound.legs));
    return {
      current: addMinutes(outboundArrTime, INBOUND_MIN_DEP_INVALID_INBOUNDS_FILTER),
    };
  } else {
    return {
      current: undefined,
    };
  }
};

export const getUserFilterVehiclesConstraints = (profile:UserModel):OptionModel[] => {
  return [
    {
      label: VEHICLE_TYPES.PLANE,
      value: verifyProperty(true, profile, ['preference', 'transportationPlane'])
    },
    {
      label: VEHICLE_TYPES.TRAIN,
      value: verifyProperty(true, profile, ['preference', 'transportationTrain'])
    },
    {
      label: VEHICLE_TYPES.BUS,
      value: verifyProperty(true, profile, ['preference', 'transportationBus'])
    },
  ];
};

export const getUserFilterAccommodationTypeConstraints = (profile: UserModel): FilterOption[] => {
  return [
    {
      name: 'vacation_rental',
      value: verifyProperty(true, profile, ['preference', 'hotelVacationRental'])
    },
    //We need this hardcoded because BE doesn't send the values for all possible options yet
    {
      name: 'hotel',
      value: true
    },
  ];
}