// Utils
import { map, filter, partial } from '../util/general';
// Constants
// Actions, Models & Interfaces
import { HotelModel } from '../models';
import { IHotelFilter } from '../interfaces';

export type HotelStarFilterConstraint = {
  min:number;
  max:number;
  currentMin:number;
  currentMax:number;
}

export default class HotelStarFilter implements IHotelFilter<HotelStarFilterConstraint> {
  private constructor() {}

  private static instance:HotelStarFilter;

  static getInstance = () => {
    if (!HotelStarFilter.instance) {
      HotelStarFilter.instance = new HotelStarFilter();
    }
    return HotelStarFilter.instance;
  }

  public id:string = 'HotelStarFilter';

  public getConstraints = (hotels:HotelModel[]):HotelStarFilterConstraint => {
    const comfortClasses = map((hotel:HotelModel) => hotel.comfortClass, hotels);

    return {
      min: Math.min(...comfortClasses),
      max: Math.max(...comfortClasses),
      currentMin: Math.min(...comfortClasses),
      currentMax: Math.max(...comfortClasses),
    }
  }

  public process = (constraint:HotelStarFilterConstraint, hotels:HotelModel[]) =>
    filter(partial(this.match, [constraint]), hotels);

  private match = (constraint:HotelStarFilterConstraint, hotel:HotelModel) =>
    (hotel.comfortClass <= constraint.currentMax && hotel.comfortClass >= constraint.currentMin);
}