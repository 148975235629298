import { Epic } from 'redux-observable';
import { isActionOf, getType } from 'typesafe-actions';
import { mergeMap, switchMap, map, catchError, filter } from 'rxjs/operators';

// Utils
import { jsonConvert, isNotNilOrEmpty, getWSCallKey } from '../util/general';
import {
  apiURL,
  apiRequest,
  apiRequestError,
} from '../util/api';
import { ActionCableObservable } from '../util/ActionCableObservable';
// Constants
import { API_URL, WS_ACTIONS, WS_CHANNELS, API_METHOD_TYPE } from '../const/api';
import { STATUS, ERRORS } from '../const/app';
// Actions
import {
  hotelActions,
  filterActions,
  generalActions,
  settingsActions,
} from '../actions';
// Models
import { HotelModel } from '../models';
// import { HotelFeature } from '@src/models';
// Interfaces
import { RootAction, RootState } from '../interfaces';

const applyAddHotels = (hotels:HotelModel[], status:string, act:any) => {
  const actionArr:RootAction[] = [];
  actionArr.push(hotelActions.appendHotels(hotels));

  if (hotels.length === 1 && hotels[0].expanded) { // Update details
    if (hotels[0].detailsStatus === STATUS.FAILED) {
      actionArr.concat([
        generalActions.applyExtActionAsync.request({
          callback: act.payload.onError,
          param: ERRORS.DETAILS_FAILED,
        }),
        settingsActions.setWSCallEnd(
          getWSCallKey(getType(hotelActions.fetchHotelDetailsAsync.request), hotels[0].id),
        ),
      ]);
    } else if (hotels[0].detailsStatus === STATUS.FINISHED) {
      actionArr.push(
        settingsActions.setWSCallEnd(
          getWSCallKey(getType(hotelActions.fetchHotelDetailsAsync.request), hotels[0].id),
        ),
      );
    }

    actionArr.push(hotelActions.addFilteredHotel(hotels[0]));
  } else { // Update hotels and filtered hotels
    actionArr.push(hotelActions.setFilteredHotels(hotels));
    if (status === STATUS.FINISHED) { // Initialize filters and execute external actions if finished
      actionArr.push(filterActions.initializeHotelFilters(''));
      actionArr.push(
        generalActions.applyExtActionAsync.request({
          callback: act.payload.onSuccess,
          param: null,
        })
      );
      actionArr.push(settingsActions.setWSCallEnd(getWSCallKey(act.type, act.payload.searchId)));
    }
  }

  return actionArr;
};

export const fetchHotelsEpic:Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(hotelActions.fetchHotelsAsync.request)),
    switchMap((action) => {
      const hotelObservable = ActionCableObservable(
        {
          channel: WS_CHANNELS.HOTELS,
          travel_search_id: action.payload.searchId,
          language: store$.value.adminUser.profile.preference.language,
        },
        {
          action: WS_ACTIONS.REBROADCAST,
          travel_search_id: action.payload.searchId,
        }
      );

      return hotelObservable.pipe(
        switchMap((res:any) => {
          if (isNotNilOrEmpty(res.hotels) && res.status !== STATUS.FAILED) {
            const hotels = res.hotels.map((ht:any) => jsonConvert.deserialize(ht, HotelModel));
            const actionArr = applyAddHotels(hotels, res.status, action);
            actionArr.push(hotelActions.setAllowanceMatchingHotelIds(res.allowance_matching_hotel_ids))
            return actionArr;
          } else {
            const actionArr:RootAction[] = [];
            if (res.status === STATUS.FINISHED) {
              actionArr.push(
                generalActions.applyExtActionAsync.request({
                  callback: action.payload.onSuccess, param: null,
                })
              );
              actionArr.push(settingsActions.setWSCallEnd(getWSCallKey(action.type, action.payload.searchId)));
            } else if (res.status === STATUS.FAILED) {
              actionArr.push(
                generalActions.applyExtActionAsync.request({
                  callback: action.payload.onError, param: ERRORS.FAILED,
                })
              );
            } else if (res.status === STATUS.IN_PROGRESS) {
              actionArr.push(
                generalActions.noOp()
              );
            } else if (res.status === STATUS.TRIGGERED) {
              actionArr.push(
                generalActions.noOp()
              );
            }
            return actionArr;
          }
        })
      );
    })
  );

export const fetchHotelDetailsEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(hotelActions.fetchHotelDetailsAsync.request)),
    switchMap((action) =>
      apiRequest(`${API_URL.HOTEL_DETAILS}${action.payload.hotelId}/details`, API_METHOD_TYPE.POST)
        .pipe(
          map(() => hotelActions.fetchHotelDetailsAsync.success()),
          catchError((err) => apiRequestError(action.payload.onError, 'FetchHotelDetailsError', err))
        )
    )
  );

export const addHotelFaresToBasketEpic:Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(hotelActions.addHotelFaresToBasketAsync.request)),
    switchMap((action) =>
      apiRequest(
        `${API_URL.BASKETS}/${store$.value.search.currentSearch.basketId}`,
        API_METHOD_TYPE.PUT,
        {
          _method: 'put',
          basket: {
            hotel_fare_ids: action.payload.hotelFareIds,
          },
        }
      )
      .pipe(
        mergeMap((res:any) => {
          return [
            hotelActions.addHotelFaresToBasketAsync.success(),
            generalActions.applyExtActionAsync.request({
              callback:action.payload.onSuccess, param:res.response,
            }),
          ];
        }),
        catchError((err) => apiRequestError(action.payload.onError, 'AddHotelFaresToBasketError', err))
      )
    )
  );

export const setFavoriteHotelEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(hotelActions.setFavoriteHotelAsync.request)),
    switchMap((action) =>
      apiRequest(
        apiURL.hotels.setFavorite(action.payload.hotelId),
        action.payload.isFavorite ? API_METHOD_TYPE.POST : API_METHOD_TYPE.DELETE
        )
        .pipe(
          map(() => hotelActions.setFavoriteHotelAsync.success()),
          catchError((err) => apiRequestError(action.payload.onError, 'SetFavoriteHotelError', err))
        )
    )
  );
