import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export default class NumberToStringConverter implements JsonCustomConvert<string> {
  public serialize(val:string): any {
    return val;
  }

  public deserialize(val:number): string {
    return String(val);
  }
}