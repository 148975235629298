import * as React from 'react';

// Utils
import i18n from '@toolkit/util/i18n';
// Constants
import { ENVIRONMENT } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Components
// Styles
import '../styles/TripTimeInfo.scss';

type Props = {
  tripTime:string;
  tripDuration:string;
  tripChangeOvers:number;
  env:ENVIRONMENT;
};

export const TripTimeInfo: React.FC<Props> = (props) => {
  return (
    <span>
      <span className="tcp-trip-time-info-duration">{props.tripDuration}</span>
      <span>
        {props.tripChangeOvers}
        <span>&nbsp;{i18n.t('TripTimeInfo.label.stops')}</span>
      </span>
    </span>
  );
};

export default TripTimeInfo;
