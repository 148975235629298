import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { head, isNil } from '@src/shared/src/util/general';
import { getProduct, getOperator } from '@src/shared/src/util/trips';
import { getVehicleIcon } from '@toolkit/util/app';
import { formatDate } from '@src/shared/src/util/date';
// Constants
import { ENVIRONMENT, DATE_FORMAT_TYPES, VEHICLE_TYPES } from '@src/shared/src/const/app';
// Actions
// Models
import { TransportSegmentModel } from '@src/shared/src/models';
// Interfaces
// Components
// Styles
import '../styles/TransportSegment.scss';

type Props = {
  env:ENVIRONMENT;
  transportSegment:TransportSegmentModel;
};

export default class TransportSegment extends React.PureComponent<Props> {

  private getPlatformMarkup = (env:ENVIRONMENT, isDep:boolean, transportSegment:TransportSegmentModel) => {
    const showPlatform = isDep ? !isNil(transportSegment.depPlatform) : !isNil(transportSegment.arrPlatform);

    if (env !== ENVIRONMENT.SEARCH && showPlatform) {
      return (
          <div>
            {
              `${isDep ? t('transportSegment.label.platform.dep') : t('transportSegment.label.platform.arr')}
              ${isDep ? transportSegment.depPlatform : transportSegment.arrPlatform}`
            }
          </div>
      );
    }
  }

  private getProductMarkup = (transportSegment: TransportSegmentModel) => {
    // In case of flights we need to display the operator code as well
    const productName = head(transportSegment.vehicles) === VEHICLE_TYPES.PLANE ?
      `${getOperator(transportSegment.operators)} ${getProduct(transportSegment.products)}`
      :
      getProduct(transportSegment.products);

    const productIcon = transportSegment.iconURL ?
      <img className="product-icon" src={transportSegment.iconURL}/>
      :
      <i className={getVehicleIcon(head(transportSegment.vehicles))}/>;
    return (
      <div>
        {productIcon}
        {productName}
      </div>
    );
  }

  public render() {
    const { env, transportSegment } = this.props;
    return (
      <ol className="tcp-transport-segment">
        <li className="tcp-transport-segment-item">
          <span className="tcp-transport-segment-time">
            {formatDate(transportSegment.depAt, DATE_FORMAT_TYPES.LONG_TIME)}
          </span>
          <span className="tcp-transport-segment-location">
            {transportSegment.depName}
          </span>
          <span className="tcp-transport-segment-direction">
            {this.getPlatformMarkup(env, true, transportSegment)} {this.getProductMarkup(transportSegment)}
          </span>

        </li>
        <li className="tcp-transport-segment-item">
          <span className="tcp-transport-segment-time">
            {formatDate(transportSegment.arrAt, DATE_FORMAT_TYPES.LONG_TIME)}
          </span>
          <div className="tcp-transport-segment-inner">
            <span className="tcp-transport-segment-direction">
              {this.getPlatformMarkup(env, false, transportSegment)}
            </span>
            <span className="tcp-transport-segment-location">
              {transportSegment.arrName}
            </span>
          </div>
        </li>
      </ol>
    );
  }
}
