import * as React from 'react';
import './styles/NoResultsWarning.scss';

type Props = {
  title:string;
  bodyText:string;
  buttonText:string;
  onClick:() => void;
  withBackground?:boolean;
}

const NoResultsWarning:React.FC<Props> = (props:Props) =>
  <div className={`lp-noresults-warning ${props.withBackground ? 'has--background': ''}`}>
    <h2 className="lp-noresults-warning-title">{props.title}</h2>
    <div className="lp-noresults-warning-inner">
      {props.bodyText}
    </div>
    <button className="button" onClick={props.onClick}>
        {props.buttonText}
      </button>
  </div>

export default NoResultsWarning;
