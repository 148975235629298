import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('PolicyViolationHotelDataModel')
export default class PolicyViolationHotelDataModel {
  @JsonProperty('stars', Number) public stars:number = undefined;
  @JsonProperty('price', Number) public price:number = undefined;
  @JsonProperty('region_country', String) public regionCountry:string = undefined;
  @JsonProperty('region_continent', String) public regionContinent:string = undefined;
  @JsonProperty('region_name', String, true) public regionName:string = undefined;
  @JsonProperty('policy_price', Number) public policyPrice:number = -1;
  @JsonProperty('policy_stars', Number) public policyStars:number = -1;
  @JsonProperty('policy_price_with_breakfast', Number, true) public policyPriceWithBreakfast:number = undefined;
}
