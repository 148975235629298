import AddCardForm from './AddCardForm';
import EmailForm from './EmailForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import PasswordForm from './PasswordForm';
import { ResetPasswordForm } from './ResetPasswordForm';

export {
  AddCardForm,
  EmailForm,
  ForgotPasswordForm,
  PasswordForm,
  ResetPasswordForm,
};
