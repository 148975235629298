// Utils
import { map, filter, partial, values } from '../util/general';
// Constants
// Actions
// Models
import { TripModel } from '../models';
import { SingleFilterConstraint } from '../interfaces';
// Interfaces
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';

export default class TripDurationFilter implements IFilter<TripModel, SingleFilterConstraint> {
  private constructor() {}

  private static instance:TripDurationFilter;

  static getInstance = () => {
    if (!TripDurationFilter.instance) {
      TripDurationFilter.instance = new TripDurationFilter();
    }
    return TripDurationFilter.instance;
  }

  public id:string = 'TripDurationFilter';

  public getConstraints = (
    opts:ConstraintsOpts<TripModel, SingleFilterConstraint>
  ):SingleFilterConstraint => {
    const allDurations = values(map((trip:TripModel) => trip.duration, opts.values));
    return {
      min: Math.min(...allDurations),
      max: Math.max(...allDurations),
      current: (opts.reinit && opts.onlyUpdateConstraint)
        ? opts.constraint.current : Math.max(...allDurations),
    };
  }

  public process = (constraint:SingleFilterConstraint, trips:TripModel[]) =>
    filter(partial(this.match, [constraint]), trips)

  private match = (constraint:SingleFilterConstraint, trip:TripModel) =>
    trip.duration <= constraint.current

}