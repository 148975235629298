import * as React from 'react';
import { useSelector } from 'react-redux';

import { basketStatus, isAnyBookingItemAirbnb, isBookingConfirmFinished } from '@src/shared/src/selectors/checkoutSelectors';
import { t } from '@toolkit/util/i18n';
import { InformationOverlay } from '@toolkit/ui'
import { STATUS } from '@src/shared/src/const/app'

type Props = {
  isJourney: boolean;
}

export const CheckoutOverlayMessages: React.FC<Props> = (props) => {
  const bookingHasAirbnb = useSelector(isAnyBookingItemAirbnb)
  const bookingConfirmFinished = useSelector(isBookingConfirmFinished)
  const status = useSelector(basketStatus) as STATUS

  const confirmOverlayTextBody = bookingHasAirbnb && bookingConfirmFinished ? t('checkout-overlay-messages.text.airbnb-booking') : t('informationOverlay.body.bookingSuccess')

  return (
    <>
      <InformationOverlay
        key={`info-overlay-confirm-${status === STATUS.CONFIRM_FINISHED}`}
        isOpen={(status === STATUS.CONFIRM_FINISHED) && !props.isJourney}
        icon="icon-radio_button_unchecked2 color-blue"
        title={bookingHasAirbnb ? t('checkout-overlay-messages.title.congratulation-airbnb') : t('informationOverlay.title.congratulation')}
        body={confirmOverlayTextBody}
        showButton={true}
        wrapperCSS='tcp-loader-overlay-inner'
        buttonText={t('informationOverlay.button.proceedConfirmation')} />
    </>
  )
}
