import { DateFormatter, CurrencyFormatter, NumberFormatter } from '../formatters';

type LocaleResolverOptions = {
  locale:string;
  currency:string;
};
export default class LocaleResolver {
  private constructor() {}

  private static instance:LocaleResolver;

  public locale:string;
  public currency:string;

  static setupInstance = (options:LocaleResolverOptions) => {
    LocaleResolver.instance = new LocaleResolver();
    LocaleResolver.instance.locale = options.locale;
    LocaleResolver.instance.currency = options.currency;

    // We need to reinit the formatters on locale changed
    DateFormatter.initFormatter(options.locale);
    CurrencyFormatter.initFormatter(options.locale);
    NumberFormatter.initFormatter(options.locale);
  }

  static getInstance = () => {
    if (!LocaleResolver.instance) {
      throw new Error('Setup the LocaleResolver by calling setupInstance() first');
    }
    return LocaleResolver.instance;
  }
}