import { JsonObject, JsonProperty } from 'json2typescript';
import RentalFareModel from './RentalFareModel';
import RentalVehicleModel from './RentalVehicleModel';
import RentalSearchModel from './RentalSearchModel';

@JsonObject('RentalModel')
export default class RentalModel {
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('search_id', Number) public searchId:number = -1;
  @JsonProperty('vehicle_id', Number) public vehicleId:number = -1;
  @JsonProperty('vehicle', RentalVehicleModel) public vehicle:RentalVehicleModel = undefined;
  @JsonProperty('arr_information', String) public arrInformation:string = '';
  @JsonProperty('dep_information', String) public depInformation:string = '';
  @JsonProperty('arr_out_of_hours', Boolean) public arrOutOfHours:boolean = undefined;
  @JsonProperty('dep_out_of_hours', Boolean) public depOutOfHours:boolean = undefined;
  @JsonProperty('expanded', Boolean) public expanded:boolean = false;
  @JsonProperty('information_url', String) public informationUrl:string = '';
  @JsonProperty('fares', [RentalFareModel]) public fares:RentalFareModel[] = [];
  @JsonProperty('search', RentalSearchModel) public rentalSearch:RentalSearchModel = undefined;
  // @JsonProperty('created_at', String) public createdAt:string =  "2019-05-20T10:15:54.128Z";
  // @JsonProperty('updated_at', String) public updatedAt:string =  "2019-05-20T10:15:54.128Z";
}
