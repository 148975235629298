// Utils
import { ascend, sortWith, prop, path, pipe, values } from '../util/general';
import { getTripFirstTS, getTripLastTS } from '../util/trips';
// Constants
import { TRANSPORT_SORT_VALUES } from '../const/app';
// Actions
// Models
import { TripModel } from '../models';
// Interfaces
import { SortingFilterConstraint } from '../interfaces';
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';

export default class TripSortingFilter implements IFilter<TripModel, SortingFilterConstraint> {
  private constructor() {}

  private static instance: TripSortingFilter;

  static getInstance = () => {
    if (!TripSortingFilter.instance) {
      TripSortingFilter.instance = new TripSortingFilter();
    }
    return TripSortingFilter.instance;
  };

  public id: string = 'TripSortingFilter';

  public getConstraints = (
    opts: ConstraintsOpts<TripModel, SortingFilterConstraint, SortingFilterConstraint>,
  ): SortingFilterConstraint => {
    if (opts.reinit && opts.onlyUpdateConstraint) {
      return opts.constraint;
    }

    return opts.userConstraint.current
      ? opts.userConstraint
      : { current: TRANSPORT_SORT_VALUES.PRICE };
  };

  private sortById = ascend(prop('id'));
  private sortByPrice = ascend(prop('baseTotal'));
  private sortByDuration = ascend(prop('duration'));
  private sortByDepTime = ascend(pipe(path(['legs']), pipe(getTripFirstTS, path(['depAt']))));
  private sortByArrTime = ascend(pipe(path(['legs']), pipe(getTripLastTS, path(['arrAt']))));

  public process = (constraint: SortingFilterConstraint, trips: TripModel[]) => {
    switch (constraint.current) {
      case TRANSPORT_SORT_VALUES.PRICE:
        return sortWith([this.sortByPrice, this.sortByDuration, this.sortById])(values(trips));
      case TRANSPORT_SORT_VALUES.DURATION:
        return sortWith([this.sortByDuration, this.sortByPrice, this.sortById])(values(trips));
      case TRANSPORT_SORT_VALUES.DEP_AT:
        return sortWith([this.sortByDepTime, this.sortByPrice, this.sortById])(values(trips));
      case TRANSPORT_SORT_VALUES.ARR_AT:
        return sortWith([this.sortByArrTime, this.sortByPrice, this.sortById])(values(trips));
    }
  };
}
