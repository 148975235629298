import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { searchHooks } from '@src/services';
// Constants
// Actions
// Models
// Interfaces
// Components
import { Button } from '@toolkit/ui';

type Props = {
  onStartSearch: () => void;
  isLoading: boolean;
};

export const SearchSubmitButton: React.FC<Props> = props => {
  const isSearchValid = searchHooks.useIsSearchValid();
  return (
    <Button
      id="btnSearchTrip"
      className='button is--large'
      onClick={props.onStartSearch}
      disabled={!isSearchValid}
      isLoading={props.isLoading}>
      {t('search.bar.button')}
    </Button>
  );
};
