import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { formatDate } from '@src/shared/src/util/date';
// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions
// Models
import { SearchModel, RentalModel } from '@src/shared/src/models';
// Interfaces
// Components

type Props = {
  search:SearchModel;
  rental:RentalModel;
};

const RentalPickUpInfo:React.FC<Props> = (props: Props) =>
  <React.Fragment>
    <div className="lp-rental-pickup">
      <div className="lp-rental-pickup-label">
        {t('RentalPickUpInfo.label.pickUp')}:&nbsp;
      </div>
      <div className="lp-rental-pickup-value">
        {props.rental.rentalSearch.depStationName}<br/>
        {formatDate(props.search.depAt, DATE_FORMAT_TYPES.LONG_DATE_TIME)}
      </div>
    </div>
    <div className="lp-rental-pickup">
      <div className="lp-rental-pickup-label">
        {t('RentalPickUpInfo.label.return')}:&nbsp;
      </div>
      <div className="lp-rental-pickup-value">
        {props.rental.rentalSearch.arrStationName}<br/>
        {formatDate(props.search.arrAt, DATE_FORMAT_TYPES.LONG_DATE_TIME)}
      </div>
    </div>
  </React.Fragment>;

export default RentalPickUpInfo;
