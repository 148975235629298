import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export default class VehicleConverter implements JsonCustomConvert<string[]> {
  public serialize(vehicles:string[]):any {
    return vehicles;
  }

  public deserialize(vehicles:string[]):string[] {
    return vehicles.map((vehicle) => vehicle.toUpperCase());
  }
}