import { Epic } from 'redux-observable';
import { isActionOf, getType } from 'typesafe-actions';
import { mergeMap, switchMap, map, catchError, filter } from 'rxjs/operators';

// Utils
import { jsonConvert, isNotNilOrEmpty, getWSCallKey } from '../util/general';
import { getACObservable } from '../util/ActionCableObservable';
import {
  apiRequest,
  apiRequestError,
} from '../util/api';
// Constants
import { API_URL, API_METHOD_TYPE, WS_CHANNELS, WS_ACTIONS } from '../const/api';
import { DIRECTION, STATUS, ERRORS } from '../const/app';
// Actions
import {
  tripsActions,
  filterActions,
  generalActions,
  settingsActions,
} from '../actions';
// Models
import { TripModel } from '../models';
// Interfaces
import { RootAction, RootState } from '../interfaces';

const applyAddTrips = (direction:string, trips:TripModel[], status:string, act:any) => {
  const actionArr:RootAction[] = [];

  actionArr.push(tripsActions.addTrips(trips, direction as DIRECTION));
  // Apply filter based on length and if the trip is expanded
  if (trips.length === 1 && trips[0].expanded) { // Detail request
    if (trips[0].status === STATUS.FAILED) {
      actionArr.concat([
        generalActions.applyExtActionAsync.request({
          callback: act.payload.onError,
          param: ERRORS.DETAILS_FAILED,
        }),
        settingsActions.setWSCallEnd(
          getWSCallKey(getType(tripsActions.fetchTripDetailsAsync.request), trips[0].id),
        ),
      ]);
    } else if (trips[0].status === STATUS.FINISHED) {
      actionArr.push(
        settingsActions.setWSCallEnd(
          getWSCallKey(getType(tripsActions.fetchTripDetailsAsync.request), trips[0].id),
        ),
      );
    }

    actionArr.push(filterActions.initializeTripFilters(direction, true, true));
    actionArr.push(settingsActions.setWSCallEnd(
      getWSCallKey(getType(tripsActions.changeShuttleTimeAsync.request), trips[0].id)));
    actionArr.push(settingsActions.setWSCallEnd(
      getWSCallKey(getType(tripsActions.changeTariffAsync.request), trips[0].id)));

  } else { // Results
    actionArr.push(filterActions.initializeTripFilters(direction, false));
  }

  if (status === STATUS.FINISHED) {
    actionArr.push(settingsActions.setWSCallEnd(getWSCallKey(act.type, act.payload.searchId)));
    actionArr.push(settingsActions.setWSCallEnd(
      getWSCallKey(getType(tripsActions.changeShuttleTypeAsync.request))));
    actionArr.push(generalActions.applyExtActionAsync.request({ callback: act.payload.onSuccess, param: null }));
  }

  return actionArr;
};

export const fetchTripsEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(
      isActionOf(tripsActions.fetchTripsAsync.request)
    ),
    switchMap((action) => {
      const tripObservable =
        getACObservable(WS_CHANNELS.TRIPS, action.payload.searchId, WS_ACTIONS.EXISTING, null, action.payload.direction);

      return tripObservable.pipe(
        mergeMap((res:any) => {
          if (isNotNilOrEmpty(res.trips) && res.status !== STATUS.FAILED) {
            const trips = res.trips.map((tm:any) => jsonConvert.deserialize(tm, TripModel));
            const actionArr = applyAddTrips(
              res.direction,
              trips,
              res.status,
              action
            );
            actionArr.push(tripsActions.setAllowanceMatchingTripIds(res.allowance_matching_trip_ids));
            return actionArr;
          } else  {
            const actionArr:RootAction[] = [];
            if (res.status === STATUS.FINISHED) {
              actionArr.push(
                generalActions.applyExtActionAsync.request({
                  callback: action.payload.onSuccess, param: null,
                })
              );
              actionArr.push(settingsActions.setWSCallEnd(getWSCallKey(action.type, action.payload.searchId)));
            } else if (res.status === STATUS.FAILED) {
              actionArr.push(
                generalActions.applyExtActionAsync.request({
                  callback: action.payload.onError, param: ERRORS.FAILED,
                })
              );
            } else if (res.status === STATUS.IN_PROGRESS) {
              actionArr.push(
                generalActions.noOp()
              );
            }
            return actionArr;
          }
        })
      );
    })
  );

export const fetchTripDetailsEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(tripsActions.fetchTripDetailsAsync.request)),
    switchMap((action) =>
      apiRequest(`${API_URL.TRIPS}${action.payload.tripId}`)
        .pipe(
          map(() => tripsActions.fetchTripDetailsAsync.success()),
          catchError((err) => apiRequestError(action.payload.onError, 'FetchTripDetailsError', err))
        )
    )
  );

export const changeShuttleTypeEpic:Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(tripsActions.changeShuttleTypeAsync.request)),
    switchMap((action) => apiRequest(
        `${API_URL.BASKETS}/${store$.value.search.currentSearch.basketId}/change_shuttle`,
        API_METHOD_TYPE.PATCH,
        {
          _method: 'patch',
          basket: {
            [`${action.payload.direction}_shuttle_at_${action.payload.depOrArr}`]: action.payload.vehicle,
          },
        }
      ).pipe(
        map(() => tripsActions.changeShuttleTypeAsync.success()),
        catchError((err) => apiRequestError(action.payload.onError, 'ChangeShuttleTypeError', err))
      )
    )
  );

export const changeTariffEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(tripsActions.changeTariffAsync.request)),
    switchMap((action) => apiRequest(
        `${API_URL.TARIFF}${action.payload.tariffId}`,
        API_METHOD_TYPE.PUT,
        {
          _method: 'PUT',
        }
      ).pipe(
        map(() => tripsActions.changeTariffAsync.success()),
        catchError((err) => apiRequestError(action.payload.onError, 'ChangeTariffError', err))
      )
    )
  );

export const changeShuttleTimeEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(tripsActions.changeShuttleTimeAsync.request)),
    switchMap((action) => apiRequest(
        `${API_URL.TRIPS}/${action.payload.legOptionId}/${action.payload.timing}`,
        API_METHOD_TYPE.POST
      ).pipe(
        map(() => tripsActions.changeShuttleTimeAsync.success()),
        catchError((err) => apiRequestError(action.payload.onError, 'ChangeShuttleTimeError', err))
      )
    )
  );
