import { createStore, applyMiddleware, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import { rootEpic, rootReducer, IRootState } from '@src/store';

const configureStore = (initialState?: IRootState): Store<IRootState> => {
  const composeEnhancers = composeWithDevTools({
    maxAge: 50,
  });
  const epicMiddleware = createEpicMiddleware();

  // create store
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(epicMiddleware)),
  ) as Store<IRootState>;

  epicMiddleware.run(rootEpic);

  if (module.hot) {
    module.hot.accept('./root.reducer', () => {
      const nextRootReducer = require('./root.reducer');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
};

export const store = configureStore();
