import * as React from 'react';
import reduxForm from 'redux-form/es/reduxForm';
import Field from 'redux-form/es/Field';
import { FormProps } from 'redux-form/es/Form';

// Utils
import { t } from '@toolkit/util/i18n';
import { navigate } from '@toolkit/util/app';
// Constants
// Actions
// Models
// Interfaces
// Components
import { Button } from '@toolkit/ui';
// Styles
import '../styles/LoginForm.scss';

const TempLoginForm: React.FC<FormProps> = (props) => {
  return (
        <div className="tcp-login">
          <div className="tcp-login-content">
            <span className="tcp-login-logo"></span>
            <h2 className="tcp-login-title">{t('loginForm.title')}</h2>
            <p>{t('loginForm.subtitle')}</p>

            <div className="tcp-login-form">
              <form onSubmit={props.handleSubmit}>
                <div className="tcp-login-form-field">
                  <Field
                    className="tcp-login-form-text"
                    name="email"
                    component="input"
                    type="email"
                    placeholder={t('loginForm.form.label.email')}/>
                </div>
                <div className="tcp-login-form-field">
                  <Field
                    className="tcp-login-form-text"
                    name="password"
                    component="input"
                    type="password"
                    placeholder={t('loginForm.form.label.password')}/>
                </div>
                <div className="tcp-login-form-field">
                  <label>
                    <Field className="" id="remember_me" name="remember_me" component="input" type="checkbox"/>
                    {t('loginForm.form.label.rememberMe')}
                  </label>
                </div>
                <Button type="submit" className="button">{t('loginForm.form.button.login')}</Button>
              </form>
              <button
                className="tcp-login-password-link is-underlined"
                onClick={() => navigate('/auth/forgot_password')}>
                {t('loginForm.link.forgotPassword')}
              </button>
            </div>
          </div>
        </div>
  );
};

export default reduxForm({
  form: 'tempLogin',
})(TempLoginForm);
