// Make ie11 happy
require('core-js/features/promise');
require('core-js/features/object/assign');
require('core-js/features/object/values');
require('core-js/features/array/includes');
require('core-js/features/array/from');
require('core-js/features/array/find');
require('core-js/features/string/starts-with');
require('core-js/features/string/pad-start');
require('core-js/features/string/includes');
require('core-js/features/number/is-nan');

declare const __RELEASE_STAGE__: string;
declare const __COMMIT_HASH__: string;

import { getBrowserLocale } from '@toolkit/util/bootstrap';

const browserLocale = getBrowserLocale();

import LocaleResolver from '@src/shared/src/util/resolvers/LocaleResolver';
LocaleResolver.setupInstance({ locale: browserLocale, currency: 'EUR' });

import ConfigBootstrap from '@src/shared/src/config.bootstrap';
ConfigBootstrap.setupInstance({
  releaseStage: __RELEASE_STAGE__,
  commitHash: __COMMIT_HASH__,
  locale: browserLocale,
});

export { browserLocale };
