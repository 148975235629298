import { createAction } from 'typesafe-actions';
// Utils
// Constants
// Actions
// Models
import { OrganizationModel } from '../models';
// Interfaces

export const setOrganization = createAction(
  'organization/SET_ORGANIZATION', (resolve) => (val:OrganizationModel) => resolve(val)
);
