import * as React from 'react';

import { CheckboxFilter } from './CheckboxFilter';
import { FilterOption } from '@src/models';
import Switch from './Switch';
import { isNil } from '@src/shared/src/util/general';

type Props = {
  options: FilterOption[];
  filterType: 'switch' | 'checkbox';
  onChange(newOptions: FilterOption[]): void;
  labelTranslation?(name: string): string;
}

export const FilterOptionList: React.FC<Props> = (props) => {

  const setOption = (currentOption: FilterOption, val: boolean) => (option: FilterOption) => option.name === currentOption.name ? { ...currentOption, value: val } : option
  const setOnlyOption = (currentOption: FilterOption) => (option: FilterOption) => option.name === currentOption.name ? { ...currentOption, value: true } : { ...option, value: false }

  const onChangeOption = (currentOption: FilterOption, val?: boolean) => {
    const newOptions = props.options.map(isNil(val) ? setOnlyOption(currentOption) : setOption(currentOption, val))
    props.onChange(newOptions)
  }

  const getOptionLabel = (option: FilterOption) => {
    const optionLabel = props.labelTranslation ? props.labelTranslation(option.name) : option.name
    return option.count ? `${optionLabel} (${option.count})` : optionLabel
  }

  switch (props.filterType) {
    case 'checkbox':
      return (
        <>
          {props.options?.map((option) =>
            <CheckboxFilter
              key={`sel-id-${option.name}`}
              id={`sel-id-${option.name}`}
              label={getOptionLabel(option)}
              isChecked={Boolean(option.value)}
              onChange={val => onChangeOption(option, val)}
              onChangeOnly={_ => onChangeOption(option)} />
          )}
        </>
      );
    case 'switch':
      return (
        <>
          {props.options?.map((option) =>
            <Switch
              icon={option.icon}
              key={`sel-id-${option.name}`}
              isChecked={Boolean(option.value)}
              label={getOptionLabel(option)}
              onChange={val => onChangeOption(option, val)} />
          )}
        </>
      )
    default:
      return null;
  }
}
