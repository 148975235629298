import * as React from 'react';
import { Form, Field } from 'react-final-form';

// Utils
import { t } from '@toolkit/util/i18n';
import { required, passwordMinLen, composeValidators } from '@toolkit/util/formsValidation';
// Constants
// Actions
// Models
// Interfaces
// Components
import { FinalFormInput } from '@toolkit/ui';
// Styles

type Props = {
  handlePasswordReset: (values: any) => void;
};
export const ResetPasswordForm: React.FC<Props> = props => {
  return (
    <Form
      onSubmit={props.handlePasswordReset}
      render={({ handleSubmit }) => (
        <div className="tcp-login">
          <div className="tcp-login-content">
            <span className="tcp-login-logo"></span>
            <h2 className="tcp-login-title">{t('resetPasswordForm.title')}</h2>
            <p>{t('resetPasswordForm.subtitle')}</p>
            <div className="tcp-login-form">
              <form onSubmit={handleSubmit}>
                <div className="tcp-login-form-field">
                  <Field
                    className="tcp-login-form-text"
                    name="password"
                    component={FinalFormInput}
                    type="password"
                    validate={composeValidators(required, passwordMinLen)}
                    placeholder={t('resetPasswordForm.form.label.password')}
                  />
                </div>
                <div className="tcp-login-form-field">
                  <Field
                    className="tcp-login-form-text"
                    name="confirmPassword"
                    component={FinalFormInput}
                    type="password"
                    validate={composeValidators(required, passwordMinLen)}
                    placeholder={t('resetPasswordForm.form.label.confirmPassword')}
                  />
                </div>
                <button type="submit" className="button">
                  {t('resetPasswordForm.form.button.confirmReset')}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    />
  );
};
