import * as React from 'react';

// Utils
import { getUserFullname } from '@src/shared/src/util/users';
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
import { PassengerModel } from '@src/shared/src/models';
// Interfaces
// Components
// Styles

type Props = {
  passengers:PassengerModel[];
};

const CheckoutPaymentPassengersInfo:React.FC<Props> = (props:Props) =>
        <div className="checkout-box checkout-passengers box">
          <h2 className="checkout-title hidden-xs-up">{t('checkoutPaymentInfo.title.travellers')}</h2>
          <div className="checkout-box-content">
            <ul className="checkout-passengers-list">
              {props.passengers.map((passenger, idx) =>
                <li key={`passenger-${passenger.id}-${idx}`} className="checkout-passengers-item">
                  <h3>{t('checkoutPaymentInfo.label.traveler')} {idx + 1}</h3>
                  <div className="checkout-passenger-travler">
                    {getUserFullname(passenger)}
                  </div>
                </li>
              )}
            </ul>
            <p className="color-gray">
              <i className="icon-info icon-info__alignment-checkout" aria-hidden="true" />
              {t('checkoutPaymentInfo.text.description.travellersNames')}
            </p>
          </div>
        </div>;

export default CheckoutPaymentPassengersInfo;
