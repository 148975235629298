import { JsonObject, JsonProperty} from 'json2typescript';
import { DateConverter } from './converters';

@JsonObject('TravelBookingModel')
export default class TravelBookingModel {
  @JsonProperty('approved_at', DateConverter) public approvedAt:Date = undefined;
  // @JsonProperty('approved_by', String) public approvedBy:string = '';
  @JsonProperty('cost_center', String) public costCenter:string = '';
  @JsonProperty('cost_unit', String, true) public costUnit:string = '';
  @JsonProperty('created_at', DateConverter) public createdAt:Date = undefined;
  @JsonProperty('creator_id', Number) public creatorId:number = -1;
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('purpose_of_trip', String) public purposeOfTrip:string = '';
  @JsonProperty('reference_code', String) public referenceCode:string = '';
  @JsonProperty('remarks', String) public remarks:string = undefined;
  @JsonProperty('search_id', Number) public searchId:number = -1;
  @JsonProperty('updated_at', DateConverter) public updatedAt:Date = undefined;
}
