import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { map, formatCents, path, sortBy } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
import { ENVIRONMENT } from '@src/shared/src/const/app';
// Actions
// Models
import {
  BookingItemModel,
  BookingHotelFareModel,
  BookingTransportFareModel,
  UserModel,
} from '@src/shared/src/models';
// Interfaces
// Components
import { TransportationBookingInfo } from '@src/components/TransportationBookingInfo';
// Styles
import '../styles/BookingInfo.scss';
import BookingRentalFareModel from '@src/shared/src/models/BookingRentalFareModel';
import { IRootState, ConnectedRedux } from '@src/store';
import { selectors } from '@src/shared/src';

type Props = ConnectedRedux<IRootState> & {
  bookingItems: BookingItemModel[];
  env: ENVIRONMENT;
  bookingHasAirbnb: boolean;
  profile: UserModel;
};

class BookingInfo extends React.Component<Props> {
  private getHotelBookingMarkup = (fare: BookingHotelFareModel) => {
    return (
      <div className="lp-booking-info-item" key={`fare-hotel-${fare.id}`}>
        <div className="lp-booking-info-item-inner">
          <div className="lp-booking-info-item-title">
            {this.props.bookingHasAirbnb ? t('global.airbnb'): t('global.hotel')}
          </div>
          <b>{t('BookingItem.label.name')}:</b>
          &nbsp;{fare.hotel.name}
        </div>
      </div>
    );
  };

  private getRentalBookingMarkup = (fare: BookingRentalFareModel) => {
    return (
      <div className="lp-booking-info-item" key={`fare-rental-${fare.id}`}>
        <div className="lp-booking-info-item-inner">
          <div className="lp-booking-info-item-title">
            {t('BookingInfo.booking.subTitle.rental')}
          </div>
          <div>
            <b>{t('BookingItem.label.carType')}:</b>
            &nbsp; {`${fare.vehicleExamples[0]} ${t('rental.titel.similar')}`}
          </div>
        </div>
      </div>
    );
  };

  public renderBookingTotal = (totalPrice: number) => (
    <div className="lp-booking-info-sub-total">
      {t('BookingInfo.label.subTotal')}:
      <span className="lp-booking-info-sub-total-price">{formatCents(totalPrice)}</span>
    </div>
  );

  public render() {
    const hotelBookings = this.props.bookingItems.filter(
      (booking) => booking.fareType === 'hotel_fare',
    );
    const transportBookings = this.props.bookingItems.filter(
      (booking) => booking.fareType === 'transport_fare',
    );
    const rentalBookings = this.props.bookingItems.filter(
      (booking) => booking.fareType === 'rental_fare',
    );

    return (
      <div className="lp-booking-info">
        <div className="lp-booking-info-title">{t('BookingInfo.title.bookingInformation')}</div>
        <div>
          {transportBookings.length === 1 &&
            map((bookingItem: BookingItemModel) => {
              const transportFares = sortBy(path(['segment', 'depAt']))(bookingItem.transportFares);
              return (
                <div key={`booking-item-${bookingItem.id}`}>
                  <div className="lp-booking-info-items">
                    {map(
                      (fare: BookingTransportFareModel) => (
                        <TransportationBookingInfo
                          key={fare.id}
                          fare={fare}
                          env={this.props.env}
                          bookingItemSeatReservationInfo={bookingItem.seatReservation}
                          profile={this.props.profile}
                        />
                      ),
                      transportFares,
                    )}
                  </div>
                  {this.renderBookingTotal(bookingItem.totalPrice)}
                </div>
              );
            }, transportBookings)}
          {transportBookings.length > 1 &&
            map((bookingItem: BookingItemModel) => {
              return (
                <div key={`booking-item-${bookingItem.id}`}>
                  <div className="lp-booking-info-items">
                    {map(
                      (fare: BookingTransportFareModel) => (
                        <TransportationBookingInfo
                          key={fare.id}
                          fare={fare}
                          env={this.props.env}
                          bookingItemSeatReservationInfo={bookingItem.seatReservation}
                          profile={this.props.profile}
                        />
                      ),
                      bookingItem.transportFares,
                    )}
                  </div>
                  {this.renderBookingTotal(bookingItem.totalPrice)}
                </div>
              );
            }, sortBy(path(['transportFares', 0, 'segment', 'depAt']))(transportBookings))}
          {map((bookingItem: BookingItemModel) => {
            return (
              <div key={`booking-item-${bookingItem.id}`}>
                <div className="lp-booking-info-items">
                  {map(
                    (fare: BookingRentalFareModel) => this.getRentalBookingMarkup(fare),
                    bookingItem.rentalFares,
                  )}
                </div>
                {this.renderBookingTotal(bookingItem.totalPrice)}
              </div>
            );
          }, rentalBookings)}
          {map((bookingItem: BookingItemModel) => {
            return (
              <div key={`booking-item-${bookingItem.id}`}>
                <div className="lp-booking-info-items">
                  {map(
                    (fare: BookingHotelFareModel) => this.getHotelBookingMarkup(fare),
                    bookingItem.hotelFares,
                  )}
                </div>
                {this.renderBookingTotal(bookingItem.totalPrice)}
              </div>
            );
          }, hotelBookings)}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  bookingHasAirbnb: selectors.checkout.isAnyBookingItemAirbnb(state),
  profile: state.adminUser.profile,
});
export default connect(mapState)(BookingInfo);
