import { JsonObject, JsonProperty } from 'json2typescript'
import { DateConverter } from './converters'
import PolicyViolationModel from './PolicyViolationModel';

@JsonObject('BasketModel')
export default class BasketModel {
  @JsonProperty('created_at', DateConverter) public createdAt:Date = undefined
  @JsonProperty('id', Number) public id:number = -1
  @JsonProperty('inbound_shuttle_at_arrival', String) public inboundShuttleAtArrival:string = ''
  @JsonProperty('inbound_shuttle_at_departure', String) public inboundShuttleAtDeparture:string = ''
  @JsonProperty('outward_shuttle_at_arrival', String) public outwardShuttleAtArrival:string = ''
  @JsonProperty('outward_shuttle_at_departure', String) public outwardShuttleAtDeparture:string = ''
  @JsonProperty('travel_search_id', Number) public travelSearchId:number = -1
  @JsonProperty('updated_at', DateConverter) public updatedAt:Date = undefined
  @JsonProperty('user_id', Number) public userId:number = -1
  @JsonProperty('status', String) public status:string = ''
  @JsonProperty('editable', Boolean) public editable:boolean = undefined
  @JsonProperty('transport_total', Number) public transportTotal:number = -1
  @JsonProperty('hotel_total', Number) public hotelTotal:number = -1
  @JsonProperty('rental_total', Number) public rentalTotal:number = -1
  @JsonProperty('total', Number) public total:number = -1
  @JsonProperty('travel_policy_violations', [PolicyViolationModel], true)
    public travelPolicyViolations:PolicyViolationModel[] = undefined
  @JsonProperty('created_by', String, true) public createdBy:string = undefined
}
