import { from, combineLatest, Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { isSameDay, isBefore, subDays } from 'date-fns';

// Utils
import { path, equals, isNil } from '@src/shared/src/util/general';
import { IRootState } from '@src/store';
import { store } from '@src/store/store.config';
import { getStateStreamSlice } from '@toolkit/util/state-utils/state.util';
import { ISearchState } from '@src/shared/src/reducers/searchReducer';
import { errorLogger } from '@src/shared/src/util/errors';
// Constants
import { SEARCH_TYPE, ROLES, DIRECTION } from '@src/shared/src/const/app';
// Actions
// Models
import { PassengerModel, RebookingModel } from '@src/shared/src/models';
// Subscriptions
import * as userSubscriptions from '../user/user.subscriptions';

const _search$: Observable<ISearchState> =
  from(store as any).pipe(map((state: IRootState) => state.search));
const _searchType$ =
  getStateStreamSlice<ISearchState, SEARCH_TYPE>(_search$, ['currentSearch', 'searchType']);
const _depLat$ = getStateStreamSlice<ISearchState, number>(_search$, ['currentSearch', 'depLat']);
const _depLng$ = getStateStreamSlice<ISearchState, number>(_search$, ['currentSearch', 'depLng']);
const _arrLat$ = getStateStreamSlice<ISearchState, number>(_search$, ['currentSearch', 'arrLat']);
const _arrLng$ = getStateStreamSlice<ISearchState, number>(_search$, ['currentSearch', 'arrLng']);
const _rentalReturnAtPickup$ =
  getStateStreamSlice<ISearchState, boolean>(_search$, ['currentSearch', 'rentalReturnAtPickup']);
const _depAt$ = getStateStreamSlice<ISearchState, Date>(_search$, ['currentSearch', 'depAt']);
const _arrAt$ = getStateStreamSlice<ISearchState, Date>(_search$, ['currentSearch', 'arrAt']);
const _depRentalTime$ = getStateStreamSlice<ISearchState, string>(
  _search$, ['currentSearch', 'depRentalTime']);
const _arrRentalTime$ = getStateStreamSlice<ISearchState, string>(
  _search$, ['currentSearch', 'arrRentalTime']);
const _passengers$ = getStateStreamSlice<ISearchState, PassengerModel[]>(
  _search$, ['currentSearch', 'passengers']);
const _rebooking$ = getStateStreamSlice<ISearchState, RebookingModel>(
  _search$, ['currentSearch', 'rebooking']);

export const isDepMissing = () => combineLatest(_searchType$, _depLat$, _depLng$).pipe(
  map(([searchType, depLat, depLng]) =>
    (searchType !== SEARCH_TYPE.HOTEL) && (depLat === -1 || depLng === -1)
  )
);

export const isArrMissing = () => combineLatest(
  _searchType$, _rentalReturnAtPickup$, _arrLat$, _arrLng$).pipe(
    map(([searchType, rentalReturnAtPickup, arrLat, arrLng]) =>
      (searchType === SEARCH_TYPE.RENTAL && rentalReturnAtPickup)
        ? false
        : (arrLat === -1 || arrLng === -1)
    )
  );

export const isHotelSameDay = () => combineLatest(_searchType$, _depAt$, _arrAt$).pipe(
  map(([searchType, depAt, arrAt]) =>
    (searchType === SEARCH_TYPE.HOTEL) && isSameDay(depAt as Date, arrAt as Date)
  )
);

export const isArrDateMissing = () => combineLatest(_searchType$, _arrAt$, _arrRentalTime$).pipe(
  map(([searchType, arrAt, arrRentalTime]) => {
    switch (searchType) {
      case SEARCH_TYPE.RENTAL: return isNil(arrRentalTime) || isNil(arrAt);
      case SEARCH_TYPE.OUTBOUND: return false;
      default: return isNil(arrAt);
    }
  })
);

export const isDepDateMissing = () => combineLatest(_searchType$, _depAt$, _depRentalTime$).pipe(
  map(([searchType, depAt, depRentalTime]) => {
    switch (searchType) {
      case SEARCH_TYPE.RENTAL: return isNil(depRentalTime) || isNil(depAt);
      default: return isNil(depAt);
    }
  })
);

export const isDepArrSame = () => combineLatest(
  _searchType$, _depLat$, _depLng$, _arrLat$, _arrLng$).pipe(
    map(([searchType, depLat, depLng, arrLat, arrLng]) => {
      switch (searchType) {
        case SEARCH_TYPE.HOTEL: return false;
        case SEARCH_TYPE.RENTAL: return false;
        default: return (depLat === arrLat && depLng === arrLng);
      }
    })
  );

export const isDepDateInPast = () => combineLatest(_depAt$).pipe(
  map(([depAt]) => isBefore(depAt as Date, subDays(new Date(), 1)))
);

export const isRentalDriverCountWrong = () => combineLatest(_searchType$, _passengers$).pipe(
  map(([searchType, passengers]) =>
    (searchType === SEARCH_TYPE.RENTAL) && ((passengers as PassengerModel[]).length !== 1)
  )
);

export const isRentalDepartureTimeInPast = () =>
  combineLatest(_searchType$, _depAt$, _depRentalTime$).pipe(
    map(([searchType, depAt, depRentalTime]) => {
      if (searchType === SEARCH_TYPE.RENTAL && isSameDay(depAt as Date, new Date())) {
        const [hours, minutes] = depRentalTime.split(':').map(Number);
        depAt.setHours(hours, minutes);
        return isBefore(depAt, new Date());
      }
      return false;
    }),
  );
export const isPassengersMissing = () => _passengers$.pipe(
  map((passengers: PassengerModel[]) => passengers.length < 1)
);

export const isPassengersSelectionWrong = () => combineLatest(
  _passengers$, userSubscriptions.loggedInUserId(), userSubscriptions.canRemoveItselfAsPassenger()
).pipe(
  map(([passengers, loggedInUserId, canRemoveItselfAsPassenger]) =>
    canRemoveItselfAsPassenger
      ? false
      : !(passengers as PassengerModel[]).find(pass => pass.userId === loggedInUserId)
  )
);

export const isPassengersMoreThanAllowed = () => _passengers$.pipe(map(passengers => passengers.length > 5))

/**
 * we should not allow to search for a departure date in the past unless it is a rebooking search
 * @returns true if the departure date is in the past or if the search is a rebooking search
 */
const isDepDateInvalid = () => combineLatest(isDepDateInPast(), _rebooking$).pipe(
  map(([depDateInPast, rebooking]) => depDateInPast && isNil(rebooking))
);

export const isSearchValid = () => combineLatest(
  isDepMissing(),
  isArrDateMissing(),
  isArrMissing(),
  isHotelSameDay(),
  isDepDateMissing(),
  isDepArrSame(),
  isDepDateInvalid(),
  isRentalDriverCountWrong(),
  isPassengersMissing(),
  isDropoffBeforePickup(),
  isRentalDepartureTimeInPast(),
  isPassengersSelectionWrong(),
  isPassengersMoreThanAllowed(),
).pipe(
  map((values) => values.every(val => val === false))
);

export const isOnlyPassengerGuest = () => _passengers$.pipe(
  map((passengers: PassengerModel[]) =>
    passengers.length === 1 && passengers[0].role === ROLES.GUEST)
);

export const firstPassenger = (): Observable<PassengerModel> => _passengers$.pipe(
  map((passengers: PassengerModel[]) => {
    const firstPassenger = passengers.find((passenger) => passenger.isFirst);
    if (isNil(firstPassenger)) {
      if (process.env.NODE_ENV !== 'test') {
        errorLogger('Could not find firstPassenger', JSON.stringify(passengers));
      }
      return undefined;
    } else {
      return firstPassenger;
    }
  })
);

export const getSubscription = (statePath: string[]) => from(store as any).pipe(
  map((state: IRootState) => statePath ? path(statePath, state) : state.search),
  distinctUntilChanged(equals)
);

export const searchDate = (direction: DIRECTION) => combineLatest(_depAt$, _arrAt$).pipe(
  map(([depAt, arrAt]) => {
    switch (direction) {
      case DIRECTION.OUTWARD: return depAt;
      case DIRECTION.INBOUND: return arrAt;
    }
  })
);

export const isDropoffBeforePickup = () => combineLatest(
  _depAt$, _arrAt$, _depRentalTime$, _arrRentalTime$, _searchType$).pipe(
    map(([depAt, arrAt, depRentalTime, arrRentalTime, searchType]) =>
      (searchType === SEARCH_TYPE.RENTAL && isSameDay(depAt as Date, arrAt as Date) && depRentalTime > arrRentalTime)
    )
  );
