// Utils
import {
  propEq,
  pathOr,
  find,
} from './general';
// Constants
// Actions
// Models
import {
  BasketItemModel,
} from '../models';
// Interfaces

const findBaksetItem = (tariffId:number) => find(propEq('assemblyTariffId', tariffId));

export const getTariffBookedStatus = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['travelBookingItem', 'status'], findBaksetItem(tariffId)(basketItems));

export const getTariffBookedTravelClass = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['approvalTravelClass'], findBaksetItem(tariffId)(basketItems));

export const getTariffBasketItemId = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['id'], findBaksetItem(tariffId)(basketItems));

export const getTariffRequestSeatReservation = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['requestSeatReservation'], findBaksetItem(tariffId)(basketItems));

export const getTariffSeatReservation = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['travelBookingItem', 'seatReservation'], findBaksetItem(tariffId)(basketItems));

export const getTariffFareLuggage = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['travelBookingItem', 'fare', 'extra'], findBaksetItem(tariffId)(basketItems));

export const getTariffDocuments = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['travelBookingItem', 'visibleDocuments'], findBaksetItem(tariffId)(basketItems));


export const getTariffOrderNumber = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['travelBookingItem', 'orderNumber'], findBaksetItem(tariffId)(basketItems));
