import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
import { RedeemingAllowanceModel } from '@src/shared/src/models';
// Interfaces
// Components
import { WarningBanner } from '@toolkit/ui';
import { hotelHooks, tripHooks } from '@src/services';
import { DIRECTION } from '@src/shared/src/const/app';
import { isNil } from '@src/shared/src/util/general';
// Styles

type Props = {
  redeemingAllowance: RedeemingAllowanceModel;
  isLoading: boolean;
  selection: 'hotel' | 'transport';
  direction?: DIRECTION;
};
export const AllowanceSelectionNotification: React.FC<Props> = props => {
  const matchingItem =
    props.selection === 'hotel'
      ? hotelHooks.useAllowanceMatchingHotel()
      : tripHooks.useAllowanceMatchingTrip(props.direction);
  const showWarning = !props.isLoading && props.redeemingAllowance && isNil(matchingItem);

  return (
    <div>
      {showWarning && (
        <WarningBanner
          icon="lp-icon-help"
          isInfo={true}
          label={
            <div>
              {t('allowanceSelectionNotification.message.noSelectionFound')}
            </div>
          }
        />
      )}
    </div>
  );
};
