import { JsonObject, JsonProperty } from 'json2typescript';

import TariffFareTermsModel from './TariffFareTermsModel';

@JsonObject('TariffModel')
export default class TariffModel {
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('fare_name', String, true) public fareName:string = '';
  @JsonProperty('price_difference', Number, true) public priceDifference:number = -1;
  @JsonProperty('selected', Boolean) public selected:boolean = false;
  @JsonProperty('leg_option_id', Number, true) public legOptionId:number = -1;
  @JsonProperty('currency', String, true) public currency:string = '';
  @JsonProperty('fare_terms', TariffFareTermsModel, true) public fareTerms:TariffFareTermsModel = undefined;
}
