import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter, NumberToStringConverter } from './converters';

@JsonObject('HotelFareModel')
export default class HotelFareModel {
  @JsonProperty('id', NumberToStringConverter) public id:string = undefined;
  @JsonProperty('price', Number) public price:number = -1;
  @JsonProperty('currency', String) public currency:string = '';
  @JsonProperty('breakfast', Boolean) public breakfast:boolean= false;
  @JsonProperty('comfort_class', String, true) public comfortClass:string = '';
  @JsonProperty('refundable_until', DateConverter, true) public refundableUntil:Date = undefined;
  @JsonProperty('room_type', String, true) public roomType:string = '';
  @JsonProperty('conditions', String, true) public conditions:string = '';
  @JsonProperty('selected', Boolean, true) public selected:boolean = false;
  @JsonProperty('payment_type', String, true) public paymentType:string = '';
  @JsonProperty('negotiated_rate', Boolean) public negotiatedRate:boolean= false;
  @JsonProperty('provider', String) public provider:string= undefined;
  @JsonProperty('room_details', String) public roomDetails:string= undefined;
  @JsonProperty('breakfast_surcharge', Number) public breakfastSurcharge:number = undefined;
  @JsonProperty('breakfast_surcharge_currency', String)
  public breakfastSurchargeCurrency:string = undefined;
  @JsonProperty('deeplink', String, true) public deepLink: string = undefined;
}
