import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Utils
import { store } from '@src/store/store.config';
import { getStateStreamSlice } from '@toolkit/util/state-utils/state.util';
// Constants
import { DIRECTION } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
import { ITripsState } from '@src/shared/src/reducers/tripsReducer';
import { IRootState } from '@src/store';
// Subscriptions
// Queries
import * as tripQueries from './trip.queries';

const _trip$: Observable<ITripsState> =
  from(store as any).pipe(map((state: IRootState) => state.trips));

const _allowanceMatchingTripIds$ =
  getStateStreamSlice<ITripsState, number[]>(_trip$, ['allowanceMatchingTripIds']);

export const allowanceMatchingTrip = (direction: DIRECTION) => _allowanceMatchingTripIds$.pipe(
  map(([allowanceMatchingTripId]) => tripQueries.findTripById(allowanceMatchingTripId, direction))
);

export const tripsCount = (direction: DIRECTION) => _trip$.pipe(
  map((tripState) => Object.keys(tripState[direction]).length)
);

export const filteredTripsCount = (direction: DIRECTION) => _trip$.pipe(
  map((tripState) => Object.keys(tripState[`${direction}FilteredIds`]).length)
);
