import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { navigate } from '@toolkit/util/app';
import { formatDateRange } from '@src/shared/src/util/date';
// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
// Components
import { LabelButton } from '@toolkit/ui';
import {SidebarRoute} from '@toolkit/ui';

// Styles

interface IProps {
  searchId:number;
  to:string;
  depAt:Date;
  arrAt:Date;
  isSearchingForOnlyHotel:boolean;
}

const TripSideBarInfo: React.FC<IProps> = (props) => {
  const getHotelDetailsMarkup = () => (
        <SidebarRoute
          iconClass="icon-rooms"
          label={t('hotelSideBarInfo.label.in')}>
          {props.to}
        </SidebarRoute>
  );
  const getTripDateDetailsMarkup = () => (
    <SidebarRoute
      iconClass="icon-calendar"
      hasSpacing={true}>
      {`${formatDateRange(props.depAt, props.arrAt, null, DATE_FORMAT_TYPES.DAY_SHORT_DATE)}`}
    </SidebarRoute>
  );


  return (
    <div className="sidebar-section is-open">
      <div className="sidebar-section-inner">
        <div className="sidebar-trip-details hidden-xs-down">
          {getHotelDetailsMarkup()}
          {getTripDateDetailsMarkup()}

          <LabelButton
            onClick={() => navigate('/')}
            className="sidebar-reset">
            <i className="icon-refresh"/>{t('TripSideBarInfo.button.reset')}
          </LabelButton>
        </div>
      </div>
    </div>
  );
};

export default TripSideBarInfo;
