import { createAsyncAction } from 'typesafe-actions';

// Utils
// Constants
// Actions
// Models
// Interfaces
import { ActionCallback } from '../interfaces';
// Components
// Styles

export const removeItemFromBasketAsync = createAsyncAction(
  'basket/REMOVE_ITEM_FROM_BASKET_REQUEST',
  'basket/REMOVE_ITEM_FROM_BASKET_SUCCESS',
  'basket/REMOVE_ITEM_FROM_BASKET_FAILURE'
)<ActionCallback & { itemType:string }, void, void>();

export const addTripTariffsToBasketAsync = createAsyncAction(
  'basket/ADD_TRIP_TARIFFS_TO_BASKET_REQUEST',
  'basket/ADD_TRIP_TARIFFS_TO_BASKET_SUCCESS',
  'basket/ADD_TRIP_TARIFFS_TO_BASKET_FAILURE'
)<ActionCallback & { tariffIds:number[], direction:string }, void, void>();

export const requestSeatReservationAsync = createAsyncAction(
  'checkout/REQUEST_SEAT_RESERVATION_REQUEST',
  'checkout/REQUEST_SEAT_RESERVATION_SUCCESS',
  'checkout/REQUEST_SEAT_RESERVATION_FAILURE'
)<ActionCallback & { basketItemId:number, requestSeatReservation:boolean }, void, void>();
