import * as React from 'react';

// Utils
import { all, equals } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
import { VEHICLE_TYPES } from '@src/shared/src/const/app';
// Actions
// Models
import { TransportSegmentModel } from '@src/shared/src/models';
// Interfaces
// Components
// Styles

type Props = {
  segmentVehicles: string[];
  subSegments: TransportSegmentModel[];
};

export const SeatAssignmentsInfo: React.FC<Props> = (props: Props) => {
  // Currently we only display seatAssignments for trains
  const isTrainSegment = all(equals(VEHICLE_TYPES.TRAIN), props.segmentVehicles);

  if (isTrainSegment) {
    return (
      <div>
        {props.subSegments.map((segment) => (
          <ul key={segment.id}>
            <li className="lp-booking-info-item-list_item">
              <div>{`${segment.depName} - ${segment.arrName}`}</div>
              {segment.seatAssignments.map((seatAssignment, index) => (
                <ul key={index}>
                  <li>
                    {seatAssignment.x && (
                      <div>{`${t('SeatAssignmentsInfo.label.carriage')}: ${seatAssignment.x}`}</div>
                    )}
                    {seatAssignment.y && (
                      <div>{`${t('SeatAssignmentsInfo.label.position')}: ${seatAssignment.y}`}</div>
                    )}
                    {seatAssignment.z && (
                      <div>{`${t('SeatAssignmentsInfo.label.deck')}: ${seatAssignment.z}`}</div>
                    )}
                  </li>
                </ul>
              ))}
            </li>
          </ul>
        ))}
      </div>
    );
  }
};
