import * as React from 'react';

// Utils
import { navigate } from '@toolkit/util/app';
import { t } from '@toolkit/util/i18n';
import classnames from 'classnames';

// Constants
import { ROUTES } from '@toolkit/const/app';
import { API_URL } from '@src/shared/src/const/api';

// Components
import Logo from './Logo';

// Models
import { models } from '@src/shared/src';
// Styles
import '../styles/HeaderMobile.scss';

// import { ENVIRONMENT } from '@src/shared/src/const/app';

interface IProps {
  currentUser?: models.UserModel;
  isCurrentUserAdmin?: boolean;
  organization: models.OrganizationModel;
  breadcrumbs: JSX.Element;
  onLogout: (val: string) => void;
}
interface IState {
  open: boolean;
}

export default class HeaderMobile extends React.Component<IProps, IState> {
  public state: IState = {
    open: false,
  };

  private closeMenu() {
    this.setState({ open: false });
  }

  private toggleMenu() {
    this.setState({ open: !this.state.open });
  }

  public render() {
    const { currentUser, organization, breadcrumbs } = this.props;

    return (
      <div>
        <nav
          className={classnames(
            'tcp-header-mobile',
            { 'has--co-logo': organization?.logoPath },
            { 'in--webview': (window as any).__IS_WEBVIEW },
          )}>
          <Logo
            language={currentUser?.preference?.language}
            orgLogoPath={organization?.logoPath}
            orgId={organization?.id}
            inHeader={true}
          />
          {!(window as any).__IS_WEBVIEW && (
            <>
              {breadcrumbs}
              <button
                className="tcp-header-mobile-button tcp-header-mobile-button-menu"
                onClick={() => this.toggleMenu()}>
                <i className={this.state.open ? 'icon-close2' : 'icon-menu'} />
              </button>
            </>
          )}
        </nav>
        <div className={`tcp-menu ${this.state.open ? 'tcp-menu-open' : ''}`}>
          <div className="tcp-menu-group">
            <span className="tcp-menu-item">
              <i className="icon-help" />
              {t('navbar.header.label.help')}
            </span>
            <ul className="tcp-menu-list">
              <li
                className="tcp-menu-list-item"
                onClick={() => window.open(t('navbar.header.label.help_link'))}>
                <i className="icon-help_center" />
                {t('navbar.header.label.help_center')}
              </li>
              <li
                className="tcp-menu-list-item"
                onClick={() => window.open(t('navbar.header.label.contact_form_link'))}>
                <i className="icon-contact_mail" />
                {t('navbar.header.label.contact_form')}
              </li>
            </ul>
          </div>
          <div className="tcp-menu-group">
            <span
              className="tcp-menu-item"
              onClick={() =>
                window.location.assign(`${API_URL.DEFAULT}/org_admin/travel/bookings`)
              }>
              <i className="icon-tickets" />
              {t('navbar.header.label.tickets')}
            </span>
          </div>
          <div className="tcp-menu-group">
            <span
              className="tcp-menu-item"
              onClick={() => {
                this.closeMenu();
                navigate(`${ROUTES.HOME}`);
              }}>
              <i className="icon-search2" />
              {t('navbar.header.label.search')}
            </span>
          </div>
          {currentUser && (
            <div className="tcp-menu-group">
              <span className="tcp-menu-item">
                <i className="icon-person" />
                {currentUser.lastName}
              </span>
              <ul className="tcp-menu-list">
                {this.props.isCurrentUserAdmin && (
                  <li
                    className="tcp-menu-list-item"
                    onClick={() =>
                      window.location.assign(
                        `${API_URL.DEFAULT}/org_admin/organizations/${organization.id}/users`,
                      )
                    }>
                    <i className="icon-people" />
                    {t('navbar.header.label.company_account')}
                  </li>
                )}
                <li
                  className="tcp-menu-list-item"
                  onClick={() =>
                    window.location.assign(`${API_URL.DEFAULT}/org_admin/travel/bookings`)
                  }>
                  <i className="icon-person" />
                  {t('navbar.header.label.user_account')}
                </li>
                <li className="tcp-menu-list-item" onClick={() => this.props.onLogout('')}>
                  <i className="icon-exit_to_app" />
                  {t('navbar.header.label.logout')}
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    );
  }
}
