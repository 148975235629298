import { createAsyncAction } from 'typesafe-actions';

// Utils
// Constants
// Actions
// Models
import { UserPreferenceModel } from '../models';
import { GuestUserDTO } from '../dto';
// Interfaces
import { ActionCallback } from '../interfaces';

export const saveUserPreferencesAsync = createAsyncAction(
  'users/SAVE_USER_PREFERENCES_REQUEST',
  'users/SAVE_USER_PREFERENCES_SUCCESS',
  'users/SAVE_USER_PREFERENCES_FAILURE'
)<ActionCallback & { userPreferences:UserPreferenceModel }, void, void>();

export const createGuestUserAsync = createAsyncAction(
  'users/CREATE_GUEST_USER_REQUEST',
  'users/CREATE_GUEST_USER_SUCCESS',
  'users/CREATE_GUEST_USER_FAILURE'
)<ActionCallback & GuestUserDTO, void, void>();

export const checkAuthAsync = createAsyncAction(
  'users/CHECK_AUTH_REQUEST',
  'users/CHECK_AUTH_SUCCESS',
  'users/CHECK_AUTH_FAILURE'
)<ActionCallback & { email:string }, void, void>();

export const doLoginAsync = createAsyncAction(
  'users/DO_LOGIN_REQUEST',
  'users/DO_LOGIN_SUCCESS',
  'users/DO_LOGIN_FAILURE'
)<ActionCallback & { email:string, password:string, rememberMe:boolean }, void, void>();

export const doLogoutAsync = createAsyncAction(
  'users/DO_LOGOUT_REQUEST',
  'users/DO_LOGOUT_SUCCESS',
  'users/DO_LOGOUT_FAILURE'
)<ActionCallback, void, void>();

export const requestResetPasswordAsync = createAsyncAction(
  'users/REQUEST_RESET_PASSWORD_REQUEST',
  'users/REQUEST_RESET_PASSWORD_SUCCESS',
  'users/REQUEST_RESET_PASSWORD_FAILURE'
)<ActionCallback & { email:string }, void, void>();

export const doResetPasswordAsync = createAsyncAction(
  'users/DO_RESET_PASSWORD_REQUEST',
  'users/DO_RESET_PASSWORD_SUCCESS',
  'users/DO_RESET_PASSWORD_FAILURE'
)<ActionCallback & { password:string, password_reset_token:string }, void, void>();
