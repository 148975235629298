import * as React from 'react';
import ImageGallery from 'react-image-gallery';

// Utils
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import '../styles/HotelImageGallery.scss';

interface IProps {
  pictureURLs:string[];
}

const HotelImageGallery: React.FC<IProps> = (props) => {
  const hotelImages = props.pictureURLs ?
    props.pictureURLs.map(
      (picture) => ({ original: picture, thumbnail: picture, originalAlt: 'Cannot Load' })) : [];
  return  (
    <div className="tcp-hotel-image-gallery">
      <ImageGallery
        showThumbnails={false}
        showPlayButton={false}
        lazyLoad={true}
        items={hotelImages}/>
    </div>
  );
};

export default HotelImageGallery;