// Utils
import { map, filter, partial } from '../util/general';
// Constants
// Actions, Models & Interfaces
import { HotelModel } from '../models';
import { IHotelFilter } from '../interfaces';

export type HotelPricePerNightFilterConstraint = {
  min:number;
  max:number;
  currentMin:number;
  currentMax:number;
}

export default class HotelPricePerNightFilter implements IHotelFilter<HotelPricePerNightFilterConstraint> {
  private constructor() {}

  private static instance:HotelPricePerNightFilter;

  static getInstance = () => {
    if (!HotelPricePerNightFilter.instance) {
      HotelPricePerNightFilter.instance = new HotelPricePerNightFilter();
    }
    return HotelPricePerNightFilter.instance;
  }

  public id:string = 'HotelPricePerNightFilter';

  public getConstraints = (hotels:HotelModel[]):HotelPricePerNightFilterConstraint => {
    const pricesPerNight = map((hotel:HotelModel) => hotel.pricePerRoomNight, hotels);

    return {
      min: Math.min(...pricesPerNight),
      max: Math.max(...pricesPerNight),
      currentMin: Math.min(...pricesPerNight),
      currentMax: Math.max(...pricesPerNight)
    }
  }

  public process = (constraint:HotelPricePerNightFilterConstraint, hotels:HotelModel[]) =>
    filter(partial(this.match, [constraint]), hotels);

  private match = (constraint:HotelPricePerNightFilterConstraint, hotel:HotelModel) =>
    (hotel.pricePerRoomNight <= constraint.currentMax && hotel.pricePerRoomNight >= constraint.currentMin);
}
