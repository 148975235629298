import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import {
  navigate,
  notifyError,
} from '@toolkit/util/app';
import {
  T,
  cond,
  equals,
  pathOr,
} from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import i18n from '@toolkit/util/i18n';
// Constants
import { ERRORS } from '@src/shared/src/const/app';
import { ROUTES } from '@toolkit/const/app';
// Actions
import {
  ConnectedRedux,
  IRootState,
} from '@src/store';
// Models, Stores & Interfaces
import { models } from '@src/shared/src';
import { usersActions } from '@src/shared/src/actions';
// Components
import TempLoginForm from '@pod/admin_user/components/TempLoginForm';
import { BENotificationBar } from '@toolkit/ui';

type Props = ConnectedRedux<IRootState>;

class LoginConn extends React.Component<Props> {
  private handleLogin = (values) => {
    this.props.dispatch(usersActions.doLoginAsync.request({
      onSuccess: (user:models.UserModel) => {
        i18n.changeLanguage(user.preference.effectiveLocale);
        navigate(
          pathOr(ROUTES.SEARCH, ['location', 'state', 'from', 'pathname'])(this.props)
        );
      },
      onError: cond([
        [equals(ERRORS.NOT_FOUND), (err) => notifyError(err, 'Username or password wrong')],
        [equals(ERRORS.UNKNOWN_ERROR), (err) => notifyError(err, t('UNPROCESSABLE_ENTITY'))],
        [T, (err) => notifyError(err)],
      ]),
      email: values.email,
      password: values.password,
      rememberMe: values.remember_me,
    }));
  }

  public render() {
    return (
      <div>
        <BENotificationBar currentPage="Login"/>
        <TempLoginForm
          onSubmit={this.handleLogin}/>
      </div>
    );
  }
}
export default connect()(LoginConn);
