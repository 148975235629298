import { JsonObject, JsonProperty } from 'json2typescript';
import SettingsModel from './SettingsModel';

@JsonObject('AppSettingsUserTrainSeatPreferencesModel')
export default class AppSettingsUserTrainSeatPreferencesModel {
  @JsonProperty('compartment_type', [SettingsModel])
  public compartmentType:SettingsModel[] = [];
  @JsonProperty('seat_location', [SettingsModel])
  public seatLocation:SettingsModel[] = [];
  @JsonProperty('zone', [SettingsModel])
  public zone:SettingsModel[] = [];
}