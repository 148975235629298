import * as React from 'react';

// Utils
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import './styles/SkeletonSpinner.scss';

const SkeletonSpinner = () => (
  <div className="skeleton-spinner">
    <div className="skeleton-spinner-circle"/>
  </div>
);

export default SkeletonSpinner;
