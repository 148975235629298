// Utils
import { values, map, filter, partial } from '../util/general';
// Constants
// Actions
// Models
import { TripModel } from '../models';
// Interfaces
import { SingleFilterConstraint } from '../interfaces';
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';

export default class TripStopsFilter implements IFilter<TripModel, SingleFilterConstraint> {
  private constructor() {}

  private static instance:TripStopsFilter;

  static getInstance = () => {
    if (!TripStopsFilter.instance) {
      TripStopsFilter.instance = new TripStopsFilter();
    }
    return TripStopsFilter.instance;
  }

  public id:string = 'TripStopsFilter';

  public getConstraints = (
    opts:ConstraintsOpts<TripModel, SingleFilterConstraint>
  ):SingleFilterConstraint => {
    const allChangeOvers = values(map((trip:TripModel) => trip.changeovers, opts.values));

    return {
      min: Math.min(...allChangeOvers),
      max: Math.max(...allChangeOvers),
      current: (opts.reinit && opts.onlyUpdateConstraint)
        ? opts.constraint.current : Math.max(...allChangeOvers)
    }
  }

  public process = (constraint:SingleFilterConstraint, trips:TripModel[]) =>
    filter(partial(this.match, [constraint]), trips);

  private match = (constraint:SingleFilterConstraint, trip:TripModel) =>
    trip.changeovers <= constraint.current;
}
