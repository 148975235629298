import { default as search, ISearchState } from './searchReducer';
import { default as settings, ISettingsState } from './settingsReducer';
import { default as trips, ITripsState } from './tripsReducer';
import { default as appSettings, IAppSettingsState } from './appSettingsReducer';
import { default as adminUser, IAdminUserState } from './adminUserReducer';
import { default as filters, IFilterState } from './filterReducer';
import { default as hotels, IHotelState } from './hotelReducer';
import { default as checkout, ICheckoutState } from './checkoutReducer';
import { default as organization, IOrganizationState } from './organizationReducer';

export {
  search,
  ISearchState,
  settings,
  ISettingsState,
  trips,
  ITripsState,
  appSettings,
  IAppSettingsState,
  adminUser,
  IAdminUserState,
  filters,
  IFilterState,
  hotels,
  IHotelState,
  checkout,
  ICheckoutState,
  organization,
  IOrganizationState,
};