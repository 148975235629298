import * as React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

// Utils
// Constants
// Actions
// Models
// Interfaces
import { IRootState, ConnectedRedux } from '@src/store';
// Components
// Styles
import '../styles/WSStatusBar.scss';

type Props = ConnectedRedux<IRootState> & {
  isWSConnected:boolean;
};
class WSStatusBar extends React.Component<Props> {
  render() {
    if (this.props.isWSConnected) {
      return null;
    }
    return (
        <div className="lp-ws-status-bar">
          <Trans i18nKey="wsStatus.text.notConnected">
            <a href="javascript:window.location.reload(true)">try again</a>
          </Trans>
        </div>
    );
  }
}

const mapState = (state:IRootState) => ({
  isWSConnected: state.settings.wsStatus,
});
export default connect(mapState)(WSStatusBar);
