import { SVGProps } from 'react';
import * as React from 'react';

export const AppartmentIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V9C3 8.45 3.19583 7.97917 3.5875 7.5875C3.97917 7.19583 4.45 7 5 7H7V5C7 4.45 7.19583 3.97917 7.5875 3.5875C7.97917 3.19583 8.45 3 9 3H15C15.55 3 16.0208 3.19583 16.4125 3.5875C16.8042 3.97917 17 4.45 17 5V11H19C19.55 11 20.0208 11.1958 20.4125 11.5875C20.8042 11.9792 21 12.45 21 13V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H13V17H11V21H5ZM5 19H7V17H5V19ZM5 15H7V13H5V15ZM5 11H7V9H5V11ZM9 15H11V13H9V15ZM9 11H11V9H9V11ZM9 7H11V5H9V7ZM13 15H15V13H13V15ZM13 11H15V9H13V11ZM13 7H15V5H13V7ZM17 19H19V17H17V19ZM17 15H19V13H17V15Z"
      fill="#1C1B1F"
    />
  </svg>
);
