import * as React from 'react';

// Utils
import { isNilOrEmpty } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
import { ENVIRONMENT, DIRECTION } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import {
  HotelModel,
  TravelBookingFareExtraModel,
  TripModel,
  RentalModel,
} from '@src/shared/src/models';
// Components
import { Trip } from '@pod/trips/components';
import { Hotel } from '@pod/hotels/components';
import { Rental } from '@pod/rentals/components';
import CheckoutPrice from './CheckoutPrice';
// Styles

type Props = {
  outwardTrip?:TripModel;
  inboundTrip?:TripModel;
  isRoundtrip:boolean;
  bookingHotels:HotelModel[];
  bookingRentals:RentalModel[];
  hotelRoomCount?:number;
  env:ENVIRONMENT;
  searchDateOutward:Date;
  searchDateInbound:Date;
  getTariffStatus?:(tariffId:number) => string;
  getTariffLuggage?:(tariffId:number) => TravelBookingFareExtraModel;
  transportTotal:number;
  hotelTotal:number;
  rentalTotal:number;
  bookedItemsTotal:number;
  showItemsDetails:boolean;
  showPrices?:boolean;
  showHotelRemarksForm?:boolean;
  onChangeHotelRemarks?:(hotelRemarks:string) => void;
  isJourney?:boolean;
};

const BookedItems:React.FC<Props> = (props) => {
  const {
    transportTotal,
    hotelTotal,
    rentalTotal,
    bookedItemsTotal,
    inboundTrip,
    outwardTrip,
    showPrices,
    isJourney,
  } = props;

  const env = isJourney ? ENVIRONMENT.JOURNEY : props.env;

  return (
      <span>
        {outwardTrip &&
          <Trip
            direction={DIRECTION.OUTWARD}
            key={outwardTrip.id}
            trip={outwardTrip}
            env={env}
            searchDate={props.searchDateOutward}
            getTariffStatus={props.getTariffStatus}
            getTariffLuggage={props.getTariffLuggage}
            detailsOpened={props.showItemsDetails}
            onFetchDetail={() => {}}
            onSelectTrip={() => {}}
            onCloseDetail={() => {}}
            isRoundtrip={props.isRoundtrip}/>}

        {inboundTrip &&
          <Trip
            direction={DIRECTION.INBOUND}
            key={inboundTrip.id}
            trip={inboundTrip}
            env={env}
            searchDate={props.searchDateInbound}
            getTariffStatus={props.getTariffStatus}
            getTariffLuggage={props.getTariffLuggage}
            detailsOpened={props.showItemsDetails}
            onFetchDetail={() => {}}
            onSelectTrip={() => {}}
            onCloseDetail={() => {}}
            isRoundtrip={props.isRoundtrip}/>}

        {showPrices && (outwardTrip || inboundTrip) &&
          <CheckoutPrice
            label={t('BookedItems.label.transportTotal')}
            price={transportTotal} />
      }

        {props.bookingRentals.map((rental:RentalModel) =>
          <Rental
            key={rental.id}
            rental={rental}
            env={props.env}
            detailsOpened={props.showItemsDetails}/>)
        }

        {showPrices && !isNilOrEmpty(props.bookingRentals) &&
          <CheckoutPrice
            label={t('BookedItems.label.rentalTotal')}
            price={rentalTotal} />
        }

        {props.bookingHotels.map((hotel:HotelModel) =>
          <Hotel
            key={hotel.id}
            hotel={hotel}
            env={props.env}
            hotelRoomCount={props.hotelRoomCount}
            detailsOpened={props.showItemsDetails}
            onCloseDetail={() => {}}
            // getTariffStatus={props.getTariffStatus}
            onFetchDetail={() => {}}
            onSelectHotel={() => {}}/>)}

        {props.showHotelRemarksForm && !isNilOrEmpty(props.bookingHotels) &&
          <div className="checkout-hotel-notes">
            <label className="checkout-hotel-notes-label">
              {t('bookedItems.hotelForm.label.remarks')}:
            </label>
            <div className="checkout-hotel-notes-field tcp-form-group-field">
              <textarea
                placeholder={t('bookedItems.hotelForm.placeholder.remarks')}
                onChange={(evt) => props.onChangeHotelRemarks(evt.target.value)}/>
            </div>
          </div>
        }

        {showPrices && !isNilOrEmpty(props.bookingHotels) &&
          <CheckoutPrice
            label={t('BookedItems.label.hotelTotal')}
            price={hotelTotal} />
        }

        {showPrices &&
          <CheckoutPrice
            label={t('BookedItems.label.totalBookable')}
            price={bookedItemsTotal} />
        }
      </span>
  );
};

export default BookedItems;
