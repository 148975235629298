import * as React from 'react';
import { Form, Field } from 'react-final-form';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
// Interfaces
// Components
import { Button } from '@toolkit/ui';
// Styles

type Props = {
  handleSubmitAddress: (values: any) => void;
};

const SupportRequestsForm: React.FC<Props> = props => {
  return (
    <div className="main-container">
      <div className="container">
        <div className="row">
          <section className="content content-checkout">
            <Form
              onSubmit={props.handleSubmitAddress}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="checkout-box checkout-payment box">
                    <h2 className="checkout-title hidden-xs-down">
                      {t('supportRequestsForm.label.add_delivery_address')}
                    </h2>
                    <div className="checkout-box-content">
                      <div className="tcp-form-group">
                        <label className="tcp-form-group-label tcp-form-group-label-big">
                          {t('supportRequestsForm.label.name_address')}
                        </label>
                        <div className="tcp-form-group-field tcp-form-group-field-full">
                          <Field
                            className="is--big"
                            name="userComment"
                            component="textarea"
                            rows={6}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="tcp-form-group">
                        <Button type="submit" className="button">
                          {t('showLastErrorStatus.notif.button.order')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default SupportRequestsForm;
