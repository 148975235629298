import * as React from 'react';
import { t } from '@src/toolkit/util/i18n';
import { Button } from '@toolkit/ui'
import { API_URL } from '@src/shared/src/const/api';

export class RedirectToBackendSwitch extends React.Component<{}> {

  render() {
    return (
      <div className="container" style={{marginLeft: '10px', marginTop: '10px'}}>
        <p>
          <strong>{t('redirectToBackendSwitch.title')}</strong>
        </p>
        <p>{t('redirectToBackendSwitch.message')}</p>
        <p>
          <strong>{t('redirectToBackendSwitch.followLinks')}</strong>
        </p>
        <ul>
          <li>
            <Button className="link" onClick={() => window.location.assign(`${API_URL.DEFAULT}/org_admin/travel/bookings`)}>
              {t('userAccountConn.nav.bookingsAndTickets')}
            </Button>
          </li>
          <li>
            <Button className="link" onClick={() => window.location.assign(`${window.location.origin}/`)}>
              {t('navbar.header.label.search')}
            </Button>
          </li>
        </ul>
      </div>
    )
  }
}