import { createAction, createAsyncAction } from 'typesafe-actions';

// Utils
// Constants
// Actions
// Models
import { AppSettingsModel } from '../models';
// Interfaces
import { ActionCallback } from '../interfaces';
// Components
// Styles

export const setAppSettings = createAction(
  'appSettings/SET_APP_SETTINGS',
  (resolve) => (appSettings: AppSettingsModel) => resolve(appSettings),
);

// Async actions

export const fetchAppSettingsAsync = createAsyncAction(
  'appSettings/FETCH_APP_SETTINGS_REQUEST',
  'appSettings/FETCH_APP_SETTINGS_SUCCESS',
  'appSettings/FETCH_APP_SETTINGS_FAILURE'
)<ActionCallback, void, void>();