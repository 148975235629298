import { getType } from 'typesafe-actions';

// Utils
import { always, evolve } from '../util/general';
// Constants
import { TIME_WINDOW_TYPE, TRANSPORT_SORT_VALUES, HOTEL_SORT_VALUES } from '../const/app';
// Actions
import { FilterActions, filterActions } from '../actions';
// Models
import { OptionModel } from '../models';
import { SelectionModel, FilterOption } from '@src/models';
// Interfaces
import {
  HotelDistanceFilterConstraint,
  HotelNameFilterConstraint,
  HotelPriceFilterConstraint,
  HotelPricePerNightFilterConstraint,
  HotelRatingFilterConstraint,
  HotelSortingFilterConstraint,
  HotelStarFilterConstraint,
  TripInvalidInboundsFilterConstraint,
} from '../filters';
import {
  IFilterTimeWindow,
  RangeFilterConstraint,
  SingleFilterConstraint,
  SortingFilterConstraint,
  StationsFilterConstraint,
  CurrentFilterConstraint,
} from '../interfaces';

export interface IFilterState {
  tripDurationFilter: SingleFilterConstraint;
  tripArrTimeFilter: RangeFilterConstraint;
  tripDepTimeFilter: RangeFilterConstraint;
  tripTimeWindowUserFilter: IFilterTimeWindow;
  tripPriceFilter: SingleFilterConstraint;
  tripStopsFilter: SingleFilterConstraint;
  tripTransportationFilter: OptionModel[];
  tripTransportationUserFilter: OptionModel[];
  tripStationsFilter: StationsFilterConstraint;
  tripSupplierFilter: OptionModel[];
  tripInvalidInboundsFilter: TripInvalidInboundsFilterConstraint;
  tripSortingFilter: SortingFilterConstraint;
  tripSortingUserFilter: SortingFilterConstraint;
  hotelNameFilter: HotelNameFilterConstraint;
  hotelPriceFilter: HotelPriceFilterConstraint;
  hotelPricePerNightFilter: HotelPricePerNightFilterConstraint;
  hotelRatingFilter: HotelRatingFilterConstraint;
  hotelRatingUserFilter: CurrentFilterConstraint<number>;
  hotelStarFilter: HotelStarFilterConstraint;
  hotelDistanceFilter: HotelDistanceFilterConstraint;
  hotelSortingFilter: HotelSortingFilterConstraint;
  hotelSortingUserFilter: HotelSortingFilterConstraint;
  hotelFeatureFilter: CurrentFilterConstraint<SelectionModel[]>;
  hotelAccommodationFilter: CurrentFilterConstraint<FilterOption[]>;
  hotelAccommodationUserFilter: CurrentFilterConstraint<FilterOption[]>;
}

const initialState: IFilterState = {
  tripArrTimeFilter: {
    currentMax: 0,
    currentMin: 0,
    max: 0,
    min: 0,
  },
  tripDepTimeFilter: {
    currentMax: 0,
    currentMin: 0,
    max: 0,
    min: 0,
  },
  tripTimeWindowUserFilter: {
    outward: {
      dep: {
        currentMax: 0,
        currentMin: 0,
        timeWindow: TIME_WINDOW_TYPE.ANYTIME,
      },
      arr: {
        currentMax: 0,
        currentMin: 0,
        timeWindow: TIME_WINDOW_TYPE.ANYTIME,
      },
    },
    inbound: {
      dep: {
        currentMax: 0,
        currentMin: 0,
        timeWindow: TIME_WINDOW_TYPE.ANYTIME,
      },
      arr: {
        currentMax: 0,
        currentMin: 0,
        timeWindow: TIME_WINDOW_TYPE.ANYTIME,
      },
    },
  },
  tripDurationFilter: {
    current: 0,
    max: 0,
    min: 0,
  },
  tripPriceFilter: {
    current: 0,
    max: 0,
    min: 0,
  },
  tripStopsFilter: {
    current: 0,
    max: 0,
    min: 0,
  },
  tripTransportationFilter: [],
  tripTransportationUserFilter: [],
  tripStationsFilter: {
    depStations: [],
    arrStations: [],
  },
  tripInvalidInboundsFilter: {
    current: undefined,
  },
  tripSortingFilter: {
    current: TRANSPORT_SORT_VALUES.PRICE,
  },
  tripSortingUserFilter: {
    current: undefined,
  },
  tripSupplierFilter: [],
  hotelNameFilter: {
    current: '',
  },
  hotelPriceFilter: {
    currentMin: 0,
    currentMax: 0,
    max: 0,
    min: 0,
  },
  hotelPricePerNightFilter: {
    currentMin: 0,
    currentMax: 0,
    max: 0,
    min: 0,
  },
  hotelRatingFilter: {
    currentMax: 0,
    currentMin: 0,
    max: 0,
    min: 0,
  },
  hotelRatingUserFilter: {
    current: 0,
  },
  hotelStarFilter: {
    currentMin: 0,
    currentMax: 0,
    max: 0,
    min: 0,
  },
  hotelDistanceFilter: {
    current: 0,
    max: 0,
    min: 0,
  },
  hotelSortingFilter: {
    current: HOTEL_SORT_VALUES.RECOMMENDATION_SCORE,
  },
  hotelSortingUserFilter: {
    current: undefined,
  },
  hotelFeatureFilter: {
    current: [],
  },
  hotelAccommodationFilter: {
    current: [],
  },
  hotelAccommodationUserFilter: {
    current: [],
  },
};

export default (state: IFilterState = initialState, action: FilterActions) => {
  switch (action.type) {
    case getType(filterActions.setTripArrTimeFilter):
      return { ...state, tripArrTimeFilter: action.payload.constraint };
    case getType(filterActions.setTripDepTimeFilter):
      return { ...state, tripDepTimeFilter: action.payload.constraint };
    case getType(filterActions.setTripDurationFilter):
      return { ...state, tripDurationFilter: action.payload.constraint };
    case getType(filterActions.setTripPriceFilter):
      return { ...state, tripPriceFilter: action.payload.constraint };
    case getType(filterActions.setTripStopsFilter):
      return { ...state, tripStopsFilter: action.payload.constraint };
    case getType(filterActions.setTripTransportationFilter):
      return { ...state, tripTransportationFilter: action.payload.constraint };
    case getType(filterActions.setTripTransportationUserFilter):
      return { ...state, tripTransportationUserFilter: action.payload };
    case getType(filterActions.setTripStationsFilter):
      return { ...state, tripStationsFilter: action.payload.constraint };
    case getType(filterActions.setTripSortingFilter):
      return { ...state, tripSortingFilter: action.payload.constraint };
    case getType(filterActions.setTripSortingUserFilter):
      return { ...state, tripSortingUserFilter: action.payload };
    case getType(filterActions.setTripSupplierFilter):
      return { ...state, tripSupplierFilter: action.payload.constraint };
    case getType(filterActions.setTripInvalidInboundsFilter):
      return { ...state, tripInvalidInboundsFilter: action.payload.constraint };
    case getType(filterActions.setHotelNameFilter):
      return { ...state, hotelNameFilter: action.payload.constraint };
    case getType(filterActions.setHotelPriceFilter):
      return { ...state, hotelPriceFilter: action.payload.constraint };
    case getType(filterActions.setHotelRatingFilter):
      return { ...state, hotelRatingFilter: action.payload.constraint };
    case getType(filterActions.setHotelRatingUserFilter):
      return { ...state, hotelRatingUserFilter: action.payload };
    case getType(filterActions.setHotelStarFilter):
      return { ...state, hotelStarFilter: action.payload.constraint };
    case getType(filterActions.setHotelPricePerNightFilter):
      return { ...state, hotelPricePerNightFilter: action.payload.constraint };
    case getType(filterActions.setHotelDistanceFilter):
      return { ...state, hotelDistanceFilter: action.payload.constraint };
    case getType(filterActions.setHotelSortingFilter):
      return { ...state, hotelSortingFilter: action.payload.constraint };
    case getType(filterActions.setHotelSortingUserFilter):
      return { ...state, hotelSortingUserFilter: action.payload };
    case getType(filterActions.setHotelFeatureFilter):
      return { ...state, hotelFeatureFilter: action.payload.constraint };
    case getType(filterActions.setHotelAccommodationFilter):
      return { ...state, hotelAccommodationFilter: action.payload.constraint };
    case getType(filterActions.setHotelAccommodationUserFilter):
      return { ...state, hotelAccommodationUserFilter: action.payload };
    case getType(filterActions.setTimeWindow):
      return evolve(
        {
          tripTimeWindowUserFilter: {
            [action.payload.direction]: {
              [action.payload.tripDirection]: always(action.payload.timeWindowConstraints),
            },
          },
        },
        state,
      );
    case getType(filterActions.clearFilters):
      // Do not clear user filters
      const {
        tripTimeWindowUserFilter,
        tripTransportationUserFilter,
        tripSortingUserFilter,
        hotelSortingUserFilter,
        hotelAccommodationUserFilter,
        hotelRatingUserFilter,
      } = state;
      return {
        ...initialState,
        tripTimeWindowUserFilter,
        tripTransportationUserFilter,
        tripSortingUserFilter,
        hotelSortingUserFilter,
        hotelAccommodationUserFilter,
        hotelRatingUserFilter,
      };
    default:
      return state;
  }
};
