// Utils
import { values, map, filter, partial, flatten, uniq, pluck, pipe, contains } from '../util/general';
import { getMainLegsHeadTS, getMainLegsTS } from '../util/trips';
// Constants
// Actions
// Models
import { TripModel, OptionModel } from '../models';
// Interfaces
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';

export default class TripSupplierFilter implements IFilter<TripModel, OptionModel[]> {
  private constructor() {}

  private static instance:TripSupplierFilter;

  static getInstance = () => {
    if (!TripSupplierFilter.instance) {
      TripSupplierFilter.instance = new TripSupplierFilter();
    }
    return TripSupplierFilter.instance;
  }

  public id:string = 'TripSupplierFilter';

  private getSuppliers = pipe(
    values,
    pluck('legs'),
    flatten,
    getMainLegsTS,
    pluck('suppliers'),
    flatten,
    uniq
  );

  public getConstraints = (
    opts:ConstraintsOpts<TripModel, OptionModel[]>
  ):OptionModel[] =>
    (opts.reinit && opts.onlyUpdateConstraint) ?
      opts.constraint
      :
      map((supplier:string) => ({ value: true, label: supplier }), this.getSuppliers(opts.values))

  public process = (constraint:OptionModel[], trips:TripModel[]) =>
    filter(partial(this.match, [constraint]), trips)

  private match = (constraint:OptionModel[], trip:TripModel) =>
    contains(
      getMainLegsHeadTS(trip.legs).suppliers[0],
      pluck('label', constraint.filter((opt) => opt.value))
    )
}