import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
import { cond } from '@src/shared/src/util/general';
import {
  baseErrorCond,
} from '@toolkit/util/app';
// Constants
// Actions
import { hotelActions } from '@src/shared/src/actions';
// Models
// Interfaces
import { IRootState, ConnectedRedux } from '@src/store';
// Components
import { LabelButton } from '@toolkit/ui';
// Styles
import '../styles/HotelTitle.scss';

type Props = ConnectedRedux<IRootState> & {
  hotelId:string;
  isFavorite:boolean;
};
type State = {
  isHotelFavorite:boolean;
};
class HotelFavoriteButton extends React.PureComponent<Props, State> {
  readonly state:State = {
    isHotelFavorite: this.props.isFavorite,
  };

  static getDerivedStateFromProps(nextProps:Props, prevState:State) {
    if (nextProps.isFavorite !== prevState.isHotelFavorite) {
      return {
        isHotelFavorite: nextProps.isFavorite,
      };
    } else {
      return null;
    }
  }

  private toggleFavorite = () => {
    const { hotelId } = this.props;
    this.props.dispatch(hotelActions.setFavoriteHotelAsync.request({
      onError: cond(baseErrorCond),
      isFavorite: !this.state.isHotelFavorite,
      hotelId,
    }));
    this.setState({ isHotelFavorite: !this.state.isHotelFavorite });
  }


  render() {
      const { isHotelFavorite } = this.state;
      const favoriteBtnLabel = isHotelFavorite ? 'hotelTitle.btn.label.isFavorite' : 'hotelTitle.btn.label.markFavorite';
      const favoriteStyle = isHotelFavorite ? 'tcp-hotel-title-is-favorite' : '';

      return (
        <LabelButton
          icon="icon-favorite"
          className={favoriteStyle}
          onClick={this.toggleFavorite}>
          {t(favoriteBtnLabel)}
        </LabelButton>
      );
  }
}

const mapState = (state:IRootState) => ({
  profile: state.adminUser.profile,
});
export default connect(mapState)(HotelFavoriteButton);
