import classNames from 'classnames';
import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { ChevronIcon } from './icons';
import Label from './Label';

import './styles/Dropdown.scss';

type Props = {
  label: string;
  title: string;
  children: React.ReactNode;
  rightAlingOnMobile?: boolean;
};

const Dropdown: React.FC<Props> = ({ label, title, children, rightAlingOnMobile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div
      className={classNames(
        'dropdown',
        { 'align-results--right': rightAlingOnMobile },
        { 'is--open': isOpen },
      )}
      ref={dropdownRef}>
      <div className="dropdown-label" onClick={() => setIsOpen(!isOpen)}>
        <Label>{label}</Label>
        <ChevronIcon />
      </div>
      <div className="dropdown-inner">
        <Label>{title}</Label>
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
