declare const HOST_URL:string;
declare const HOST_SSL_SUFFIX:string;

const __API_URL__ = `http${HOST_SSL_SUFFIX}://${HOST_URL}`;
const __WS_URL__ = `ws${HOST_SSL_SUFFIX}://${HOST_URL}/cable`;

const API_PATH = `${__API_URL__}/api`;

//TODO: Use API_PATH instead
export const API_URL = {
  DEFAULT:                     __API_URL__,
  WEBSOCKET:                   __WS_URL__,
  TRIPS:                       __API_URL__ + '/tmp/travel/trips/',
  BASKETS:                     __API_URL__ + '/api/baskets',
  BASKET_ITEMS:                __API_URL__ + '/api/basket_items',
  TARIFF:                      __API_URL__ + '/tmp/travel/tariffs/',
  SEARCHES:                    __API_URL__ + '/tmp/travel/searches/',
  HOTEL_DETAILS:               __API_URL__ + '/api/hotel/hotels/',
  TRANSPORT_SEARCHES_GET:      __API_URL__ + '/api/transport/searches',
  DOCUMENTS_HOTEL:             __API_URL__ + '/api/hotels/documents',
  TRANSPORT_NODE_SUGGEST:      __API_URL__ + '/api/transport/nodes/suggest.json?input=',
  TRANSPORT_TRIPS:             __API_URL__ + '/api/transport/trips',
  TRANSPORT_BOOKINGS:          __API_URL__ + '/api/transport/bookings',
  TRAVEL_BOOKINGS:             __API_URL__ + '/api/travel/bookings',
  ORG_ADMIN_TRAVEL_BOOKINGS:   __API_URL__ + '/org_admin/travel/bookings',
  TRANSPORT_APPROVAL_REQUESTS: __API_URL__ + '/api/transport/approval_requests',
  SETTINGS:                    __API_URL__ + '/api/settings.json',
  AIRLINES_SETTINGS:           __API_URL__ + '/api/settings/airlines',
  BOOKINGS:                    __API_URL__ + '/api/bookings',
  HOTELS:                      __API_URL__ + '/api/hotels',
  USER_INVITATIONS:            __API_URL__ + '/api/user_invitations',
  USER_PASSWORD_RESET:         __API_URL__ + '/api/user_password_reset',
  USERS:                       __API_URL__ + '/api/users',
  RECEIPTS:                    __API_URL__ + '/api/receipts',
  SESSIONS:                    __API_URL__ + '/api/sessions',
  AUTH_CHECK:                  __API_URL__ + '/auth/check.json',
  ACCOUNTING_DOCUMENTS:        __API_URL__ + '/api/organizations/documents',
  DOCUMENTS:                   __API_URL__ + '/api/documents',
  GOOGLE_DIRECTIONS:           'https://maps.googleapis.com/maps/api/js',
  GOOGLE_TIMEZONES:            'https://maps.googleapis.com/maps/api/timezone/json',
  ORGANIZATIONS:               __API_URL__ + '/api/organizations',
  TAXI_TRANSIT:                'https://crawler2api.com/searchTaxicalculator',
  OPEN_EXCHANGE:               'https://openexchangerates.org/api/latest.json',
  WHOAMI:                      __API_URL__ + '/api/users/whoami',
  GUEST_USERS:                 __API_URL__ + '/api/guest_users',
  TRAVEL_SEARCH:               `${__API_URL__}/api/travel/searches`,
  COST_CENTER_ENTITIES:        `${API_PATH}/cost_center/entities`,
  ZENDESK_MESSENGER_SNIPPET:   'https://static.zdassets.com/ekr/snippet.js',
  RENTAL_SEARCH:               __API_URL__ + '/api/rental/vehicle_availabilities/',
};

export enum API_METHOD_TYPE  {
  POST = 'POST',
  GET = 'GET',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum API_TIMOUTS {
  POLLING_TIMEOUT = 80,
}

export enum API_ERRORS {
  API_RESPONSE_UNEXPECTED = 'API did not respond accordingly',
  API_RESPONSE_PARSE_ERROR = 'API response parse error',
  REQUEST_NOT_PROCESSED = 'Could not process request',
  POLLING_TIMEOUT_OCCURED = 'Polling timeout occured',
}

export enum WS_CHANNELS {
  SEARCH = 'SearchStatusChannel',
  TRIPS = 'AssemblyTripsChannel',
  BASKETS = 'BasketsChannel',
  HOTELS = 'Hotel::HotelsChannel',
  BOOKINGS = 'Travel::BookingsChannel',
}

export enum WS_ACTIONS {
  REBROADCAST = 'rebroadcast',
  CONFIRM_SUB = 'confirm_subscription',
  EXISTING = 'existing',
}

export const ZENDESK_MESSENGER_KEYS = {
  'production': {
    'de-DE': '186d8a70-6b80-4a5e-ab9b-e57027b0c980',
    'en-US': '7441552e-5f50-4492-a7a9-acde04b3974e',
  },
  'sandbox': {
    'de-DE': 'e442456b-5086-485a-ac96-d2036a24987e',
    'en-US': 'b908cd9d-be5d-49d3-a470-40eb08a2ba5f',
  },
};
