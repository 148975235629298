import * as React from 'react';
import { t } from '@toolkit/util/i18n';
import { Link } from '@toolkit/ui';
import { ArrowBackIcon } from '@toolkit/ui/icons';
import '../styles/SearchBarSlideOutNavbar.scss';

interface IProps {
  onClose: any;
  hideConfirmButton?: boolean;
}

export default class SearchBarSlideOutNavbar extends React.Component<IProps, {}> {
  public render() {
    return (
      <div className="lp-search-bar-slideout-navbar">
        <button onClick={this.props.onClose} className="lp-search-bar-slideout-navbar-close">
          <ArrowBackIcon />
          {t('search.bar.slide.out.back')}
        </button>
        {!this.props.hideConfirmButton ? (
          <Link onClick={this.props.onClose} className="lp-search-bar-slideout-navbar-confirm">
            {t('search.bar.slide.out.button.confirm')}
          </Link>
        ) : (
          ''
        )}
      </div>
    );
  }
}
