// Utils
import { map, filter, partial, path, uniq, find, values } from '../util/general';
import { getMainLegFirstTS, getMainLegLastTS } from '../util/trips';
// Constants
// Actions
// Models
import { TripModel, OptionModel } from '../models';
// Interfaces
import { StationsFilterConstraint } from '../interfaces';
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';


export default class TripStationsFilter implements IFilter<TripModel, StationsFilterConstraint> {
  private constructor() {}

  private static instance:TripStationsFilter;

  static getInstance = () => {
    if (!TripStationsFilter.instance) {
      TripStationsFilter.instance = new TripStationsFilter();
    }
    return TripStationsFilter.instance;
  }

  public id:string = 'TripStationsFilter';

  public getConstraints = (
    opts:ConstraintsOpts<TripModel, StationsFilterConstraint>
  ):StationsFilterConstraint => {

    if (opts.reinit && opts.onlyUpdateConstraint) {
      return opts.constraint;
    }
    const depStations = values(map(
      (trip:TripModel) => ({
        label: path(['depName'], getMainLegFirstTS(trip.legs)),
        value: 'true'
      }), opts.values));

    const arrStations = values(map(
      (trip:TripModel) => ({
        label: path(['arrName'], getMainLegLastTS(trip.legs)),
        value: 'true'
      }), opts.values));
    return {
      depStations: uniq(depStations),
      arrStations: uniq(arrStations),
    };
  }

  public process = (constraint:StationsFilterConstraint, trips:TripModel[]) =>
    filter(partial(this.match, [constraint]), trips)

  private match = (constraint:StationsFilterConstraint, trip:TripModel) => {
    const depStation = find(
      (st:OptionModel) => st.label === path(['depName'], getMainLegFirstTS(trip.legs)),
      constraint.depStations
    );
    const arrStation = find(
      (st:OptionModel) => st.label === path(['arrName'], getMainLegLastTS(trip.legs)),
      constraint.arrStations
    );

    return (depStation && arrStation) ? (depStation.value === 'true' && arrStation.value === 'true') : false;
  }
}
