import { JsonObject, JsonProperty } from 'json2typescript';

import { IdCode } from '@src/models';

@JsonObject('CountriesModel')
export default class CountriesModel {
  @JsonProperty('ident', Number) public ident: number = -1;
  @JsonProperty('name', String) public name: string = '';
  @JsonProperty('official_currency', String) public officialCurrency: string = '';
  @JsonProperty('key', String) public key: string = '';
  @JsonProperty('phone_country_codes', [IdCode]) public phoneCountryCodes: IdCode[] = [];
}
