import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { formatDate } from '@src/shared/src/util/date';
// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Components
import { LabelButton, SidebarRoute } from '@toolkit/ui';
// Styles

type Props = {
  title:string;
  hotelName:string;
  checkinDate:Date;
  checkoutDate:Date;
  travellersCount:number;
  roomCount:number;
  onEdit:() => void;
};

const CheckoutSideBarHotelInfo: React.FC<Props> = (props:Props) => {
  return (
        <div className="sidebar-section is-open">
          <div className="sidebar-section-inner">
            <span className="sidebar-section-title">{props.title}</span>
            <div className="sidebar-trip-details">
              <SidebarRoute>
                {props.hotelName}
              </SidebarRoute>
              <SidebarRoute iconClass="icon-calendar" hasSpacing={true}>
                {`
                  ${formatDate(props.checkinDate, DATE_FORMAT_TYPES.DAY_SHORT_DATE)}
                  -
                  ${formatDate(props.checkoutDate, DATE_FORMAT_TYPES.DAY_SHORT_DATE)}
                `}
              </SidebarRoute>
              <SidebarRoute
                iconClass="icon-male-female"
                label={t('checkoutSideBarHotelInfo.label.travellers')}>
                {props.travellersCount}
              </SidebarRoute>
               <SidebarRoute
                iconClass="icon-rooms"
                label={t('checkoutSideBarHotelInfo.label.rooms')}>
                {props.roomCount}
              </SidebarRoute>
              {props.onEdit &&
                <LabelButton
                  onClick={props.onEdit}
                  className="sidebar-edit">
                  <i className="icon-edit"/>{t('checkoutSideBarHotelInfo.button.edit')}
                </LabelButton>
              }
            </div>
          </div>
        </div>
  );
};

export default CheckoutSideBarHotelInfo;
