import { JsonObject, JsonProperty } from 'json2typescript'
import { DateConverter } from './converters';
import TravelBookingFareExtraModel from './TravelBookingFareExtraModel';

@JsonObject('TravelBookingFareModel')
export default class TravelBookingFareModel {
  @JsonProperty('conditions', String, true) public conditions:string = undefined;
  @JsonProperty('created_at', DateConverter, true) public createdAt:Date = undefined
  @JsonProperty('currency', String, true) public currency:string = undefined;
  @JsonProperty('extra', TravelBookingFareExtraModel, true) public extra:TravelBookingFareExtraModel = undefined;
  @JsonProperty('id', Number, true) public id:number = -1
  @JsonProperty('name',String, true) public name:string = undefined;
  @JsonProperty('price', Number, true) public price:number = undefined;
  @JsonProperty('provider', String, true) public provider:string = undefined;
  @JsonProperty('seat_reservation', String, true) public seatReservation:string = undefined;
  @JsonProperty('seat_reservation_price', Number, true) public seatReservationPrice:number = undefined;
  @JsonProperty('segment_id', Number, true) public segmentId:number = undefined;
  @JsonProperty('travel_class', String, true) public travelClass:string = undefined;
  @JsonProperty('updated_at', DateConverter, true) public updatedAt:Date = undefined;
}