import * as React from 'react';
import classNames from 'classnames';

// Utils
// import { map, mapIndexed, all, path, concat, pipe, equals, isNil } from '@src/shared/src/util/general';
import { map, mapIndexed, isNil } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
// Actions, Models & Interfaces
import { OptionModel } from '@src/shared/src/models';
// Components
// import { Checkbox } from '@toolkit/ui';
import { CheckboxFilter } from './CheckboxFilter';
// Styles

type Props = {
  title: string;
  depStations: OptionModel[];
  arrStations: OptionModel[];
  isOpen?: boolean;
  isDisabled?: boolean;
  onChange: (depStations: OptionModel[], arrStations: OptionModel[]) => void;
};

type State = {
  isOpen: boolean;
};

export default class StationsFilter extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: isNil(props.isOpen) ? true : props.isOpen,
    };
  }

  private toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  onChangeStationOnly = (stationName: string, isDep: boolean) => {
    const newStations = map(
      (station: OptionModel) =>
        station.label === stationName
          ? { label: stationName, value: String(true) }
          : { label: station.label, value: String(false) },
      isDep ? this.props.depStations : this.props.arrStations,
    );
    this.props.onChange(isDep ? newStations : this.props.depStations, isDep ? this.props.arrStations : newStations);
  };

  onChangeDepStations = (stationName: string, value: boolean) => {
    const newDepStations = this.props.depStations.map(depStation =>
      depStation.label === stationName ? { label: stationName, value: String(value) } : depStation,
    );
    this.props.onChange(newDepStations, this.props.arrStations);
  };

  onChangeArrStations = (stationName: string, value: boolean) => {
    const newArrStations = this.props.arrStations.map(arrStation =>
      arrStation.label === stationName ? { label: stationName, value: String(value) } : arrStation,
    );
    this.props.onChange(this.props.depStations, newArrStations);
  };

  // private onChangeAllStation = (value: boolean) => {
  //   const changeStationsValues = map(station => ({ label: station.label, value: String(value) }));
  //   this.props.onChange(changeStationsValues(this.props.depStations), changeStationsValues(this.props.arrStations));
  // };

  private depCheckboxes = () =>
    mapIndexed(
      (station: OptionModel, index: number) => (
        <CheckboxFilter
          key={`dep-stat-${index}`}
          id={`dep-stat-${index}`}
          label={station.label}
          isChecked={station.value === 'true'}
          onChange={value => this.onChangeDepStations(station.label, value)}
          onChangeOnly={val => this.onChangeStationOnly(val, true)}
        />
      ),
      this.props.depStations,
    );

  private arrCheckboxes = () =>
    mapIndexed(
      (station: OptionModel, index: number) => (
        <CheckboxFilter
          key={`arr-station-${index}`}
          id={`arr-station-${index}`}
          label={station.label}
          isChecked={station.value === 'true'}
          onChange={value => this.onChangeArrStations(station.label, value)}
          onChangeOnly={val => this.onChangeStationOnly(val, false)}
        />
      ),
      this.props.arrStations,
    );

  // private isActive = value => {
  //   return all(
  //     pipe(
  //       path(['value']),
  //       equals(value),
  //     ),
  //     concat(this.props.depStations, this.props.arrStations),
  //   );
  // };

  public render() {
    const { title, isDisabled } = this.props;
    const indicatorStyle = classNames('sidebar-section-indicator', {
      'sidebar-section-indicator-disabled': isDisabled,
    });
    return (
      <div className={`sidebar-section ${this.state.isOpen ? 'is-open' : ''}`}>
        <button className="sidebar-section-title" onClick={isDisabled ? null : this.toggleOpen}>
          {title}
          <i className={indicatorStyle} aria-hidden="true" />
        </button>
        <div className="sidebar-section-inner">
          <div className="sidebar-section-headline">{t('stationsFilter.title.departure')}</div>
          {this.depCheckboxes()}
          <div className="sidebar-section-headline">{t('stationsFilter.title.arrival')}</div>
          {this.arrCheckboxes()}
          {/* <button
            className={`sidebar-check-all ${this.isActive('true') ? 'active' : ''}`}
            onClick={() => this.onChangeAllStation(true)}>
            <span>{t('stationsFilter.button.selectAll')}</span>
          </button>
          <span className="sidebar-check-all divider">|</span>
          <button
            className={`sidebar-check-all ${this.isActive('false') ? 'active' : ''}`}
            onClick={() => this.onChangeAllStation(false)}>
            <span>{t('stationsFilter.button.clearAll')}</span>
          </button> */}
        </div>
      </div>
    );
  }
}
