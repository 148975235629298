import * as React from 'react';
import { default as ReactResponsiveModal } from 'react-responsive-modal';

// Utils
import { isNil } from '@src/shared/src/util/general';
// Constants
// Actions
// Models
// Interfaces
// Components
import { CloseIcon } from './icons';
// Styles
import 'react-responsive-modal/styles.css';
import './styles/Modal.scss';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  children: React.ReactNode;
};

const Modal: React.FC<Props> = (props) => {
  return (
    <ReactResponsiveModal
      open={props.isOpen}
      onClose={props.onClose}
      classNames={{
        overlay: 'lp-modal-overlay',
        modal: 'lp-modal',
      }}
      closeIcon={<CloseIcon />}>
      {!isNil(props.title) && (
        <div className="lp-modal-header">
          <h2 className="lp-modal-title">{props.title}</h2>
        </div>
      )}
      <div className="lp-modal-body">{props.children}</div>
    </ReactResponsiveModal>
  );
};
export default Modal;
