import * as React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';

// Utils
import { t } from '@toolkit/util/i18n';
import { navigate, baseErrorCond } from '@toolkit/util/app';
import { cond } from '@src/shared/src/util/general';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
// Constants
import { ROUTES } from '@toolkit/const/app';
import { HOTEL_SORT_VALUES } from '@src/shared/src/const/app';
// Actions
import { filterActions, basketActions } from '@src/shared/src/actions';
// Models
import { OptionModel } from '@src/shared/src/models';
// Interfaces
import { IRootState, ConnectedRedux } from '@src/store';
import SortBar from '@pod/trips/components/SortBar';
// Components
// Styles

type Props = ConnectedRedux<IRootState> & AppContextProp & {
  searchId:number;
  currentHotelSortingFilter:string;
  basketHotelsLen:number;
  isDisplayHotelList:boolean;
  isSearchingForOnlyHotel:boolean;
  onToggleDisplayList: () => void;
  filteredHotelsLen:number;
  totalHotelsLen:number;
  setHotelDetailsOpenedId:(val:string) => void;
};

class HotelsHeader extends React.PureComponent<Props> {

  private sortingOptions = ():OptionModel[] => [
    { label: t('HotelsConn.select.option.recommendationSort'), value: HOTEL_SORT_VALUES.RECOMMENDATION_SCORE},
    { label: t('HotelsConn.select.option.starsSort'), value: HOTEL_SORT_VALUES.STARS},
    { label: t('HotelsConn.select.option.totalPriceSort'), value: HOTEL_SORT_VALUES.TOTAL_PRICE},
    { label: t('HotelsConn.select.option.ratingsSort'), value: HOTEL_SORT_VALUES.RATING},
    { label: t('HotelsConn.select.option.distanceSort'), value: HOTEL_SORT_VALUES.DISTANCE},
  ]

  private onChangeSortOption = (nOpt:OptionModel) => {
    this.props.setHotelDetailsOpenedId('');
    this.props.dispatch(filterActions.setHotelSortingFilter({
      constraint: { current: nOpt.value },
      onlyUpdateConstraint: false,
    }));
  }

  private onNoHotelNeeded = () => {
    if (this.props.basketHotelsLen > 0) {
      this.props.dispatch(basketActions.removeItemFromBasketAsync.request({
        onSuccess: () => navigate(`${ROUTES.BOOKING.RESERVE}${this.props.searchId}`),
        onError: cond(baseErrorCond),
        itemType: 'hotel',
      }));
    } else {
      navigate(`${ROUTES.BOOKING.RESERVE}${this.props.searchId}`)
    }
  }

  render() {
    const headerSortStyle = classNames('content-header-sort', {
      'content-header-sort-separated': !this.props.isSearchingForOnlyHotel,
    });

    return (
          <div className="content-header content-header-hotel">
            {!this.props.appContext.isMediaBPNotMobile &&
              <SortBar
                options={this.sortingOptions()}
                value={this.props.currentHotelSortingFilter}
                onChange={this.onChangeSortOption}
                />}
            <h2 className="content-title">
              {t('hotelsConn.header.title')}&nbsp;
              {t('global.results.label.of',
                { total:this.props.totalHotelsLen, filtered: this.props.filteredHotelsLen })}
            </h2>
            <button className="content-header-show_map" onClick={this.props.onToggleDisplayList}>
              {this.props.isDisplayHotelList ?
                <span>
                  <i className="icon-to" />
                  {t('HotelsConn.header.label.showMap')}
                </span>
                :
                t('HotelsConn.header.label.showList')}
            </button>
            <div className={headerSortStyle}>
              {t('HotelsConn.header.label.sort')}:
              <Select
                className="Select--trips"
                options={this.sortingOptions()}
                clearable={false}
                backspaceRemoves={false}
                deleteRemoves={false}
                value={this.props.currentHotelSortingFilter}
                onChange={this.onChangeSortOption}
              />
            </div>
            {!this.props.isSearchingForOnlyHotel &&
              <button className="button button-light" onClick={this.onNoHotelNeeded}>
                {t('hotelSideBarInfo.checkbox.label.noHotelNeeded')}
              </button>
            }
          </div>
    );
  }
}
const mapState = (state:IRootState) => ({
  currentHotelSortingFilter: state.filters.hotelSortingFilter.current,
  basketHotelsLen: state.checkout.hotels.length,
});
export default connect(mapState)(withAppContext(HotelsHeader));
