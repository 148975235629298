import { Observable, Subscription } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { useState, useEffect } from 'react';
import { path } from '@src/shared/src/util/general';

export function onEmit<T>(source$: Observable<T>, nextFn: (value: T) => void): Subscription {
  return source$.subscribe(nextFn, console.error);
}

export function getDerivedHook<T>(onEmitCall: Function): T {
  const setState = useState<T>()[1];
  useEffect(() => {
    const subscription: Subscription = onEmit<T>(onEmitCall(), val => setState(val));
    return () => subscription.unsubscribe();
  });
  /* Send back the very first subscription value as the initial value for the hook in order to avoid
  `undefined` bugs */
  let temporalState;
  onEmitCall()
    .subscribe(val => (temporalState = val), console.error)
    .unsubscribe();
  return temporalState;
}

export function getStateStreamSlice<T, Q>(
  source$: Observable<T>,
  statePath: string[]
): Observable<Q> {
  return source$.pipe(
    map(val => path(statePath, val)),
    distinctUntilChanged()
    // shareReplay(1),
  );
}
