import * as React from 'react';
import { useSelector } from 'react-redux';

// Constants
import { API_URL } from '@src/shared/src/const/api';
// Hooks
import { useTranslation } from 'react-i18next';
// Selectors
import { searchSelectors } from '@src/shared/src/selectors';
// Components
import WarningBanner from './WarningBanner';

export const RebookingBanner: React.FC = () => {
  const { t } = useTranslation();
  const { rebooking } = useSelector(searchSelectors.currentSearch);

  if (rebooking) {
    return (
      <WarningBanner
        icon="lp-icon-warning"
        label={
          <>
            {t('rebookingBanner.heading')}
            <a
              className="tcp-link"
              target="_blank"
              href={`${API_URL.ORG_ADMIN_TRAVEL_BOOKINGS}/${rebooking.originalTravelBookingId}`}>
              {t('rebookingBanner.link')}
            </a>
          </>
        }
        isInfo={true}
      />
    );
  }
};
