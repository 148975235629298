import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

// Utils
// Constants
import { DIRECTION } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
import { IConnectedRedux, IRootState } from '@src/store';
// Components
import { TripsConn } from '@pod/trips';
// Styles

type Props = IConnectedRedux<IRootState> & {
  direction:DIRECTION;
  searchId:number;
};

class TripsRouter extends React.Component<Props> {

  render() {
    return (
      <div>
        <Route render={() => <TripsConn direction={this.props.direction}/>}/>
      </div>
    );
  }
}
const mapState = (state:IRootState) => ({
  searchId: state.search.currentSearch.id,
});
export default connect(mapState)(TripsRouter);