declare const __APP_VERSION__: string;
export const appVersion = __APP_VERSION__;
/**
 * Contains general utilty methods that are not specific to any business logic part of the application
 */
import { JsonConvert, ValueCheckingMode } from 'json2typescript';
import { differenceInDays, startOfDay } from 'date-fns';
import { CENTS_CONVERSION_TYPES } from '../const/app';
import CurrencyFormatter from './formatters/CurrencyFormatter';
import NumberFormatter from './formatters/NumberFormatter';
import ConfigBootstrap from '../config.bootstrap';

// --Ramda Exports--

export const T = require('ramda/src/T');
export const addIndex = require('ramda/src/addIndex');
export const adjust = require('ramda/src/adjust');
export const all = require('ramda/src/all');
export const always = require('ramda/src/always');
export const and = require('ramda/src/and');
export const any = require('ramda/src/any');
export const ascend = require('ramda/src/ascend');
export const assoc = require('ramda/src/assoc');
export const complement = require('ramda/src/complement');
export const compose = require('ramda/src/compose');
export const concat = require('ramda/src/concat');
export const cond = require('ramda/src/cond');
export const contains = require('ramda/src/contains');
export const curry = require('ramda/src/curry');
export const defaultTo = require('ramda/src/defaultTo');
export const descend = require('ramda/src/descend');
export const eqBy = require('ramda/src/eqBy');
export const equals = require('ramda/src/equals');
export const evolve = require('ramda/src/evolve');
export const filter = require('ramda/src/filter');
export const find = require('ramda/src/find');
export const findIndex = require('ramda/src/findIndex');
export const flatten = require('ramda/src/flatten');
export const fromPairs = require('ramda/src/fromPairs');
export const gt = require('ramda/src/gt');
export const gte = require('ramda/src/gte');
export const head = require('ramda/src/head');
export const ifElse = require('ramda/src/ifElse');
export const indexOf = require('ramda/src/indexOf');
export const innerJoin = require('ramda/src/innerJoin');
export const intersection = require('ramda/src/intersection');
export const isEmpty = require('ramda/src/isEmpty');
export const isNil = require('ramda/src/isNil');
export const join = require('ramda/src/join');
export const last = require('ramda/src/last');
export const length = require('ramda/src/length');
export const lte = require('ramda/src/lte');
export const map = require('ramda/src/map');
export const mapObjIndexed = require('ramda/src/mapObjIndexed');
export const match = require('ramda/src/match');
export const merge = require('ramda/src/merge');
export const mergeAll = require('ramda/src/mergeAll');
export const none = require('ramda/src/none');
export const not = require('ramda/src/not');
export const nth = require('ramda/src/nth');
export const nthArg = require('ramda/src/nthArg');
export const omit = require('ramda/src/omit');
export const or = require('ramda/src/or');
export const partial = require('ramda/src/partial');
export const partialRight = require('ramda/src/partialRight');
export const path = require('ramda/src/path');
export const pathOr = require('ramda/src/pathOr');
export const pick = require('ramda/src/pick');
export const pipe = require('ramda/src/pipe');
export const pluck = require('ramda/src/pluck');
export const prop = require('ramda/src/prop');
export const propEq = require('ramda/src/propEq');
export const propOr = require('ramda/src/propOr');
export const propSatisfies = require('ramda/src/propSatisfies');
export const range = require('ramda/src/range');
export const reduce = require('ramda/src/reduce');
export const remove = require('ramda/src/remove');
export const replace = require('ramda/src/replace');
export const reverse = require('ramda/src/reverse');
export const sort = require('ramda/src/sort');
export const sortBy = require('ramda/src/sortBy');
export const sortWith = require('ramda/src/sortWith');
export const startsWith = require('ramda/src/startsWith');
export const take = require('ramda/src/take');
export const takeLast = require('ramda/src/takeLast');
export const times = require('ramda/src/times');
export const toPairs = require('ramda/src/toPairs');
export const toUpper = require('ramda/src/toUpper');
export const unionWith = require('ramda/src/unionWith');
export const uniq = require('ramda/src/uniq');
export const update = require('ramda/src/update');
export const values = require('ramda/src/values');
export const when = require('ramda/src/when');
export const whereEq = require('ramda/src/whereEq');
export const countBy = require('ramda/src/countBy');
export const either = require('ramda/src/either');
export const difference = require('ramda/src/difference');
export const includes = require('ramda/src/includes');

// const currencyFormatter = new Intl.NumberFormat({ style: 'currency', currency: 'EUR'});

// --General utility methods--

export const jsonConvert = new JsonConvert();
jsonConvert.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;

export const isNotNil = complement(isNil);

export const pathIsNotNil = (pathVal:any) => compose(isNotNil, path(pathVal));

export const isNilOrEmpty = (val: any) => isNil(val) || isEmpty(val);

export const isNotNilOrEmpty = complement(isNilOrEmpty);

export const findIndexById = (idVal:any) => findIndex(propEq('id', idVal));

export const findById = (idVal:any) => find(propEq('id', idVal));

export const mapIndexed = addIndex(map);

export const findBySelected = find(propEq('selected', true));

export const findByIdent = (ident:string) => find((propEq('ident', ident)));

/**
 * Checks for property access, also checks for nested property access by giving a string array for nested levels
 * @param defaultValue
 * @param obj
 * @param accessStringArr
 */
export const verifyProperty = (defaultValue: any, obj: any, accessStringArr?: string[]) => {
  const valueAtPosition = isNil(accessStringArr) ? obj : path(accessStringArr, obj);
  return isNilOrEmpty(valueAtPosition) ? defaultValue : valueAtPosition;
};

export const saveLS = (key:string, value:string) => localStorage.setItem(key, JSON.stringify(value));

export const getLS = (key:string) => JSON.parse(localStorage.getItem(key));

export const size = pipe(values, length);

// --Date/Time--

export const getUTCDate = (dateString:string = new Date().toString()) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const minutesToHoursAndMinutes = (duration:number) => {
  const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  if (minutes === 0) {
    return `${hours}h`;
  }
  return `${hours}h ${minutes}m`;
};

export const formatDayDifference = (date:Date, refDate:Date) => {
  const dayDifference = differenceInDays(startOfDay(date), startOfDay(refDate));
  return dayDifference !== 0 ? `${dayDifference > 0 ? '+' : ''}${dayDifference}d` : '';
};

// --Currency--

// TODO: set default locale later
export const formatCents = (cents:number, conversion:CENTS_CONVERSION_TYPES = CENTS_CONVERSION_TYPES.NONE) => {
  let val;
  const fractionDigits = conversion === CENTS_CONVERSION_TYPES.NONE ? 2 : 0;
  switch (conversion) {
    case CENTS_CONVERSION_TYPES.FLOOR:
      val = Math.floor(cents / 100);
      break;
    case CENTS_CONVERSION_TYPES.ROUND:
      val = Math.round(cents / 100);
      break;
    default:
      val = cents / 100;
      break;
  }

  return CurrencyFormatter.getInstance(fractionDigits).format(val);
};

// --Numbers--

export const formatThousands = (val:number) => { // 10564 => 10,564
  return NumberFormatter.getInstance().format(val);
};

/**
 * @deprecated
 * Use roundTo
 */
export const roundToOneDigit = (nbr: number) => { // 9.6542 => 9.6
  return Math.round(nbr * 10) / 10;
};

/**
 * Rounds a number to the decimal place specified
 * @param {number} num - The number to round
 * @param {number} dec - The decimal place to round to, defaults to 2
 */
export const roundTo = (num:number, dec:number = 2) => {
  const numPow = Math.pow(10, dec);
  return Math.round(num * numPow) / numPow;
};

/**
 * Transforms camel case strings to snake case
 * @param key - camel case string
 */
const camelCaseToSnakeCase = (key:string) => key.replace(/([A-Z0-9])/g, "_$1").toLowerCase();

export const mapObjKeysToSnakeCase =
  curry((obj:any) => fromPairs(map(adjust(0, camelCaseToSnakeCase), toPairs(obj))));

export const consoleLog = (val:any) => {
  if (ConfigBootstrap.getInstance().getReleaseStage() !== 'production') {
    console.log(val);
  }
};

type convertArrToObjByIdType = {
  id:number;
};
/**
 * Converts an array of items to an object with those items as properties while using `item.id` as key
 * @param arrToConvert - The array to convert to object, the items must have a property 'id'
 */
export const convertArrToObjById = (arrToConvert:convertArrToObjByIdType[]) =>
  mergeAll(map((obj:convertArrToObjByIdType) => ({ [obj.id]: obj }), arrToConvert));

export const getWSCallKey = (actionType:string, id?:number | string) =>
  `${actionType}_${id ? id : ''}`;

type isLengthType = {
  max?:number;
  min?:number;
};
export const isLength = (str:string, opt:isLengthType) => {
  const min = opt.min || 0;
  const max = opt.max || undefined;
  const len = str.length;
  return len >= min && (typeof max === 'undefined' || len <= max);
}

export const getProp = <O, P extends keyof O>(obj:O, key:P) => obj[key];

export const setProp = <O, P extends keyof O>(obj:O, key:P, value:O[P]) => obj[key] = value;

export const isPropNil = curry(compose(isNil, prop));

export const hasEmptyValues = (obj: Object) => values(obj).filter((val: any) => !isNil(val) && !isEmpty(val)).length === 0;
