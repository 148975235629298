// Utils
import { getDerivedHook } from '@toolkit/util/state-utils/state.util';
// Constants
// Actions
// Models
import { RedeemingAllowanceModel } from '@src/shared/src/models';
// Interfaces
// Subscriptions
import * as checkoutSubscriptions from './checkout.subscriptions';

export const useRedeemingAllowance = () =>
  getDerivedHook<RedeemingAllowanceModel>(checkoutSubscriptions.redeemingAllowance);

export const useIsJustificationRequired = () =>
  getDerivedHook<boolean>(checkoutSubscriptions.isPolicyViolationJustificationRequired);

export const useIsCostCenterRequired = () =>
  getDerivedHook<boolean>(checkoutSubscriptions.isCostCenterRequired);

export const useIsCostUnitRequired = () =>
  getDerivedHook<boolean>(checkoutSubscriptions.isCostUnitRequired);

export const useIsReferenceCodeRequired = () =>
  getDerivedHook<boolean>(checkoutSubscriptions.isReferenceCodeRequired);

export const useIsPurposeOfTripRequired = () =>
  getDerivedHook<boolean>(checkoutSubscriptions.isPurposeOfTripRequired);
