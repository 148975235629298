import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
import { TripModel } from '@src/shared/src/models';
// Interfaces
// Components
import TripSideBarInfo from './TripSideBarInfo';
// Styles
import '../styles/TripSideBarSelectionInfo.scss';

type Props = {
  outward:TripModel;
  inbound?:TripModel;
  searchId:number;
  cheapestInbound?:number;
};

const TripSideBarSelectionInfo: React.FC<Props> = (props) => {
  return (
        <div className="sidebar-section is-open lp-trip-sidebar-selection-info">
          <div className="sidebar-section-inner">
            <div>
              <div className="sidebar-section-title">
                {t('tripSideBarOutwardInfo.title.selection')}
              </div>
              <TripSideBarInfo
                trip={props.outward}/>
              <div className="lp-trip-sidebar-selection-info-inner">
                {/* {props.cheapestInbound &&
                  <div className="lp-trip-sidebar-selection-info-row">
                    {`${t('tripSideBarOutwardInfo.label.priceFrom')} `}
                    <span className="lp-trip-sidebar-selection-info-price">
                      {formatCents(props.outward.baseTotal + props.cheapestInbound)}
                    </span>
                  </div>
                } */}
              </div>

            {props.inbound &&
              <TripSideBarInfo
                trip={props.inbound}
                isLast={true}/>
            }
            </div>
          </div>
        </div>
  );
};

export default TripSideBarSelectionInfo;
