import * as React from 'react';
import * as sanitizeHTML from 'sanitize-html';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNil } from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import '../styles/HotelDescription.scss';

interface IProps {
  description: string;
}
const HotelDescription: React.FC<IProps> = (props) => {
  const description = isNil(props.description) ? '' : props.description;
  return (
    <div>
      <div className="tcp-hotel-details-title">{t('HotelDescription.label.title')}</div>
    <div
      className="tcp-hotel-description-text"
      dangerouslySetInnerHTML={{ __html: sanitizeHTML(description) }} />
    </div>
  );
};

export default HotelDescription;
