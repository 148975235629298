// Utils
import { store } from '@src/store/store.config';
import { cond } from '@src/shared/src/util/general';
import { baseErrorCond, navigate } from '@toolkit/util/app';
// Constants
import { DIRECTION, SEARCH_TYPE } from '@src/shared/src/const/app';
import { ROUTES } from '@toolkit/const/app';
// Actions
import {
  tripsActions,
  basketActions,
} from '@src/shared/src/actions';
import { uiActions } from '@pod/ui';
// Models
// Interfaces
// Subscriptions
// Queries

const { dispatch } = store;

export const fetchTripDetails = (tripId: number, direction: DIRECTION) => {
  dispatch(tripsActions.setExpanded(tripId, false, direction));
  dispatch(tripsActions.fetchTripDetailsAsync.request({
    onError: cond(baseErrorCond),
    tripId,
  }));
};

export const selectTrip = (tripId: number, tariffIds: number[], direction: DIRECTION) => {
  dispatch(uiActions.setUiIsSelectingTrip(true));

  const searchType = store.getState().search.currentSearch.searchType;
  const searchId = store.getState().search.id;
  // TODO: change this to be in search service
  const isSearchingForOnlyTransport = store.getState().settings.isSearchingForOnlyTransport;

  dispatch(basketActions.addTripTariffsToBasketAsync.request({
    tariffIds,
    direction,
    onSuccess: () => {
      store.dispatch(uiActions.setUiIsSelectingTrip(false));
      store.dispatch(
        direction === DIRECTION.INBOUND
          ? tripsActions.setSelectedInboundId(tripId) : tripsActions.setSelectedOutboundId(tripId)
      );
      let nextRoute = `${ROUTES.TRIPS.INBOUND}${searchId}`;

      // TODO: make the condition more declarative
      if (
        (searchType === SEARCH_TYPE.OUTBOUND)
        || (direction === DIRECTION.INBOUND && isSearchingForOnlyTransport)) {
        nextRoute = `${ROUTES.BOOKING.RESERVE}${searchId}`;
      } else if (direction === DIRECTION.INBOUND) {
        nextRoute = `${ROUTES.HOTELS}${searchId}`;
      }
      store.dispatch(uiActions.setUiMobileNoScrolling(false));
      navigate(nextRoute);
    },
    onError: (err) => {
      store.dispatch(uiActions.setUiIsSelectingTrip(false));
      return cond(baseErrorCond)(err);
    },
  }));
};
