import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIntercom } from 'react-use-intercom';
import { useHistory, useLocation } from 'react-router';

// Utils
import { navigate } from '@toolkit/util/app';
// Constants
import { ROUTES } from '@toolkit/const/app';
import { API_URL } from '@src/shared/src/const/api';
// Actions
// Models
import { UserModel, OrganizationModel } from '@src/shared/src/models';
// Interfaces
// Components
// Styles
import '../styles/Navbar.scss';

type Props = {
  currentUser?: UserModel;
  currentOrganization?: OrganizationModel;
  isCurrentUserNotTraveller?: boolean;
  onLogout: (val: string) => void;
};

export const Navbar: React.FC<Props> = (props) => {
  const { currentUser, currentOrganization, isCurrentUserNotTraveller, onLogout } = props;
  const { t } = useTranslation();
  const { shutdown, boot } = useIntercom();
  const [intercomLoaded, setIntercomLoaded] = useState(false);
  let location = useLocation();
  const history = useHistory();

  const bootIntercom = () => {
    boot({
      customAttributes: {
        language_override: currentUser.preference.language.substring(0, 2),
      },
    });
    setIntercomLoaded(true);
  };

  const shutdownIntercom = () => {
    shutdown();
    setIntercomLoaded(false);
  };

  const toggleIntercom = () => {
    shutdownIntercom();
    bootIntercom();
  };

  React.useEffect(() => {
    if (currentOrganization?.showIntercomOnboarding) {
      if (currentUser?.preference.activateUserGuide && !(window as any).__IS_WEBVIEW) {
        if (!intercomLoaded) {
          bootIntercom();
        }
        if (location.pathname === '/') {
          history.push({ search: 'product_tour_home=true' });
        }
      } else {
        if (location.pathname === '/' && !intercomLoaded) {
          bootIntercom();
        } else if (intercomLoaded) {
          shutdownIntercom();
        }
      }
    }
  }, [currentOrganization, location]);

  const adminUserLink = `${API_URL.DEFAULT}/org_admin/travel/bookings`;

  const adminCompanyLink = `${API_URL.DEFAULT}/org_admin/organizations/${currentOrganization?.id}/users`;
  return (
    <div className="tcp-navbar">
      {currentOrganization?.showIntercomOnboarding && currentUser?.preference.activateUserGuide && (
        <div className="tcp-navbar-item" onClick={toggleIntercom}>
          <i className="icon-touch_app" />
          {t('navbar.header.label.restart_tour')}
        </div>
      )}
      <div className="tcp-navbar-item tcp-navbar-item-dropdown">
        <i className="icon-help" />
        {t('navbar.header.label.help')}
        <i className="icon-keyboard_arrow_down tcp-navbar-dropdown-icon" />
        <ul className="tcp-navbar-dropdown">
          <li
            className="tcp-navbar-dropdown-item" onClick={() => window.open(t('navbar.header.label.help_link'))}>
            <i className="icon-help_center" />
            {t('navbar.header.label.help_center')}
          </li>
          <li className="tcp-navbar-dropdown-item" onClick={() => window.open(t('navbar.header.label.contact_form_link'))}>
            <i className="icon-contact_mail" />
            {t('navbar.header.label.contact_form')}
          </li>
        </ul>
      </div>
      <div className="tcp-navbar-item" onClick={() => navigate(`${ROUTES.HOME}`)}>
        <i className="icon-search2" />
        {t('navbar.header.label.search')}
      </div>
      <div className="tcp-navbar-item" onClick={() => window.location.assign(adminUserLink)}>
        <i className="icon-tickets" />
        {t('navbar.header.label.tickets')}
      </div>
      {currentUser && (
        <div className="tcp-navbar-item tcp-navbar-item-dropdown">
          {currentUser.firstName} {currentUser.lastName}
          <i className="icon-keyboard_arrow_down tcp-navbar-dropdown-icon" />
          <ul className="tcp-navbar-dropdown">
            {isCurrentUserNotTraveller && (
              <li
                className="tcp-navbar-dropdown-item"
                onClick={() => window.location.assign(adminCompanyLink)}>
                <i className="icon-people" />
                {t('navbar.header.label.company_account')}
              </li>
            )}
            <li
              className="tcp-navbar-dropdown-item"
              onClick={() => window.location.assign(adminUserLink)}>
              <i className="icon-person" />
              {t('navbar.header.label.user_account')}
            </li>
            <li className="tcp-navbar-dropdown-item" onClick={() => onLogout('')}>
              <i className="icon-exit_to_app" />
              <strong>{t('navbar.header.label.logout')}</strong>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
export default Navbar;
