import { JsonObject, JsonProperty } from 'json2typescript';
// import RentalModel from './RentalModel';

@JsonObject('BookingRentalFareModel')
export default class BookingRentalFareModel {
  @JsonProperty('id', Number) public id:number = undefined;
  @JsonProperty('rental_days', Number) public rentalDays:number = undefined;
  @JsonProperty('price', Number) public price:number = -1;
  // @JsonProperty('created_at', Date) public createdAt:Date = undefined;
  // @JsonProperty('updated_at', Date) public updatedAt:Date = undefined;
  // @JsonProperty('currency', String) public currency:string = '';
  // @JsonProperty('currency_conversion_rate', String) public currencyConversionRate:string = '';
  // @JsonProperty('details_status', String) public detailsStatus:string = '';
  // @JsonProperty('mileage_additional_currency', String) public mileageAdditionalCurrency:string = '';
  // @JsonProperty('mileage_additional_net_amount', Number) public mileageAdditionalNetAmount:number = undefined;
  // @JsonProperty('mileage_additional_vat_percent', String) public mileageAdditionalVatPercent:string = '';
  // @JsonProperty('mileage_free_distance', Number) public mileageFreeDistance:number = undefined;
  // @JsonProperty('mileage_free_unit', String) public mileageFreeUnit:string = '';
  // @JsonProperty('mileage_unlimited', Boolean) public mileageUnlimited:boolean = undefined;
  // @JsonProperty('original_currency', String) public originalCurrency:string = '';
  // @JsonProperty('original_price', String) public originalPrice:string = '';
  // @JsonProperty('rate_code', String) public rateCode:string = '';
  // @JsonProperty('rate_plan', String) public ratePlan:string = '';
  // @JsonProperty('required_driver_age', Number) public requiredDriverAge:number = undefined;
  // @JsonProperty('required_driver_license', Number) public requiredDriverLicense:number = undefined;
  // @JsonProperty('required_driver_license_age', Number) public requiredDriverLicenseAge:number = undefined;
  // @JsonProperty('selected', Boolean) public selected:boolean = undefined;
  // @JsonProperty('vehicle_availability_id', Number) public vehicleAvailabilityId:number = undefined;
  @JsonProperty('vehicle_examples', [String]) public vehicleExamples:string[] = [];
  // @JsonProperty('vehicle_availability', RentalModel) public vehicleAvailability:RentalModel = undefined;
}