import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { formatCents } from '@src/shared/src/util/general';
// Constants
// Actions
// Models
import { RedeemingAllowanceModel } from '@src/shared/src/models';
// Interfaces
// Components
import { WarningBanner } from '@toolkit/ui';
// Styles
import '../styles/RedeemedSearchNotification.scss';

type Props = {
  redeemingAllowance: RedeemingAllowanceModel;
};

export const RedeemedSearchNotification: React.FC<Props> = props => {
  if (props.redeemingAllowance) {
    return (
      <WarningBanner
        icon="lp-icon-help"
        isInfo={true}
        label={
          <div>
            {t('redeemedSearchNotification.message.text', { maxPrice: formatCents(props.redeemingAllowance.maxPrice) })}
          </div>
        }
      />
    );
  } else {
    return null;
  }
};
