import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import {
  partial,
  isNilOrEmpty,
} from '@src/shared/src/util/general';
import { selectors } from '@src/shared/src';
import {
  getTariffBookedStatus,
  getTariffFareLuggage,
} from '@src/shared/src/util/tariffs';
// Constants
import { ENVIRONMENT } from '@src/shared/src/const/app';
// Actions
// Models
import {
  BasketItemModel,
  HotelModel,
  RentalModel,
  SearchModel,
  TripModel,
} from '@src/shared/src/models';
// Interfaces
import { ConnectedRedux, IRootState } from '@src/store';
// Components
import BookedItems from './BookedItems';
// Styles

type Props = ConnectedRedux<IRootState> & {
  env:ENVIRONMENT;
  bookingHotels:HotelModel[];
  bookingRentals:RentalModel[];
  basketItems:BasketItemModel[];
  transportTotal:number;
  hotelTotal:number;
  rentalTotal:number;
  bookedItemsTotal:number;
  search:SearchModel;
  outwardTrip:TripModel;
  inboundTrip:TripModel;
  showItemsDetails:boolean;
  showPrices?:boolean;
  showHotelRemarksForm?:boolean;
  onChangeHotelRemarks?:(hotelRemarks:string) => void;
  isJourney?:boolean;
};

class CheckoutBookedItemsDetail extends React.Component<Props> {
  render() {
    const {
      transportTotal,
      hotelTotal,
      rentalTotal,
      bookedItemsTotal,
      basketItems,
      bookingHotels,
      bookingRentals,
      env,
      inboundTrip,
      outwardTrip,
      search,
      showItemsDetails,
      showPrices,
      showHotelRemarksForm,
    } = this.props;
    return (
      <BookedItems
        env={env}
        transportTotal={transportTotal}
        hotelTotal={hotelTotal}
        rentalTotal={rentalTotal}
        bookedItemsTotal={bookedItemsTotal}
        searchDateOutward={search.depAt}
        searchDateInbound={search.arrAt}
        getTariffStatus={partial(getTariffBookedStatus, [basketItems])}
        getTariffLuggage={partial(getTariffFareLuggage, [basketItems])}
        outwardTrip={outwardTrip}
        inboundTrip={inboundTrip}
        isRoundtrip={!isNilOrEmpty(inboundTrip)}
        bookingHotels={bookingHotels}
        bookingRentals={bookingRentals}
        hotelRoomCount={search.hotelRoomCount}
        showPrices={showPrices}
        showHotelRemarksForm={showHotelRemarksForm}
        showItemsDetails={showItemsDetails}
        isJourney={this.props.isJourney}
        onChangeHotelRemarks={this.props.onChangeHotelRemarks} />
    );
  }
}
const mapState = (state:IRootState) => ({
  search: selectors.search.search(state.search),
  transportTotal: state.checkout.basket.transportTotal,
  hotelTotal: state.checkout.basket.hotelTotal,
  rentalTotal: state.checkout.basket.rentalTotal,
  bookedItemsTotal: state.checkout.basket.total,
  basketItems: selectors.checkout.basketItems(state.checkout),
  outwardTrip: selectors.checkout.outwardTrip(state.checkout),
  inboundTrip: selectors.checkout.inboundTrip(state.checkout),
  bookingHotels: state.checkout.hotels,
  bookingRentals: state.checkout.rentals,
});
export default connect(mapState)(CheckoutBookedItemsDetail);
