import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { verifyProperty, cond } from '@src/shared/src/util/general';
import { baseErrorCond } from '@toolkit/util/app';
import { getUserFilterVehiclesConstraints } from '@src/shared/src/util/filters';
import { t } from '@toolkit/util/i18n';

// Constants
// Actions, Models & Interfaces
import { usersActions, filterActions } from '@src/shared/src/actions';
import { UserModel } from '@src/shared/src/models';
import { IRootState } from '@src/store';
// Components
import { Checkbox, Dropdown } from '@toolkit/ui';
import { BusIcon, Flight, TrainIcon } from '@toolkit/ui/icons';

// Styles
import '../styles/SearchBarVehicles.scss';

const transports = [
  {
    value: 'transportationPlane',
    label: 'search.bar.vehicles.plane',
    icon: <Flight />,
  },
  {
    value: 'transportationTrain',
    label: 'search.bar.vehicles.train',
    icon: <TrainIcon />,
  },
  {
    value: 'transportationBus',
    label: 'search.bar.vehicles.bus',
    icon: <BusIcon />,
  },
];

const SearchBarVehicles: React.FC = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state: IRootState) => state.adminUser.profile);

  const editUserPreferences = (obj: { [key: string]: boolean }) => {
    dispatch(
      usersActions.saveUserPreferencesAsync.request({
        onSuccess: (currentUser: UserModel) =>
          dispatch(
            filterActions.setTripTransportationUserFilter(
              getUserFilterVehiclesConstraints(currentUser),
            ),
          ),
        onError: cond(baseErrorCond),
        userPreferences: { ...profile.preference, ...obj },
      }),
    );
  };

  const getLabel = () => {
    const profileSelectedTransportCount = transports.filter((transport) =>
      verifyProperty(true, profile, ['preference', transport.value]),
    );

    switch (profileSelectedTransportCount.length) {
      case 0:
        return t('search.bar.vehicles.none');
      case 1:
        return t(profileSelectedTransportCount[0].label);
      case transports.length:
        return t('search.bar.vehicles.all');
      default:
        return `${profileSelectedTransportCount.length} ${t('search.bar.vehicles.transport')}`;
    }
  };

  return (
    <Dropdown title={t('search.bar.vehicles.title')} label={getLabel()}>
      {transports.map((transport) => (
        <Checkbox
          key={transport.value}
          id={transport.value}
          label={t(transport.label)}
          icon={transport.icon}
          isChecked={verifyProperty(true, profile, ['preference', transport.value])}
          onChange={(val) => editUserPreferences({ [transport.value]: val })}
        />
      ))}
    </Dropdown>
  );
};

export default SearchBarVehicles;
