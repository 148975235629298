import * as React from 'react';

// Utils
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import './styles/Spinner.scss';

type Props = {
  small?: boolean;
}

const Spinner: React.FC<Props> = (props) => (
  <div className={`tcp-spinner ${props.small ? 'is--small' : ''}`}>
    <div className="tcp-spinner-circle" />
  </div>
);

export default Spinner;
