import * as React from 'react';
import { GoogleMap } from '@react-google-maps/api';

// Utils
// Constants
// Actions, Models & Interfaces
import { HotelModel, CoordinatesModel } from '@src/shared/src/models';
// Components
import HotelMapMarker from './HotelMapMarker';
import HotelDestMarker from './HotelDestMarker';
// Styles
import '../styles/HotelMap.scss';

type Props = {
  hotel:HotelModel;
  destCoord:CoordinatesModel;
};

class HotelMapDetails extends React.Component<Props> {
  public render() {
    const mapOptions = {
      minZoom: 8,
      maxZoom: 17,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    };

    const { hotel, destCoord } = this.props;

    return (
      <div className="tcp-hotel-details-map">
        <div className="tcp-hotel-details-map-element">
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: '100%' }}
            zoom={12}
            clickableIcons={false}
            center={{ lat: hotel.latitude, lng: hotel.longitude }}
            options={mapOptions}>
            <HotelMapMarker hotel={hotel} />
            <HotelDestMarker position={destCoord} />
          </GoogleMap>
        </div>
      </div>
    );
  }
}

export default HotelMapDetails;
