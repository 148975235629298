import * as React from 'react';
import reduxForm from 'redux-form/es/reduxForm';
import FormSection from 'redux-form/es/FormSection';
import FormProps from 'redux-form/es/Form';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Services
// Models, Stores & Interfaces
import { SettingsModel } from '@src/shared/src/models';
// Components
import { Button, Link } from '@toolkit/ui';
import CreateGuestForm from './CreateGuestForm';
import { AddCardForm } from '@pod/admin_user/components';
// Styles
import '@src/styles/modules/_form.scss';
import { ChevronIcon, ChevronUpIcon } from '@toolkit/ui/icons';

type Props = FormProps & {
  userTitles: SettingsModel[];
  appSettingsFlightLoyaltyCards: SettingsModel[];
  appSettingsTrainLoyaltyCards: SettingsModel[];
};

type State = {
  showAddCardForm: boolean;
};

class SearchBarGuestForm extends React.Component<Props, State> {
  readonly state: State = {
    showAddCardForm: false,
  };

  private toggleShowAddCardForm = (event) => {
    event.preventDefault();
    this.props.resetSection('addCard');
    this.setState({ showAddCardForm: !this.state.showAddCardForm });
  };

  public render() {
    return (
      <div className="form">
        <p>{t('search.bar.guest.form.description')}</p>
        <p>{t('search.bar.guest.form.guestInheritingPolicies')}</p>
        <p>
          <b>{t('search.bar.guest.form.subHeader')}</b>
        </p>
        <br />
        <form onSubmit={this.props.handleSubmit}>
          <FormSection name="createGuest">
            <CreateGuestForm userTitles={this.props.userTitles} />
          </FormSection>
          <div className="form-field">
            <Link
              icon={this.state.showAddCardForm ? <ChevronUpIcon /> : <ChevronIcon />}
              onClick={this.toggleShowAddCardForm}>
              {t('search.bar.guest.form.button.addDiscountCard')}
            </Link>
          </div>
          {this.state.showAddCardForm && (
            <FormSection name="addCard">
              <AddCardForm
                appSettingsFlightLoyaltyCards={this.props.appSettingsFlightLoyaltyCards}
                appSettingsTrainLoyaltyCards={this.props.appSettingsTrainLoyaltyCards}
                resetForm={() => this.props.resetSection('addCard')}
              />
            </FormSection>
          )}
          <div className="form-submit">
            <Button type="submit" className="button is--medium">
              {t('search.bar.guest.form.button.createGuest')}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default reduxForm({
  form: 'createGuest',
})(SearchBarGuestForm);
