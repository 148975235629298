import { IRootState } from '@src/store';

export const uiSearchDepSuggestOverlay = (state:IRootState) => state.ui.uiSearchDepSuggestOverlay;
export const uiSearchArrSuggestOverlay = (state:IRootState) => state.ui.uiSearchArrSuggestOverlay;
export const uiSearchDepAtOverlay = (state:IRootState) => state.ui.uiSearchDepAtOverlay;
export const uiSearchArrAtOverlay = (state:IRootState) => state.ui.uiSearchArrAtOverlay;
export const uiSearchPassengersOverlay = (state:IRootState) => state.ui.uiSearchPassengersOverlay;
export const uiSearchConciergeOverlay = (state:IRootState) => state.ui.uiSearchConciergeOverlay;
export const uiMobileFilterOverlay = (state:IRootState) => state.ui.uiMobileFilterOverlay;
export const uiMobileNoScrolling = (state:IRootState) => state.ui.uiMobileNoScrolling;
