import { isActionOf, getType } from 'typesafe-actions';
import { filter, switchMap, map, flatMap, catchError, debounceTime, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';

// Utils
import { head, jsonConvert, isNotNilOrEmpty } from '@src/shared/src/util/general';
import { getGoogleSuggestions, getGoogleSuggestionDetail } from '@toolkit/util/app';
import { apiRequestError } from '@src/shared/src/util/api';
// Constants
// Actions, Models & Interfaces
import { searchActions } from '@src/shared/src/actions';
import { SearchNodeModel, CoordinatesModel } from '@src/shared/src/models';
// Components
// Styles

export const fetchLocationSuggestionsEpic = (action$: any) =>
  action$.pipe(
    filter(isActionOf([searchActions.setSearchDep, searchActions.setSearchArr])),
    filter((action: any) => isNotNilOrEmpty(action.payload)),
    debounceTime(200),
    switchMap((action: any) =>
      from(getGoogleSuggestions(action.payload)).pipe(
        map((googleSuggestions: any) =>
          googleSuggestions.map((val: any) => jsonConvert.deserialize(val, SearchNodeModel)),
        ),
        map((suggestionsList: SearchNodeModel[]) =>
          action.type === getType(searchActions.setSearchDep)
            ? searchActions.setSearchDepSuggestions(suggestionsList)
            : searchActions.setSearchArrSuggestions(suggestionsList),
        ),
      ),
    ),
  );

export const fetchLocationDetailEpic = (action$: any, state$: any) =>
  action$.pipe(
    filter(isActionOf(searchActions.fetchLocationDetailAsync.request)),
    mergeMap((action: any) =>
      from(getGoogleSuggestionDetail(action.payload.location.reference)).pipe(
        flatMap((plCoor: CoordinatesModel) => {
          const types = action.payload.location.types;
          const preferredType = types.includes('locality') ? 'locality' : head(types);
          const resultAct = action.payload.isDep
            ? [
                searchActions.setSearchDep(action.payload.location.name),
                searchActions.setSearchDepCoor(plCoor.lat, plCoor.lng),
                searchActions.setSearchDepLocType(preferredType),
              ]
            : ([
                searchActions.setSearchArr(action.payload.location.name),
                searchActions.setSearchArrCoor(plCoor.lat, plCoor.lng),
                searchActions.setSearchArrLocType(preferredType),
              ] as any);
          return resultAct;
        }),
        catchError(err => apiRequestError(action.payload.onError, 'FetchLocationDetailError', err)),
      ),
    ),
  );
