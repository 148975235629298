import * as React from 'react';
import { Tooltip } from '@toolkit/ui';
import { t } from '@toolkit/util/i18n';

const TripTooltip:React.FC<{}> = () =>
        <Tooltip
          label={t('trip.tooltip.title.roundTrip')}
          bodyLabel={t('trip.tooltip.body.title.whyRoundTrip')}
          bodyTextFirst={t('trip.tooltip.body.text.roundTripDescription')}/>

export default TripTooltip;
