import * as React from 'react';

// Utils
// Constants
// Actions
// Models & Interfaces
// Components
// Styles
import './styles/ScrollToTop.scss';

export const ScrollToTop: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="lp-scroll-up-btn" onClick={scrollToTop}>
      <span />
    </div>
  );
};
