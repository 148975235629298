import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export default class NullToArrayConverter implements JsonCustomConvert<number> {
  public serialize(val:any): any {
    return val;
  }

  public deserialize(val:null): any {
    return val || [];
  }
}