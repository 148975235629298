// Utils
import { store } from '@src/store/store.config';
import { pipe, find, pathOr } from '@src/shared/src/util/general';
// Constants
// Actions
// Models
import { BookingItemModel } from '@src/shared/src/models';
// Interfaces
// Subscriptions
// Queries

export const getBookingItemStatusById = (bookingItemId: number) =>
  pipe(
    find((bookingItem: BookingItemModel) => Number(bookingItem.id) === bookingItemId),
    pathOr(undefined, ['status']),
  )(store.getState().checkout.currentBookingItems);

export const getCurrentBookingId = () =>
  pathOr(undefined, ['currentBooking', 'id'])(store.getState().checkout);
