import * as React from 'react';

// Utils
import { getOperatorImageDetailURL } from '@toolkit/util/app';
// Constants
import { VEHICLE_TYPES } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Interfaces
// Components

const RentalTicketOptions:React.FC<{ aggregatorName: string }> = ({ aggregatorName }) => {
  return(
    <div className="lp-rental-details-inner-car_ticket_description">
      <div className="tcp-transport-segment-ticket-images">
        <img
          src={getOperatorImageDetailURL(aggregatorName, VEHICLE_TYPES.RENTAL)}
          className="tcp-transport-segment-ticket-images-main"/>
      </div>
    </div>
  );
}

export default RentalTicketOptions;
