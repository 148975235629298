import * as React from 'react';

// Utils
import { requireAsset, requireHotelImages } from '@toolkit/util/app';
import { t } from '@toolkit/util/i18n';
// Constants
import { TRANSPORT_ICONS } from '@toolkit/const/app';
// Actions, Models & Interfaces
// Components
// Styles
import './styles/LoaderOverlayBody.scss';

type Props = {
  showHotel?:boolean,
  showTrips?:boolean,
  title?:string,
  delayedTitle?:string,
  subTitle?:any
  isSearchDelayed?:boolean,
};
const initialState = {
  currentIconIdx: 0,
  currentHotelIconIdx: 0,
};
type State = Readonly<typeof initialState>;

export default class LoaderOverlayBody extends React.Component<Props, State> {
  readonly state:State = initialState;

  private tickInterval: any;

  private hotelImages = requireHotelImages();

  public componentDidMount() {
    this.tickInterval = setInterval(
      () => {
        this.setState({
          currentIconIdx: (this.state.currentIconIdx + 1) % LoaderOverlayBody.icons.length,
          currentHotelIconIdx: (this.state.currentHotelIconIdx + 1) % this.hotelImages.length
        });
      }, 250);
  }


  public componentWillUnmount() {
    clearInterval(this.tickInterval);
  }

  private static icons = [
    TRANSPORT_ICONS.TRAIN,
    TRANSPORT_ICONS.PLANE,
    TRANSPORT_ICONS.BUS,
  ];

  public render() {
   

    return (
      <div className="tcp-loader-overlay-body">
        {this.props.showHotel &&
          <div className="tcp-loader-overlay-body-images">
            <img src={this.hotelImages[this.state.currentHotelIconIdx]}/>
          </div>
        }
        <div className="tcp-loader-overlay-body-loading">
          <i className="icon-from"></i>

          <div className="tcp-loader-overlay-body-track">
              <div className="icons">
                <i className={LoaderOverlayBody.icons[this.state.currentIconIdx]} />
              </div>
          </div>

          <i className="icon-to"></i>
        </div>
        
        {this.props.isSearchDelayed ? 
          <div className="tcp-loader-overlay-body-title">{this.props.delayedTitle}</div> 
        : 
          <>
            {this.props.title &&
              <div className="tcp-loader-overlay-body-title">{this.props.title}</div>}
            {this.props.subTitle &&
            <div className="tcp-loader-overlay-body-text">{this.props.subTitle}</div>}
          </>
        }
          { this.props.subTitle &&
          <div className="tcp-loader-overlay-body-promotion">
            <img src={requireAsset('genius.svg')}/>
            {t('loaderOverlayBody.promotion.text')} 
          </div>
        }
          
      </div>
    );
  }
}
