import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNilOrEmpty } from '@src/shared/src/util/general';
// Constants
// Actions
// Models
import { RentalFareExtraModel } from '@src/shared/src/models';
// Interfaces
// Interfaces
// Components
// Styles

type Props = {
  rentalExtras: RentalFareExtraModel[];
}

const RentalCarExtras:React.FC<Props> = (props:Props) => {
  if (isNilOrEmpty(props.rentalExtras)) {
    return null;
  }

  return(
    <div>
      <div className="lp-rental-details-inner-title">
        {t('RentalCarExtras.label.carExtras')}
      </div>
      {props.rentalExtras.map((extra, index) => (
        <div key={index} className="lp-rental-details-inner-car_option">
          {extra.amountSelected > 1 ? `${extra.amountSelected}x ` : ''}{extra.name}
        </div>
      ))}
    </div>
  );
}

export default RentalCarExtras;
