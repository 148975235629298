// Utils
import { map, filter, partial } from '../util/general';
// Constants
// Actions, Models & Interfaces
import { HotelModel } from '../models';
import { IHotelFilter } from '../interfaces';

export type HotelPriceFilterConstraint = {
  min:number;
  max:number;
  currentMin:number;
  currentMax:number;
}

export default class HotelPriceFilter implements IHotelFilter<HotelPriceFilterConstraint> {
  private constructor() {}

  private static instance:HotelPriceFilter;

  static getInstance = () => {
    if (!HotelPriceFilter.instance) {
      HotelPriceFilter.instance = new HotelPriceFilter();
    }
    return HotelPriceFilter.instance;
  }

  public id:string = 'HotelPriceFilter';

  public getConstraints = (hotels:HotelModel[]):HotelPriceFilterConstraint => {
    const prices = map((hotel:HotelModel) => hotel.price, hotels);

    return {
      min: Math.min(...prices),
      max: Math.max(...prices),
      currentMin: Math.min(...prices),
      currentMax: Math.max(...prices)
    }
  }

  public process = (constraint:HotelPriceFilterConstraint, hotels:HotelModel[]) =>
    filter(partial(this.match, [constraint]), hotels);

  private match = (constraint:HotelPriceFilterConstraint, hotel:HotelModel) =>
    (hotel.price <= constraint.currentMax && hotel.price >= constraint.currentMin);
}
