import { JsonObject, JsonProperty } from 'json2typescript';
import RentalFareExtraModel from './RentalFareExtraModel';

@JsonObject('RentalFareModel')
export default class RentalFareModel {
  // @JsonProperty('created_at', String) public createdAt:string = "2019-05-20T10:15:54.140Z"
  // @JsonProperty('ident', String) public ident:string = undefined;
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('currency', String, true) public currency:string = undefined;
  @JsonProperty('currency_conversion_rate', String) public currencyConversionRate:string = undefined;
  // @JsonProperty('details_status', String) public detailsStatus:string = undefined;
  // @JsonProperty('mileage_additional_currency', String) public mileageAdditionalCurrency:string = null
  // @JsonProperty('mileage_additional_net_amount', String) public mileageAdditionalNetAmount:string = null
  // @JsonProperty('mileage_additional_vat_percent', String) public mileageAdditionalVatPercent:string = null
  // @JsonProperty('mileage_free_distance', String) public mileageFreeDistance:string = null
  // @JsonProperty('mileage_free_unti', String) public mileageFreeUnti:string = null
  // @JsonProperty('mileage_unlimited', String) public mileageUnlimited:string = true
  @JsonProperty('original_currency', String) public originalCurrency:string = "AED"
  @JsonProperty('original_price', String) public originalPrice:string = '';
  @JsonProperty('price', Number) public price:number = -1;
  @JsonProperty('rate_code', String) public rateCode:string = '';
  @JsonProperty('rate_plan', String) public ratePlan:string = '';
  @JsonProperty('rental_days', Number) public rentalDays:number = -1;
  @JsonProperty('required_driver_age', Number) public requiredDriverAge:number = undefined;
  // @JsonProperty('required_driver_license', String) public requiredDriverLicense:string = null
  @JsonProperty('required_driver_license_age', Number) public requiredDriverLicenseAge:number = undefined;
  @JsonProperty('selected', Boolean) public selected:boolean = false;
  // @JsonProperty('updated_at', String) public updatedAt:string = "2019-05-20T10:16:29.904Z"
  @JsonProperty('vehicle_availability_id', Number) public vehicleAvailabilityId:number = -1;
  @JsonProperty('vehicle_examples', [String]) public vehicleExamples:string[] = [];
  @JsonProperty('car_image_url', String, true) public carImageUrl:string = undefined;
  // @JsonProperty('coverages', [RentalFareCoverageModel]) public coverages:RentalFareCoverageModel[] = [];
  @JsonProperty('translated_selected_optional_extras', [RentalFareExtraModel]) public extras:RentalFareExtraModel[] = [];
}
