import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('TariffFareTermsModel')
export default class TariffFareTermsModel {
  @JsonProperty('baggage', String, true) 
  baggage:string = undefined;

  @JsonProperty('conditions', String, true) 
  conditions:string = undefined;

  @JsonProperty('hand_luggage', String, true) 
  handLuggage:string = undefined;

  @JsonProperty('de_description', String, true) 
  deDescription:string = undefined;

  @JsonProperty('en_description', String, true) 
  enDescription:string = undefined;

  @JsonProperty('cancellation', String, true) 
  cancellation:string = undefined;

  @JsonProperty('de_cancellation_description', String, true) 
  deCancellationDescription:string = undefined;

  @JsonProperty('en_cancellation_description', String, true) 
  enCancellationDescription:string = undefined;

  @JsonProperty('rebooking', String, true) 
  rebooking:string = undefined;

  @JsonProperty('de_rebooking_description', String, true) 
  deRebookingDescription:string = undefined;

  @JsonProperty('en_rebooking_description', String, true) 
  enRebookingDescription:string = undefined;

  @JsonProperty('seat', String, true) 
  seat:string = undefined;

  @JsonProperty('de_seat_description', String, true) 
  deSeatDescription:string = undefined;

  @JsonProperty('en_seat_description', String, true) 
  enSeatDescription:string = undefined;

  @JsonProperty('seat_reservation', String, true) 
  seatReservation:string = undefined;

  @JsonProperty('seat_reservation_price', Number, true) 
  seatReservationPrice:number = -1;

  @JsonProperty('dep_city_ticket', Boolean, true) 
  depCityTicket: boolean = undefined;

  @JsonProperty('arr_city_ticket', Boolean, true)
  arrCityTicket: boolean = undefined;

  @JsonProperty('de_dep_city_ticket_description', String, true)
  deDepCityTicketDescription: string = undefined;

  @JsonProperty('de_arr_city_ticket_description', String, true)
  deArrCityTicketDescription: string = undefined;

  @JsonProperty('en_arr_city_ticket_description', String, true)
  enArrCityTicketDescription: string = undefined;


  @JsonProperty('en_dep_city_ticket_description', String, true)
  enDepCityTicketDescription: string = undefined;

}
