import * as React from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';

// Utils
import { formatCents } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
import { ENVIRONMENT, CENTS_CONVERSION_TYPES } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { HotelModel } from '@src/shared/src/models';
// Components
import HotelTitle from './HotelTitle';
import { HotelRating } from './HotelRating';
import HotelFavoriteButton from './HotelFavoriteButton';
import { HotelPrice } from './HotelPrice';
// Styles

interface IProps {
  hotel:HotelModel;
  onShowDetails?:(hotelId:string) => void;
  onHandelTooltip?:(hotelId:string) => void;
  isTooltipOpen?:boolean;
}

class HotelMapMarker extends React.Component<IProps> {
  private toggleDisplayTooltip = () =>
    this.props.onShowDetails && this.props.onHandelTooltip(this.props.hotel.id);

  public render() {
    const { hotel } = this.props;
    const rectSVG = {
      path: 'M-20 -15 H 22 V 12 H -20 L -20 -20',
      fillColor: '#e73f0c',
      strokeColor: '#992400',
      fillOpacity: 1,
      scale: 1,
      strokeWeight: 1,
    };

    return (
      <Marker
        label={{text: formatCents(hotel.pricePerRoomNight, CENTS_CONVERSION_TYPES.FLOOR), color: '#fff'}}
        position={{lat: hotel.latitude, lng: hotel.longitude}}
        icon={rectSVG}
        onClick={this.toggleDisplayTooltip}>
        {this.props.isTooltipOpen &&
          <InfoWindow
            position={{lat: hotel.latitude, lng: hotel.longitude}}
            zIndex={1}
            onCloseClick={this.toggleDisplayTooltip}>
                <div className="hotel-marker-details">
                  <div>
                    <img className="hotel-marker-details-image" src={hotel.primaryPictureURL}/>
                  </div>
                  <div>
                    <HotelTitle
                      name={hotel.name}
                      negotiatedRates={hotel.negotiatedRates}
                      distance={`${hotel.distance} km`}
                      comfortClass={hotel.comfortClass}
                      address={hotel.address}
                      env={ENVIRONMENT.SEARCH}
                      />
                    <HotelFavoriteButton
                      hotelId={hotel.id}
                      isFavorite={hotel.favorited} />
                    <HotelRating
                      numReviews={hotel.numReviews}
                      rating={hotel.originalUserRating}
                      scale={hotel.originalUserRatingScale}/>
                    <div>
                      {t('hotelMapMarker.label.pricesFrom')} <HotelPrice price={hotel.price} env={ENVIRONMENT.SEARCH} accommodationType={hotel.accommodationType}/>
                    </div>
                    <br/>
                    <button
                      className="is-bold"
                      onClick={() => this.props.onShowDetails(hotel.id)}>
                      {t('hotelMapMarker.button.viewOffer')}
                    </button>
                  </div>
                </div>
          </InfoWindow>}
      </Marker>
    );
  }
}

export default HotelMapMarker;
