import { createAction, createAsyncAction } from 'typesafe-actions';

type ApplyExtActionType = {
  callback:Function,
  param:any
};

export const inProgress = createAction('general/IN_PROGRESS');

export const noOp = createAction('general/NO_OP');

export const applyExtActionAsync = createAsyncAction(
  'general/APPLY_EXT_ACTION_REQUEST',
  'general/APPLY_EXT_ACTION_SUCCESS',
  'general/APPLY_EXT_ACTION_FAILURE'
)<ApplyExtActionType, ApplyExtActionType, number>();
