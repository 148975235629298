require('./app.bootstrap');
import Bugsnag from '@bugsnag/js';
import { browserLocale } from './app.bootstrap';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as ReactHintFactory from 'react-hint';
const ReactHint = ReactHintFactory(React);

// i18n
require('@toolkit/util/i18n');
import i18n from '@src/toolkit/util/i18n';
i18n.changeLanguage(browserLocale);
// Store
import { store } from '@src/store/store.config';
require('@src/bootstrap/bugsnag.bootstrap');

// We need to ignore the lint error otherwise at the moment `createErrorBoundary` complains about React types
// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

// Styles
require('./styles/main.scss');

import { isMediaBP } from '@toolkit/util/style';
import { AppContextType, AppContextProvider } from '@toolkit/util/AppContext';
const appContext: AppContextType = {
  isMediaBPNotMobile: !isMediaBP('md'),
  isMediaBPTabletUp: !isMediaBP('lg'),
};

import { IntercomProvider } from 'react-use-intercom';
import { INTERCOM } from '@toolkit/const/app';
import App from './App';

const render = (Component) => {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <AppContextProvider value={appContext}>
          <IntercomProvider appId={INTERCOM.APP_ID}>
            <ReactHint events delay={50} />
            <Component />
          </IntercomProvider>
        </AppContextProvider>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept();
}
