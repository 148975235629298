import { getTime } from 'date-fns';

// Utils
import { map, path, filter, partial, values } from '../util/general';
import { getTripLastTS } from '../util/trips';
// Constants
import { TIME_WINDOW_TYPE } from '../const/app';
// Actions
// Models
import { TripModel } from '../models';
// Interfaces
import { RangeFilterConstraint, TimeWindowFilterConstraint } from '../interfaces';
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';

export default class TripArrTimeFilter implements IFilter<TripModel, RangeFilterConstraint, TimeWindowFilterConstraint> {
  private constructor() {}

  private static instance:TripArrTimeFilter;

  static getInstance = () => {
    if (!TripArrTimeFilter.instance) {
      TripArrTimeFilter.instance = new TripArrTimeFilter();
    }
    return TripArrTimeFilter.instance;
  }

  public id:string = 'TripArrTimeFilter';

  public getConstraints = (
    opts:ConstraintsOpts<TripModel, RangeFilterConstraint, TimeWindowFilterConstraint>
  ):RangeFilterConstraint => {

    if (opts.reinit && opts.onlyUpdateConstraint) {
      return opts.constraint;
    }

    const allArrTimes = values(map(
      (trip:TripModel) => getTime(path(['arrAt'], getTripLastTS(trip.legs))), opts.values));

    const calculatedConstraints:RangeFilterConstraint = {
      min: Math.min(...allArrTimes),
      max: Math.max(...allArrTimes),
      currentMin: Math.min(...allArrTimes),
      currentMax: Math.max(...allArrTimes),
    };

    if ((opts.reinit && !opts.onlyUpdateConstraint) || opts.userConstraint.timeWindow === TIME_WINDOW_TYPE.ANYTIME) {
      return calculatedConstraints;
    }

    return {
      ...calculatedConstraints,
      currentMin: opts.userConstraint.currentMin,
      currentMax: opts.userConstraint.currentMax,
    };
  }

  public process = (constraint:RangeFilterConstraint, trips:TripModel[]) =>
    filter(partial(this.match, [constraint]), trips)

  private match = (constraint:RangeFilterConstraint, trip:TripModel) => {
    const tripArrAt = getTime(path(['arrAt'], getTripLastTS(trip.legs)));
    return (tripArrAt >= constraint.currentMin) && (tripArrAt <= constraint.currentMax);
  }
}
