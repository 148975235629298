import * as React from 'react';

interface IProps {
  id:string;
  name:string;
  value:string;
  label:string;
  onChange:(value:boolean) => void;
  outerCSS?:string;
  wrapperCSS?:string;
  labelCSS?:string;
  checked?:boolean;
}

const Radio: React.FC<IProps> = (props) => {
  return (
    <div className={props.outerCSS}>
      <div className={props.wrapperCSS }>
        <div className="fac fac-radio fac-primary">
          <span/>
          <input
            id={props.id}
            type="radio"
            name={props.name}
            value={props.value}
            checked={props.checked}
            onChange={(e:any) => props.onChange(e.target.checked)}/>
          <label className={props.labelCSS} htmlFor={props.id}>
            {props.label}
          </label>
        </div>
      </div>
    </div>
  );
};

export default Radio;
