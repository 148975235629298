import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter, StringToNumberConverter } from './converters';
import OptionModel from './OptionModel';

@JsonObject('UserPreferenceModel')
export default class UserPreferenceModel {
  @JsonProperty('id', Number) public id: number = -1;
  @JsonProperty('user_id', Number) public userId: number = -1;
  @JsonProperty('language', String) public language: string = '';
  @JsonProperty('effective_locale', String) public effectiveLocale: string = '';
  @JsonProperty('flight_seat_bulkhead_long_haul', String) public flightSeatBulkheadLongHaul: string = '';
  @JsonProperty('flight_seat_bulkhead_short_medium_haul', String) public flightSeatBulkheadShortMediumHaul: string = '';
  @JsonProperty('flight_seat_close_to_rest_room_long_haul', String) public flightSeatCloseToRestRoomLongHaul: string = '';
  @JsonProperty('flight_seat_close_to_rest_room_short_medium_haul', String) public flightSeatCloseToRestRoomShortMediumHaul: string = '';
  @JsonProperty('flight_seat_priorities_1_long_haul', String) public flightSeatPriorities1LongHaul: string = '';
  @JsonProperty('flight_seat_priorities_1_short_medium_haul', String) public flightSeatPriorities1ShortMediumHaul: string = '';
  @JsonProperty('flight_seat_priorities_2_long_haul', String) public flightSeatPriorities2LongHaul: string = '';
  @JsonProperty('flight_seat_priorities_2_short_medium_haul', String) public flightSeatPriorities2ShortMediumHaul: string = '';
  @JsonProperty('flight_seat_proximity_to_emergency_exit_long_haul', String) public flightSeatProximityToEmergencyExitLongHaul: string = '';
  @JsonProperty('flight_seat_proximity_to_emergency_exit_short_medium_haul', String) public flightSeatProximityToEmergencyExitShortMediumHaul: string = '';
  @JsonProperty('shuttle_at_arrival', String) public shuttleAtArrival: string = '';
  @JsonProperty('shuttle_at_departure', String) public shuttleAtDeparture: string = '';
  @JsonProperty('train_seat_compartment_type', String) public trainSeatCompartmentType: string = '';
  @JsonProperty('train_seat_location', String) public trainSeatLocation: string = '';
  @JsonProperty('train_seat_reservation', Boolean) public trainSeatReservation: boolean = false;
  @JsonProperty('train_seat_zone', String) public trainSeatZone: string = '';
  @JsonProperty('transportation_bus', Boolean) public transportationBus: boolean = true;
  @JsonProperty('transportation_plane', Boolean) public transportationPlane: boolean = true;
  @JsonProperty('transportation_rental_car', Boolean) public transportationRentalCar: boolean = true;
  @JsonProperty('transportation_train', Boolean) public transportationTrain: boolean = true;
  @JsonProperty('updated_at', DateConverter) public updatedAt: Date = new Date();
  @JsonProperty('location_favorite', [OptionModel]) public locationFavorite: OptionModel[] = [];
  @JsonProperty('location_home', OptionModel, true) public locationHome: OptionModel = undefined;
  @JsonProperty('location_work', OptionModel, true) public locationWork: OptionModel = undefined;
  @JsonProperty('transportation_sorting', String, true) public transportationSorting: string = undefined;
  @JsonProperty('hotel_sorting', String, true) public hotelSorting: string = undefined;
  @JsonProperty('hotel_vacation_rental', Boolean, true) public hotelVacationRental: boolean = undefined;
  @JsonProperty('activate_user_guide', Boolean) public activateUserGuide: boolean = false;
  @JsonProperty('hotel_rating_filter_min', StringToNumberConverter, true) public hotelRatingFilterMin: number = undefined;
}
