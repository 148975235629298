import * as models from './models';
import * as actions from './actions';
import * as reducers from './reducers';
import * as selectors from './selectors';
import * as epics from './epics';
import * as apiConst from './const/api';
import * as appUtil from './util/app';
import * as generalUtil from './util/general';
import ConfigBootstrap from './config.bootstrap';

// Verify instances
require('./util/resolvers/LocaleResolver').default.getInstance();
require('./config.bootstrap').default.getInstance();

export {
  models,
  actions,
  selectors,
  reducers,
  epics,
  apiConst,
  appUtil,
  generalUtil,
  ConfigBootstrap,
};
