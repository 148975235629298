import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models & Interfaces
import { UserModel } from '@src/shared/src/models';
// Components

type Props = {
  profile: UserModel;
};

export const RequestHotelLink: React.FC<Props> = ({ profile: { email } }) => {
  return (
    <div className="lp-hotels-conn-request" id="hotels-list-request-form">
      <strong>{t('hotelsConn.link.typeform.title')}</strong>
      {t('hotelsConn.link.typeform.label')}
      <a className="button" target="_blank" href={t('hotelsConn.link.typeform.url', { email })}>
        {t('hotelsConn.link.typeform.button')}
      </a>
    </div>
  );
};
