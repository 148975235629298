import * as React from 'react';
import classNames from 'classnames';

interface IProps {
  label:string;
  isActive?:boolean;
  activeClassName?:string;
  onClick:() => void;
}

const BreadcrumbItem:React.FC<IProps> = (props) => {
  const activeClass = props.isActive ? props.activeClassName : '';
  const linkStyle = classNames('breadcrumbs-link', {
    'breadcrumbs-link-is-active': props.isActive,
    'breadcrumbs-link-is-disabled': !props.isActive
  });
  const onClick = () => {
    if (props.isActive) {
      props.onClick();
    }
  };

  return (
    <li className={`breadcrumbs-item ${activeClass}`} onClick={onClick}>
      <span className={linkStyle}>{props.label}</span>
      <i className="icon-keyboard_arrow_right" />
    </li>
  );
};

export default BreadcrumbItem;
