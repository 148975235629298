import * as React from 'react';

// Utils
// Constants
// Actions, Models & Interfaces
// Components
import Spinner from './Spinner';
// Styles
import './styles/LoaderOverlay.scss';

interface IProps {
  title?: string;
  body?: any;
  showInBox?: boolean;
  showSpinner: boolean;
}

const LoaderOverlay: React.FC<IProps> = (props) => {
  return (
    <div className="tcp-loader-overlay">
      <div className="tcp-loader-overlay-content">
        {props.showSpinner && <Spinner />}
        {props.title &&
          <div className="tcp-loader-overlay-content-title">
            {props.title}
          </div>
        }
        {props.showInBox ? <div className="tcp-loader-overlay-inner">{props.body}</div> : props.body}
      </div>
    </div>
  );
};
export default LoaderOverlay;
