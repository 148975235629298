import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { formatCents } from '@src/shared/src/util/general';
// Constants
import { API_URL } from '@src/shared/src/const/api';
// Actions
// Models
import { RedeemingAllowanceModel } from '@src/shared/src/models';
// Interfaces
// Components
import { WarningBanner } from '@toolkit/ui';

type Props = {
  exceedsAllowance:boolean;
  redeemingAllowance:RedeemingAllowanceModel;
}

export const ExceedsAllowanceWarning:React.FC<Props> = (props) => {

  if (props.exceedsAllowance) {
   return(
    <WarningBanner
        icon="lp-icon-help"
        isInfo={true}
        label={
          <div>
            <p>
              {t('exceedsAllowanceWarning.exceedsAllowance.message',
              { redeemingAllowanceMaxPrice: formatCents(props.redeemingAllowance.maxPrice) })}
            </p>
            <div>
              <form method="post" action={`${API_URL.DEFAULT}/org_admin/allowances/${props.redeemingAllowance.id}/redeem`}>
                <button className="button" type="submit">
                  {t('exceedsAllowanceWarning.exceedsAllowance.link.redeem')}
                </button>
              </form>
            </div>
          </div>
        }
      />
   );
  } else {
    return null;
  }
}
