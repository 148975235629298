import * as React from 'react';

export type AppContextType = {
  isMediaBPNotMobile:boolean;
  isMediaBPTabletUp: boolean;
};

export const AppContext = React.createContext<AppContextType>({
  isMediaBPNotMobile: true,
  isMediaBPTabletUp: false,
});

export const AppContextProvider = AppContext.Provider;
export const AppContextConsumer = AppContext.Consumer;

// type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

// export function withAppContext<
//   P extends { appContext?: AppContextType },
//   R = Omit<P, 'appContext'>
// >(
//   Component: React.ComponentClass<P> | React.StatelessComponent<P>
// ): React.FC<R> {
//   return function BoundComponent(props: R) {
//     return (
//       <AppContextConsumer>
//         {(value) => <Component {...props as unknown as P} appContext={value} />}
//       </AppContextConsumer>
//     );
//   };
// }



export type AppContextProp = {
  appContext:AppContextType;
};

export function withAppContext<P extends AppContextProp>(Component: React.ComponentType<P>) {
  return function BoundComponent(props: Pick<P, Exclude<keyof P, keyof AppContextProp>>) {
    return (
      <AppContextConsumer>
        {(value) => <Component {...props as P} appContext={value} />}
      </AppContextConsumer>
    );
  };
}
