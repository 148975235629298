import * as React from 'react';
import reduxForm from 'redux-form/es/reduxForm';
import Field from 'redux-form/es/Field';
import { FormProps } from 'redux-form/es/Form';

// Utils
import { t } from '@toolkit/util/i18n';
import { navigate } from '@toolkit/util/app';
import { required } from '@toolkit/util/formsValidation';
// Constants
// Actions
// Models
// Interfaces
// Components
import { FormInput } from '@toolkit/ui';
// Styles

const ForgotPasswordForm: React.FC<FormProps> = (props) => {
  return (
    <div className="tcp-login">
      <div className="tcp-login-content">
        <span className="tcp-login-logo"></span>
        <h2 className="tcp-login-title">{t('forgotPasswordForm.title')}</h2>
        <p>{t('forgotPasswordForm.subtitle')}</p>
        <div className="tcp-login-form">
          <form onSubmit={props.handleSubmit}>
            <div className="tcp-login-form-field">
              <Field
                className="tcp-login-form-text"
                name="email"
                component={FormInput}
                type="email"
                validate={required}
                placeholder={t('forgotPasswordForm.form.label.email')} />
            </div>
            <button type="submit" className="button">
              {t('forgotPasswordForm.form.button.requestReset')}
            </button>
          </form>
          <button
            className="tcp-login-password-link is-underlined"
            onClick={() => navigate('/auth/login')}>
            {t('forgotPasswordForm.link.backToLogin')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default reduxForm({
  form: 'forgotPassword',
})(ForgotPasswordForm);
