// Utils
import {
  find,
  propEq,
  map,
  prop,
  path,
  pipe,
  head,
  last,
  defaultTo,
  nth,
  pluck,
  filter,
  flatten,
  isNilOrEmpty,
} from './general';
// Constants
import { VEHICLE_TYPES } from '../const/app';
// Actions, Models & Interfaces
import {
  TransportSegmentModel,
  LegModel,
  TripModel,
  TariffModel,
} from '../models';

const getOperatorsArr = map(prop('operators'));

/**
 * Find the first element in an array with the property `selected` set to `true`
 */
export const findBySelected = find(propEq('selected', true));

export const reduceByDuration = (acc:number, next:TransportSegmentModel) => acc + next.duration;

export const getLegTransportSegments = path(['legOption', 'transportSegments']);

export const getTripFirstTS = pipe(head, getLegTransportSegments, head);

export const getTripLastTS = pipe(last, getLegTransportSegments, last);

export const getOperator = (operators:string[] = []): string => {
  /* The operators could be in either ['LH'] or ['LH'|'SU'] format. Where in the latter, the string
   * before the | is always the provider
   */
  const operatorArr = defaultTo('', head(operators)).split('|');
  return `${defaultTo(`${head(operatorArr)}`, nth(1, operatorArr))}`;
};

// WARN doesn't work with more thqn 1 leg (Because it can be some waiting time in between)
export const getLegsDuration = (legs:LegModel[]) =>
  legs.reduce((acc:number, next:LegModel) => acc + next.legOption.duration, 0);

// TODO: Verify if it works with more than 1 leg (we maybe should substruct 1 from the sum)
export const getLegsChangeOvers = (legs:LegModel[]) =>
  legs.reduce((acc:number, next:LegModel) => acc + next.legOption.changeovers, 0);


export const getProduct = (products:string[] = []): string =>
  defaultTo('', head(products));

export const getTotalTransportSegmentNumber = (tripLegs:LegModel[]) => tripLegs.reduce(
  (acc: any, next: any) => acc + next.legOption.transportSegments.length, 0);

/**
 * @deprecated
 * User getMainLegs as that supports multiple main legs
 */
export const getMainLeg = (tripLegs:LegModel[]) =>
  find((leg:LegModel) => leg.legOption.changeable === false, tripLegs);

/**
 * @deprecated
 * User getMainLegHeadTS as that supports multiple main legs
 */
export const getMainLegFirstTS = pipe(getMainLeg, getLegTransportSegments, head);

/**
 * @deprecated
 * User getMainLegTailTS as that supports multiple main legs
 */
export const getMainLegLastTS = pipe(getMainLeg, getLegTransportSegments, last);

export const getMainLegOperators = pipe(getMainLeg, getLegTransportSegments, getOperatorsArr, flatten);

export const getSelectedTariff = (tariffs:TariffModel[]):TariffModel => (find(propEq('selected', true))(tariffs));

export const isLegTariffNil = pipe(path(['legOption', 'tariffs']), isNilOrEmpty);

/**
 * Returns all the selected tariffs from trips
 * @param {TripModel[]} trips - The trips for which to find the selected tariffs
 */
export const getSelectedTariffsByMainLeg:(trips:TripModel[]) => TariffModel[] = pipe(
  map((trip:TripModel) => getMainLegs(trip.legs)),
  flatten,
  map((tripLeg:LegModel) => findBySelected(tripLeg.legOption.tariffs))
);

/**
 * Returns all the main legs of a trip
 * @param {TripModel} trip - The trip for which to get the main legs
 */
export const getMainLegs:(legs:LegModel[]) => LegModel[] = filter((leg:LegModel) => leg.legOption.changeable === false);

/**
 * Returns all the `transportSegments` of a trip's main legs
 * @param {LegModel[]} legs - The legs of a trip
 */
export const getMainLegsTS:(legs:LegModel[]) => TransportSegmentModel[] = pipe(
  getMainLegs,
  pluck('legOption'),
  pluck('transportSegments'),
  flatten
);

/**
 * Returns the first transport segment of the main leg
 * @param {LegModel[]} legs - The legs of a trip
 */
export const getMainLegsHeadTS:(legs:LegModel[]) => TransportSegmentModel = pipe(getMainLegsTS, head);

/**
 * Returns the last transport segment of the main leg
 * @param {LegModel[]} legs - The legs of a trip
 */
export const getMainLegHeadTS:(legs:LegModel[]) => TransportSegmentModel = pipe(getMainLegsTS, last);

export const getTripsVechiles: (trips:TripModel[]) => VEHICLE_TYPES[] = pipe(
  pluck('legs'),
  flatten,
  getMainLegsTS,
  pluck('vehicles'),
  flatten,
);