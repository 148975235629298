import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { notify, navigate } from '@toolkit/util/app';
import { equals, includes } from '@src/shared/src/util/general';
// Constants
import { ROUTES, LAST_ERROR_STATUSES } from '@toolkit/const/app';
import { STATUS, NOTIF_TYPE } from '@src/shared/src/const/app';
// Subscriptions
import { bookingItemsErrorStatuses } from '@src/services/checkout/checkout.subscriptions';
// Queries
import { getBookingItemStatusById } from '@src/services/checkout/checkout.queries';
// Actions
// Models
import { BookingItemLastErrorStatusModel } from '@src/models';
// Interfaces
// Components
import { withObservableStream } from '@toolkit/util/state-utils/hoc.util';
// Styles

type Props = {
  emittedValues: BookingItemLastErrorStatusModel;
};

type State = {
  notified: BookingItemLastErrorStatusModel[];
};

class ShowLastErrorStatus extends React.Component<Props, State> {
  readonly state: State = {
    notified: [],
  };

  componentDidUpdate(prevProps: Props) {
    if (!equals(prevProps.emittedValues, this.props.emittedValues)) {
      if (!includes(this.props.emittedValues, this.state.notified)) {
        this.setState({ notified: [...this.state.notified, this.props.emittedValues] });

        const bookingItemStatus = getBookingItemStatusById(this.props.emittedValues.id);

        const notificationType =
          bookingItemStatus === STATUS.PREPARE_FINISHED ||
          bookingItemStatus === STATUS.CONFIRM_FINISHED
            ? NOTIF_TYPE.WARNING
            : NOTIF_TYPE.ERROR;

        const notificationButtons: [string, (instance: any, toast: any) => void][] =
          this.props.emittedValues.lastErrorStatus ===
          LAST_ERROR_STATUSES.PREPARE_FULFILLMENT_TYPE_NOT_ALLOWED
            ? [
                [
                  `<button>${t('showLastErrorStatus.notif.button.order')}</button>`,
                  (instance: any, toast: any) => {
                    navigate(ROUTES.SUPPORT_REQUESTS);
                    instance.hide({}, toast);
                  },
                ],
              ]
            : undefined;

        notify(
          t(`checkoutConn.lastErrorStatus.text.${this.props.emittedValues.lastErrorStatus}`),
          notificationType,
          notificationButtons,
          0,
        );
      }
    }
  }

  render() {
    return null;
  }
}

export default withObservableStream<BookingItemLastErrorStatusModel>(bookingItemsErrorStatuses())(
  ShowLastErrorStatus,
);
