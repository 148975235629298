// Utils
// Constants
import { DIRECTION, FLIGHTS_CABIN_CLASS, LOG_TYPE, SEARCH_TYPE } from '../const/app';
import SupplierCodes from './SupplierCodes';
import { find, propEq, isNilOrEmpty, isNil, equals } from './general';
import { errorLogger } from './errors';
import { SearchModel } from '../models';
// Actions
// Models
// Interfaces

export const applyExtAction = (callback:Function, param: any) => (callback) ? callback(param) : null;

export const getSupplierNameByCode = (code:string) => {
  const supplier = find(propEq('ident', code))(SupplierCodes);

  if (isNilOrEmpty(supplier)) {
    // Use the code as is
    return code;
  }
  return supplier.name;
};

export const getFlightCabinClassByKey = (key:string) => {
  if (isNilOrEmpty(key)) {
    errorLogger('Flight cabin class cannot be null or empty', LOG_TYPE.ERROR);
    return FLIGHTS_CABIN_CLASS.ECONOMY;
  }

  switch (key[0].toUpperCase()) {
    case 'E':
      return FLIGHTS_CABIN_CLASS.ECONOMY;
    case 'P':
      return FLIGHTS_CABIN_CLASS.PREMIUM_ECONOMY;
    case 'B':
      return FLIGHTS_CABIN_CLASS.BUSINESS;
    case 'F':
      return FLIGHTS_CABIN_CLASS.FIRST;
    default:
      return FLIGHTS_CABIN_CLASS.ECONOMY;
  }
};

// the search.searchType and search.types are not in sync
// E.g. search.searchType = SEARCH_TYPE.ALL and search.types = ['transport'] (but isSeachingForOnlyTransport = true)
// SEARCH_TYPE.TRANSPORT is never used
// I think we should always rely on search.types and get rid of search.searchType and isSeachingForOnlyXXX
// not sure why we are using both, I don't think we need search.searchType
// TODO: try to get rid of search.searchType and always use search.types
export const getSearchTypeFromSearch = (search:SearchModel) => {
  if (equals(search.types, ['rental'])) {
    return SEARCH_TYPE.RENTAL;
  } else if (equals(search.types, ['hotel'])) {
    return SEARCH_TYPE.HOTEL;
  } else if (isNil(search.arrAt)) {
    return SEARCH_TYPE.OUTBOUND;
  } else {
    return SEARCH_TYPE.ALL;
  }
};

export const getOppositeDirection = (direction: DIRECTION) =>
  direction === DIRECTION.OUTWARD ? DIRECTION.INBOUND : DIRECTION.OUTWARD;
