import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { getSupplierNameByCode } from '@src/shared/src/util/app';
// Constants
// Actions
// Models
import { TripModel } from '@src/shared/src/models';
// Interfaces
// Components
import { LabelButton, SidebarRoute } from '@toolkit/ui';
import { TripSideBarInfo } from '@pod/trips/components';
// Styles

type Props = {
  title:string;
  trip:TripModel;
  supplierCode:string;
  onEdit:() => void;
};

const CheckoutSideBarTripInfo: React.FC<Props> = (props:Props) => {
  return (
        <div className="sidebar-section is-open">
          <div className="sidebar-section-inner">
            <span className="sidebar-section-title">
              {props.title}
            </span>
            <TripSideBarInfo
              trip={props.trip}/>
            <div className="sidebar-trip-details">
              <SidebarRoute iconClass="">
                {getSupplierNameByCode(props.supplierCode)}
              </SidebarRoute>
              {props.onEdit &&
                <LabelButton
                  onClick={props.onEdit}
                  className="sidebar-edit">
                  <i className="icon-edit"/>{t('checkoutSideBarTripInfo.button.edit')}
                </LabelButton>
              }
            </div>
          </div>
        </div>
  );
};

export default CheckoutSideBarTripInfo;
