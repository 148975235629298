declare global {
  interface Window {
    zE: any;
  }
}

import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import {
  catchError,
  debounceTime,
  filter,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { empty } from 'rxjs';

// Utils
import { jsonConvert } from '../util/general';
import {
  apiRequest,
  apiRequestError,
} from '../util/api';
import { getUserFilterAccommodationTypeConstraints, getUserFilterVehiclesConstraints } from '../util/filters';
import { errorLogger } from '../util/errors';
// Constants
import {
  API_URL,
} from '../const/api';
// Actions
import {
  adminUserActions,
  filterActions,
  generalActions,
  organizationActions,
  usersActions,
} from '../actions';
// Models
import {
  UserModel,
} from '../models';
// Interfaces
import { RootAction } from '../interfaces';

export const verifyUserEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(adminUserActions.verifyUserAsync.request)),
    switchMap((action) =>
      apiRequest(API_URL.WHOAMI).pipe(
        mergeMap((res:any) => {
          if (res.response.signup_complete) {
            const user:UserModel = jsonConvert.deserializeObject(res.response, UserModel);

            return [
              adminUserActions.setUserProfile(user),
              organizationActions.setOrganization(user.organization),
              generalActions.applyExtActionAsync.request({ callback: action.payload.onSuccess, param: user }),
              filterActions.setTripTransportationUserFilter(
                getUserFilterVehiclesConstraints(user)
              ),
              filterActions.setHotelAccommodationUserFilter({
                current: getUserFilterAccommodationTypeConstraints(user)
              }),
              filterActions.setTripSortingUserFilter({
                current: user.preference.transportationSorting,
              }),
              filterActions.setHotelSortingUserFilter({
                current: user.preference.hotelSorting,
              }),
              filterActions.setHotelRatingUserFilter({
                current: user.preference.hotelRatingFilterMin,
              }),
            ];
          } else {
            return [
              generalActions.applyExtActionAsync.request({ callback: action.payload.onError, param: null }),
            ];
          }
        }),
        catchError((err) => {
          return apiRequestError(action.payload.onError, 'VerifyUserError', err);
        })
      )
    )
  );

// TODO: Check this epic should be somewhere else?
// Maybe find a better solution for this
export const addUserIdentityToZendeskEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(adminUserActions.zendeskWidgetLoaded)),
    debounceTime(3000),
    switchMap((action) => {
      try {
        if (window?.zE) {
          const { zendeskToken, userLocale } = action.payload;
          window.zE('messenger:set', 'locale', userLocale === 'en-GB' ? 'en-US' : userLocale);
          window.zE('messenger', 'loginUser', (callback) => callback(zendeskToken));
        }
      } catch (error) {
        errorLogger('addUserIdentityToZendeskError', error);
      }

      return empty();
    }),
  );

export const logoutUserFromZendeskEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(usersActions.doLogoutAsync.request)),
    switchMap(() => {
      try {
        window?.zE?.('messenger', 'logoutUser');
        return empty(); 
      } catch (error) {
        errorLogger('logoutUserFromZendeskError', error);
      }
    }),
  );
