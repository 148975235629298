import * as React from 'react';
import classNames from 'classnames';

// Utils
import { isNil } from '@src/shared/src/util/general';
import { getSupplierNameByCode } from '@src/shared/src/util/app';
// Constants
// Actions
// Models
import { OptionModel } from '@src/shared/src/models';
// Interfaces
// Components
// import Switch from './Switch';
// import { Checkbox } from '@toolkit/ui';
import { CheckboxFilter } from './CheckboxFilter';
// Styles

type Props = {
  title: string;
  suppliers: OptionModel[];
  isOpen?: boolean;
  isDisabled?: boolean;
  onChange: (vehicles: OptionModel[]) => void;
};
type State = {
  isOpen: boolean;
};

export default class SupplierFilter extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: isNil(props.isOpen) ? true : props.isOpen,
    };
  }

  private toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  public render() {
    const { title, suppliers, isDisabled } = this.props;
    const indicatorStyle = classNames('sidebar-section-indicator', {
      'sidebar-section-indicator-disabled': isDisabled,
    });
    return (
      <div className={`sidebar-section ${this.state.isOpen ? 'is-open' : ''}`}>
        <button className="sidebar-section-title" onClick={isDisabled ? null : this.toggleOpen}>
          {title}
          <i className={indicatorStyle} aria-hidden="true" />
        </button>
        <div className="sidebar-section-inner">
          {suppliers.map((supplier: OptionModel) => (
            <CheckboxFilter
              id={`su-id-${supplier.label}`}
              key={`su-key-${supplier.label}`}
              label={`${getSupplierNameByCode(supplier.label)}`}
              isChecked={Boolean(supplier.value)}
              onChange={val => {
                const nSuppliers: any = suppliers.map((sup: OptionModel) =>
                  sup.label === supplier.label
                    ? {
                        label: sup.label,
                        value: val,
                      }
                    : sup,
                );
                this.props.onChange(nSuppliers);
              }}
              onChangeOnly={() => {
                const nSuppliers: any = suppliers.map((sup: OptionModel) =>
                  sup.label === supplier.label ? { label: sup.label, value: true } : { label: sup.label, value: false },
                );
                this.props.onChange(nSuppliers);
              }}
            />
          ))}
        </div>
      </div>
    );
  }
}
