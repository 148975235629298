import * as React from 'react';
import { Field } from 'react-final-form';

// Utils
import { t } from '@toolkit/util/i18n';
import { required } from '@toolkit/util/formsValidation';
import { getPolicyViolationMessage } from '@toolkit/util/app';
// Constants
// Actions
// Models
import { PolicyViolationModel } from '@src/shared/src/models';
// Interfaces
// Components
// Styles
import '../styles/FormGroup.scss';

type Props = {
  policyViolations: PolicyViolationModel[];
  justificationRequired: boolean;
};
class CheckoutPaymentPolicyJustificationsForm extends React.Component<Props> {
  private getFormFields = () =>
    this.props.policyViolations.map((policyViolation: PolicyViolationModel, index: number) => (
      <div className="tcp-form-group tcp-form-group-vertical" key={`justification-${index}`}>
        <label className="tcp-form-group-label">{getPolicyViolationMessage(policyViolation)}</label>
        <div className="tcp-form-group-field tcp-form-group-field-full">
          <Field
            name={`policyViolations.justification-${policyViolation.id}`}
            parse={value =>
              value &&
              JSON.stringify({
                bookingItemId: policyViolation.bookingItemId,
                policyId: policyViolation.id,
                justification: value,
              })
            }
            format={value => value && JSON.parse(value).justification}
            validate={this.props.justificationRequired ? required : undefined}>
            {({ input, meta }) => (
              <div>
                <input {...input} type="text" />
                {meta.error && meta.touched && <div className="form-error-message">{meta.error}</div>}
              </div>
            )}
          </Field>
        </div>
      </div>
    ));

  public render() {
    return (
      <div className="checkout-box checkout-payment box">
        <h2 className="checkout-title hidden-xs-down">
          {t('checkoutPaymentPolicyJustificationsForm.title.justification')}
        </h2>
        <div className="checkout-box-content">{this.getFormFields()}</div>
      </div>
    );
  }
}

export default CheckoutPaymentPolicyJustificationsForm;
