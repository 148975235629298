import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
// Interfaces
// Components
// Styles

type Props = {
  navToPrepare: () => void;
};

const CheckoutHeaderPayment: React.FC<Props> = (props) => {
  return (
    <div className="content-header">
      <button className="tcp-link" onClick={props.navToPrepare}>
        <i className="icon-arrow_back" />
        {t('CheckoutHeader.button.backToOverview')}
      </button>
    </div>
  );
};

export default CheckoutHeaderPayment;
