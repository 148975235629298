import * as React from 'react';
require ('./styles/Button.scss');
require ('./styles/Spinner.scss');

import { omit } from '@src/shared/src/util/general';

interface IProps {
  children:any;
  className?:string;
  withIcon?:boolean;
  onClick?:any;
  isLoading?:boolean;
  disabled?:boolean;
  id?: string;
  type?:string;
}

const Button: React.FC<IProps> = (props) => {
  return (
    <button
      id={props.id}
      disabled={props.disabled || props.isLoading}
      className={props.className}
      onClick={props.onClick}
      {...omit(['withIcon', 'isLoading'], props)}>
      {props.isLoading &&
        <div className="tcp-spinner tcp-spinner-button">
          <div className="tcp-spinner-circle tcp-spinner-circle-button"/>
        </div>
      }
      {props.children}
      {props.withIcon && <i className="icon-arrow_forward" />}
    </button>
  );
};

Button.defaultProps = {
  className: 'button'
};

export default Button;
