import { JsonObject, JsonProperty } from 'json2typescript';
import { DIRECTION } from '../const/app';

@JsonObject('RebookingModel')
export default class RebookingModel {
  @JsonProperty('id', Number) id: number = -1;
  @JsonProperty('original_travel_booking_id', Number) originalTravelBookingId: number = -1;
  @JsonProperty('original_travel_booking_item_id', Number) originalTravelBookingItemId: number = -1;
  @JsonProperty('original_fare_id', Number, true) originalFareId: number = -1;
  @JsonProperty('original_fare_type', String, true) originalFareType: string;
  originalFareDirection?: DIRECTION;

  constructor(args: Partial<RebookingModel>) {
    this.id = args?.id || -1;
    this.originalTravelBookingId = args?.originalTravelBookingId || -1;
    this.originalTravelBookingItemId = args?.originalTravelBookingItemId || -1;
    this.originalFareId = args?.originalFareId || -1;
    this.originalFareType = args?.originalFareType || '';
    this.originalFareDirection = args?.originalFareDirection;
  }
}
