import { Subscription } from 'rxjs';
import { useState, useEffect } from 'react';

// Utils
import { onEmit, getDerivedHook } from '@toolkit/util/state-utils/state.util';
// Constants
import { DIRECTION } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Subscriptions
import * as searchSubscriptions from './search.subscriptions';
// Queries

//TODO: we can probably get rid of this method and use getDerivedHook here as well after binding the subscription method
export const useSearchState = <T>(statePath: string[]): [T] => {
  const [state, setState] = useState<T>();
  useEffect(() => {
    const subscriptions: Subscription[] = [
      onEmit<T>(
        searchSubscriptions.getSubscription(
          ['search'].concat(statePath)), search => setState(search)),
    ];

    return () => { subscriptions.map(it => it.unsubscribe()); };
  });

  return [state];
};

export const useIsDepMisisng = () => getDerivedHook<boolean>(searchSubscriptions.isDepMissing);
export const useIsArrMissing = () => getDerivedHook<boolean>(searchSubscriptions.isArrMissing);
export const useIsHotelSameDay = () => getDerivedHook<boolean>(searchSubscriptions.isHotelSameDay);
export const useIsArrDateMissing = () =>
  getDerivedHook<boolean>(searchSubscriptions.isArrDateMissing);
export const useIsDepDateMissing = () =>
  getDerivedHook<boolean>(searchSubscriptions.isDepDateMissing);
export const useIsDepArrSame = () =>
  getDerivedHook<boolean>(searchSubscriptions.isDepArrSame);
export const useIsDepDateInPast = () =>
  getDerivedHook<boolean>(searchSubscriptions.isDepDateInPast);
export const useIsRentalDriverCountWrong = () =>
  getDerivedHook<boolean>(searchSubscriptions.isRentalDriverCountWrong);
export const useIsRentalDepartureTimeInPast = () =>
  getDerivedHook<boolean>(searchSubscriptions.isRentalDepartureTimeInPast);
export const useIsSearchValid = () => getDerivedHook<boolean>(searchSubscriptions.isSearchValid);
export const useIsPassengersMissing = () =>
  getDerivedHook<boolean>(searchSubscriptions.isPassengersMissing);
export const useIsPassengersSelectionWrong = () =>
  getDerivedHook<boolean>(searchSubscriptions.isPassengersSelectionWrong);
export const useIsOnlyPassengerGuest = () =>
  getDerivedHook<boolean>(searchSubscriptions.isOnlyPassengerGuest);
export const useSearchDate = (direction: DIRECTION) =>
  getDerivedHook<Date>(() => searchSubscriptions.searchDate(direction));
export const useIsDropoffBeforePickup = () =>
  getDerivedHook<boolean>(() => searchSubscriptions.isDropoffBeforePickup());
export const useIsPassengersMoreThanAllowed = () =>
  getDerivedHook<boolean>(() => searchSubscriptions.isPassengersMoreThanAllowed());
