import * as React from 'react';

import '@src/toolkit/ui/styles/Rating.scss';
import '../styles/HotelRating.scss';
import { formatThousands } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import { getHotelRatingOpacity } from '@toolkit/util/style';

type Props = {
  rating: string;
  numReviews: number;
  scale: number;
};

export const HotelRating: React.FC<Props> = ({ rating, numReviews, scale }) => {

  return (
    <div className={`tcp-hotel-rating tcp-hotel-rating-${getHotelRatingOpacity(Number(rating))}`}>
      {rating &&
        <span className="tcp-hotel-rating-count"><b>{rating}/</b>{scale}</span>}
      <span>{numReviews ? formatThousands(numReviews) : 0} {t('HotelRating.label.ratings')}</span>
    </div>
  );
}
