import { createAction } from 'typesafe-actions';

// Utils
// Constants
import { TRIP_DIRECTION, DIRECTION } from '../const/app';
// Actions
// Models
import { OptionModel } from '../models';
// Interfaces
import {
  HotelDistanceFilterConstraint,
  HotelNameFilterConstraint,
  HotelPriceFilterConstraint,
  HotelPricePerNightFilterConstraint,
  HotelRatingFilterConstraint,
  HotelSortingFilterConstraint,
  HotelStarFilterConstraint,
  TripInvalidInboundsFilterConstraint,
} from '../filters';
import {
  TimeWindowFilterConstraint,
  IFilterAction,
  RangeFilterConstraint,
  SingleFilterConstraint,
  SortingFilterConstraint,
  StationsFilterConstraint,
  CurrentFilterConstraint,
} from '../interfaces';
import { FilterOption, SelectionModel } from '@src/models';

export const clearFilters = createAction('filters/CLEAR_FILTERS');

// Trip Filters
export const initializeTripFilters = createAction(
  'filters/INITIALIZE_TRIP_FILTERS',
  (resolve) => (direction: string, reinit: boolean, onlyUpdateConstraint?: boolean) =>
    resolve({ direction, reinit, onlyUpdateConstraint }),
);

export const setTripDurationFilter = createAction(
  'filters/SET_TRIP_DURATION_FILTER',
  (resolve) => (constraint: IFilterAction<SingleFilterConstraint>) => resolve(constraint),
);

export const setTripArrTimeFilter = createAction(
  'filters/SET_TRIP_ARR_TIME_FILTER',
  (resolve) => (constraint: IFilterAction<RangeFilterConstraint>) => resolve(constraint),
);

export const setTripDepTimeFilter = createAction(
  'filters/SET_TRIP_DEP_TIME_FILTER',
  (resolve) => (constraint: IFilterAction<RangeFilterConstraint>) => resolve(constraint),
);

export const setTimeWindow = createAction(
  'filters/SET_TIME_WINDOW',
  (resolve) => (
    timeWindowConstraints: TimeWindowFilterConstraint,
    tripDirection: TRIP_DIRECTION,
    direction: DIRECTION,
  ) => resolve({ timeWindowConstraints, tripDirection, direction }),
);

export const setTripPriceFilter = createAction(
  'filters/SET_TRIP_PRICE_FILTER',
  (resolve) => (constraint: IFilterAction<SingleFilterConstraint>) => resolve(constraint),
);

export const setTripStopsFilter = createAction(
  'filters/SET_TRIP_STOPS_FILTER',
  (resolve) => (constraint: IFilterAction<SingleFilterConstraint>) => resolve(constraint),
);

export const setTripTransportationFilter = createAction(
  'filters/SET_TRIP_TRANSPORTATION_FILTER',
  (resolve) => (constraint: IFilterAction<OptionModel[]>) => resolve(constraint),
);

export const setTripTransportationUserFilter = createAction(
  'filters/SET_TRIP_TRANSPORTATION_USER_FILTER',
  (resolve) => (constraint: OptionModel[]) => resolve(constraint),
);

export const setTripStationsFilter = createAction(
  'filters/SET_TRIP_STATIONS_FILTER',
  (resolve) => (constraint: IFilterAction<StationsFilterConstraint>) => resolve(constraint),
);

export const setTripSupplierFilter = createAction(
  'filters/SET_TRIP_SUPPLIER_FILTER',
  (resolve) => (constraint: IFilterAction<OptionModel[]>) => resolve(constraint),
);

export const setTripInvalidInboundsFilter = createAction(
  'filters/SET_TRIP_INVALID_INBOUNDS_FILTER',
  (resolve) => (constraint: IFilterAction<TripInvalidInboundsFilterConstraint>) =>
    resolve(constraint),
);

export const setTripSortingFilter = createAction(
  'filters/SET_TRIP_SORTING_FILTER',
  (resolve) => (constraint: IFilterAction<SortingFilterConstraint>) => resolve(constraint),
);

export const setTripSortingUserFilter = createAction(
  'filters/SET_TRIP_SORTING_USER_FILTER',
  (resolve) => (constraint: SortingFilterConstraint) => resolve(constraint),
);

// Hotel Filters
export const initializeHotelFilters = createAction(
  'filters/INITIALIZE_HOTEL_FILTERS',
  (resolve) => (direction: string) => resolve(direction),
);

export const setHotelNameFilter = createAction(
  'filters/SET_HOTEL_NAME_FILTER',
  (resolve) => (constraint: IFilterAction<HotelNameFilterConstraint>) => resolve(constraint),
);

export const setHotelPriceFilter = createAction(
  'filters/SET_HOTEL_PRICE_FILTER',
  (resolve) => (constraint: IFilterAction<HotelPriceFilterConstraint>) => resolve(constraint),
);

export const setHotelRatingFilter = createAction(
  'filters/SET_HOTEL_RATING_FILTER',
  (resolve) => (constraint: IFilterAction<HotelRatingFilterConstraint>) => resolve(constraint),
);

export const setHotelRatingUserFilter = createAction(
  'filters/SET_HOTEL_RATING_USER_FILTER',
  (resolve) => (constraint: CurrentFilterConstraint<number>) => resolve(constraint),
);

export const setHotelStarFilter = createAction(
  'filters/SET_HOTEL_STAR_FILTER',
  (resolve) => (constraint: IFilterAction<HotelStarFilterConstraint>) => resolve(constraint),
);

export const setHotelDistanceFilter = createAction(
  'filters/SET_HOTEL_DISTANCE_FILTER',
  (resolve) => (constraint: IFilterAction<HotelDistanceFilterConstraint>) => resolve(constraint),
);

export const setHotelPricePerNightFilter = createAction(
  'filters/SET_HOTEL_PRICE_PER_NIGHT_FILTER',
  (resolve) => (constraint: IFilterAction<HotelPricePerNightFilterConstraint>) =>
    resolve(constraint),
);

export const setHotelSortingFilter = createAction(
  'filters/SET_HOTEL_SORTING_FILTER',
  (resolve) => (constraint: IFilterAction<HotelSortingFilterConstraint>) => resolve(constraint),
);

export const setHotelSortingUserFilter = createAction(
  'filters/SET_HOTEL_SORTING_USER_FILTER',
  (resolve) => (constraint: HotelSortingFilterConstraint) => resolve(constraint),
);

export const setHotelFeatureFilter = createAction(
  'filters/SET_HOTEL_FEATURE_FILTER',
  (resolve) => (constraint: IFilterAction<CurrentFilterConstraint<SelectionModel[]>>) => resolve(constraint),
);

export const setHotelAccommodationFilter = createAction(
  'filters/SET_HOTEL_ACCOMMODATION_FILTER',
  (resolve) => (constraint: IFilterAction<CurrentFilterConstraint<FilterOption[]>>) => resolve(constraint),
);

export const setHotelAccommodationUserFilter = createAction(
  'filters/SET_HOTEL_ACCOMMODATION_USER_FILTER',
  (resolve) => (constraint: CurrentFilterConstraint<FilterOption[]>) => resolve(constraint),
);

export const applyFilter = createAction(
  'filters/APPLY_FILTER',
  (resolve) => (filterId: string, constraint: any, direction: string) =>
    resolve({ filterId, constraint, direction }),
);
