import * as React from 'react';

// Utils
import { isNilOrEmpty, isNil } from '@src/shared/src/util/general';
import { getUserFullname } from '@src/shared/src/util/users';
// Constants
// Actions
// Models
import { SettingsModel, PassengerModel } from '@src/shared/src/models';
// Interfaces
// Components
import { SidebarRoute } from '@toolkit/ui';
// Styles
type Props = {
  passengers:PassengerModel[];
  loyaltyCards:SettingsModel[];
};

const SideBarPassengersInfo: React.FC<Props> = (props:Props) => {
  const passengers = props.passengers.map((passenger:PassengerModel, index:number) => {
    if (!isNil(passenger)) {
      return (
        <SidebarRoute iconClass="icon-male-female" key={index}>
          {getUserFullname(passenger)}
          &nbsp;
          {!isNilOrEmpty(passenger.membershipCards) &&
            `(${(passenger.membershipCards).join(' | ')})`}
        </SidebarRoute>
      );
    }
    return null;
  });

  return (
    <span>{passengers}</span>
  );
};

export default SideBarPassengersInfo;
