import { from, Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

// Utils
import { store } from '@src/store/store.config';
import { isNil } from '@src/shared/src/util/general';
import { getStateStreamSlice } from '@toolkit/util/state-utils/state.util';
// Constants
import { ROLES } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
import { IRootState } from '@src/store';
import { UserModel } from '@src/shared/src/models';

const _currentUser$: Observable<UserModel> =
  from(store as any).pipe(map((state: IRootState) => state.adminUser.profile));

export const loggedInUser = () => _currentUser$;

export const loggedInUserId = () => getStateStreamSlice(_currentUser$, ['id']);

export const canRemoveItselfAsPassenger = () => _currentUser$.pipe(
  filter(profile => !isNil(profile)),
  map(profile => {
    switch (profile.role) {
      case ROLES.READ_ONLY_TRAVELLER:
      case ROLES.TRAVELLER:
      case ROLES.ACCOUNTANT:
      case ROLES.READ_ONLY_ADMIN:
        return false;
      default:
        return true;
    }
  })
);
