import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './converters';

@JsonObject('LoyaltyCardModel')
export default class LoyaltyCardModel {
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('user_id', Number) public userId:number = -1;
  @JsonProperty('applies_to', String) public appliesTo:string = '';
  @JsonProperty('ident', String) public ident:string = '';
  @JsonProperty('number', String) public number:string = '';
  @JsonProperty('valid_until', DateConverter, true) validUntil:Date = undefined;
  @JsonProperty('created_at', DateConverter, true) createdAt:Date = undefined;
  @JsonProperty('updated_at', DateConverter, true) updatedAt:Date = undefined;
}
