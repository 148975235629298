import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
import {
  isNil,
  join,
  map,
  pipe,
} from '@src/shared/src/util/general';
import { formatDate } from '@src/shared/src/util/date';
import { checkout } from '@src/shared/src/selectors';
import { getPolicyViolationMessage } from '@toolkit/util/app';
import { getUserFullname } from '@src/shared/src/util/users';
// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions
// Models
import {
  PassengerModel,
  BookingModel,
  PolicyViolationModel,
} from '@src/shared/src/models';
import { ConnectedRedux, IRootState } from '@src/store';
// Interfaces
// Components
// Styles
import '../styles/JourneyPlanInformation.scss';

type Props = ConnectedRedux<IRootState> & {
  currentBooking:BookingModel,
  policyViolations:PolicyViolationModel[],
  referenceCode?:string;
  costCenter?:string;
  purposeOfTrip?:string;
  passengers:PassengerModel[];
  bookedBy:string;
  invoiceProfileName?:string;
};

class JourneyPlanInformation extends React.Component<Props> {
  renderPolicyViolationJustifcations = () => {
    const justifications = [];
    this.props.policyViolations.map((polViolation:PolicyViolationModel) => {
      justifications.push(
        <p key={polViolation.id}>{getPolicyViolationMessage(polViolation)}: {polViolation.justification}<br/></p>
      );
    });
    return (
      <div>
        {justifications}
      </div>
    );
  }

  render() {
    const {
      referenceCode,
      costCenter,
      purposeOfTrip,
      passengers,
      bookedBy,
      invoiceProfileName,
      currentBooking,
    } = this.props;
    if (isNil(currentBooking)) {
      return null;
    }

    return (
      <div className="lp-booking-info lp-journey-plan-information">
        <div>
          <button className="button no-print-chk" style={{float: 'right'}} onClick={() => (window as any).print()}>
            {t('journeyPlanInformation.btn.print')}
          </button>
          <div className="lp-booking-info-title">{t('journeyPlanInformation.title')}</div>
        </div>
        <hr/>
        <table>
          <tbody>
            <tr>
              <td><strong>{t('journeyPlanInformation.label.bookingId')}:</strong></td>
              <td>{currentBooking.id}</td>
            </tr>
            {referenceCode &&
              <tr>
                <td><strong>{t('journeyPlanInformation.label.referenceCode')}:</strong></td>
                <td>{referenceCode}</td>
              </tr>
            }
            {costCenter &&
              <tr>
                <td><strong>{t('journeyPlanInformation.label.costCenter')}:</strong></td>
                <td>{costCenter}</td>
              </tr>
            }
            <tr>
              <td><strong>{t('journeyPlanInformation.label.bookedAt')}:</strong></td>
              <td>{formatDate(currentBooking.createdAt)} -
                {formatDate(currentBooking.createdAt, DATE_FORMAT_TYPES.LONG_TIME)}</td>
            </tr>
            {purposeOfTrip &&
              <tr>
                <td><strong>{t('journeyPlanInformation.label.purposeOfTrip')}:</strong></td>
                <td>{purposeOfTrip}</td>
              </tr>
            }
            <tr>
              <td><strong>{t('journeyPlanInformation.label.travellers')}:</strong></td>
              <td>
                {pipe(map(getUserFullname), join(' | '))(passengers)}
              </td>
            </tr>
            <tr>
              <td><strong>{t('journeyPlanInformation.label.bookedBy')}:</strong></td>
              <td>{bookedBy}</td>
            </tr>
            <tr>
              <td><strong>{t('journeyPlanInformation.label.invoiceProfile')}:</strong></td>
              <td>{invoiceProfileName}</td>
            </tr>
            <tr>
              <td><strong>{t('journeyPlanInformation.label.remark')}:</strong></td>
              <td><textarea cols={50} rows={4}  className="lp-journey-plan-information-remark"/></td>
            </tr>
          </tbody>
        </table>
        {this.props.policyViolations.length !== 0 &&
          <div>
            <p><strong>{t('journeyPlanInformation.label.justifications')}:</strong></p>
            <div>{this.renderPolicyViolationJustifcations()}</div>
          </div>
        }
      </div>
    );
  }
}
const mapState = (state:IRootState) => ({
  currentBooking: state.checkout.currentBooking,
  policyViolations: checkout.policyViolations(state.checkout),
});
export default connect(mapState)(JourneyPlanInformation);
