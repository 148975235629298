import * as React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

// Utils
import { required } from '@toolkit/util/formsValidation';
import { t } from '@toolkit/util/i18n';
import {
  composeValidators,
  checkSelectValueUniqueness,
  checkSelectPercentage,
  checkRequiredCostCenter,
  checkNullCostCenter,
} from '@toolkit/util/formsValidation';
// Constants
import { COST_CENTER_CATEGORIES } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Components
import { SelectWithPercentage } from '@toolkit/ui';
// Styles

type Props = {
  required: boolean;
  userIds: number[];
  displayAllUrl: string;
  formFieldName: string;
  category: COST_CENTER_CATEGORIES;
};
export const MultiCostCentersFormField: React.FC<Props> = (props) => {
  const validators = [checkSelectPercentage, checkSelectValueUniqueness, checkNullCostCenter];
  if (props.required) {
    validators.push(checkRequiredCostCenter);
  }
  return (
    <div className="tcp-form-group tcp-form-group-cost-center">
      <label htmlFor="costCenter" className="tcp-form-group-label">
        {props.category === COST_CENTER_CATEGORIES.COST_CENTER
          ? t('checkoutPaymentAccountingForm.label.costCenter')
          : t('checkoutPaymentAccountingForm.label.costUnit')}
        <div className="tcp-form-group-info">
          {t('costCentersForm.label.costCenterAllocationMessage')}
        </div>
        <div>
          {!(window as any).__IS_WEBVIEW &&
            <a
              className="lp-booking-info-item-link is-font-medium"
              href={props.displayAllUrl}
              target="_blank">
              <i className="icon-external_link" />
              {props.category === COST_CENTER_CATEGORIES.COST_CENTER
                ? t('checkoutPaymentAccountingForm.link.all_cost_centers')
                : t('checkoutPaymentAccountingForm.link.all_cost_units')}
            </a>
          }
        </div>
      </label>
      <div className="tcp-form-group tcp-form-group-spaced tcp-form-group-full">
        <FieldArray name={props.formFieldName} validate={composeValidators(...validators)}>
          {({ fields, meta }) => {

            if (fields.length < 1 && props.required) {
              fields.push({
                selectValue: undefined,
                selectLabel: undefined,
                percentageValue: 100,
              });
            }

            return (
              <div className="tcp-form-group-multi">
                {fields.map((costCenter, index) => (
                  <div key={`${costCenter}-${index}`} className="tcp-form-group-field">
                    <Field
                      name={costCenter}
                      clearable={false}
                      backspaceRemoves={false}
                      deleteRemoves={false}
                      validate={props.required ? required : undefined}
                      required={props.required}
                      userIds={props.userIds}
                      category={props.category}
                      component={SelectWithPercentage}
                    />
                  </div>
                ))}
                {fields.length < 5 && (
                  <button
                    className="tcp-form-group-button"
                    type="button"
                    onClick={() =>
                      fields.push({
                        selectValue: undefined,
                        selectLabel: undefined,
                        percentageValue: 100,
                      })
                    }>
                    <i className="icon-add_box" />
                    {t('costCentersForm.button.add')}
                  </button>
                )}
                {(fields.length > 1 || (fields.length === 1 && !props.required)) && (
                  <button
                    className="tcp-form-group-button tcp-form-group-button-delete"
                    type="button"
                    onClick={() => fields.remove(fields.length - 1)}>
                    <i className="icon-delete" />
                    {t('costCentersForm.button.remove')}
                  </button>
                )}
                {meta.error && <div className="form-error-message">{meta.error}</div>}
                {fields.length > 1 && (
                  <div className="tcp-form-group-notice">
                    {t('costCentersForm.label.costCenterRemoveMessage')}
                  </div>
                )}
              </div>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};
