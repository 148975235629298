import * as React from 'react';
import { useState } from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNil } from '@src/shared/src/util/general';
// Constants
import { ENVIRONMENT, VEHICLE_TYPES } from '@src/shared/src/const/app';
// Actions
// Models
import { TariffFareTermsModel } from '@src/shared/src/models';
// Interfaces
// Components
import { Modal } from '@toolkit/ui';
import { TrainSeatReservation } from './TrainSeatReservation';
import { TariffPlaneFareTerms } from './TariffPlaneFareTerms';
import { CityTicketTerms } from './CityTicketTerms';
// Styles
import '../styles/TariffFareTerms.scss';

type Props = {
  fareName: string;
  fareTerms: TariffFareTermsModel;
  vehicle: VEHICLE_TYPES;
  profileLocale: string;
  env: ENVIRONMENT;
  basketItemId: number;
  requestSeatReservation: boolean;
  setRequestSeatReservation: (value: boolean) => void;
  isBasketEditable: boolean;
};

const TariffFareTerms: React.FC<Props> = (props: Props) => {
  const [showTariffConditions, setShowTariffConditions] = useState<boolean>(false);

  const getFareTermsByVehicle = () => {
    if (!isNil(props.fareTerms)) {
      switch (props.vehicle.toUpperCase()) {
        case VEHICLE_TYPES.TRAIN:
          return (
            <TrainSeatReservation
              env={props.env}
              fareTerms={props.fareTerms}
              basketItemId={props.basketItemId}
              requestSeatReservation={props.requestSeatReservation}
              setRequestSeatReservation={props.setRequestSeatReservation}
              isBasketEditable={props.isBasketEditable}
            />
          );
        case VEHICLE_TYPES.PLANE:
          return (
            <TariffPlaneFareTerms fareTerms={props.fareTerms} profileLocale={props.profileLocale} />
          );
        default:
          return null;
      }
    }
    return null;
  };

  const getTariffConditionsMarkup = () => {
    if (!isNil(props.fareTerms.conditions)) {
      return (
        <div className="lp-tariff-fare-terms-wrapper">
          <button
            className="lp-tariff-fare-terms-link"
            onClick={() => setShowTariffConditions(true)}>
            {t('tariffFareTerms.button.showTariffConditions')}
          </button>
          <Modal
            isOpen={showTariffConditions}
            onClose={() => setShowTariffConditions(false)}
            title={t('tariffFareTerms.modal.tariffConditionsTitle')}>
            <div>
              <h4>{props.fareName}</h4>
              <p>{props.fareTerms.conditions}</p>
            </div>
          </Modal>
        </div>
      );
    }
  };

  return (
    <div className="lp-tariff-fare-terms">
      {getFareTermsByVehicle()}
      <CityTicketTerms 
        tariffFareTerms={props.fareTerms}/>
      {getTariffConditionsMarkup()}
    </div>
  );
};

export default TariffFareTerms;
