import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { head, map, nth, find, isNil } from '@src/shared/src/util/general';
import { getSupplierNameByCode } from '@src/shared/src/util/app';
import { getOperatorImageDetailURL } from '@toolkit/util/app';
// Constants
// Actions
// Models
import { TransportSegmentModel } from '@src/shared/src/models';
// Interfaces
// Components
// Styles
import '../styles/TransportSegmentTicketImages.scss';

type Props = {
  transportSegments:TransportSegmentModel[];
  isChangeable:boolean;
};

const TransportSegmentTicketImages: React.FC<Props> = (props) => {
  // Take the supplier from the first transport segment
  const firstTS = head(props.transportSegments) as TransportSegmentModel;
  const supplierVehicle = head(firstTS.vehicles);
  const supplierName = head(firstTS.suppliers);

  // In case of changeable leg only show the supplier name
  if (props.isChangeable) {
    const tsWithSupplierName = find((ts) => !isNil(head(ts.suppliers)))(props.transportSegments);
    if (!isNil(tsWithSupplierName)) {
      return (
        <strong>{head(tsWithSupplierName.suppliers)}</strong>
      );
    }
    return null;
  }

  return (
        <div className="tcp-transport-segment-ticket-images">
          <div>
            {getSupplierNameByCode(supplierName)}
            {
              map((ts:TransportSegmentModel) => {
                const operatorsArr = head(ts.operators).split('|');
                const operatorName = nth(1, operatorsArr) ? nth(1, operatorsArr) : nth(0, operatorsArr);

                // Only show operator logo if it's different than the supplier
                return operatorName === supplierName ?
                  null
                  :
                  (
                    <div className="tcp-transport-segment-ticket-images-item" key={ts.id}>
                      {t('trip.ticket.images.label.operatedby')}: {getSupplierNameByCode(operatorName)}
                      {/* <img
                        alt={operatorName}
                        src={getOperatorImageDetailURL(operatorName, supplierVehicle)}
                        className="tcp-trip-ticket-images"/> */}
                    </div>
                  );
              }, props.transportSegments)
            }
          </div>
          <img
            alt={supplierName}
            src={getOperatorImageDetailURL(supplierName, supplierVehicle)}
            className="tcp-transport-segment-ticket-images-main"/>
        </div>
  );
};

export default TransportSegmentTicketImages;
