import { JsonObject, JsonProperty } from 'json2typescript';
import CoordinatesModel from './CoordinatesModel';

@JsonObject('SearchNodeModel')
export default class SearchNodeModel {
  @JsonProperty('id', String, true)
  public id:string = '';
  @JsonProperty('name', String)
  public name:string = '';
  @JsonProperty('reference', String)
  public reference:string = '';
  @JsonProperty('types', [String])
  public types:string[] = [];

  public coordinates: CoordinatesModel;
}
