import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
// Interfaces
// Components
// Styles

type Props = {
  onShowDownloadICal:() => void;
};

const CheckoutHeaderConfirm:React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <button className="button button-light" onClick={props.onShowDownloadICal}>
        <i className="icon-add_calendar"/>
        {t('CheckoutHeader.button.addToCalendar')}
      </button>
      <button
        className="text-right"
        onClick={
          () => window.location.replace(window.location.origin + '/admin/#/admin/user/user_booking')
        }>
        <span className="is-underlined is-bold">
          {t('CheckoutHeader.button.seeAllBookings')}
        </span>
      </button>
    </React.Fragment>
  );
};

export default CheckoutHeaderConfirm;