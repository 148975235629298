import { isAfter } from 'date-fns';
// Utils
import { path, filter, partial } from '../util/general';
import { getTripFirstTS } from '../util/trips';
// Constants
// Actions
// Models
import { TripModel } from '../models';
// Interfaces
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';

export type TripInvalidInboundsFilterConstraint = {
  current:Date;
};

export default class TripInvalidInboundsFilter implements IFilter<TripModel, TripInvalidInboundsFilterConstraint> {
  private constructor() {}

  private static instance:TripInvalidInboundsFilter;

  static getInstance = () => {
    if (!TripInvalidInboundsFilter.instance) {
      TripInvalidInboundsFilter.instance = new TripInvalidInboundsFilter();
    }
    return TripInvalidInboundsFilter.instance;
  }

  public id:string = 'TripInvalidInboundsFilter';

  public getConstraints = (
    opts:ConstraintsOpts<TripModel, TripInvalidInboundsFilterConstraint>
  ):TripInvalidInboundsFilterConstraint => {
    return {
      current: opts.constraint.current,
    };
  }

  public process = (constraint:TripInvalidInboundsFilterConstraint, trips:TripModel[]) =>
    filter(partial(this.match, [constraint]), trips)

  private match = (constraint:TripInvalidInboundsFilterConstraint, trip:TripModel) => {
    if (constraint.current) {
      const tripDepAt = path(['depAt'], getTripFirstTS(trip.legs));
      return isAfter(tripDepAt, constraint.current);
    }
    return true;
  }
}
