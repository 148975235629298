import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { times } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
// Constants
import { ENVIRONMENT } from '@src/shared/src/const/app';
// Actions
// Models
import { UserModel } from '@src/shared/src/models';
// Interfaces
import { IRootState, ConnectedRedux } from '@src/store';
// Components

// Styles
import '../styles/HotelTitle.scss';

type Props = ConnectedRedux<IRootState> & AppContextProp & {
  comfortClass:number;
  name:string;
  negotiatedRates:boolean;
  address:string;
  distance:string;
  env:ENVIRONMENT;
  profile:UserModel;
};

class HotelTitle extends React.PureComponent<Props> {

  render() {
    const { comfortClass, name, negotiatedRates, distance, address } = this.props;
    const hotelStars = times((idx) =>
      <span key={`star-${idx}`} className="icon-star" />, (comfortClass === -1 ? 0 : comfortClass));

    return (
      <div className="tcp-hotel-title">
        {!this.props.appContext.isMediaBPNotMobile &&
          <div>
            {negotiatedRates &&
              <div className="tcp-hotel-title-negotiated-rates">{t('hotel.tooltip.negotiatedRates')}</div>
            }
            <div className="tcp-hotel-title-stars">{hotelStars}</div>
          </div>
        }
        <div className="tcp-hotel-title-wrapper">
          <h2 className="tcp-hotel-title-name">{name} </h2>
        </div>
        {this.props.appContext.isMediaBPNotMobile &&
          <div className="tcp-hotel-title-stars">{hotelStars}</div>}
        <div className="tcp-hotel-title-location">
          <i className="icon-walk" />&nbsp;{distance}&nbsp;-&nbsp;
          <span className="tcp-hotel-title-location-address">
            {address}
          </span>
        </div>
      </div>
    );
  }
}
const mapState = (state:IRootState) => ({
  profile: state.adminUser.profile,
});
export default connect(mapState)(withAppContext(HotelTitle));
