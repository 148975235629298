import * as React from 'react';
import classNames from 'classnames';

// Components
// Styles
import '../styles/LegWaypoint.scss';

interface IProps {
  icon: string;
  vertical?: boolean;
  isChangeable?:boolean;
}

const LegWaypoint: React.FC<IProps> = (props) => {
    const mainClass = classNames('tcp-leg-waypoint', {
      'tcp-leg-waypoint-vertical': props.vertical,
      'tcp-leg-waypoint-vertical-item-public': props.isChangeable
    });
    const iconClass = classNames('tcp-leg-waypoint-icon', {
      'tcp-leg-waypoint-vertical-item-public-icon': props.isChangeable
    });
    return (
      <div className={mainClass}>
        <span className={iconClass}>
          <i className={props.icon} />
        </span>
      </div>
    );
};

export default LegWaypoint;