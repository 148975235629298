import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('HotelFeatureModel')
export class HotelFeatureModel {
  @JsonProperty('category', String) public category: string = undefined;
  @JsonProperty('de_name', String) public deName: string = undefined;
  @JsonProperty('en_name', String) public enName: string = undefined;
  @JsonProperty('fr_name', String) public frName: string = undefined;
  @JsonProperty('es_name', String) public esName: string = undefined;
}
