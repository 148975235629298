import { map, filter, partial, pipe, pluck, uniq, flatten, all, includes } from '../util/general';
import { HotelModel } from '../models';
import { IHotelFilter, CurrentFilterConstraint } from '../interfaces';
import { SelectionModel, HotelFeatureModel } from '@src/models';

export class HotelFeatureFilter implements IHotelFilter<CurrentFilterConstraint<SelectionModel[]>> {
  private constructor() {}

  private static instance: HotelFeatureFilter;

  static getInstance = () => {
    if (!HotelFeatureFilter.instance) {
      HotelFeatureFilter.instance = new HotelFeatureFilter();
    }
    return HotelFeatureFilter.instance;
  };

  id: string = 'HotelFeatureFilter';

  getConstraints = (hotels: HotelModel[]) => {
    const selections = pipe(
      pluck('features'),
      flatten(),
      uniq(),
      map((feature: HotelFeatureModel) => ({ ...feature, isSelected: false })),
    )(hotels);

    return {
      current: selections as SelectionModel[],
    };
  };

  process = (
    constraint: CurrentFilterConstraint<SelectionModel[]>,
    hotels: HotelModel[],
  ): HotelModel[] => filter(partial(this.match, [constraint]), hotels);

  private match = (constraint: CurrentFilterConstraint<SelectionModel[]>, hotel: HotelModel) => {
    const selectedFilters = constraint.current.filter(selection => selection.isSelected);

    // If no filter is selected do not compare
    // TODO: remove de/en mapping when we have translation sorted out
    if (selectedFilters.length === 0) {
      return true;
    } else {
      const allFeaturesMatched = pipe(
        map((selection: SelectionModel) => ({
          category: selection.category,
          enName: selection.enName,
          deName: selection.deName,
          frName: selection.frName,
          esName: selection.esName,
        })),
        all(selection => includes(selection, hotel.features))
      )(selectedFilters);

      return allFeaturesMatched;
    }
  };
}
