import * as React from 'react';
import './styles/ErrorMessageTooltip.scss';

type Props = {
  displayMessage:boolean;
  errorMessage:string;
}

const ErrorMessageTooltip:React.FC<Props> = (props:Props) => {
  if (props.displayMessage) {
    return (
      <div className="tcp-error-tooltip">
        {props.errorMessage}
      </div>
    );
  }
  return null;
}

export default ErrorMessageTooltip;
