// Utils
// Constants
// Actions
// Models
// Interfaces
import { RootState } from '../interfaces';
// Components
// Styles

export const tripDepTimeFilter = (state:RootState) => state.filters.tripDepTimeFilter;

export const tripArrTimeFilter = (state:RootState) => state.filters.tripArrTimeFilter;