import { createAction, createAsyncAction } from 'typesafe-actions';

// Utils
// Constants
// Actions
// Models
import { UserModel, UserPreferenceModel } from '../models';
// Interfaces
import { ActionCallback } from '../interfaces';
// Components
// Styles

export const clearAdminUser = createAction('adminUser/CLEAR_ADMIN_USER');

export const setUserId = createAction('adminUser/SET_USER_ID', (resolve) => (userId:number) => resolve(userId));

export const fetchUserPreference = createAction('adminUser/FETCH_USER_PREFERENCE');

export const setUserPreference = createAction(
  'adminUser/SET_USER_PREFERENCE',
  (resolve) => (userPreference:UserPreferenceModel) => resolve(userPreference)
);

export const setUserProfile = createAction(
  'adminUser/SET_USER_PROFILE',
  (resolve) => (user:UserModel) => resolve(user)
);

export const setUserAuthToken = createAction(
  'adminUser/SET_USER_AUTH_TOKEN',
  (resolve) => (authToken:string) => resolve(authToken)
);

export const zendeskWidgetLoaded = createAction(
  'appSettings/ZENDESK_WIDGET_LOADED',
  (resolve) => (zendeskToken: string, userLocale: string) => resolve({ zendeskToken, userLocale }),
);

// Async actions

export const verifyUserAsync = createAsyncAction(
  'adminUser/VERIFY_USER_REQUEST',
  'adminUser/VERIFY_USER_SUCCESS',
  'adminUser/VERIFY_USER_FAILURE'
)<ActionCallback, void, void>();
