import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { tripSelectors } from '@src/shared/src/selectors';
import { tripActions } from '@src/services';
import { selectors } from '@src/shared/src';
// Constants
import { DIRECTION, ENVIRONMENT } from '@src/shared/src/const/app';
// Actions
import { setExpanded } from '@src/shared/src/actions/tripsActions';
// Models
import { TripModel } from '@src/shared/src/models';
// Interfaces
import { IRootState } from '@src/store';
// Components
import Trip from './Trip';
import { ScrollList } from '@toolkit/ui';
// Styles
import '../styles/TripList.scss';

type Props = {
  direction: DIRECTION;
  trips: TripModel[];
  isRT: boolean;
  isTripsLoading: boolean;
};

const TripsList: React.FC<Props> = ({ direction, trips, isTripsLoading, isRT }) => {
  const dispatch = useDispatch();
  const searchDate: Date = useSelector((state: IRootState) =>
    direction === DIRECTION.OUTWARD
      ? selectors.search.searchDepAt(state.search)
      : selectors.search.searchArrAt(state.search),
  );
  const nonFilterTripsLen: number = useSelector((state: IRootState) =>
    direction === DIRECTION.OUTWARD
      ? tripSelectors.outwardTripsCount(state.trips)
      : tripSelectors.inboundTripsCount(state.trips),
  );
  const isSelectingTrip: boolean = useSelector((state: IRootState) => state.ui.isSelectingTrip);

  const onCloseDetails = (tripId: number) => dispatch(setExpanded(tripId, false, direction));

  return (
    <div className="list list-outbound" key={`triplist-${nonFilterTripsLen}`}>
      <ScrollList
        items={trips}
        render={(trip) => (
          <Trip
            trip={trip}
            direction={direction}
            env={ENVIRONMENT.SEARCH}
            searchDate={searchDate}
            onFetchDetail={(tripId) => tripActions.fetchTripDetails(tripId, direction)}
            onCloseDetail={onCloseDetails}
            isSelectingTrip={isSelectingTrip}
            onSelectTrip={tripActions.selectTrip}
            isRoundtrip={isRT}
            detailsOpened={trip.expanded}
            isLoading={isTripsLoading}
          />
        )}
      />
    </div>
  );
};
export default TripsList;
