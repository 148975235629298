import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './converters';
import BookingHotelFareModel from './BookingHotelFareModel';
import BookingTransportFareModel from './BookingTransportFareModel';
import BookingRentalFareModel from './BookingRentalFareModel';
import PolicyViolationModel from './PolicyViolationModel';
import AggregatorModel from './AggregatorModel';

@JsonObject('BookingItemModel')
export default class BookingItemModel {
  @JsonProperty('booked_at', DateConverter) public bookedAt: Date = undefined;
  @JsonProperty('booking_id', Number) public bookingId: number = undefined;
  @JsonProperty('cancelled_at', DateConverter, true) public cancelledAt: Date = undefined;
  @JsonProperty('created_at', DateConverter) public createdAt: Date = undefined;
  @JsonProperty('currency', String, true) public currency: string = undefined;
  @JsonProperty('fare_id', Number) public fareId: number = undefined;
  @JsonProperty('fare_ids', [Number]) public fareIds: number[] = [];
  @JsonProperty('fare_type', String) public fareType: string = undefined;
  @JsonProperty('id', Number) public id: number = -1;
  @JsonProperty('last_error_status', String) public lastErrorStatus: string = '';
  @JsonProperty('price', Number, true) public price: number = undefined;
  @JsonProperty('refundable_until', DateConverter, true) public refundableUntil: Date = undefined;
  @JsonProperty('remote_status', String, true) public remoteStatus: string = undefined;
  @JsonProperty('retracted_at', DateConverter, true) public retractedAt: Date = undefined;
  @JsonProperty('seat_reservation', String, true) public seatReservation: string = undefined;
  @JsonProperty('status', String) public status: string = undefined;
  @JsonProperty('total_price', Number, true) public totalPrice: number = undefined;
  @JsonProperty('updated_at', DateConverter) public updatedAt: Date = undefined;
  @JsonProperty('hotel_fares', [BookingHotelFareModel], true)
  public hotelFares: BookingHotelFareModel[] = [];
  @JsonProperty('transport_fares', [BookingTransportFareModel], true)
  public transportFares: BookingTransportFareModel[] = [];
  @JsonProperty('rental_fares', [BookingRentalFareModel], true)
  public rentalFares: BookingRentalFareModel[] = [];
  @JsonProperty('policy_violations', [PolicyViolationModel], true)
  public policyViolations: PolicyViolationModel[] = undefined;
  @JsonProperty('aggregator', AggregatorModel) aggregator: AggregatorModel = undefined;
  @JsonProperty('hard_fail', Boolean, true) hardFail: boolean = undefined;
}
