import * as React from 'react';
import { WrappedFieldProps } from 'redux-form/es/Form';
import { OptionModel } from '@src/shared/src/models';

type Props = WrappedFieldProps & {
	options:OptionModel[];
};

const RadioGroup:React.FC<Props> = (props) => (
	<div>
		{props.options.map((opt:OptionModel) =>
			<label key={opt.value} className="form-radio">
				<input
					type="radio"
					className="form-radio"
					{...props.input}
					value={opt.value}
					checked={opt.value === props.input.value} />
				{opt.label}
			</label>
		)}
		{props.meta.touched && props.meta.error &&
			<div className="form-error-message">{props.meta.error}</div>
		}
	</div>
);

export default RadioGroup;
