import * as React from 'react';
import { connect } from 'react-redux';
import { isAfter, isBefore, isSameDay, format } from 'date-fns';

// Utils
import { notify } from '@toolkit/util/app';
import { isNil, times, flatten } from '@src/shared/src/util/general';
import { getTimeWindow } from '@src/shared/src/util/filters';
import dayPickerLocale from '@src/toolkit/util/dayPickerLocale';
import { t } from '@toolkit/util/i18n';
// Constants
import {
  TIME_WINDOW_TYPE,
  DIRECTION,
  TRIP_DIRECTION,
  SEARCH_TYPE,
} from '@src/shared/src/const/app';
import { NOTIF_TYPE } from '@src/shared/src/const/app';
// Actions
import { filterActions, searchActions } from '@src/shared/src/actions';
// Models
import { OptionModel } from '@src/shared/src/models';
// Interfaces
import { ConnectedRedux, IRootState } from '@src/store';
// Components
import Select from 'react-select';
import { MenuListDevider, Radio } from '@toolkit/ui';
import DayPicker from 'react-day-picker';
// Styles
import '../styles/SearchBarDateTimeSelector.scss';
// Selectors
import { search as searchSelectors } from '@src/shared/src/selectors';

type Props = ConnectedRedux<IRootState> & {
  show: boolean;
  value: Date;
  minDate: Date;
  maxDate: Date;
  direction: DIRECTION;
  selectedDepTimeWindow: string;
  selectedArrTimeWindow: string;
  isSearchingForOnlyHotel: boolean;
  searchDepAt: Date;
  searchArrAt: Date;
  onDateChange?: (nDate: Date) => void;
  onConfirm: () => void;
  onMinDateNullErrorMsg?: string;
  searchType: SEARCH_TYPE;
};
type State = {
  selectedTime: TRIP_DIRECTION;
  rentalTime: string;
};
class SearchBarDateTimeSelector extends React.PureComponent<Props, State> {
  readonly state: State = {
    selectedTime: TRIP_DIRECTION.DEP,
    rentalTime: '',
  };

  componentDidMount() {
    const { direction, searchDepAt, searchArrAt } = this.props;
    if (direction === DIRECTION.OUTWARD) {
      const searchDepTime = searchDepAt ? format(searchDepAt, 'HH:mm') : '09:00';
      this.setState({ rentalTime: searchDepTime });
      this.props.dispatch(searchActions.setSearchDepRentalTime(searchDepTime));
    } else {
      const searchArrTime = searchArrAt ? format(searchArrAt, 'HH:mm') : '09:00';
      this.setState({ rentalTime: searchArrTime });
      this.props.dispatch(searchActions.setSearchArrRentalTime(searchArrTime));
    }
  }

  private windowOpts = (): OptionModel[] => [
    { value: TIME_WINDOW_TYPE.ANYTIME, label: t('SearchBarDateTimeSelector.option.anytime') },
    { value: TIME_WINDOW_TYPE.EARLY, label: t('SearchBarDateTimeSelector.option.early') },
    { value: TIME_WINDOW_TYPE.MORNING, label: t('SearchBarDateTimeSelector.option.morning') },
    { value: TIME_WINDOW_TYPE.AFTERNOON, label: t('SearchBarDateTimeSelector.option.afternoon') },
    { value: TIME_WINDOW_TYPE.EVENING, label: t('SearchBarDateTimeSelector.option.evening') },
    { value: TIME_WINDOW_TYPE.NIGHT, label: t('SearchBarDateTimeSelector.option.night') },
  ];

  private timeOpts = () =>
    flatten(
      times((curr: number) => {
        const currPaded = String(curr).padStart(2, '0');
        return [
          { value: `${currPaded}:00`, label: `${currPaded}:00` },
          { value: `${currPaded}:30`, label: `${currPaded}:30` },
        ];
      }, 24),
    );

  private onDateChange = (date: Date) => {
    if (isNil(this.props.minDate) && !isNil(this.props.onMinDateNullErrorMsg)) {
      notify(this.props.onMinDateNullErrorMsg, NOTIF_TYPE.ERROR);
    } else if (
      isSameDay(date, this.props.minDate) ||
      isSameDay(date, this.props.maxDate) ||
      (isAfter(date, this.props.minDate) && isBefore(date, this.props.maxDate))
    ) {
      this.props.onDateChange(date);
    }
  };

  onRentalTimeChange = (timeStr: string) => {
    this.setState({ rentalTime: timeStr });
    this.props.dispatch(
      this.props.direction === DIRECTION.OUTWARD
        ? searchActions.setSearchDepRentalTime(timeStr)
        : searchActions.setSearchArrRentalTime(timeStr),
    );
  };

  private getOptionMarkup = (opt: OptionModel) => (
    <div>
      <i className={`icon-watch_later`} /> {opt.label}
    </div>
  );

  private getCalendarMarkup = () => {
    const { maxDate, minDate, show, value } = this.props;
    const modifiers = {
      active: (day: Date) => isAfter(day, minDate) && isBefore(day, value),
      startDate: (day: Date) => isSameDay(day, minDate),
    };

    if (show) {
      return (
        <DayPicker
          localeUtils={dayPickerLocale}
          onDayClick={this.onDateChange}
          fromMonth={minDate}
          toMonth={maxDate}
          modifiers={modifiers}
          initialMonth={value ? value : minDate}
          selectedDays={[value]}
          disabledDays={{ before: minDate, after: maxDate }}
        />
      );
    }
  };

  private onChangeTimeWindow = (val: TIME_WINDOW_TYPE, direction: DIRECTION) => {
    const { selectedTime } = this.state;
    const refDate: Date =
      direction === DIRECTION.OUTWARD ? this.props.searchDepAt : this.props.searchArrAt;

    if (!isNil(refDate)) {
      this.props.dispatch(
        filterActions.setTimeWindow(getTimeWindow(refDate, val), selectedTime, direction),
      );
      // If the user changes the dep/arr time window the arr/dep should be resetted to ANYTIME
      this.props.dispatch(
        filterActions.setTimeWindow(
          {
            currentMax: -1,
            currentMin: -1,
            timeWindow: TIME_WINDOW_TYPE.ANYTIME,
          },
          selectedTime === TRIP_DIRECTION.DEP ? TRIP_DIRECTION.ARR : TRIP_DIRECTION.DEP,
          direction,
        ),
      );
    } else {
      notify('Please select a date first', NOTIF_TYPE.ERROR);
    }
  };

  private getTimeWindowMarkup = (direction: DIRECTION) => {
    return (
      <div className="tcp-search-bar-date-time-selector-window-time">
        <div className="tcp-search-bar-date-time-selector-window-time-direction">
          <div className="tcp-search-bar-date-time-selector-window-time-label">
            {t('searchBarDateTimeSelector.label.timeOf')}
          </div>
          <Radio
            id={`${direction}-dep`}
            value="dep"
            name={direction}
            label={t('searchBarDateTimeSelector.radio.label.departure')}
            checked={this.state.selectedTime === TRIP_DIRECTION.DEP}
            onChange={(value) => value && this.setState({ selectedTime: TRIP_DIRECTION.DEP })}
          />
          <Radio
            id={`${direction}-arr`}
            value="arr"
            name={direction}
            label={t('searchBarDateTimeSelector.radio.label.arrival')}
            checked={this.state.selectedTime === TRIP_DIRECTION.ARR}
            onChange={(value) => value && this.setState({ selectedTime: TRIP_DIRECTION.ARR })}
          />
        </div>
        <Select
          clearable={false}
          backspaceRemoves={false}
          deleteRemoves={false}
          searchable={false}
          className="Select--window-time"
          options={this.windowOpts()}
          valueRenderer={this.getOptionMarkup}
          value={
            this.state.selectedTime === TRIP_DIRECTION.DEP
              ? this.props.selectedDepTimeWindow
              : this.props.selectedArrTimeWindow
          }
          simpleValue={true}
          onChange={(val) => this.onChangeTimeWindow(val, direction)}
        />
      </div>
    );
  };

  renderTimeSelector = () => {
    return (
      <div className="tcp-search-bar-date-time-selector-rental-time">
        <Select
          clearable={false}
          backspaceRemoves={false}
          deleteRemoves={false}
          searchable={false}
          className="Select--window-time"
          options={this.timeOpts()}
          value={this.state.rentalTime}
          valueRenderer={this.getOptionMarkup}
          simpleValue={true}
          placeholder={
            this.props.direction === DIRECTION.OUTWARD
              ? t('searchBarDateTimeSelector.rental.time.pickup.placeholder')
              : t('searchBarDateTimeSelector.rental.time.return.placeholder')
          }
          onChange={(val) => this.onRentalTimeChange(val)}
        />
      </div>
    );
  };

  public render() {
    const { direction, isSearchingForOnlyHotel, searchType } = this.props;
    return (
      <div className="tcp-search-bar-date-time-selector">
        {this.getCalendarMarkup()}

        {!isSearchingForOnlyHotel && searchType !== SEARCH_TYPE.RENTAL && (
          <>
            <MenuListDevider />
            {this.getTimeWindowMarkup(direction)}
          </>
        )}
        {searchType === SEARCH_TYPE.RENTAL ? this.renderTimeSelector() : null}
      </div>
    );
  }
}
const mapState = (state: IRootState) => ({
  isSearchingForOnlyHotel: state.settings.isSearchingForOnlyHotel,
  searchDepAt: searchSelectors.searchDepAt(state.search),
  searchArrAt: searchSelectors.searchArrAt(state.search),
});
export default connect(mapState)(SearchBarDateTimeSelector);
