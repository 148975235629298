import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNil } from '@src/shared/src/util/general';
// Constants
// Actions
// Models
// Interfaces
// Components
import CheckoutPrice from './CheckoutPrice';

// Styles

type Props = React.PropsWithChildren & {
  totalPrice: number;
};

const CheckoutFooter: React.FC<Props> = ({ totalPrice, children }) => {
  return (
    <div className="checkout-footer">
      {!isNil(totalPrice) && (
        <CheckoutPrice label={t('CheckoutFooter.label.totalBookable')} price={totalPrice} />
      )}
      {children}
    </div>
  );
};

export default CheckoutFooter;
