import { verifyProperty } from '../util/general';

export default class CoordinatesModel {
  public lat: number;
  public lng: number;

  constructor(lat: number, lng: number) {
    this.lat = verifyProperty(-1, lat);
    this.lng = verifyProperty(-1, lng);
  }
}