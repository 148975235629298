/**
 *    _____                           _         _   _ _ _ _                          _   _               _
 *   / ____|                         | |       | | (_) (_) |                        | | | |             | |
 *  | |  __  ___ _ __   ___ _ __ __ _| |  _   _| |_ _| |_| |_ _   _   _ __ ___   ___| |_| |__   ___   __| |___
 *  | | |_ |/ _ \ '_ \ / _ \ '__/ _` | | | | | | __| | | | __| | | | | '_ ` _ \ / _ \ __| '_ \ / _ \ / _` / __|
 *  | |__| |  __/ | | |  __/ | | (_| | | | |_| | |_| | | | |_| |_| | | | | | | |  __/ |_| | | | (_) | (_| \__ \
 *   \_____|\___|_| |_|\___|_|  \__,_|_|  \__,_|\__|_|_|_|\__|\__, | |_| |_| |_|\___|\__|_| |_|\___/ \__,_|___/
 *                                                             __/ |
 *                                                            |___/
 */

export const saveLS = (key:string, value:string) => localStorage.setItem(key, JSON.stringify(value));
export const getLS = (key:string) => JSON.parse(localStorage.getItem(key));
export const clearLS = () => localStorage.clear();

/**
 *   _____        _           _________ _
 *  |  __ \      | |         / /__   __(_)
 *  | |  | | __ _| |_ ___   / /   | |   _ _ __ ___   ___
 *  | |  | |/ _` | __/ _ \ / /    | |  | | '_ ` _ \ / _ \
 *  | |__| | (_| | ||  __// /     | |  | | | | | | |  __/
 *  |_____/ \__,_|\__\___/_/      |_|  |_|_| |_| |_|\___|
 *
 *
 */


/**
 *    _____
 *   / ____|
 *  | |    _   _ _ __ _ __ ___ _ __   ___ _   _
 *  | |   | | | | '__| '__/ _ \ '_ \ / __| | | |
 *  | |___| |_| | |  | | |  __/ | | | (__| |_| |
 *   \_____\__,_|_|  |_|  \___|_| |_|\___|\__, |
 *                                         __/ |
 *                                        |___/
 */

 export const titleize = (value: string) =>
  value ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase() : '';