import * as React from 'react';
import Field from 'redux-form/es/Field';

// Utils
import { settingsToSelectOptions } from '@src/shared/src/util/settings';
import { find, partial, pathOr, pipe, startsWith } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import { maxLen, minLen, required, shouldStartsWith } from '@toolkit/util/formsValidation';
// Constants
// Actions, Models & Interfaces
import { SettingsModel } from '@src/shared/src/models';
import { getMilesAndMoreStatusByCardIdent } from '@src/shared/src/util/users';
// Components
import { FormInput, SelectInput } from '@toolkit/ui';
// Styles

type Props = {
  appSettingsFlightLoyaltyCards: SettingsModel[];
  appSettingsTrainLoyaltyCards: SettingsModel[];
  resetForm: () => void;
};

type State = {
  currentCardType: string;
  currentCardIdent: string;
  milesAndMoresCardStatus: string;
};

class AddCardForm extends React.Component<Props, State> {
  readonly state: State = {
    currentCardType: '',
    currentCardIdent: '',
    milesAndMoresCardStatus: '',
  };

  private appliesToOptions = [
    { label: t('addCardForm.form.options.train'), value: 'train' },
    { label: t('addCardForm.form.options.plane'), value: 'plane' },
  ];

  private getCardOptions = (cardType: string) => {
    if (cardType === 'train') {
      return settingsToSelectOptions(this.props.appSettingsTrainLoyaltyCards);
    }
    if (cardType === 'plane') {
      return settingsToSelectOptions(this.props.appSettingsFlightLoyaltyCards);
    }
  };

  private onAppliesToChanged = (event: any, newValue: any, previousValue: any) => {
    this.setState({
      currentCardType: newValue,
      currentCardIdent: '',
      milesAndMoresCardStatus: '',
    });
    this.props.resetForm();
  };

  private onCardNameChanged = (event: any, newValue: any, previousValue: any) => {
    this.setState({ currentCardIdent: newValue });
  };

  private onCardNumberChanged = (e: any) => {
    if (this.state.currentCardType === 'plane') {
      if (this.state.currentCardIdent === 'LH') {
        this.setState({
          milesAndMoresCardStatus: getMilesAndMoreStatusByCardIdent(e.target.value),
        });
      }
    }
  };

  private getPlaneStatusMarkup = () => {
    if (this.state.currentCardType === 'plane') {
      // TODO: Should we check the label Miles & More?
      if (this.state.currentCardIdent === 'LH') {
        return (
          <div className="form-field">
            <label className="form-label">{t('addCardForm.label.status')}:</label>
            <h4>{t(this.state.milesAndMoresCardStatus)}</h4>
          </div>
        );
      }
    }
  };

  shouldStartWith7081 = partial(shouldStartsWith, ['7081']);
  minLen16 = partial(minLen, [16]);
  maxLen16 = partial(maxLen, [16]);
  minLen6 = partial(minLen, [6]);
  maxLen20 = partial(maxLen, [20]);

  private getValidationByVehicle = () => {
    if (this.state.currentCardType === 'train') {
      const isCurrentCardDBCard = pipe(
        find((card: SettingsModel) => card.ident === this.state.currentCardIdent),
        pathOr('', ['name']),
        startsWith('DB BahnCard'),
      )(this.props.appSettingsTrainLoyaltyCards);

      if (isCurrentCardDBCard) {
        return [this.shouldStartWith7081, this.minLen16, this.maxLen16];
      }
    }

    if (this.state.currentCardType === 'plane') {
      return [this.minLen6, this.maxLen20, required];
    }
  };

  public render() {
    return (
      <React.Fragment>
        <div className="form-row">
          <div className="form-field">
            <Field
              name="appliesTo"
              simpleValue={true}
              onChange={this.onAppliesToChanged}
              options={this.appliesToOptions}
              validate={required}
              component={SelectInput}
              placeholder={t('addCardForm.label.appliesTo')}
            />
          </div>
          <div className="form-field">
            <Field
              name="cardIdent"
              simpleValue={true}
              onChange={this.onCardNameChanged}
              validate={required}
              options={this.getCardOptions(this.state.currentCardType)}
              component={SelectInput}
              placeholder={t('addCardForm.label.card')}
            />
          </div>
        </div>
        <div className="form-field">
          <Field
            name="cardNumber"
            type="text"
            onChange={this.onCardNumberChanged}
            className="form-textfield"
            validate={this.getValidationByVehicle()}
            component={FormInput}
            placeholder={t('addCardForm.label.number')}
          />
        </div>
        {this.getPlaneStatusMarkup()}
      </React.Fragment>
    );
  }
}

export default AddCardForm;
