// Utils
import {
  find,
  findBySelected,
  head,
  isNilOrEmpty,
  map,
  pathOr,
  propEq,
} from './general';
// Constants
// Actions, Models & Interfaces
import {
  BasketItemModel,
  HotelModel,
  HotelFareModel,
} from '../models';

export const getPrimaryPictureURL = (primaryPicutreURL:string, pictureURLs:string[]) =>
  isNilOrEmpty(primaryPicutreURL) ? (isNilOrEmpty(pictureURLs) ? '' : head(pictureURLs)) : primaryPicutreURL;

export const getSelectedFares:(hotels:HotelModel[]) => HotelFareModel[] =
  map((hotel:HotelModel) => findBySelected(hotel.fares));

const findHotelBaksetItem = (tariffId:number) => find(propEq('hotelFareId', tariffId));

export const getHotelTariffDocuments = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['travelBookingItem', 'visibleDocuments'], findHotelBaksetItem(tariffId)(basketItems));

export const getHotelTariffOrderNumber = (basketItems:BasketItemModel[], tariffId:number) =>
  pathOr(null, ['travelBookingItem', 'orderNumber'], findHotelBaksetItem(tariffId)(basketItems));
