import * as React from 'react';
import { useContext } from 'react';

// Utils
import { searchHooks } from '@src/services';
import { join, map, pipe } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import { AppContext } from '@toolkit/util/AppContext';
import { getUserFullname } from '@src/shared/src/util/users';
// Constants
import { SEARCH_TYPE } from '@src/shared/src/const/app';
import { API_URL } from '@src/shared/src/const/api';
// Actions
// Models
import { PassengerModel } from '@src/shared/src/models';
// Interfaces
// Components
import { Link, TextField } from '@toolkit/ui';
import SearchBarSlideOutNavbar from '@src/pod/search/components/SearchBarSlideOutNavbar';
import {
  SearchBarGuestCreator,
  SearchBarPassengersSelector,
  SearchBarSlideOut,
} from '@pod/search/components';
import { AccountCirlceIcon } from '@toolkit/ui/icons';
// Styles
import '../styles/SearchBarPassengers.scss';

type Props = {
  uiSearchPassengersOverlay: boolean;
  onSetUiSearchPassengersOverlay: (value: string, type?: boolean) => void;
  isCurrentUserAdmin: boolean;
  isCurrentUserTravelAssistant: boolean;
  isCurrentUserManager: boolean;
  searchPassengers: PassengerModel[];
  organizationId: number;
  disabled?: boolean;
};

const SearchBarPassengers: React.FC<Props> = (props) => {
  const appContext = useContext(AppContext);
  const [searchType] = searchHooks.useSearchState<SEARCH_TYPE>(['currentSearch', 'searchType']);
  const isPassengersMissing = searchHooks.useIsPassengersMissing();
  const isPassengersSelectionWrong = searchHooks.useIsPassengersSelectionWrong();
  const isRentalDriverCountWrong = searchHooks.useIsRentalDriverCountWrong();
  const isPassengersMoreThanAllowed = searchHooks.useIsPassengersMoreThanAllowed();

  const getErrorMsg = () => {
    if (isPassengersMissing) return t('searchBarConn.notify.text.removeFirstPassenger');
    if (isPassengersSelectionWrong) return t('searchBarConn.notify.text.removeYourself');
    if (isRentalDriverCountWrong) return t('searchBarConn.notify.text.rentalDriverCount');
    if (isPassengersMoreThanAllowed) return t('search.bar.passengers.maxPassenger.error');
  };

  return (
    <div className="tcp-search-bar-passengers">
      <TextField
        name="passengers"
        label={
          searchType === SEARCH_TYPE.RENTAL
            ? t('searchBarPassengers.title.rental')
            : t('search.bar.passengers.title.travellers')
        }
        value={pipe(map(getUserFullname), join(' | '))(props.searchPassengers)}
        icon={<AccountCirlceIcon />}
        errorMsg={getErrorMsg()}
        onFocus={() => props.onSetUiSearchPassengersOverlay('passengersSelector')}
        forceFocus={props.uiSearchPassengersOverlay}
        disabled={props.disabled}
      />
      <SearchBarSlideOut isOpen={props.uiSearchPassengersOverlay} className="is--passenger">
        {!appContext.isMediaBPNotMobile ? (
          <SearchBarSlideOutNavbar
            onClose={() => props.onSetUiSearchPassengersOverlay('passengersSelector', false)}
          />
        ) : null}
        <SearchBarPassengersSelector searchType={searchType} passengers={props.searchPassengers} />
        {props.isCurrentUserAdmin && appContext.isMediaBPNotMobile && (
          <Link
            onClick={() => {
              window.location.assign(
                `${API_URL.DEFAULT}/org_admin/organizations/${props.organizationId}/users/new`,
              );
            }}
            small>
            {t('search.bar.passengers.link.addTravellers')}
          </Link>
        )}

        {(props.isCurrentUserAdmin ||
          props.isCurrentUserTravelAssistant ||
          props.isCurrentUserManager) && <SearchBarGuestCreator />}
      </SearchBarSlideOut>
    </div>
  );
};
export default SearchBarPassengers;
