import { filter, partial, pipe, pluck } from '../util/general';
import { HotelModel } from '../models';
import { CurrentFilterConstraint } from '../interfaces';
import { FilterOption } from '@src/models';
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';
import { ACCOMMODATION_TYPES } from '../const/app';

export class HotelAccommodationFilter
  implements
    IFilter<
      HotelModel,
      CurrentFilterConstraint<FilterOption[]>,
      CurrentFilterConstraint<FilterOption[]>
    > {
  private constructor() {}

  private static instance: HotelAccommodationFilter;

  static getInstance = () => {
    if (!HotelAccommodationFilter.instance) {
      HotelAccommodationFilter.instance = new HotelAccommodationFilter();
    }
    return HotelAccommodationFilter.instance;
  };

  id: string = 'HotelAccommodationFilter';

  getConstraints = (
    opts: ConstraintsOpts<
      HotelModel,
      CurrentFilterConstraint<FilterOption[]>,
      CurrentFilterConstraint<FilterOption[]>
    >,
  ) => {
    // taking all values because we always want to show all even if we get results in only one type https://github.com/TripClickPro/flow/issues/982
    const accommodationTypes: FilterOption[] = Object.values(ACCOMMODATION_TYPES).map(
      (accommodationType) => ({
        name: accommodationType,
        value: opts.userConstraint.current.find((opt) => opt.name === accommodationType).value,
        icon: accommodationType === ACCOMMODATION_TYPES.VACATION_RENTAL ? 'icon-airbnb' : 'icon-rooms',
        count: filter(
          (hotel: HotelModel) => hotel.accommodationType === accommodationType,
          opts.values,
        ).length,
      }),
    );

    return {
      current: accommodationTypes,
    };
  };

  process = (
    constraint: CurrentFilterConstraint<FilterOption[]>,
    values: HotelModel[],
  ): HotelModel[] => filter(partial(this.match, [constraint]), values);

  private match = (constraint: CurrentFilterConstraint<FilterOption[]>, hotel: HotelModel) => {
    const selectedAccommodationsLabel = pipe(
      filter((option: FilterOption) => option.value),
      pluck('name'),
    )(constraint.current);

    return selectedAccommodationsLabel.includes(hotel.accommodationType);
  };
}
