import * as React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
import { ENVIRONMENT } from '@src/shared/src/const/app';
// Actions
import * as selectors from '@src/shared/src/selectors';
// Models
// Interfaces
import { IRootState } from '@src/store';
// Components
import Hotel from './Hotel';
// Styles

export const BookedHotel: React.FC = () => {
  const bookedHotel = useSelector((state: IRootState) => selectors.checkout.hotel(state.checkout));

  if (bookedHotel) {
    return (
      <React.Fragment>
        <div className="content-header">
          <h2 className="content-title">{t('BookedHotel.title.bookedHotel')}</h2>
        </div>
        <Hotel
          hotel={bookedHotel}
          env={ENVIRONMENT.SEARCH}
          isSelectingHotel={false}
          detailsOpened={true}
          onCloseDetail={() => {}}
          onFetchDetail={() => {}}
          onSelectHotel={() => {}}
        />
      </React.Fragment>
    );
  }

  return null;
};
