import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('BENotificationMessageModel')
export default class BENotificationMessageModel {
  @JsonProperty('en_message', String, true) public enMessage:string = undefined;
  @JsonProperty('es_message', String, true) public esMessage:string = undefined;
  @JsonProperty('de_message', String, true) public deMessage:string = undefined;
  @JsonProperty('page_category_booking', Boolean, true) public pageCategoryBooking = false;
  @JsonProperty('page_category_hotel_search', Boolean, true) public pageCategoryHotelSearch = false;
  @JsonProperty('page_category_transport_search', Boolean, true) public pageCategoryTransportSearch = false;
  @JsonProperty('page_category_login', Boolean, true) public pageCategoryLogin = false;
  @JsonProperty('page_category_home', Boolean, true) public pageCategoryHome = false;
}
