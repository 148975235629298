import * as React from 'react';
import classNames from 'classnames';
import { isNil } from '@src/shared/src/util/general';

type Props = {
  title: string;
  isOpen?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  isHidden?: boolean;
  children: React.ReactNode;
}

export const Filter: React.FC<Props> = props => {
  if (props.isHidden) {
    return null;
  }

  const [isOpen, setIsOpen] = React.useState(isNil(props.isOpen) ? true : props.isOpen)

  const containerStyle = classNames('sidebar-section', {
    'is-open': isOpen,
  });
  const indicatorStyle = classNames('sidebar-section-indicator', {
    'sidebar-section-indicator-disabled': props.isDisabled,
  });

  return (
    <div className={containerStyle}>
      <button className="sidebar-section-title" onClick={(_) => props.isDisabled ? null : setIsOpen(!isOpen)}>
        <span>
          {props.title}
          &nbsp;{props.tooltip && <i data-rh={props.tooltip} className="icon-info_outline" />}
        </span>
        <i className={indicatorStyle} aria-hidden="true" />
      </button>
      <div className="sidebar-section-inner">
        {props.children}
      </div>
    </div>
  );
}
