import * as React from 'react';
import { getDaysInMonth } from 'date-fns';
import { WrappedFieldProps } from 'redux-form';

// Utils
import { t } from '@toolkit/util/i18n';
import { range } from '@src/shared/src/util/general';
import dayPickerLocale from '@toolkit/util/dayPickerLocale';
// Constants
// Actions, Models & Interfaces
import { OptionModel } from '@src/shared/src/models';
// Components
import Select from 'react-select';
// Styles
require ('./styles/SelectDatePicker.scss');

type Props = WrappedFieldProps & {
  onChange:(date:Date) => void;
};

type State = {
  selectedDay:number;
  selectedMonth:number;
  selectedYear:number;
};

export default class SelectDatePicker extends React.Component<Props, State> {
  public state:State = {
    selectedDay: -1,
    selectedMonth: -1,
    selectedYear: -1,
  };

  private getDaysOptions = () => {
    const { selectedYear, selectedMonth } = this.state;
    const selectedDate = new Date(selectedYear, selectedMonth);
    const days = range(1, getDaysInMonth(selectedDate) + 1);

    return days.map((opt) => {
      const dayOpt = new OptionModel();
      dayOpt.value = opt;
      dayOpt.label = opt;
      return dayOpt;
    });
  }

  private getMonthsOptions = () => {
    const months = dayPickerLocale.getMonths();

    return months.map((opt, index) => {
      const monthOpt = new OptionModel();
      monthOpt.value = String(index);
      monthOpt.label = opt;
      return monthOpt;
    });
  }

  private getYearsOptions = () => {
    const currentYear = new Date().getFullYear() - 17; // Only users older than 18YO can signup
    const years = range(currentYear - 77, currentYear); // Only users younger than 95 years can signup

    return years.map((opt) => {
      const yearOpt = new OptionModel();
      yearOpt.value = opt;
      yearOpt.label = opt;
      return yearOpt;
    });
  }

  private handleChange = (selectedYear:number, selectedMonth:number, selectedDay:number) => {
    this.setState({ selectedYear, selectedMonth, selectedDay });
    if (selectedYear !== -1 &&  selectedMonth !== -1 && selectedDay !== -1) {
      this.props.input.onChange(
        new Date(
          Date.UTC(selectedYear, selectedMonth, selectedDay)));
    } else {
      this.props.input.onChange('');
    }
  };

  public render() {
    const { selectedYear, selectedMonth, selectedDay } = this.state;

    return (
      <div>
        <div className="SelectDatePicker">
          <Select
            options={this.getDaysOptions()}
            clearable={false}
            backspaceRemoves={false}
            deleteRemoves={false}
            value={selectedDay}
            simpleValue={true}
            placeholder={t('global.select')}
            onChange={(value:number) => this.handleChange(selectedYear, selectedMonth, value)} />

          <Select
            options={this.getMonthsOptions()}
            clearable={false}
            backspaceRemoves={false}
            deleteRemoves={false}
            value={selectedMonth}
            simpleValue={true}
            placeholder={t('global.select')}
            onChange={(value:number) => this.handleChange(selectedYear, value, selectedDay)} />

          <Select
            options={this.getYearsOptions()}
            clearable={false}
            backspaceRemoves={false}
            deleteRemoves={false}
            value={selectedYear}
            simpleValue={true}
            placeholder={t('global.select')}
            onChange={(value:number) => this.handleChange(value, selectedMonth, selectedDay)} />
        </div>
        {this.props.meta.touched && this.props.meta.error &&
          <div className="form-error-message">{this.props.meta.error}</div>
        }
      </div>
    );
  }
}
