import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { mediaBP } from '@toolkit/util/style';
import { useQueryParams } from '@src/hooks';
import { getOppositeDirection } from '@src/shared/src/util/app';
// Constants
import { DIRECTION, SEARCH_TYPE } from '@src/shared/src/const/app';
// Actions
import {
  setSearchRebooking,
  setSearchArrAt,
  setSearchType,
  invertSearchLocations,
  setSearchDepAt,
} from '@src/shared/src/actions/searchActions';
import { setIsSearchingForOnlyOutbound } from '@src/shared/src/actions/settingsActions';
// Models, Stores & Interfaces
import { IRootState } from '@src/store';
import { selectors } from '@src/shared/src';
import { TravelBookingItemModel, RebookingModel } from '@src/shared/src/models';
// Components
import { SearchBarConn } from '@pod/search';
import { SearchMap } from '@pod/search/components';
// Styles
import '../styles/Search.scss';

const getFareIdDirection = (fareId: number, bookingItem: TravelBookingItemModel) => {
  if (bookingItem.fareIds.includes(fareId)) {
    if (bookingItem.fareId === fareId) {
      return bookingItem.direction;
    } else {
      return getOppositeDirection(bookingItem.direction);
    }
  }
};

const SearchConn: React.FC = () => {
  const dispatch = useDispatch();
  const query = useQueryParams();

  const search = useSelector((state: IRootState) => selectors.search.search(state.search));
  const depCoor = useSelector((state: IRootState) => selectors.search.searchDepCoor(state.search));
  const arrCoor = useSelector((state: IRootState) => selectors.search.searchArrCoor(state.search));
  const isSearchingForOnlyHotel = useSelector(
    (state: IRootState) => state.settings.isSearchingForOnlyHotel,
  );
  const basketItems = useSelector((state: IRootState) => state.checkout.basketItems);

  const bookingItemId = Number(query.get('original_booking_item_id'));
  const originalFareId = Number(query.get('original_fare_id'));
  const originalFareType = query.get('original_fare_type');
  const basketItem = basketItems?.find((item) => item.travelBookingItem?.id === bookingItemId);
  React.useEffect(() => {
    if (search.id !== -1 && basketItem) {
      const rebooking: RebookingModel = new RebookingModel({
        originalTravelBookingId: basketItem.travelBookingItem.bookingId,
        originalTravelBookingItemId: bookingItemId,
        originalFareId: originalFareId,
        originalFareType: originalFareType,
        originalFareDirection: getFareIdDirection(originalFareId, basketItem.travelBookingItem),
      });

      dispatch(setSearchRebooking(rebooking));

      if (basketItem.travelBookingItem.isRoundtrip) {
        // in order to skip hotel results page, we should set the search type to either TRANSPORT or OUTBOUND
        dispatch(setSearchType(SEARCH_TYPE.TRANSPORT));
        dispatch(setIsSearchingForOnlyOutbound(false));
      } else {
        dispatch(setSearchType(SEARCH_TYPE.OUTBOUND));
        dispatch(setIsSearchingForOnlyOutbound(true));
        dispatch(setSearchArrAt(null));

        if (basketItem.travelBookingItem.direction === DIRECTION.INBOUND) {
          dispatch(invertSearchLocations());
          dispatch(setSearchDepAt(search.arrAt));
        }
      }
    } else {
      dispatch(setSearchRebooking(null));
    }
  }, [search.id, basketItem?.id]);

  return (
    <React.Fragment>
      <div id="map-container">
        {mediaBP('md') && (
          <SearchMap
            depName={search.depName}
            arrName={search.arrName}
            depAt={search.depAt}
            arrAt={search.arrAt}
            depMarkerCoord={depCoor}
            arrMarkerCoord={arrCoor}
            isSearchingForOnlyHotel={isSearchingForOnlyHotel}
          />
        )}
        <SearchBarConn />
      </div>
    </React.Fragment>
  );
};
export default SearchConn;
