import * as React from 'react';

interface IProps {
  id: string;
  label: string;
  icon?: React.ReactNode;
  onChange: (value: boolean) => void;
  description?: string;
  outerCSS?: string;
  wrapperCSS?: string;
  labelCSS?: string;
  isChecked?: boolean;
}

const Checkbox: React.FC<IProps> = (props) => {
  return (
    <div className={props.outerCSS}>
      <div className={props.wrapperCSS}>
        <div className="fac fac-checkbox-o fac-primary">
          <span />
          <input
            id={props.id}
            type="checkbox"
            checked={props.isChecked}
            onChange={(e: any) => props.onChange(e.target.checked)}
          />
          <label className={props.labelCSS} htmlFor={props.id}>
            {props.icon}
            {props.label}
          </label>
          {props.description ? (
            <p className="color-gray is-font-small">{props.description}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
