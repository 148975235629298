
import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export default class UpperCaseConverter implements JsonCustomConvert<string> {
  public serialize(vehicle:string): any {
    return vehicle;
  }

  public deserialize(vehicle:string):string {
    return vehicle.toUpperCase();
  }
}