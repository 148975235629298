import * as React from 'react';

// Utils
import { navigate, requireAsset } from '@toolkit/util/app';
import Bugsnag from '@bugsnag/js';

// Constants
import { API_URL } from '@src/shared/src/const/api';
// Actions, Models & Interfaces
// Utils
// Components
// Styles
import '../styles/Logo.scss';
import { ROUTES } from '@toolkit/const/app';

type Props = {
  language: string;
  orgLogoPath: string;
  orgId: number;
  inHeader?: boolean;
};

const Logo: React.FC<Props> = (props) => {

  const { language, orgLogoPath, orgId, inHeader } = props;

  const coLogoLP = !inHeader
    ? `logos/primary-co-brand-${language}.svg`
    : `logos/secondary-co-brand-${language}.svg`;

  let fallbackCoLogoLP = !inHeader
    ? `logos/primary-co-brand-en-US.svg`
    : `logos/secondary-co-brand-en-US.svg`;

  try {
    if (language) {
      fallbackCoLogoLP = requireAsset(coLogoLP) ? coLogoLP : fallbackCoLogoLP;
    }
  } catch (err) {
    Bugsnag.notify(err);
  }

  const mainLogo = !inHeader ? `logos/primary.svg` : `logos/secondary.svg`;


  const handleClick = () => {
    if (inHeader) navigate(ROUTES.HOME);
  }

  return (
    <div
      className={`logo ${inHeader ? 'in--header' : ''}`}
      onClick={handleClick}>
      {orgLogoPath ? (
        <>
          <img className="logo-co" width="86" height="36" src={requireAsset(fallbackCoLogoLP)} />
          <img
            className="logo-brand"
            src={`${API_URL.DEFAULT}/logos/${orgId}/${encodeURIComponent(orgLogoPath)}`}
          />
        </>
      ) : (
        <img
          className={`logo-main ${inHeader ? 'in--header' : ''}`}
          width="86"
          height="36"
          src={requireAsset(mainLogo)}
        />
      )}
    </div>
  );
};

export default Logo;
