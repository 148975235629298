import * as React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

// Utils
import { required } from '@toolkit/util/formsValidation';
import { t } from '@toolkit/util/i18n';
import { selectors } from '@src/shared/src';
import { pluck } from '@src/shared/src/util/general';
// Constants
import { API_URL } from '@src/shared/src/const/api';
import { COST_CENTER_CATEGORIES } from '@src/shared/src/const/app';
// Actions
// Models & Interfaces
import { IRootState } from '@src/store';
// Components
import { SelectInputCostCenter } from '@toolkit/ui';
import { MultiCostCentersFormField } from './MultiCostCentersFormField';
// Styles

type Props = {
  organizationId: number;
  formFieldName: string;
  category: COST_CENTER_CATEGORIES;
  multiValuesEnabled: boolean;
  valueRequired: boolean;
};

export const CostCentersFormField: React.FC<Props> = (props) => {
  const profileId = useSelector((state: IRootState) => state.adminUser.profile.id);
  const passengers = useSelector((state: IRootState) =>
    selectors.search.searchPassengers(state.search),
  );
  const userIds = [profileId, ...pluck('userId', passengers)];
  const userParam = userIds.map((uId) => `user_ids[]=${uId}`).join('&');
  const displayAllCostCentersByCategoryUrl = `${API_URL.DEFAULT}/org_admin/organizations/${props.organizationId}/cost_centers/applicable?${userParam}&category=${props.category}`;

  if (props.multiValuesEnabled) {
    return (
      <div className="checkout-form-row">
        <MultiCostCentersFormField
          category={props.category}
          formFieldName={props.formFieldName}
          required={props.valueRequired}
          userIds={userIds}
          displayAllUrl={displayAllCostCentersByCategoryUrl}
        />
      </div>
    );
  }
  return (
    <div className="checkout-form-row">
      <div className="tcp-form-group tcp-form-group-cost-center">
        <label htmlFor="costCenter" className="tcp-form-group-label">
          {props.category === COST_CENTER_CATEGORIES.COST_CENTER
            ? t('checkoutPaymentAccountingForm.label.costCenter')
            : t('checkoutPaymentAccountingForm.label.costUnit')}
          <div>
            {!(window as any).__IS_WEBVIEW &&
              <a
                className="lp-booking-info-item-link is-font-medium"
                href={displayAllCostCentersByCategoryUrl}
                target="_blank">
                <i className="icon-external_link" />
                {props.category === COST_CENTER_CATEGORIES.COST_CENTER
                  ? t('checkoutPaymentAccountingForm.link.all_cost_centers')
                  : t('checkoutPaymentAccountingForm.link.all_cost_units')}
              </a>
            }
          </div>
        </label>
        <div className="tcp-form-group-field">
          <Field
            name={props.formFieldName}
            parse={(value: any) =>
              value && [{ selectValue: value.id, percentageValue: 100, selectLabel: value.name }]
            }
            format={(value: any) =>
              value && { id: value[0].selectValue, name: value[0].selectLabel }
            }
            validate={props.valueRequired ? required : undefined}
            mandatory={props.valueRequired}
            userIds={userIds}
            category={props.category}
            component={SelectInputCostCenter}
          />
        </div>
      </div>
    </div>
  );
};
