import * as React from 'react';

// Styles
import '../styles/Switch.scss';

type Props = {
  label?: string;
  icon?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange: (value: boolean) => void;
};

const Switch:React.FC<Props> = (props) => {
  const { label, icon, onChange, isChecked, isDisabled } = props;
  return (
    <div className={`tcp-radio ${isChecked? 'tcp-radio-checked':''} ${isDisabled? 'tcp-radio-disabled':''}`}>
      <span>
        {icon && <i className={icon} aria-hidden="true" />}
        {label && <span className="tcp-radio-title">{label}</span>}
      </span>
      <div>
        <label className="tcp-radio-switch">
          <input
            data-key="all"
            className="all"
            type="checkbox"
            onChange={(e: any) => onChange(e.target.checked)}
            disabled={isDisabled}
            checked={isChecked} />
          <div className="tcp-radio-switch-inner" />
        </label>
      </div>
    </div>
  );
};

export default Switch;
