import * as React from 'react';
import { connect } from 'react-redux';
import Slider, { Range } from 'rc-slider';

// Utils
import {
  minutesToHoursAndMinutes,
  formatCents,
  isNilOrEmpty,
  formatDayDifference,
  partialRight,
  prop,
} from '@src/shared/src/util/general';
import { formatDate } from '@src/shared/src/util/date';
import { tripSelectors } from '@src/shared/src/selectors';
import { t } from '@toolkit/util/i18n';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';

// Constants
import {
  CENTS_CONVERSION_TYPES,
  DATE_FORMAT_TYPES,
  DIRECTION,
} from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { filterActions } from '@src/shared/src/actions';
import {
  SettingsModel,
  SearchModel,
  TripModel,
  BasketModel,
  OptionModel,
} from '@src/shared/src/models';
import { IConnectedRedux, IRootState } from '@src/store';
import {
  appSettingsSelectors,
  filterSelectors,
} from '@src/shared/src/selectors';
import { selectors } from '@src/shared/src';
import {
  SingleFilterConstraint,
  StationsFilterConstraint,
  RangeFilterConstraint,
} from '@src/shared/src/interfaces';
import { setUiMobileFilterOverlay, setUiMobileNoScrolling } from '@pod/ui/actions';

// Components
import {
  TransportationFilter,
  StationsFilter,
  BaseFilter,
  SupplierFilter,
} from '@pod/filters';
import {
  TripShuttleTypeSelector,
  TripSideBarSelectionInfo,
} from '@pod/trips/components';
import { SearchSideBarInfo } from '@pod/search/components';
import { Navbar } from '@toolkit/ui';
// Styles

type IProps = IConnectedRedux<IRootState> & AppContextProp & {
  tripDurationFilter:SingleFilterConstraint;
  tripArrTimeFilter:RangeFilterConstraint;
  tripDepTimeFilter:RangeFilterConstraint;
  tripPriceFilter:SingleFilterConstraint;
  tripStopsFilter:SingleFilterConstraint;
  tripTransportationFilter:OptionModel[];
  tripSupplierFilter:OptionModel[];
  tripStationsFilter:StationsFilterConstraint;
  search:SearchModel;
  basket:BasketModel;
  title:string;
  direction:string;
  selectedOutbound:TripModel;
  cheapestInbound:number;
  isTripsLoading:boolean;
  isOpen?:boolean;
  loyaltyCards:SettingsModel[];
  onChangeShuttleType: (vehicle:string, depOrArr:string) => void;
}
class TripSideBarConn extends React.PureComponent<IProps, {}> {
  private getShuttleType = (isDep:boolean, direction:string, basket:BasketModel) => {
    if (isDep) {
      return direction === DIRECTION.OUTWARD ?
        basket.outwardShuttleAtDeparture : basket.inboundShuttleAtDeparture;
    } else {
      return direction === DIRECTION.OUTWARD ?
        basket.outwardShuttleAtArrival : basket.inboundShuttleAtArrival;
    }
  }

  private formatTimeValue = (value) =>
    <span>
      {formatDate(value, DATE_FORMAT_TYPES.SHORT_TIME)}
      <sup>{formatDayDifference(value,
        this.props.direction === DIRECTION.OUTWARD ? this.props.search.depAt : this.props.search.arrAt)}</sup>
    </span>

  private onChangeShuttleTypeArrival = (vehicle) => this.props.onChangeShuttleType(vehicle, 'arrival');

  private onChangeShuttleTypeDeparture = (vehicle) => this.props.onChangeShuttleType(vehicle, 'departure');

  private onChangeSupplierFilter = (suppliers) => {
    this.props.dispatch(filterActions.setTripSupplierFilter({
      direction: this.props.direction,
      constraint: suppliers,
      onlyUpdateConstraint: false,
    }));
  }

  private onChangeStationsFilter = (depStations, arrStations) => {
    this.props.dispatch(filterActions.setTripStationsFilter({
      direction: this.props.direction,
      constraint: {
        depStations,
        arrStations,
      },
      onlyUpdateConstraint: false,
    }));
  }

  private onChangeTransportationFilter = (vehicles) => {
    this.props.dispatch(filterActions.setTripTransportationFilter({
      direction: this.props.direction,
      constraint: vehicles,
      onlyUpdateConstraint: false,
    }));
  }

  private onChangeArrTimeFilter =(val) => {
    this.props.dispatch(filterActions.setTripArrTimeFilter({
      direction: this.props.direction,
      constraint: {
        min: this.props.tripArrTimeFilter.min,
        max: this.props.tripArrTimeFilter.max,
        currentMin: val[0],
        currentMax: val[1],
      },
      onlyUpdateConstraint: false,
    }));
  }
  private onChangeDepTimeFilter =(val) => {
    this.props.dispatch(filterActions.setTripDepTimeFilter({
      direction: this.props.direction,
      constraint: {
        min: this.props.tripDepTimeFilter.min,
        max: this.props.tripDepTimeFilter.max,
        currentMin: val[0],
        currentMax: val[1],
      },
      onlyUpdateConstraint: false,
    }));
  }

  private onChangePriceFilter =(value) => {
    this.props.dispatch(filterActions.setTripPriceFilter({
      direction: this.props.direction,
      constraint: {
        min: this.props.tripPriceFilter.min,
        max: this.props.tripPriceFilter.max,
        current: value[0],
      },
      onlyUpdateConstraint: false,
    }));
  }

  private onChangeStopsFilter =(value) => {
    this.props.dispatch(filterActions.setTripStopsFilter({
      direction: this.props.direction,
      constraint: {
        min: this.props.tripStopsFilter.min,
        max: this.props.tripStopsFilter.max,
        current: value[0],
      },
      onlyUpdateConstraint: false,
    }));
  }

  private onChangeDurationFilter = (value) => {
    this.props.dispatch(filterActions.setTripDurationFilter({
      direction: this.props.direction,
      constraint: {
        min: this.props.tripDurationFilter.min,
        max: this.props.tripDurationFilter.max,
        current: value[0],
      },
      onlyUpdateConstraint: false,
    }));
  }

  private closeMobileFilterOverlay = () =>  {
    this.props.dispatch(setUiMobileFilterOverlay(false));
    this.props.dispatch(setUiMobileNoScrolling(false));
  }

  public render() {
    const { tripArrTimeFilter, tripDepTimeFilter, tripStopsFilter, tripDurationFilter, selectedOutbound,
      tripPriceFilter, tripTransportationFilter, direction, search, tripSupplierFilter, basket,
      isTripsLoading, loyaltyCards, tripStationsFilter, title, appContext, isOpen } = this.props;
    return (
          <aside className={
            `sidebar sidebar-trip sidebar-${direction === DIRECTION.INBOUND ? direction : 'outbound'} ${isOpen ? 'is--open' : ''}`
            }>
             {appContext.isMediaBPNotMobile ?
              <h2 className="sidebar-title">{title}</h2> :
              <Navbar actionRight={() =>this.closeMobileFilterOverlay()} iconRight="icon-arrow_forward" labelRight={t('search.bar.slide.out.button.confirm')}>
                Filter
              </Navbar>
            }
            <SearchSideBarInfo
              from={prop('depName', search)}
              to={prop('arrName', search)}
              depAt={prop('depAt', search)}
              arrAt={prop('arrAt', search)}
              loyaltyCards={loyaltyCards}/>
            {!isNilOrEmpty(selectedOutbound) && direction === DIRECTION.INBOUND  &&
              <TripSideBarSelectionInfo
                searchId={search.id}
                outward={selectedOutbound}
                cheapestInbound={this.props.cheapestInbound}/>
            }
            <TripShuttleTypeSelector
              departureShuttle={basket ? this.getShuttleType(true, direction, basket) : 'none'}
              arrivalShuttle={basket ? this.getShuttleType(false, direction, basket): 'none'}
              onSelectArr={this.onChangeShuttleTypeArrival}
              onSelectDep={this.onChangeShuttleTypeDeparture}
              isTripsLoading={isTripsLoading}
              isDisabled={isTripsLoading}
             />
            <BaseFilter
              key={`duration-filter-${isTripsLoading}`}
              title={t('tripSideBar.durationFilter.title')}
              min={tripDurationFilter.min}
              max={tripDurationFilter.max}
              currentMin={tripDurationFilter.min}
              currentMax={tripDurationFilter.current}
              edgeFormatValue={minutesToHoursAndMinutes}
              midFormatValue={minutesToHoursAndMinutes}
              isOpen={!isTripsLoading}
              isDisabled={isTripsLoading}
              render={({currentMax, currentMin, min, max, onChange }) => (
                <Slider
                  value={currentMax}
                  min={min}
                  max={max}
                  onChange={(val) => onChange([val])}
                />
              )}
              onChange={this.onChangeDurationFilter}/>
            <BaseFilter
              key={`stops-filter-${isTripsLoading}`}
              title={t('tripSideBar.stopFilter.title')}
              min={tripStopsFilter.min}
              max={tripStopsFilter.max}
              currentMin={tripStopsFilter.min}
              currentMax={tripStopsFilter.current}
              isOpen={!isTripsLoading}
              isDisabled={isTripsLoading}
              render={({currentMax, currentMin, min, max, onChange }) => (
                <Slider
                  value={currentMax}
                  min={min}
                  max={max}
                  onChange={(val) => onChange([val])}
                />
              )}
              onChange={this.onChangeStopsFilter}/>
            <BaseFilter
              key={`price-filter-${isTripsLoading}`}
              title={t('tripSideBar.priceFilter.title')}
              min={tripPriceFilter.min}
              max={tripPriceFilter.max}
              currentMin={tripPriceFilter.min}
              currentMax={tripPriceFilter.current}
              edgeFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
              midFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
              isOpen={!isTripsLoading}
              isDisabled={isTripsLoading}
              render={({currentMax, currentMin, min, max, onChange }) => (
                <Slider
                  value={currentMax}
                  min={min}
                  max={max}
                  onChange={(val) => onChange([val])}
                />
              )}
              onChange={this.onChangePriceFilter}/>
            <BaseFilter
              key={`depTime-filter-${isTripsLoading}`}
              title={t('tripSideBar.departureTimeFilter.title')}
              min={tripDepTimeFilter.min}
              max={tripDepTimeFilter.max}
              currentMin={tripDepTimeFilter.currentMin}
              currentMax={tripDepTimeFilter.currentMax}
              edgeFormatValue={this.formatTimeValue}
              midFormatValue={this.formatTimeValue}
              isOpen={!isTripsLoading}
              isDisabled={isTripsLoading}
              render={({currentMax, currentMin, min, max, onChange }) => (
                <Range
                  value={[currentMin, currentMax]}
                  min={min}
                  max={max}
                  onChange={onChange}
                />
              )}
              onChange={this.onChangeDepTimeFilter}/>
            <BaseFilter
              key={`arrTime-filter-${isTripsLoading}`}
              title={t('tripSideBar.arrivalTimeFilter.title')}
              min={tripArrTimeFilter.min}
              max={tripArrTimeFilter.max}
              currentMin={tripArrTimeFilter.currentMin}
              currentMax={tripArrTimeFilter.currentMax}
              edgeFormatValue={this.formatTimeValue}
              midFormatValue={this.formatTimeValue}
              isOpen={!isTripsLoading}
              isDisabled={isTripsLoading}
              render={({currentMax, currentMin, min, max, onChange }) => (
                <Range
                  value={[currentMin, currentMax]}
                  min={min}
                  max={max}
                  onChange={onChange}
                />
              )}
              onChange={this.onChangeArrTimeFilter}/>
            <TransportationFilter
              key={`transportation-filter-${isTripsLoading}`}
              title={t('tripSideBar.transportationFilter.title')}
              vehicles={tripTransportationFilter}
              isOpen={!isTripsLoading}
              isDisabled={isTripsLoading}
              onChange={this.onChangeTransportationFilter}/>
            <SupplierFilter
              key={`suppplier-filter-${isTripsLoading}`}
              title={t('tripSideBar.supplierFilter.title')}
              suppliers={tripSupplierFilter}
              isOpen={!isTripsLoading}
              isDisabled={isTripsLoading}
              onChange={this.onChangeSupplierFilter}/>
            <StationsFilter
              key={`stations-filter-${isTripsLoading}`}
              title={t('tripSideBar.stationsFilter.title')}
              depStations={tripStationsFilter.depStations}
              arrStations={tripStationsFilter.arrStations}
              isOpen={!isTripsLoading}
              isDisabled={isTripsLoading}
              onChange={this.onChangeStationsFilter}/>
          </aside>
    );
  }
}

const mapStateToProps = (state:IRootState) => ({
  tripDurationFilter: state.filters.tripDurationFilter,
  tripArrTimeFilter: filterSelectors.tripArrTimeFilter(state),
  tripDepTimeFilter: filterSelectors.tripDepTimeFilter(state),
  tripPriceFilter: state.filters.tripPriceFilter,
  tripStopsFilter: state.filters.tripStopsFilter,
  tripTransportationFilter: state.filters.tripTransportationFilter,
  tripSupplierFilter: state.filters.tripSupplierFilter,
  tripStationsFilter: state.filters.tripStationsFilter,
  selectedOutbound: selectors.checkout.outwardTrip(state.checkout),
  cheapestInbound: tripSelectors.cheapestInbound(state.trips),
  search: selectors.search.search(state.search),
  loyaltyCards: appSettingsSelectors.loyaltyCards(state),
  basket: state.checkout.basket,
});
export default connect(mapStateToProps)(withAppContext(TripSideBarConn));
