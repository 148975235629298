import * as React from 'react';

export default (props) => (
  <div>
    <input
      {...props.input}
      className={props.className ? props.className : ''}
      type={props.type}
      placeholder={props.placeholder} />
    {props.meta.touched && props.meta.error &&
      <div className="form-error-message">{props.meta.error}</div>
    }
  </div>
);
