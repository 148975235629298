import { JsonObject, JsonProperty, Any } from 'json2typescript';
import { DateConverter } from './converters';
import TariffModel from './TariffModel';
import TravelBookingItemModel from './TravelBookingItemModel';
import { STATUS } from '../const/app';

@JsonObject('BasketItemModel')
export default class BasketItemModel {
  @JsonProperty('approval_travel_class', Any) public approvalTravelClass:any = undefined;
  @JsonProperty('assembly_tariff', TariffModel, true) public assemblyTariff:TariffModel= undefined;
  @JsonProperty('assembly_tariff_id', Number) public assemblyTariffId:number = -1;
  @JsonProperty('basket_id', Number) public basketId:number = -1;
  // @JsonProperty('booking_item', , true) public bookingItem:BookingItemModel = undefined;
  @JsonProperty('travel_booking_item', TravelBookingItemModel, true)
  public travelBookingItem:TravelBookingItemModel= undefined;
  @JsonProperty('created_at', DateConverter) public createdAt:Date = undefined;
  @JsonProperty('destroyed', Boolean) public destroyed:boolean = false;
  @JsonProperty('hotel_fare_id', Number) public hotelFareId:number = -1;
  @JsonProperty('rental_fare_id', Number) public rentalFareId:number = -1;
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('updated_at', DateConverter) public updatedAt:Date = undefined;
  @JsonProperty('request_seat_reservation', Boolean) public requestSeatReservation:boolean = undefined;

  public get hardFail() : boolean {
    return this.travelBookingItem?.status === STATUS.CONFIRM_FAILED && this.travelBookingItem?.hardFail
  }

}
