import { JsonConverter, JsonCustomConvert } from 'json2typescript';
import { getUTCDate } from '../../util/general';

@JsonConverter
export default class DateConverter implements JsonCustomConvert<Date> {
  public serialize(date: Date): any {
    return date.toString();
  }

  public deserialize(date: string): any {
    // The timezone is not set correctly but the datetime is parsed correctly in UTC
    return date ? getUTCDate(date) : null;
  }
}