import * as React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
import { map, isNil } from '@src/shared/src/util/general';
import { getUserFullname } from '@src/shared/src/util/users';
import { ENVIRONMENT, SEARCH_TYPE } from '@src/shared/src/const/app';
import { searchHooks } from '@src/services';
// Constants
import { API_URL } from '@src/shared/src/const/api';
// Actions
import { selectors } from '@src/shared/src';
// Models
import { DocumentModel, PassengerModel } from '@src/shared/src/models';
// Interfaces
import { IRootState } from '@src/store';
// Components
// Styles
import '../styles/BookedItemInfo.scss';

type Props = {
  env: ENVIRONMENT;
  bookingNumbers: string[];
  documents: DocumentModel[];
};

const BookedItemInfo: React.FC<Props> = props => {
  const passengers = useSelector((state: IRootState) => selectors.search.searchPassengers(state.search));
  const [searchType] = searchHooks.useSearchState<SEARCH_TYPE>(['currentSearch', 'searchType']);
  const bookingCreatorName = useSelector((state: IRootState) => state.checkout.basket.createdBy);
  const { env, bookingNumbers, documents } = props;

  const ticketLabel =
    searchType === SEARCH_TYPE.RENTAL ? t('tripBookedInfo.label.rentalVoucher') : t('tripBookedInfo.label.tickets');

  const renderDocuments = () => {
    switch (searchType) {
      case SEARCH_TYPE.RENTAL:
        return t('tripBookedInfo.label.rentalUpcoming');
      default:
        return map((document: DocumentModel) => {
          if (!isNil(document)) {
            return (
              <a
                key={document.id}
                href={`${API_URL.DOCUMENTS}/${document.id}/download`}
                className="lp-booked-info-ticket">
                <i className="icon-download"></i> {document.name}
                <br />
              </a>
            );
          }
        }, documents || []);
    }
  };

  if (env === ENVIRONMENT.CONFIRMATION || env === ENVIRONMENT.BOOKED) {
    return (
      <div className="lp-booked-info">
        <div>
          <div className="lp-booked-info-label">{t('tripBookedInfo.label.passengers')}</div>
          {passengers.map((passenger: PassengerModel) => (
            <div key={`passenger-${passenger.id}`} className="lp-booked-info-passenger">
              {getUserFullname(passenger)}
            </div>
          ))}
        </div>
        <div>
          <div className="lp-booked-info-label">{t('tripBookedInfo.label.createdBy')}</div>
          <span>{bookingCreatorName}</span>
          <br />
          <br />
          <div className="lp-booked-info-label">{t('tripBookedInfo.lable.bookingNumber')}</div>
          <span>{bookingNumbers}</span>
        </div>
        <div>
          <div className="lp-booked-info-label">{ticketLabel}</div>
          <span>{renderDocuments()}</span>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default BookedItemInfo;
