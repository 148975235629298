import { JsonObject, JsonProperty } from 'json2typescript';
import { CeilConverter } from './converters';

import TransportSegmentModel from './TransportSegmentModel';
import TariffModel from './TariffModel';

@JsonObject('LegOptionModel')
export default class LegOptionModel {
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('transport_segments', [TransportSegmentModel]) public transportSegments:TransportSegmentModel[]= [];
  @JsonProperty('tariffs', [TariffModel], true) public tariffs:TariffModel[] = [];
  @JsonProperty('duration', CeilConverter) public duration:number = -1;
  @JsonProperty('changeovers', Number) public changeovers:number = -1;
  @JsonProperty('changeable', Boolean) public changeable:boolean = undefined;
  @JsonProperty('estimated', Boolean) public estimated:boolean = undefined;
}
