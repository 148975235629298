import { ActionType } from 'typesafe-actions';

import * as uiActions from './actions';
import { default as ui, IUIState } from './reducer';

type UIActions = ActionType<typeof uiActions>;

export {
  uiActions,
  UIActions,
  ui,
  IUIState,
};