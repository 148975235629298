import * as React from 'react';
import { useSelector } from 'react-redux';

import { isAnyBookingItemAirbnb, isBookingConfirmFinished } from '@src/shared/src/selectors/checkoutSelectors';
import { WarningBanner } from '@toolkit/ui';
import { t } from '@toolkit/util/i18n';

export const CheckoutMessages = () => {
  const bookingHasAirbnb = useSelector(isAnyBookingItemAirbnb)
  const bookingConfirmFinished = useSelector(isBookingConfirmFinished)

  if (bookingConfirmFinished) {
    return (
      <>
        {bookingHasAirbnb &&
          <WarningBanner
            icon="lp-icon-help"
            isInfo={true}
            label={t('checkout-messages.text.airbnb-booking')} />}
      </>
    )
  }

  return null;
}
