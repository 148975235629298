// Utils
import { map, filter, partial, values } from '../util/general';
// Constants
// Actions
// Models
import { TripModel } from '../models';
// Interfaces
import { SingleFilterConstraint } from '../interfaces';
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';

export default class TripPriceFilter implements IFilter<TripModel, SingleFilterConstraint> {
  private constructor() {}

  private static instance:TripPriceFilter;

  static getInstance = () => {
    if (!TripPriceFilter.instance) {
      TripPriceFilter.instance = new TripPriceFilter();
    }
    return TripPriceFilter.instance;
  }

  public id:string = 'TripPriceFilter';

  public getConstraints = (
    opts:ConstraintsOpts<TripModel, SingleFilterConstraint>
  ):SingleFilterConstraint => {
    const allPrices = values(map((trip:TripModel) => trip.baseTotal, opts.values));

    return {
      min: Math.min(...allPrices),
      max: Math.max(...allPrices),
      current: (opts.reinit && opts.onlyUpdateConstraint)
        ? opts.constraint.current : Math.max(...allPrices),
    }
  }

  public process = (constraint:SingleFilterConstraint, trips:TripModel[]) =>
    filter(partial(this.match, [constraint]), trips);

  private match = (constraint:SingleFilterConstraint, trip:TripModel) =>
    trip.baseTotal <= constraint.current;
}
