import * as React from 'react';
import { connect } from 'react-redux';

require ('./styles/Navbar.scss');
import { navigate, navigateExternally, isCurrentPath} from '@toolkit/util/app';

// Constants
import { ROUTES } from '@toolkit/const/app';
import { API_URL } from '@src/shared/src/const/api';

// Models
import { RentalModel } from '@src/shared/src/models';

// Interfaces
import { IRootState, ConnectedRedux } from '@src/store';

// Actions
import { selectors } from '@src/shared/src';

type Props = ConnectedRedux<IRootState> & {
  showBackButton?:boolean;
  iconRight?:string;
  labelRight?:string;
  actionRight?:any;
  children?:any;
  theme?:string;
  searchId:number;
  isSearchingForOnlyHotel:boolean;
  isSearchingForOnlyRental:boolean;
  isSearchingForOnlyOutbound:boolean;
  isSearchingForOnlyTransport:boolean;
  isCurrentUserNotTraveller?:boolean;
  bookingRental:RentalModel;
};

class Navbar extends React.Component<Props, {}> {


  private getBackRoute = () => {
    const {
      searchId,
      isSearchingForOnlyTransport,
      isSearchingForOnlyHotel,
      isSearchingForOnlyOutbound,
    } = this.props;

    if(isCurrentPath(ROUTES.TRIPS.INBOUND)) {
      return ROUTES.TRIPS.OUTWARD + searchId;
    }

    if (isCurrentPath(ROUTES.HOTELS)) {
      if (isSearchingForOnlyHotel) {
        return ROUTES.HOME;
      }
      return ROUTES.TRIPS.INBOUND + searchId;
    }

    if (isCurrentPath('/booking/')) {

      if (isSearchingForOnlyTransport) {
        return ROUTES.TRIPS.INBOUND + searchId;
      }

      if (isSearchingForOnlyOutbound) {
        return ROUTES.TRIPS.OUTWARD + searchId;
      }

      return ROUTES.HOTELS + searchId;

    }

    return ROUTES.HOME;

  }

  private navigateBack = () => {
    if (this.props.isSearchingForOnlyRental) {
      navigateExternally(API_URL.RENTAL_SEARCH + this.props.bookingRental.id);
    } else {
      navigate(this.getBackRoute());
    }
  }

  public render() {

    const {showBackButton, iconRight, actionRight, labelRight, children, theme} = this.props;

    return (
      <div className={`lp-navbar ${theme ? 'lp-navbar-' + theme : ''}`}>
        {showBackButton?
          <button onClick={() => this.navigateBack()} className="lp-navbar-button lp-navbar-button-left"><i className="icon-arrow_back"></i></button>
          : ''
        }
        {children}
        {actionRight?
          <button onClick={actionRight} className="lp-navbar-button lp-navbar-button-right">{labelRight ? <div className="lp-navbar-button-label">{labelRight}</div> : ''}<i className={iconRight}></i></button>
          : ''
        }
      </div>
    );
  }
}

const mapState = (state:IRootState) => ({
  searchId: state.search.currentSearch.id,
  isSearchingForOnlyHotel: state.settings.isSearchingForOnlyHotel,
  isSearchingForOnlyOutbound: state.settings.isSearchingForOnlyOutbound,
  isSearchingForOnlyRental: state.settings.isSearchingForOnlyRental,
  bookingRental: selectors.checkout.rental(state.checkout),
  isSearchingForOnlyTransport: state.settings.isSearchingForOnlyTransport,
});

export default connect(mapState)(Navbar);
