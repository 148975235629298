import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { navigate } from '@toolkit/util/app';
import { formatDate, formatDateRange } from '@src/shared/src/util/date';
// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions
// Models & Interfaces
import { SearchModel } from '@src/shared/src/models';
// Components
import { Navbar } from '@toolkit/ui';

type Props = {
  search: SearchModel;
};

export const HotelMobileNavbar: React.FC<Props> = ({ search }) => {
  return (
    <Navbar
      showBackButton={true}
      iconRight="icon-edit"
      actionRight={() => navigate('/')}
      theme="hotel">
      <div>
        {t('hotelConn.navbar.hotel.label')}
        <div className="lp-navbar-subtitle">
          {search.arrAt
            ? formatDateRange(search.depAt, search.arrAt, '-', DATE_FORMAT_TYPES.DAY_SHORT_DATE)
            : formatDate(search.depAt, DATE_FORMAT_TYPES.DAY_SHORT_DATE)}
        </div>
      </div>
    </Navbar>
  );
};
