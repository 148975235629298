import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
import { HOTEL_PROVIDERS } from '@src/util/const';
// Actions, Models & Interfaces
// Components
import { LoaderProgress, Spinner } from '@toolkit/ui';
import { useState, useEffect } from 'react';

// Styles
import '../styles/LoaderHotelDetails.scss';

type Props = {
  finished: boolean;
}

const LoaderHotelDetails: React.FC<Props> = (props) => {
  const [indexLabel, setIndexLabel] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndexLabel((indexLabel + 1) % HOTEL_PROVIDERS.length);
    }, 1000);
    return () => clearInterval(interval);
  });

  return (
    <div className="loader-hotel-details">
      <div className="loader-hotel-details-progress">
        <LoaderProgress finished={props.finished} duration={30000} />
      </div>
      <div className="loader-hotel-details-inner">
        <Spinner small />
        <div className="loader-hotel-details-loading">
          {t('loaderHotelDetails.loading')}
          <br />
          <strong>
            {t('loaderHotelDetails.searching')} {HOTEL_PROVIDERS[indexLabel]}
          </strong>
        </div>
      </div>
    </div>
  );
};

export default LoaderHotelDetails;
