import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
import {
  DIRECTION,
  ENVIRONMENT,
} from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { TripModel } from '@src/shared/src/models';
import { IRootState, ConnectedRedux } from '@src/store';
import * as selectors from '@src/shared/src/selectors';
// Components
import Trip from './Trip';

type Props = ConnectedRedux<IRootState> & {
  direction:DIRECTION;
  outwardTrip:TripModel;
  inboundTrip:TripModel;
  searchDate:Date;
  isRT:boolean;
};

class BookedTrip extends React.PureComponent<Props> {
  render() {
    const { direction, outwardTrip, inboundTrip, searchDate, isRT } = this.props;
    if (direction === DIRECTION.OUTWARD ? outwardTrip : inboundTrip) {
      return (
        <React.Fragment>
          <div className="content-header">
            <h2 className="content-title">{t('BookedTrip.title.bookedTrip')}</h2>
          </div>
          <Trip
            trip={direction === DIRECTION.OUTWARD ? outwardTrip : inboundTrip}
            direction={direction}
            env={ENVIRONMENT.SEARCH}
            searchDate={searchDate}
            isSelectingTrip={false}
            isRoundtrip={isRT}
            detailsOpened={true}
            onFetchDetail={() => {}}
            onSelectTrip={() => {}}
            onCloseDetail={() => {}}
            isLoading={false} />
        </React.Fragment>
      );
    }

    return null;
  }
}
const mapState = (state:IRootState, props:any) => ({
  outwardTrip: selectors.checkout.outwardTrip(state.checkout),
  inboundTrip: selectors.checkout.inboundTrip(state.checkout),
  searchDate: props.direction === DIRECTION.OUTWARD
    ? selectors.search.searchDepAt(state.search) : selectors.search.searchArrAt(state.search),
});
export default connect(mapState)(BookedTrip);
