import * as React from 'react';
import './styles/Label.scss';

type Props = {
  children: React.ReactNode;
};

const Label: React.FC<Props> = (props) => {
  return <div className="label">{props.children}</div>;
};

export default Label;
