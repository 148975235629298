import { createAction } from 'typesafe-actions';

export const setIsSearchingForOnlyHotel = createAction(
  'settings/SET_IS_SEARCHING_FOR_ONLY_HOTEL', (resolve) => (val:boolean) => resolve(val)
);

export const setIsSearchingForOnlyRental = createAction(
  'settings/SETTINGS_SET_IS_SEARCHING_FOR_ONLY_RENTAL',
  (resolve) => (val:boolean) => resolve(val)
);

export const setIsSearchingForOnlyOutbound = createAction(
  'settings/SET_IS_SEARCHING_FOR_ONLY_OUTBOUND', (resolve) => (val:boolean) => resolve(val)
);

export const setIsSearchingForOnlyTransport = createAction(
  'settings/SET_IS_SEARCHING_FOR_ONLY_TRANSPORT', (resolve) => (val:boolean) => resolve(val)
);

export const setWSCallStart = createAction(
  'settings/SET_WS_CALL_START',
  (resolve) => (wsCallKey:string) => resolve(wsCallKey)
);

export const setWSCallEnd = createAction(
  'settings/SET_WS_CALL_END',
  (resolve) => (wsCallKey:string) => resolve(wsCallKey)
);

export const setWSStatus = createAction(
  'settings/SET_WS_STATUS',
  (resolve) => (wsStatus:boolean) => resolve(wsStatus)
);

export const startWSCheck = createAction('settings/START_WS_CHECK');

export const clearWSCalls = createAction('settings/CLEAR_WS_CALLS');