import * as React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

// Utils
import { isNilOrEmpty } from '@src/shared/src/util/general';
import { formatDate, formatDateRange } from '@src/shared/src/util/date';
// Constants
// Actions, Models & Interfaces
import { CoordinatesModel } from '@src/shared/src/models';
// Components
import { MapMarker } from '@pod/search/components';
// Styles

interface IProps {
  depName:string;
  arrName:string;
  depAt:Date;
  arrAt:Date;
  depMarkerCoord:CoordinatesModel;
  arrMarkerCoord:CoordinatesModel;
  isSearchingForOnlyHotel:boolean;
}

interface IState {
  googleMap:google.maps.Map;
  currentZoomValue:number;
}

class SearchMap extends React.Component<IProps, IState> {

  public state:IState = {
    googleMap: null,
    currentZoomValue: 6,
  };

  private setCurrentZoomValue = () => {
    if (this.state.googleMap) this.setState({ currentZoomValue: this.state.googleMap.getZoom() });
  }

  // TODO: react-google-maps isn't exporting the typing correctly at the moment, so have to use any
  private static customMapStyle:any =
  [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [{ lightness: '0' }],
    },
    {
      featureType: 'all',
      elementType: 'geometry',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'all',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#444444' }],
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry',
      stylers: [{ visibility: 'on' }, { color: '#fefefe' }, { weight: '0.90' }],
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative.province',
      elementType: 'geometry.stroke',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'all',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'geometry.stroke',
      stylers: [{ visibility: 'on' }],
    },
    {
      featureType: 'landscape',
      elementType: 'all',
      stylers: [{ gamma: '1' }, { weight: '1' }, { color: '#E5EDF5' }, { visibility: 'on' }],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [{ saturation: -100 }, { lightness: '100' }, { visibility: 'off' }, { color: '#eeefef' }],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [{ visibility: 'simplified' }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [{ color: '#F4F7F9' }, { visibility: 'on' }],
    },
  ];

  private getCurvedPolyline = () => {
    if (!isNilOrEmpty(this.state.googleMap) && !isNilOrEmpty(this.state.googleMap.getProjection())) {
      const pos1 = new google.maps.LatLng(this.props.depMarkerCoord.lat, this.props.depMarkerCoord.lng);
      const pos2 = new google.maps.LatLng(this.props.arrMarkerCoord.lat, this.props.arrMarkerCoord.lng);
      const p1 = this.state.googleMap.getProjection().fromLatLngToPoint(pos1);
      const p2 = this.state.googleMap.getProjection().fromLatLngToPoint(pos2);
      const e = new google.maps.Point(p2.x - p1.x, p2.y - p1.y);
      const m = new google.maps.Point(e.x / 2, e.y / 2);
      const o = new google.maps.Point(e.y, -e.x);
      const c = new google.maps.Point(m.x + 0.25 * o.x, m.y + 0.25 * o.y);
      const pathDef = 'M 0,0 ' + 'q ' + c.x + ',' + c.y + ' ' + e.x + ',' + e.y;
      const scale = 1 / Math.pow(2, - this.state.currentZoomValue);
      const icon = {path: pathDef, scale, strokeWeight: 2, strokeColor: '#D6DEE6', fillColor: 'none'};
      return <Marker position={this.props.depMarkerCoord} icon={icon} />;
    }
  }

  private getArrDatesLabel = (isSearchingForOnlyHotel:boolean) => {
    if (!isSearchingForOnlyHotel) {
      return this.props.arrAt && formatDate(this.props.arrAt);
    } else {
      if (this.props.depAt && this.props.arrAt) {
        return formatDateRange(this.props.depAt, this.props.arrAt);
      }
    }
  }

  public render() {
    const mapOptions = {
      styles: SearchMap.customMapStyle,
      minZoom: 3,
      maxZoom: 8,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
    };
    return (
      <GoogleMap
        zoom={this.state.currentZoomValue}
        center={{lat: 51, lng: 8}}
        options={mapOptions}
        onLoad={(googleMap) => this.setState({ googleMap })}
        onUnmount={() => this.setState({ googleMap: null })}
        onZoomChanged={this.setCurrentZoomValue}
        onProjectionChanged={this.setCurrentZoomValue}>
        {this.props.depMarkerCoord && !this.props.isSearchingForOnlyHotel &&
          <MapMarker
            position={this.props.depMarkerCoord}
            dateLabel={this.props.depAt && formatDate(this.props.depAt)}
            locationLabel={this.props.depName}
            isDep={true}/>
        }
        {this.props.arrMarkerCoord &&
          <MapMarker
            position={this.props.arrMarkerCoord}
            dateLabel={this.getArrDatesLabel(this.props.isSearchingForOnlyHotel)}
            locationLabel={this.props.arrName}
            isDep={false}/>
        }
        {this.props.depMarkerCoord && this.props.arrMarkerCoord && !this.props.isSearchingForOnlyHotel &&
          this.getCurvedPolyline()
        }
      </GoogleMap>);
  }
}

export default SearchMap;
