import { getType } from 'typesafe-actions';

// Utils
// Constants
// Actions
import { OrganizationActions, organizationActions } from '../actions';
// Models
import { OrganizationModel } from '../models';
// Interfaces
// Components
// Styles

export interface IOrganizationState {
  org:OrganizationModel;
}

const initialState:IOrganizationState = {
  org:undefined,
};

export default (state:IOrganizationState = initialState, action:OrganizationActions) => {
  switch (action.type) {
    case getType(organizationActions.setOrganization):
      return { ...state, org: action.payload };
    default:
      return state;
  }
};