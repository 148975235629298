import { getType} from 'typesafe-actions';

// Utils
import { merge } from '../util/general';
// Constants
// Actions
import { SettingsActions, settingsActions } from '../actions';
// Models
// Interfaces
import { ObjectArray, WSCall } from '../interfaces';

export interface ISettingsState {
  isSearchingForOnlyHotel:boolean;
  isSearchingForOnlyOutbound:boolean;
  isSearchingForOnlyRental:boolean;
  isSearchingForOnlyTransport:boolean;
  wsCalls:ObjectArray<WSCall>;
  wsStatus:boolean;
}

const initialState: ISettingsState = {
  isSearchingForOnlyHotel: false,
  isSearchingForOnlyOutbound: false,
  isSearchingForOnlyRental: false,
  isSearchingForOnlyTransport: false,
  wsCalls: {},
  wsStatus: true,
};

export default (state:ISettingsState = initialState, action:SettingsActions) => {
  switch (action.type) {
    case getType(settingsActions.setIsSearchingForOnlyHotel):
      return {...state, isSearchingForOnlyHotel: action.payload };
    case getType(settingsActions.setIsSearchingForOnlyRental):
      return {...state, isSearchingForOnlyRental: action.payload };
    case getType(settingsActions.setIsSearchingForOnlyOutbound):
      return {...state, isSearchingForOnlyOutbound: action.payload };
    case getType(settingsActions.setIsSearchingForOnlyTransport):
      return {...state, isSearchingForOnlyTransport: action.payload };
    case getType(settingsActions.setWSStatus):
      return {...state, wsStatus: action.payload };
    case getType(settingsActions.setWSCallStart):
      return {
        ...state,
        wsCalls: merge(
          state.wsCalls,
          {
            [action.payload]: {
              endTime: null,
              startTime: Date.now(),
              state: false,
              wsCallKey: action.payload,
            },
          }
        ),
      };
    case getType(settingsActions.setWSCallEnd):
      /* TODO: If val is 'true' make sure the key exists before setting it. Remove this logic from the reducer,
         not a good place to put it here, but currently avoiding code repetition in every epic. */

      if (!state.wsCalls.hasOwnProperty(action.payload)) {
        return state;
      }
      return {
        ...state,
        wsCalls: merge(
          state.wsCalls,
          {
            [action.payload]: merge(
              state.wsCalls[action.payload],
              {
                endTime: Date.now(),
                state: true,
              }
            ),
          }
        ),
      };
    case getType(settingsActions.clearWSCalls):
      return {
        ...state,
        wsCalls: {},
      };
    default: return state;
  }
};
