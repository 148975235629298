import { DATE_FORMAT_TYPES } from '../../const/app';

export default class DateFormatter {
  private constructor() {}

  private static instance:DateFormatter;

  private static twoDigitDate: any = {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  };

  private static twoDigitTime: any = {
    hour: '2-digit',
    minute: '2-digit',
  };

  private fmtShortDate:Intl.DateTimeFormat;
  private fmtShortTime:Intl.DateTimeFormat;
  private fmtLongTime:Intl.DateTimeFormat;
  private fmtDayLongDate:Intl.DateTimeFormat;
  private fmtYearLongDate:Intl.DateTimeFormat;
  private fmtDayShortDate:Intl.DateTimeFormat;
  private fmtLongDateTime:Intl.DateTimeFormat;
  private fmtShortDayOfWeek:Intl.DateTimeFormat;
  private fmtLongDayOfWeek:Intl.DateTimeFormat;
  private fmtMonthYear:Intl.DateTimeFormat;
  private fmtLongMonth:Intl.DateTimeFormat;

  private setFmtShortDate = (locale:string)  => {
    DateFormatter.instance.fmtShortDate = new Intl.DateTimeFormat(locale, DateFormatter.twoDigitDate);
  }

  private setFmtShortTime = (locale:string) => {
    DateFormatter.instance.fmtShortTime = new Intl.DateTimeFormat(locale, DateFormatter.twoDigitTime);
  }

  private setFmtLongTime = (locale:string) => {
    const isDeOrFr = ['de', 'fr'].includes(locale.split('-')[0]);
    DateFormatter.instance.fmtLongTime =
      new Intl.DateTimeFormat(locale, Object.assign(
        { hour12: !isDeOrFr },
        DateFormatter.twoDigitTime));
  }

  private setFmtDayLongDate = (locale:string) => {
    DateFormatter.instance.fmtDayLongDate = new Intl.DateTimeFormat(
      locale, Object.assign({ weekday: 'long' }, DateFormatter.twoDigitDate));
  }

  private setFmtYearLongDate = (locale:string) => {
    DateFormatter.instance.fmtYearLongDate = new Intl.DateTimeFormat(
      locale, Object.assign(DateFormatter.twoDigitDate, { year: 'numeric' }));
  }

  private setFmtDayShortDate = (locale:string) => {
    DateFormatter.instance.fmtDayShortDate = new Intl.DateTimeFormat(
      locale, Object.assign({ weekday: 'short' }, DateFormatter.twoDigitDate));
  }

  private setFmtLongDateTime = (locale:string) => {
    const isDeOrFr = ['de', 'fr'].includes(locale.split('-')[0]);
    DateFormatter.instance.fmtLongDateTime = new Intl.DateTimeFormat(
      locale,
      Object.assign(
        { weekday: 'short', hour12: !isDeOrFr },
        DateFormatter.twoDigitDate, DateFormatter.twoDigitTime));
  }

  private setFmtShortDayOfWeek = (locale:string) => {
    DateFormatter.instance.fmtShortDayOfWeek = new Intl.DateTimeFormat(
      locale, { weekday: 'short' });
  }

  private setFmtLongDayOfWeek = (locale:string) => {
    DateFormatter.instance.fmtLongDayOfWeek = new Intl.DateTimeFormat(
      locale, { weekday: 'long' });
  }

  private setFmtMonthYear = (locale:string) => {
    DateFormatter.instance.fmtMonthYear = new Intl.DateTimeFormat(
      locale, { year: 'numeric', month: 'long' });
  }

  private setFmtLongMonth = (locale:string) => {
    DateFormatter.instance.fmtLongMonth = new Intl.DateTimeFormat(
      locale, { month: 'long' });
  }

  static getInstance = () => {
    if (!DateFormatter.instance) {
      DateFormatter.instance = new DateFormatter();
    }
    return DateFormatter.instance;
  }

  static initFormatter = (locale:string) => {
    DateFormatter.instance = new DateFormatter();
    DateFormatter.instance.setFmtShortDate(locale);
    DateFormatter.instance.setFmtShortTime(locale);
    DateFormatter.instance.setFmtLongTime(locale);
    DateFormatter.instance.setFmtDayLongDate(locale);
    DateFormatter.instance.setFmtYearLongDate(locale);
    DateFormatter.instance.setFmtDayShortDate(locale);
    DateFormatter.instance.setFmtLongDateTime(locale);
    DateFormatter.instance.setFmtShortDayOfWeek(locale);
    DateFormatter.instance.setFmtLongDayOfWeek(locale);
    DateFormatter.instance.setFmtMonthYear(locale);
    DateFormatter.instance.setFmtLongMonth(locale);
  }

  public getFormatter = (fmtType:DATE_FORMAT_TYPES) => {
    switch (fmtType) {
      case DATE_FORMAT_TYPES.SHORT_DATE:
        return DateFormatter.instance.fmtShortDate;
      case DATE_FORMAT_TYPES.SHORT_TIME:
        return DateFormatter.instance.fmtShortTime;
      case DATE_FORMAT_TYPES.DAY_LONG_DATE:
        return DateFormatter.instance.fmtDayLongDate;
      case DATE_FORMAT_TYPES.YEAR_LONG_DATE:
        return DateFormatter.instance.fmtYearLongDate;
      case DATE_FORMAT_TYPES.DAY_SHORT_DATE:
        return DateFormatter.instance.fmtDayShortDate;
      case DATE_FORMAT_TYPES.LONG_TIME:
        return DateFormatter.instance.fmtLongTime;
      case DATE_FORMAT_TYPES.LONG_DATE_TIME:
        return DateFormatter.instance.fmtLongDateTime;
      case DATE_FORMAT_TYPES.SHORT_DAY_OF_WEEK:
        return DateFormatter.instance.fmtShortDayOfWeek;
      case DATE_FORMAT_TYPES.LONG_DAY_OF_WEEK:
        return DateFormatter.instance.fmtLongDayOfWeek;
      case DATE_FORMAT_TYPES.MONTH_YEAR:
        return DateFormatter.instance.fmtMonthYear;
      case DATE_FORMAT_TYPES.LONG_MONTH:
        return DateFormatter.instance.fmtLongMonth;
      default:
        return DateFormatter.instance.fmtShortDate;
    }
  }
}
