import * as React from 'react';
import { useDispatch } from 'react-redux';

// Utils
import { notify, navigate, baseErrorCond } from '@toolkit/util/app';
import { cond } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
import { ROUTES } from '@toolkit/const/app';
import { NOTIF_TYPE } from '@src/shared/src/const/app';
// Queries
import { getCurrentBookingId } from '@src/services/checkout/checkout.queries';
// Actions
import { checkoutActions } from '@src/shared/src/actions';
// Models
// Interfaces
// Components
import SupportRequestsForm from '../components/SupportRequestsForm';

const SupportRequestsConn: React.FC = () => {
  const dispatch = useDispatch();

  const handleSubmitAddress = values => {
    dispatch(
      checkoutActions.submitSupportRequestAsync.request({
        onSuccess: () => {
          notify(t('checkoutPrepareConn.notification.thankYou'), NOTIF_TYPE.SUCCESS);
          navigate(ROUTES.HOME);
        },
        onError: cond(baseErrorCond),
        bookingId: getCurrentBookingId(),
        reason: 'request_mail_only_ticket',
        userComment: values.userComment,
      }),
    );
  };

  return <SupportRequestsForm handleSubmitAddress={handleSubmitAddress} />;
};

export default SupportRequestsConn;
