import * as React from 'react';
import { FieldProps } from 'redux-form';
import { ErrorMessageTooltip } from '@src/toolkit/ui';

type Props = FieldProps & {
  id:string;
  label:string;
  icon?:string;
  outerCSS?:string;
  wrapperCSS?:string;
  labelCSS?:string;
  errorMessage?:string;
}

const FormCheckbox:React.FC<Props> = (props:Props) => (
  <div className={props.outerCSS}>
    <div className={props.wrapperCSS}>
      <div className="fac fac-checkbox-o fac-default">
        <span/>
        <input
          {...props.input}
          id={props.id}
          type="checkbox" />
        <label className={props.labelCSS} htmlFor={props.id}>
          {props.icon &&
            <i className={props.icon}/>
          }
          {props.label}
        </label>
      </div>
      <ErrorMessageTooltip
        displayMessage={props.meta.touched && props.meta.error}
        errorMessage={props.meta.error}/>
    </div>
  </div>
);

export default FormCheckbox;
