import * as React from 'react';
// import { useState } from 'react';

// Utils
// Constants
import { LOADER_TIMEOUT } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
// Components
// Styles
import './styles/LoaderProgress.scss';

interface IProps {
  finished: boolean;
  duration?: number;
  repeat?: boolean;
  rounded?: boolean;
}

const LoaderProgress: React.FC<IProps> = (props) => {
  return (
    <div className={`tcp-loader-progress ${props.finished ? 'tcp-loader-progress-finished' : ''} ${props.rounded ? 'tcp-loader-progress-rounded' : ''}`} style={{
      animationDuration: (props.duration ? props.duration : LOADER_TIMEOUT.DEFAULT_DURATION) + 'ms',
      animationIterationCount: props.repeat ? 2 : 1
    }}>
      <div className={`tcp-loader-progress-inner`}></div>
    </div>
  );
};

export default LoaderProgress;
