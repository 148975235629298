// Utils
import { getDerivedHook } from '@toolkit/util/state-utils/state.util';
// Constants
// Actions
// Models
import { HotelModel } from '@src/shared/src/models';
// Interfaces
// Subscriptions
import * as hotelSubscriptions from './hotel.subscriptions';
// Queries

export const useAllowanceMatchingHotel = () =>
  getDerivedHook<HotelModel>(hotelSubscriptions.allowanceMatchingHotel);
