import { createSelector } from 'reselect';
import { RootState } from '../interfaces';
import { pluck, flatten } from '../util/general';
// import { IAppSettingsState } from '../reducers';

// import { SettingsModel } from '../models';

export const flightLoyaltyCards = (state:RootState) => state.appSettings.config.flightLoyaltyCards;

export const trainLoyaltyCards = (state:RootState) => state.appSettings.config.trainLoyaltyCards;

export const rentalLoyaltyCards = (state:RootState) => state.appSettings.config.rentalLoyaltyCards;

export const notifications = (state:RootState) => state.appSettings.config.notifications;

export const loyaltyCards = createSelector(
  flightLoyaltyCards,
  trainLoyaltyCards,
  rentalLoyaltyCards,
  (fltCards, trainCards, rentalCards) => (fltCards.concat(trainCards)).concat(rentalCards)
);

export const countries = (state: RootState) => state.appSettings.config.countries;

export const phoneCountryCodes = createSelector(
  countries,
  (countries) => flatten(pluck('phoneCountryCodes')(countries))
)