// Utils
// Constants
// Actions, Models & Interfaces
import {
  SettingsModel,
} from '../models';

export const settingsToSelectOptions = (settings:SettingsModel[], translateFunc?: any) => {
  return settings.map((setting) => ({
    label: translateFunc ? translateFunc(setting.key) : setting.name,
    value: setting.ident,
  }));
};
