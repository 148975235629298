import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  map,
} from 'rxjs/operators';

// Utils
import {
  all,
  isNil,
  jsonConvert,
  mapObjKeysToSnakeCase,
  values,
  pipe,
} from '../util/general';
import {
  apiRequest,
  apiRequestError,
} from '../util/api';
import { getUserFilterAccommodationTypeConstraints, getUserFilterVehiclesConstraints } from '../util/filters';
// Constants
import {
  API_METHOD_TYPE,
  API_URL,
} from '../const/api';
// Actions
import {
  adminUserActions,
  generalActions,
  searchActions,
  usersActions,
  filterActions,
  organizationActions,
} from '../actions';
// Models
import { UserModel, PassengerModel } from '../models';
// Interfaces
import { RootAction } from '../interfaces';

export const saveUserPreferencesEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(usersActions.saveUserPreferencesAsync.request)),
    switchMap((action) =>
    apiRequest(
      `${API_URL.USERS}/${action.payload.userPreferences.userId}`,
      API_METHOD_TYPE.PATCH,
      {
        user: {
          preference_attributes: mapObjKeysToSnakeCase(action.payload.userPreferences),
        },
      }
    )
    .pipe(
      mergeMap((res: any) => {
        const currentUser = jsonConvert.deserializeObject(res.response, UserModel);
        return [
          adminUserActions.setUserProfile(currentUser),
          generalActions.applyExtActionAsync.request({
            callback: action.payload.onSuccess,
            param: currentUser,
          }),
        ];
      }),
      catchError((err) => apiRequestError(action.payload.onError, 'SaveUserPreferencesError', err))
    )
  )
);

export const createGuestUserEpic:Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(usersActions.createGuestUserAsync.request)),
    switchMap((action) => {
      const guestUserLCData = {
        applies_to: action.payload.appliesTo,
        ident: action.payload.cardIdent,
        number: action.payload.cardNumber,
      };

      return apiRequest(
        `${API_URL.GUEST_USERS}`,
        API_METHOD_TYPE.POST,
        {
          user: {
            first_name: action.payload.firstName,
            last_name: action.payload.lastName,
            title: action.payload.title,
            birthdate_on: action.payload.birthdateOn,
            gender: action.payload.gender,
            loyalty_cards_attributes: pipe(values, all(isNil))(guestUserLCData) ? undefined : [guestUserLCData],
          },
        }
      )
      .pipe(
        mergeMap((res:any) => {
          const guestUser:UserModel = jsonConvert.deserializeObject(res.response, UserModel);
          const guestPassenger:PassengerModel = new PassengerModel();
          guestPassenger.firstName = guestUser.firstName;
          guestPassenger.lastName = guestUser.lastName;
          guestPassenger.userId = guestUser.id;
          return [
            searchActions.setSearchPassengers([...store$.value.search.currentSearch.passengers, guestPassenger]),
            generalActions.applyExtActionAsync.request({ callback: action.payload.onSuccess, param: null }),
          ];
        }),
        catchError((err) => apiRequestError(action.payload.onError, 'CreateGuestUserError', err))
      );
    }
  )
);

export const checkAuthEpic:Epic<RootAction, RootAction> = (action$) =>
action$.pipe(
  filter(isActionOf(usersActions.checkAuthAsync.request)),
  switchMap((action) =>
    apiRequest(API_URL.AUTH_CHECK, API_METHOD_TYPE.POST, {
      client: 'derp',
      email: action.payload.email,
    }).pipe(
      map((res:any) => {
        const redirectURL = res.response.redirect_url;
        return generalActions.applyExtActionAsync.request({
          callback: action.payload.onSuccess, param: redirectURL
        });
      }),
      catchError((err) => apiRequestError(action.payload.onError, 'CheckAuthError', err))
    )
  )
);

export const doLoginEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(usersActions.doLoginAsync.request)),
    switchMap((action) =>
      apiRequest(API_URL.SESSIONS, API_METHOD_TYPE.POST, {
        email: action.payload.email,
        password: action.payload.password,
        remember_me: action.payload.rememberMe,
      }).pipe(
        mergeMap((res:any) => {
          const currentUser = jsonConvert.deserializeObject(res.response.user, UserModel);
          if (action.payload.onSuccess) {
            action.payload.onSuccess(currentUser);
          }
          return [
            adminUserActions.setUserProfile(currentUser),
            organizationActions.setOrganization(currentUser.organization),
            filterActions.setTripTransportationUserFilter(
              getUserFilterVehiclesConstraints(currentUser)
            ),
            filterActions.setHotelAccommodationUserFilter({
              current: getUserFilterAccommodationTypeConstraints(currentUser)
            }),
          ];
        }),
        catchError((err) => apiRequestError(action.payload.onError, 'DoLoginError', err))
      )
    )
  );

export const doLogoutEpic:Epic<RootAction, RootAction> =  (action$) =>
  action$.pipe(
    filter(isActionOf(usersActions.doLogoutAsync.request)),
    switchMap((action) =>
      apiRequest(API_URL.SESSIONS, API_METHOD_TYPE.DELETE)
      .pipe(
        mergeMap(() =>
          [
            generalActions.applyExtActionAsync.request({ callback: action.payload.onSuccess, param: '' }),
            adminUserActions.clearAdminUser(),
          ]
        ),
        catchError((err) => apiRequestError(action.payload.onError, 'DoLogoutError', err))
      )
    )
  );

export const requestResetPasswordEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(usersActions.requestResetPasswordAsync.request)),
    switchMap((action) =>
      apiRequest(API_URL.USER_PASSWORD_RESET, API_METHOD_TYPE.POST, {
        user: {
          email: action.payload.email,
        },
      })
      .pipe(
        map(() => generalActions.applyExtActionAsync.request({callback: action.payload.onSuccess, param: null})
        ),
        catchError((err) => apiRequestError(action.payload.onError, 'RequestResetPasswordError', err))
      )
    )
  );

export const doResetPasswordEpic:Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(usersActions.doResetPasswordAsync.request)),
    switchMap((action) =>
      apiRequest(API_URL.USER_PASSWORD_RESET, API_METHOD_TYPE.PUT, {
        user: {
          password: action.payload.password,
          password_reset_token: action.payload.password_reset_token,
        },
      })
      .pipe(
        map(() => generalActions.applyExtActionAsync.request({callback: action.payload.onSuccess, param: null})
        ),
        catchError((err) => apiRequestError(action.payload.onError, 'DoResetPasswordError', err))
      )
    )
  );
