import { ACCOMMODATION_TYPES } from '@src/shared/src/const/app';
import { formatDate } from '@src/shared/src/util/date';
import * as React from 'react';
import { t } from '@toolkit/util/i18n';

type Props = {
  refundableUntil: Date;
  conditions: string;
  accommodationType: ACCOMMODATION_TYPES;
  deepLink: string;
}

export const HotelFareRefundableUntil: React.FC<Props> = ({ refundableUntil, conditions, accommodationType, deepLink }) => {
  if (refundableUntil) {
    return (
      <span className="is-font-small" data-rh={conditions} data-rh-at="left">
        {`${t('hotelFare.tbody.until')} ${formatDate(refundableUntil)}`}
      </span>
    )
  }

  if (accommodationType === ACCOMMODATION_TYPES.VACATION_RENTAL) {
    return (
      <div data-rh={t('hotel-fare-refundable-until.tooltip.airbnb-check')} data-rh-at="left">
        <a href={deepLink} target="_blank">{t('hotel-fare-refundable-until.text.deep-link')}&nbsp;<i className="icon-external_link"/></a>
      </div>
    )
  }

  return (
    <span className="is-font-small">{t('hotelFare.tbody.notCancellable')}</span>
  )
};
