import { JsonObject, JsonProperty } from 'json2typescript';

import EventModel from './EventModel';

@JsonObject('EventListingModel')
export default class EventListingModel {
  @JsonProperty('id', Number) public id: number = undefined;
  @JsonProperty('events_status', String) public eventsStatus: string = undefined;
  @JsonProperty('events', [EventModel]) public events: EventModel[] = [];
}
