import { JsonObject, JsonProperty } from 'json2typescript';
import {
  DateConverter,
  CeilConverter,
  NullToArrayConverter,
  NumberToStringConverter,
  VehicleConverter,
} from './converters';

import AggregatorModel from './AggregatorModel';
import SeatMapModel from './SeatMapModel';
import SegmentSeatAssignment from './SegmentSeatAssignment';

@JsonObject('TransportSegmentModel')
export default class TransportSegmentModel {
  @JsonProperty('id', NumberToStringConverter) public id:number = undefined;
  @JsonProperty('duration', CeilConverter) public duration:number = -1;
  @JsonProperty('changeovers', Number) public changeOvers:number = -1;
  @JsonProperty('aggregator', AggregatorModel, true) public aggregator:AggregatorModel = undefined;
  @JsonProperty('dep_name', String) public depName:string = '';
  @JsonProperty('arr_name', String) public arrName:string = '';
  @JsonProperty('dep_at', DateConverter) public depAt:Date = undefined;
  @JsonProperty('arr_at', DateConverter) public arrAt:Date = undefined;
  @JsonProperty('operators', NullToArrayConverter) public operators:string[] = [];
  @JsonProperty('suppliers', [String]) public suppliers:string[] = [];
  @JsonProperty('products', [String]) public products:string[] = [];
  @JsonProperty('vehicles', VehicleConverter) public vehicles:string[] = [];
  @JsonProperty('icon_url', String, true) public iconURL:string = undefined;
  @JsonProperty('dep_platform', String, true) public depPlatform:string = undefined;
  @JsonProperty('arr_platform', String, true) public arrPlatform:string = undefined;
  @JsonProperty('seat_maps', [SeatMapModel], true) public seatMaps:SeatMapModel[] = [];
  @JsonProperty('segments', [TransportSegmentModel], true) public segments:TransportSegmentModel[] = [];
  @JsonProperty('seat_assignments', [SegmentSeatAssignment], true) public seatAssignments:SegmentSeatAssignment[] = [];
}
