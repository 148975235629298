import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
import { findBySelected, prop } from '@src/shared/src/util/general';
// Constants
import { ACCOMMODATION_TYPES, ENVIRONMENT, STATUS } from '@src/shared/src/const/app';
// Actions
import { setUiMobileNoScrolling } from '@pod/ui/actions';
import { IRootState, ConnectedRedux } from '@src/store';
// Models
import { HotelModel } from '@src/shared/src/models';
// Interfaces
// Components
import HotelMainImage from './HotelMainImage';
import HotelTitle from './HotelTitle';
import HotelFavoriteButton from './HotelFavoriteButton';
import { HotelRating } from './HotelRating';
import HotelDetails from './HotelDetails';
import { Navbar} from '@toolkit/ui';
import { BookedHotelItemInfo } from '@src/pod/checkout/components';
import { HotelPrice } from './HotelPrice';

// Styles
import '../styles/Hotel.scss';

type Props = ConnectedRedux<IRootState> & AppContextProp & {
  hotel:HotelModel;
  hotelRoomCount?:number;
  env:ENVIRONMENT;
  detailsOpened?:boolean;
  isSelectingHotel?:boolean;
  onFetchDetail: (hotelId:string) => void;
  onSelectHotel:(hotelFareIds:string[]) => void;
  onCloseDetail:(hotelId:string) => void;
};
type State = {
  showDetails:boolean;
};

class Hotel extends React.PureComponent<Props, State> {
  readonly state:State = {
    showDetails: this.props.detailsOpened || false,
  };

  static getDerivedStateFromProps(nextProps:Props) {
    if (nextProps.hotel.detailsStatus === STATUS.FAILED) {
      return {
        showDetails: false,
      };
    }
    return null;
  }

  private toggleDetails = () => {
    if (!this.state.showDetails) {
      this.props.onFetchDetail(this.props.hotel.id);
      this.props.dispatch(setUiMobileNoScrolling(true))
    } else {
      this.props.onCloseDetail(this.props.hotel.id);
      this.props.dispatch(setUiMobileNoScrolling(false))
    }
    this.setState({ showDetails: !this.state.showDetails });
  }

  private getHotelSelectionMarkup = () => {
    const { hotel, env } = this.props;

    if (hotel.detailsStatus === STATUS.FAILED) {
      return (
          <div className="tcp-hotel-select tcp-hotel-select-not-available">
            <i className="icon-disabled"/>{t('hotel.text.unavailable')}
          </div>
      );
    } else {
      return (
          <div className="tcp-hotel-select">
            <div className="tcp-hotel-select-price">
              <HotelPrice
                price={env === ENVIRONMENT.SEARCH ? hotel.price : prop('price', findBySelected(hotel.fares))}
                env={env}
                pricePerNight={hotel.pricePerRoomNight}
                accommodationType={hotel.accommodationType}
                currencyChanged={hotel.currency !== hotel.originalCurrency}/>
              {hotel.negotiatedRates && <i data-rh={t('hotel.tooltip.negotiatedRates')} className="icon-monetization_on"/>}
              {hotel.orgFavorited && <i data-rh={t('hotel.tooltip.orgFavorite')} className="icon-star_circle"/>}
            </div>
            <button
              className={`button ${this.state.showDetails ? 'button-light' : ''}`}
              onClick={this.toggleDetails}>
              {this.state.showDetails ?
                <span>
                  {t('global.close_details')}
                  <i className="icon-arrow_upward"/>
                </span>
                :
                <span>
                  {t('global.view_details')}
                  <i className="icon-arrow_downward"/>
                </span>
              }
            </button>
          </div>
      );
    }
  }


  public render() {
    const { hotel, env, appContext } = this.props;

    return (
      <div className={`tcp-hotel ${this.state.showDetails ? 'tcp-hotel-is-open-details' : ''}`} onClick={!appContext.isMediaBPNotMobile && !this.state.showDetails ? () => this.toggleDetails() : null}>
       {!appContext.isMediaBPNotMobile && this.state.showDetails &&
          <Navbar actionRight={() => this.toggleDetails()} iconRight="icon-close2">
            {t('trip.button.details')}
          </Navbar> }
        <div className="tcp-hotel-inner">
          <div className="tcp-hotel-image">
            <HotelMainImage
              primaryImageURL={hotel.primaryPictureURL}
              pictureURLs={hotel.pictureURLs}/>
          </div>
          <div className="tcp-hotel-info">
            <HotelTitle
              name={hotel.name}
              negotiatedRates={hotel.negotiatedRates}
              distance={`${hotel.distance} km`}
              comfortClass={hotel.comfortClass}
              address={hotel.address}
              env={env}/>
              <div className="tcp-hotel-footer">
              {hotel.accommodationType == ACCOMMODATION_TYPES.VACATION_RENTAL  &&
                <div className="tcp-hotel-airbnb">
                  <i className="icon-airbnb"></i>
                </div>
              }
                <HotelRating
                  numReviews={hotel.numReviews}
                  rating={hotel.originalUserRating}
                  scale={hotel.originalUserRatingScale}/>
                <HotelFavoriteButton
                  hotelId={hotel.id}
                  isFavorite={hotel.favorited} />
                {!appContext.isMediaBPNotMobile &&
                  <div className="tcp-hotel-footer-price">
                    <HotelPrice
                      price={env === ENVIRONMENT.SEARCH ? hotel.price : prop('price', findBySelected(hotel.fares))}
                      env={env}
                      accommodationType={hotel.accommodationType}
                      currencyChanged={hotel.currency !== hotel.originalCurrency}/>
                  </div>
                }
              </div>
          </div>
          {appContext.isMediaBPNotMobile && this.getHotelSelectionMarkup()}
        </div>
        {!appContext.isMediaBPNotMobile ?
        <BookedHotelItemInfo hotel={hotel} env={env} />
         : ''}
        {this.state.showDetails && <div>
          <HotelDetails
            hotel={hotel}
            env={env}
            isSelectingHotel={this.props.isSelectingHotel}
            hotelRoomCount={this.props.hotelRoomCount}
            onSelectHotel={this.props.onSelectHotel}/>
          </div>
        }
      </div>
    );
  }
}

export default connect()(withAppContext(Hotel));
