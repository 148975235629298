// Utils
import { map, filter, partial, isNil } from '../util/general';
// Constants
// Actions, Models & Interfaces
import { HotelModel } from '../models';
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';
import { CurrentFilterConstraint } from '../interfaces';

export type HotelRatingFilterConstraint = {
  min: number;
  max: number;
  currentMin: number;
  currentMax: number;
};

export default class HotelRatingFilter implements IFilter<HotelModel, HotelRatingFilterConstraint> {
  private constructor() {}

  private static instance: HotelRatingFilter;

  static getInstance = () => {
    if (!HotelRatingFilter.instance) {
      HotelRatingFilter.instance = new HotelRatingFilter();
    }
    return HotelRatingFilter.instance;
  };

  public id: string = 'HotelRatingFilter';

  public getConstraints = (
    opts: ConstraintsOpts<HotelModel, HotelRatingFilterConstraint, CurrentFilterConstraint<number>>,
  ): HotelRatingFilterConstraint => {
    const ratings = map((hotel: HotelModel) => hotel.userRating, opts.values);
    const minRating = Math.min(...ratings);
    const maxRating = Math.max(...ratings);

    if ((opts.reinit && !opts.onlyUpdateConstraint) || isNil(opts.userConstraint.current)) {
      return {
        min: minRating,
        max: maxRating,
        currentMin: minRating,
        currentMax: maxRating,
      };
    }

    return {
      min: minRating,
      max: maxRating,
      currentMin: opts.userConstraint.current,
      currentMax: maxRating,
    };
  };

  public process = (constraint: HotelRatingFilterConstraint, hotels: HotelModel[]) =>
    filter(partial(this.match, [constraint]), hotels);

  private match = (constraint: HotelRatingFilterConstraint, hotel: HotelModel) =>
    hotel.userRating >= constraint.currentMin && hotel.userRating <= constraint.currentMax;
}
