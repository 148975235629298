import { JsonObject, JsonProperty } from 'json2typescript';
import { UpperCaseConverter } from './converters';

@JsonObject('AggregatorModel')
export default class AggregatorModel {
  @JsonProperty('name', String, true) public name:string = '';
  @JsonProperty('vehicle', UpperCaseConverter, true) public vehicle:string = '';
  @JsonProperty('ident', String, true) ident: string = undefined;
  @JsonProperty('effective_ident', String, true) effectiveIdent: string = '';
}
