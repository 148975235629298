import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models & Interfaces
// Components
// Styles
// Images

type Props = {
  showHotelsList: boolean;
  toggleDisplayHotelList(): void;
  filterOverlayOpen: boolean;
  toggleMobileFilterOverlay(): void;
};

export const HotelMobileFilterButtons: React.FC<Props> = ({
  toggleDisplayHotelList,
  showHotelsList,
  toggleMobileFilterOverlay,
  filterOverlayOpen,
}) => {
  return (
    <div className="content-filter-buttons">
      <button className="button" onClick={toggleDisplayHotelList}>
        {showHotelsList && <i className="icon-to" />}
        {t(`HotelsConn.header.mobileLabel.${showHotelsList ? 'showMap' : 'showList'}`)}
      </button>
      <button className="button" onClick={toggleMobileFilterOverlay}>
        <i className="icon-filter_list" />
        {t(`tripsConn.button.${filterOverlayOpen ? 'close' : 'filter'}`)}
      </button>
    </div>
  );
};
