import { JsonObject, JsonProperty } from 'json2typescript';

import { NameIdModel } from './NameIdModel';

@JsonObject('PassengerModel')
export default class PassengerModel {
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('user_id', Number) public userId:number = -1;
  @JsonProperty('first_name', String) public firstName:string = '';
  @JsonProperty('middle_name', String) public middleName:string = '';
  @JsonProperty('last_name', String) public lastName:string = '';
  @JsonProperty('is_first', Boolean) public isFirst:boolean = undefined;
  // TODO: Make role mandatory when BE always return it
  @JsonProperty('role', String, true) public role:string = '';
  @JsonProperty('cost_center_required', Boolean, true) public costCenterRequired: boolean = undefined;
  @JsonProperty('cost_unit_required', Boolean, true) public costUnitRequired: boolean = undefined;
  @JsonProperty('purpose_of_trip_required', Boolean, true) public purposeOfTripRequired: boolean = undefined;
  @JsonProperty('reference_code_required', Boolean, true) public referenceCodeRequired: boolean = undefined;
  @JsonProperty('require_justification_for_policy_violation', Boolean, true) public requireJustificationForPolicyViolation: boolean = undefined;
  @JsonProperty('membership_cards', [String], true) public membershipCards: string[] = [];
  @JsonProperty('accounting_invoice_profiles', [NameIdModel], true) public accountingInvoiceProfiles: NameIdModel[] = [];
}
