import * as React from 'react';

// Utils
import { findIndexById, take } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
import { INITIAL_SEARCH_LIST } from '@toolkit/const/app';
// Actions
// Models
// Interfaces
// Components
// Styles
import './styles/ScrollList.scss';

type Props = {
  items: any[];
  render: (item) => React.ReactNode;
  targetItemId?: string;
  fetchDetails?: (itemId: string) => void;
  onShowMore?: (itemCount: number) => void;
};

const ScrollList: React.FC<Props> = ({ items, render, targetItemId, fetchDetails, onShowMore }) => {
  const [shownItemsLength, setShownItemsLength] = React.useState<number>(INITIAL_SEARCH_LIST.NUM);
  const targetItemRef = React.useRef(null);

  // show results all the way down to the target item
  React.useEffect(() => {
    if (targetItemId && targetItemId !== '-1') {
      const targetItemIdx: number = findIndexById(targetItemId)(items);
      if (targetItemIdx !== -1) {
        fetchDetails?.(items[targetItemIdx].id);
        if (targetItemIdx > INITIAL_SEARCH_LIST.NUM) setShownItemsLength(targetItemIdx + 1);
      }
    }
  }, [targetItemId]);

  // Scroll to the target item every time the target item ref changes
  React.useEffect(() => {
    targetItemRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [targetItemRef.current]);

  const handleShowMore = () => {
    const newCount = shownItemsLength + INITIAL_SEARCH_LIST.STEP;
    setShownItemsLength(newCount);
    onShowMore?.(newCount);
  };

  return (
    <>
      <div id="items-list">
        {take(shownItemsLength, items).map((item) => (
          <div key={item.id} ref={item.id === targetItemId ? targetItemRef : null}>
            {render(item)}
          </div>
        ))}
      </div>
      {shownItemsLength < items.length && (
        <div className="lp-show-more-wrapper">
          <div className="lp-show-more-label">
            {t('global.results.label.of', { total: items.length, filtered: shownItemsLength })}
          </div>
          <button className="lp-show-more-btn" onClick={handleShowMore}>
            {t('global.showMore')}
            <i className="icon-arrow_downward" />
          </button>
        </div>
      )}
    </>
  );
};

export default ScrollList;
