import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export default class MissingValueConverter implements JsonCustomConvert<any> {
  public serialize(val:any): any {
    console.log(val);
    return val;
  }

  public deserialize(val:any): any {
    return val;
  }
}