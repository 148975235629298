import * as React from 'react';
// import { useState } from 'react';

// Utils
import { t } from '@toolkit/util/i18n';

// Constants
// Actions, Models & Interfaces
// Components
import { LoaderProgress, Spinner } from '@toolkit/ui';

// Styles
import '../styles/LoaderResults.scss';

interface IProps {
    finished: boolean;
}

const LoaderResults: React.FC<IProps> = (props) => {
    return (
        <div className='loader-results'>
            <div className='loader-results-inner'>
                <Spinner />
                <div>{t('loaderResults.loading')}</div>
            </div>
            <LoaderProgress finished={props.finished} rounded={true} />
        </div>
    );
};

export default LoaderResults;
