import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('SelectedSeatModel')
export default class SelectedSeatModel {
  @JsonProperty('id', Number) public id:number = undefined;
  @JsonProperty('availability', String) public availability:string = undefined;
  @JsonProperty('col', String) public col:string = undefined;
  @JsonProperty('deck', String) public deck:string = undefined;
  @JsonProperty('number', String) public number:string = undefined;
  @JsonProperty('reservation_confirmed', Boolean) public reservationConfirmed:boolean = undefined;
  @JsonProperty('row', Number) public row:number = undefined;
  @JsonProperty('seat_type', String) public seatType:string = undefined;
  @JsonProperty('discounted', Boolean) public discounted:boolean = undefined;
  @JsonProperty('price', Number) public price:number = -1;
  @JsonProperty('display', String) public display:string = undefined;
}
