import * as React from 'react';

// Utils
import { hotelHooks, hotelActions } from '@src/services';
import { t } from '@toolkit/util/i18n';
// Constants
import { ENVIRONMENT } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Components
import Hotel from './Hotel';
// Styles

export const SelectedHotel: React.FC = () => {
  const matchingHotel = hotelHooks.useAllowanceMatchingHotel();

  if (matchingHotel) {
    return (
      <React.Fragment>
        <div className="content-header">
          <h2 className="content-title">{t('BookedHotel.title.bookedHotel')}</h2>
        </div>
        <Hotel
          hotel={matchingHotel}
          env={ENVIRONMENT.SEARCH}
          isSelectingHotel={false}
          detailsOpened={false}
          onCloseDetail={() => {}}
          onFetchDetail={hotelActions.fetchHotelDetails}
          onSelectHotel={(hotelFareIds) => hotelActions.selectHotel(matchingHotel.id, hotelFareIds)}
        />
      </React.Fragment>
    );
  }

  return null;
};
