import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNil, isNilOrEmpty } from '@src/shared/src/util/general';
// Constants
import {
  TERMS_CANCELLATION_STATUS,
  TERMS_REBOOKING_STATUS,
  TERMS_SEAT_STATUS,
} from '@src/shared/src/const/app';
// Actions
// Models
import { TariffFareTermsModel } from '@src/shared/src/models';
// Interfaces
// Components
import { Tooltip } from '@toolkit/ui';
// Styles

type Props = {
  fareTerms: TariffFareTermsModel;
  profileLocale: string;
};

export const TariffPlaneFareTerms: React.FC<Props> = (props: Props) => {
  const { fareTerms } = props;
  const isLocaleDE = props.profileLocale === 'de' || props.profileLocale === 'de-DE';
  const {
    baggage,
    handLuggage,
    deDescription,
    enDescription,
    cancellation,
    deCancellationDescription,
    enCancellationDescription,
    rebooking,
    deRebookingDescription,
    enRebookingDescription,
    seat,
    deSeatDescription,
    enSeatDescription,
  } = fareTerms;

  return (
    <div>
      {!isNilOrEmpty(handLuggage) && (
        <Tooltip
          icon="lp-icon-terms lp-icon-terms-hand-luggage-true"
          alignLeft={true}
          bodyLabel={t('tariffFareTerms.tooltip.title.handLuggage')}
          bodyTextFirst={handLuggage}
        />
      )}
      {!isNil(baggage) && (
        <Tooltip
          icon={`lp-icon-terms lp-icon-terms-baggage-${baggage !== 'NIL' && baggage !== ''}`}
          alignLeft={true}
          bodyLabel={t('tariffFareTerms.tooltip.title.baggage')}
          bodyTextFirst={baggage !== 'NIL' && baggage !== '' ? baggage : '0'}
        />
      )}
      {!isNilOrEmpty(rebooking) && rebooking !== TERMS_REBOOKING_STATUS.REBOOKABLE_UNKNOWN && (
        <Tooltip
          icon={`lp-icon-terms lp-icon-terms-rebook-${
            rebooking === TERMS_REBOOKING_STATUS.REBOOKABLE
          }`}
          alignLeft={true}
          bodyLabel={t('tariffFareTerms.tooltip.title.rebooking')}
          bodyTextFirst={isLocaleDE ? deRebookingDescription : enRebookingDescription}
        />
      )}
      {!isNilOrEmpty(cancellation) &&
        cancellation !== TERMS_CANCELLATION_STATUS.CANCELABLE_UNKNOWN && (
          <Tooltip
            icon={`lp-icon-terms lp-icon-terms-refund-${
              cancellation === TERMS_CANCELLATION_STATUS.CANCELABLE
            }`}
            alignLeft={true}
            bodyLabel={t('tariffFareTerms.tooltip.title.cancellation')}
            bodyTextFirst={isLocaleDE ? deCancellationDescription : enCancellationDescription}
          />
        )}
      {!isNilOrEmpty(seat) && seat !== TERMS_SEAT_STATUS.SEAT_UNKNOWN && (
        <Tooltip
          icon={`lp-icon-terms lp-icon-terms-seating-${seat === TERMS_SEAT_STATUS.SEAT_INCLUDED}`}
          alignLeft={true}
          bodyLabel={t('tariffFareTerms.tooltip.title.seatReservation')}
          bodyTextFirst={isLocaleDE ? deSeatDescription : enSeatDescription}
        />
      )}
      {!isNilOrEmpty(isLocaleDE ? deDescription : enDescription) && (
        <Tooltip
          icon="lp-icon-terms lp-icon-terms-more-default"
          alignLeft={true}
          hovedIcon="lp-icon-terms lp-icon-terms-more-hover"
          bodyLabel={t('tariffFareTerms.tooltip.title.more')}
          bodyTextFirst={isLocaleDE ? deDescription : enDescription}
        />
      )}
    </div>
  );
};
