import * as React from 'react';
import classNames from 'classnames';
require ('./styles/Tooltip.scss');
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';

type Props = AppContextProp & {
  className?:string;
  label?: string;
  labelCSS?: string;
  icon?: string;
  hovedIcon?: string;
  iconStyle?: string;
  bodyLabel?: string;
  bodyTextFirst?: any;
  bodyTextSecond?: string
  alignLeft?: boolean;
  isDisabled?: boolean;
}

interface IState {
  hovered: boolean;
  shift: number;
}

class Tooltip extends React.PureComponent<Props, IState> {
  tooltip: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.tooltip = React.createRef();
  }

  public state: IState = {
    hovered: false,
    shift: 0
  };

  public onMouseEnter = () => {
    if (!this.props.isDisabled) {
      this.setState({hovered: true})
      this.setState({shift: this.getTooltipShift(this.tooltip)});
    }
  }

  public onMouseLeave = () => {
    this.setState({hovered: false});
  }

  public getTooltipShift = (element:React.RefObject<any>) => {
    const DOMRect = element.current.getBoundingClientRect();

    if (!this.props.appContext.isMediaBPNotMobile && this.props.alignLeft && (DOMRect.width + DOMRect.left + 30) > window.innerWidth ) {
      const shift = 280 - (window.innerWidth - DOMRect.left - DOMRect.width);

      return shift;
    }
    return 0;
  }

  public isLarge = () => {
    const bodyTextFirstSize = this.props.bodyTextFirst
      ? this.props.bodyTextFirst && this.props.bodyTextFirst.length > 50 : false;
    const bodyTextSecondSize = this.props.bodyTextSecond
      ? this.props.bodyTextSecond && this.props.bodyTextSecond.length > 50 : false;
    const bodySize = this.props.bodyLabel
      ? this.props.bodyLabel.length > 75 : false;

    return bodyTextFirstSize || bodyTextSecondSize ||  bodySize;
  }

  public render() {
    const bodyClass = classNames('tcp-tooltip-body', {
      'is-large': this.isLarge(),
      'is-open': this.state.hovered,
      'align-left': this.props.alignLeft
    });

    const {
      className,
      labelCSS,
      icon,
      hovedIcon,
      iconStyle,
      label,
      bodyLabel,
      bodyTextFirst,
      bodyTextSecond,
    } = this.props;

    return(
      <div className={`tcp-tooltip ${className || ''}`}
        onMouseOver={this.onMouseEnter}
        onMouseOut={this.onMouseLeave}
        ref={this.tooltip}
        >
        <div className={`tcp-tooltip-header ${labelCSS || ''}`}>
          {icon &&
            <i className={`${(this.state.hovered && hovedIcon) ? hovedIcon : icon} ${ iconStyle || ''}`}/>
          }
          {label}
        </div>

        <div className={bodyClass} style={{left: -this.state.shift || 'auto' }}>
          <div className="tcp-tooltip-tip" style={{left: this.state.shift || 'auto' }}></div>
          {bodyLabel &&
            <span className="tcp-tooltip-body-header">{bodyLabel}</span>
          }
          <div className="tcp-tooltip-body-text">
            {bodyTextFirst &&
              <div>{bodyTextFirst}</div>
            }
            {bodyTextSecond &&
              <div>{bodyTextSecond}</div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default withAppContext(Tooltip);
