import { getTime } from 'date-fns';

// Utils
import { map, path, filter, partial, values } from '../util/general';
import { getTripFirstTS } from '../util/trips';
// Constants
import { TIME_WINDOW_TYPE } from '../const/app';
// Actions
// Models
import { TripModel } from '../models';
// Interfaces
import { RangeFilterConstraint, TimeWindowFilterConstraint } from '../interfaces';
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';

export default class TripDepTimeFilter implements IFilter<TripModel, RangeFilterConstraint, TimeWindowFilterConstraint> {
  private constructor() {}

  private static instance:TripDepTimeFilter;

  static getInstance = () => {
    if (!TripDepTimeFilter.instance) {
      TripDepTimeFilter.instance = new TripDepTimeFilter();
    }
    return TripDepTimeFilter.instance;
  }

  public id:string = 'TripDepTimeFilter';

  public getConstraints = (
    opts:ConstraintsOpts<TripModel, RangeFilterConstraint, TimeWindowFilterConstraint>
  ):RangeFilterConstraint => {
    if (opts.reinit && opts.onlyUpdateConstraint) {
      return opts.constraint;
    }

    const allDepTimes = values(map(
      (trip:TripModel) => getTime(path(['depAt'], getTripFirstTS(trip.legs))), opts.values));

    const calculatedConstraints:RangeFilterConstraint = {
      min: Math.min(...allDepTimes),
      max: Math.max(...allDepTimes),
      currentMin: Math.min(...allDepTimes),
      currentMax: Math.max(...allDepTimes),
    };

    if ((opts.reinit && !opts.onlyUpdateConstraint) || opts.userConstraint.timeWindow === TIME_WINDOW_TYPE.ANYTIME) {
      return calculatedConstraints;
    }

    return {
      ...calculatedConstraints,
      currentMin: opts.userConstraint.currentMin,
      currentMax: opts.userConstraint.currentMax,
    };
  }

  public process = (constraint:RangeFilterConstraint, trips:TripModel[]) =>
    filter(partial(this.match, [constraint]), trips)

  private match = (constraint:RangeFilterConstraint, trip:TripModel) => {
    const tripDepAt = getTime(path(['depAt'], getTripFirstTS(trip.legs)));
    return (tripDepAt >= constraint.currentMin) && (tripDepAt <= constraint.currentMax);
  }
}
