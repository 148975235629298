import { formatDate as customFormatDate } from '@src/shared/src/util/date';
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
import { setISODay, setMonth } from 'date-fns';

// Not Used
const formatDay = (day:number | Date) =>
  customFormatDate(day, DATE_FORMAT_TYPES.DAY_LONG_DATE);

const formatMonthTitle = (date:number | Date) =>
  customFormatDate(date, DATE_FORMAT_TYPES.MONTH_YEAR);

const formatWeekdayShort = (day:number) =>
  customFormatDate(setISODay(new Date(), day), DATE_FORMAT_TYPES.SHORT_DAY_OF_WEEK);

// Not Used
const formatWeekdayLong = (day:number) =>
  customFormatDate(setISODay(new Date(), day), DATE_FORMAT_TYPES.LONG_DAY_OF_WEEK);

// we need the firstDayOfWeek (Monday / Sunday) depending on locale
// which is not provided by date-fns right now
// We always set to Monday for now
const getFirstDayOfWeek = () => 1

const getMonths = () => {
  const months = [];
  let i = 0;
  while (i < 12) {
    months.push(
      customFormatDate(setMonth(new Date(), i), DATE_FORMAT_TYPES.LONG_MONTH)
    );
    i += 1;
  }
  // Typing happiness
  return months as [string, string, string, string, string, string, string, string, string, string, string, string];
}

export default {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
};