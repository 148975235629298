import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNil } from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import '../styles/CheckoutSeatMapLink.scss';

type Props = {
  seatMapSelectorUrl: string;
};

const CheckoutSeatMapLink: React.FC<Props> = props => {
  if (!isNil(props.seatMapSelectorUrl)) {
    return (
      <React.Fragment>
        <a className="lp-checkout-button-link" href={props.seatMapSelectorUrl}>
          <span className="button button-light">
            <i className="icon-seating" />
            &nbsp;{t('checkoutPrepareConn.button.selectSeat')}
          </span>
        </a>
      </React.Fragment>
    );
  }
  return null;
};

export default CheckoutSeatMapLink;
