import * as React from 'react';
import './styles/SidebarRoute.scss';


interface IProps {
  iconClass?: string;
  label?: string;
  hasSpacing?: boolean;
  children: any;
}

export default (props: IProps) => {
  return (
    <div className={`tcp-sidebar-route ${props.hasSpacing ? 'tcp-sidebar-route--spacing' : ''}`}>
      {props.iconClass ?
        <i className={`tcp-sidebar-route-icon ${props.iconClass}
          ${props.label ? 'tcp-sidebar-route-icon-centered' : ''}`} />
        : ''
      }
      <div className="tcp-sidebar-route-title">
        {props.label ?
          <div className="tcp-sidebar-route-title-label">{props.label}</div>
          : ''}
        {props.children}
      </div>
    </div>
  );
};
