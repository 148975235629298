import * as React from 'react';

// Utils
import { navigate, isCurrentPath } from '@toolkit/util/app';
import i18n from '@toolkit/util/i18n';
// Constants
import { ROUTES } from '@toolkit/const/app';
// Components
import HeaderItem from './BreadcrumbItem';
import { useLocation } from 'react-router-dom';

// Styles
import '../styles/Breadcrumbs.scss';

interface IProps {
  searchId?: number;
  isSearchingForOnlyHotel: boolean;
  isSearchingForOnlyOutbound: boolean;
  isSearchingForOnlyRental: boolean;
  isSearchingForOnlyTransport: boolean;
  isBasketEditable: boolean;
  isCurrentUserNotTraveller?: boolean;
}

const Breadcrumbs: React.FC<IProps> = (props) => {
  const {
    isBasketEditable,
    isSearchingForOnlyTransport,
    isSearchingForOnlyHotel,
    isSearchingForOnlyOutbound,
    isSearchingForOnlyRental,
    searchId,
  } = props;

  let location = useLocation();

  const isPathPastPrepare = () =>
    isCurrentPath('/booking/prepare') ||
    isCurrentPath('/booking/payment') ||
    isCurrentPath('/booking/confirm');

  const isInboundHeaderActive: boolean =
    !isPathPastPrepare() &&
    (!isBasketEditable ||
      isCurrentPath(ROUTES.TRIPS.INBOUND) ||
      isCurrentPath(ROUTES.HOTELS) ||
      isCurrentPath('/booking/'));

  const isHotelHeaderActive: boolean =
    !isPathPastPrepare() &&
    (!isBasketEditable || isCurrentPath(ROUTES.HOTELS) || isCurrentPath('/booking/'));

  const isCheckoutHeaderActive: boolean = isBasketEditable && isCurrentPath('/booking/');

  const isOutboundHeaderActive = !isPathPastPrepare();
  let HeaderRoutes =
    isCurrentPath(ROUTES.TRIPS.OUTWARD) ||
    isCurrentPath(ROUTES.TRIPS.INBOUND) ||
    isCurrentPath(ROUTES.HOTELS) ||
    isCurrentPath('/booking/');

  React.useEffect(() => {}, [location]);

  return HeaderRoutes ? (
    <ol className="breadcrumbs">
      {!isSearchingForOnlyHotel && !isSearchingForOnlyRental && (
        <HeaderItem
          label={i18n.t('HeaderComponent.link.outbound')}
          activeClassName="breadcrumbs-item-active"
          isActive={isOutboundHeaderActive}
          onClick={() => navigate(`${ROUTES.TRIPS.OUTWARD}${searchId}`)}
        />
      )}
      {!isSearchingForOnlyHotel && !isSearchingForOnlyOutbound && !isSearchingForOnlyRental && (
        <HeaderItem
          label={i18n.t('HeaderComponent.link.inbound')}
          activeClassName="breadcrumbs-item-active"
          isActive={isInboundHeaderActive}
          onClick={() => navigate(`${ROUTES.TRIPS.INBOUND}${searchId}`)}
        />
      )}
      {!isSearchingForOnlyOutbound && !isSearchingForOnlyTransport && !isSearchingForOnlyRental && (
        <HeaderItem
          label={i18n.t('HeaderComponent.link.hotel')}
          activeClassName="breadcrumbs-item-active"
          isActive={isHotelHeaderActive}
          onClick={() => navigate(`${ROUTES.HOTELS}${searchId}`)}
        />
      )}
      {isSearchingForOnlyRental && (
        <HeaderItem
          label={i18n.t('HeaderComponent.link.rental')}
          activeClassName="breadcrumbs-item-active"
          isActive={false}
          onClick={() => {}}
        />
      )}
      <HeaderItem
        label={i18n.t('HeaderComponent.link.checkout')}
        activeClassName="breadcrumbs-item-active"
        isActive={isCheckoutHeaderActive}
        onClick={() => navigate(`${ROUTES.BOOKING.RESERVE}${searchId}`)}
      />
    </ol>
  ) : (
    <></>
  );
};

export default Breadcrumbs;
