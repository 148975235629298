import { combineEpics } from 'redux-observable';
import * as searchEpics from '@pod/search/epics';
import { epics } from '@src/shared/src';

export const rootEpic = combineEpics(
  epics.generalEpics.applyExtActionEpic,
  epics.generalEpics.serverTimeoutEpic,
  epics.generalEpics.wsStatusCheckEpic,
  epics.searchEpics.startSearchEpic,
  epics.searchEpics.checkSearchExpirationEpic,
  epics.searchEpics.initSearchEpic,
  epics.searchEpics.fetchPassengersSuggestionsEpic,
  epics.searchEpics.fetchEventsEpic,
  epics.tripsEpics.fetchTripsEpic,
  epics.tripsEpics.fetchTripDetailsEpic,
  epics.tripsEpics.changeShuttleTypeEpic,
  epics.tripsEpics.changeTariffEpic,
  epics.tripsEpics.changeShuttleTimeEpic,
  epics.basketEpics.addTariffsToBasketEpic,
  epics.basketEpics.removeItemFromBasketEpic,
  epics.basketEpics.requestSeatReservationEpic,
  epics.hotelsEpics.fetchHotelsEpic,
  epics.hotelsEpics.fetchHotelDetailsEpic,
  epics.hotelsEpics.addHotelFaresToBasketEpic,
  epics.hotelsEpics.setFavoriteHotelEpic,
  epics.adminUserEpics.verifyUserEpic,
  epics.adminUserEpics.addUserIdentityToZendeskEpic,
  epics.adminUserEpics.logoutUserFromZendeskEpic,
  epics.usersEpics.checkAuthEpic,
  epics.usersEpics.doLoginEpic,
  epics.usersEpics.doLogoutEpic,
  epics.usersEpics.requestResetPasswordEpic,
  epics.usersEpics.doResetPasswordEpic,
  epics.usersEpics.saveUserPreferencesEpic,
  epics.usersEpics.createGuestUserEpic,
  epics.appSettingsEpics.fetchAppSettingsEpic,
  epics.filterEpics.initTripFilterEpic,
  epics.filterEpics.applyTripFilterEpic,
  epics.filterEpics.initHotelFilterEpic,
  epics.filterEpics.reinitHotelFilterEpic,
  epics.filterEpics.applyHotelFilterEpic,
  epics.checkoutEpics.prepareBookingEpic,
  epics.checkoutEpics.initBasketEpic,
  epics.checkoutEpics.confirmBookingEpic,
  epics.checkoutEpics.initBookingEpic,
  epics.checkoutEpics.submitSupportRequestEpic,
  epics.generalEpics.wsCallEpic,
  searchEpics.fetchLocationDetailEpic,
  searchEpics.fetchLocationSuggestionsEpic,
);
