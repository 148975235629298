import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions, Models & Interfaces
// Components
import { Button } from '@toolkit/ui';
// Styles

type Props = {
  navToPayment:() => void;
  isBasketPrepared:boolean;
  onCallPrepare:() => void;
}

const CheckoutHeaderOverview:React.FC<Props> = (props) => {
  return (
        <div className="content-header content-header-reverse">
          {props.isBasketPrepared ?
            <Button onClick={props.navToPayment}>
                {t('CheckoutHeader.button.continueToPayment')}
                <i className="icon-arrow_forward"/>
            </Button>
            :
            <Button onClick={props.onCallPrepare}>
                {t('CheckoutHeader.button.reserveBooking')}
                <i className="icon-arrow_forward"/>
            </Button>
          }
          {/* <Button className="button button-light">
            <i className="icon-share" aria-hidden="true"/>
            {t('CheckoutHeader.button.shareWithOthers')}
          </Button> */}
        </div>
  );
};

export default CheckoutHeaderOverview;