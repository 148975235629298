export default class CurrencyFormatter {
  private constructor() {}

  private static instance:CurrencyFormatter;

  private fmtZeroFraction:Intl.NumberFormat;
  private fmtTwoFraction:Intl.NumberFormat;

  private getNumberFormatter = (fractionDigits:number, locale:string) => new Intl.NumberFormat(
    locale,
    {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: fractionDigits,
    }
  )

  private setFmtZeroFraction = (locale:string) =>
    CurrencyFormatter.instance.fmtZeroFraction = this.getNumberFormatter(0, locale)

  private setFmtTwoFraction = (locale:string) =>
    CurrencyFormatter.instance.fmtTwoFraction = this.getNumberFormatter(2, locale)

  static getInstance = (fractionDigits:number) => {
    if (!CurrencyFormatter.instance) {
      CurrencyFormatter.instance = new CurrencyFormatter();
    }
    return fractionDigits === 2 ?
      CurrencyFormatter.instance.fmtTwoFraction : CurrencyFormatter.instance.fmtZeroFraction;
  }

  static initFormatter = (locale:string) => {
    CurrencyFormatter.instance = new CurrencyFormatter();
    CurrencyFormatter.instance.setFmtZeroFraction(locale);
    CurrencyFormatter.instance.setFmtTwoFraction(locale);
  }
}