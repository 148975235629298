import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import {
  navigate,
  notifyError,
} from '@toolkit/util/app';
import {
  T,
  cond,
  equals,
  isNilOrEmpty,
  pathOr,
} from '@src/shared/src/util/general';
import i18n from '@toolkit/util/i18n';
import { t } from '@toolkit/util/i18n';
// Constants
import { API_URL } from '@src/shared/src/const/api';
import { ERRORS } from '@src/shared/src/const/app';
import { ROUTES } from '@toolkit/const/app';
// Actions
import {
  ConnectedRedux,
  IRootState,
} from '@src/store';
// Models, Stores & Interfaces
import { models } from '@src/shared/src';
import { usersActions } from '@src/shared/src/actions';
// Components
import {
  EmailForm,
  PasswordForm,
} from '@pod/admin_user/components';
import { BENotificationBar } from '@toolkit/ui';

type Props = ConnectedRedux<IRootState>;

type State = {
  showEmail: boolean;
  currentEmail: string;
}

class LoginConn extends React.Component<Props, State> {
  readonly state:State = {
    showEmail: true,
    currentEmail: '',
  };

  private checkAuth = (values) => {
    this.setState({ currentEmail: values.email });
    this.props.dispatch(usersActions.checkAuthAsync.request({
      onSuccess: (redirectURL: string) => window.location.replace(redirectURL),
      onError: cond([
        [equals(ERRORS.NOT_FOUND), () => this.setState({ showEmail: false })],
        [T, (err) => notifyError(err)],
      ]),
      email: values.email,
    }))
  }

  private handleLogin = (values) =>
    this.props.dispatch(usersActions.doLoginAsync.request({
      onSuccess: (user:models.UserModel) => {
        const orgAdminPath:string = pathOr(undefined, ['location', 'state', 'orgAdminPath'])(this.props)
        if (!isNilOrEmpty(orgAdminPath)) {
          window.location.replace(`${API_URL.DEFAULT}${orgAdminPath}`);
        } else {
          i18n.changeLanguage(user.preference.effectiveLocale);
          navigate(
            pathOr(ROUTES.SEARCH, ['location', 'state', 'internalPath'])(this.props)
          );
        }
      },
      onError: cond([
        [equals(ERRORS.NOT_FOUND), (err) => notifyError(err, 'Username or password wrong')],
        [equals(ERRORS.UNKNOWN_ERROR), (err) => notifyError(err, t('UNPROCESSABLE_ENTITY'))],
        [T, (err) => notifyError(err)],
      ]),
      email: this.state.currentEmail,
      password: values.password,
      rememberMe: values.remember_me,
    }));

  public render() {
    return (
      <React.Fragment>
        <BENotificationBar currentPage="Login"/>
        <div className="tcp-login">
          <div className="tcp-login-content">
            <span className="tcp-login-logo"></span>
            <h2 className="tcp-login-title">{t('loginForm.title')}</h2>
            <p>{t('loginForm.subtitle')}</p>
            <div className="tcp-login-form">
              {this.state.showEmail
                ? <EmailForm
                    initialValues={
                      {
                        email: this.state.currentEmail,
                      }
                    }
                    onSubmit={this.checkAuth} />
                : <PasswordForm
                    onEditEmail={() => this.setState({ showEmail: true })}
                    onSubmit={this.handleLogin} />
              }
              <button
                className="tcp-login-password-link is-underlined"
                onClick={() => navigate('/auth/forgot_password')}>
                {t('loginForm.link.forgotPassword')}
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default connect()(LoginConn);
