import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('TravelBookingFareExtraModel')
export default class TravelBookingFareExtraModel {
  @JsonProperty('baggage', String)
  public baggage:string = '';
  @JsonProperty('cancellation', String)
  public cancellation:string = '';
  @JsonProperty('hand_luggage', String)
  public handLuggage:string = '';
  @JsonProperty('rebooking', String)
  public rebooking:string = '';
}