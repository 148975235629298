// Utils
import { store } from '@src/store/store.config';
import { pathOr } from '@src/shared/src/util/general';
// Constants
import { DIRECTION } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Subscriptions
// Queries

export const findTripById = (tripId: number, direction: DIRECTION) => {
  switch (direction) {
    case DIRECTION.OUTWARD: return pathOr(null, [`${tripId}`], store.getState().trips.outward);
    case DIRECTION.INBOUND: return pathOr(null, [`${tripId}`], store.getState().trips.inbound);
  }
};
