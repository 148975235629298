import * as React from 'react';
import Select from 'react-select';

// Utils
import {
  formatCents,
  head,
  isNilOrEmpty,
  ifElse
} from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
import { ENVIRONMENT } from '@src/shared/src/const/app';
// Actions
// Models
import {
  TariffModel,
  OptionModel,
} from '@src/shared/src/models';
// Interfaces
// Components
import { Spinner } from '@toolkit/ui';
// Styles
import '../styles/TariffOptions.scss';

type Props = {
  env:ENVIRONMENT;
  tariffs:TariffModel[];
  selectedTariff:TariffModel;
  changeable?:boolean;
  onChangeTariff:(tariffId:string) => void;
};

export default class TariffOptions extends React.Component<Props> {

  handleOptionChange = (option:OptionModel) =>
    Number(option.value) !== this.props.selectedTariff.id
      ? this.props.onChangeTariff(option.value)
      : null

  getTariffPriceDifference = (priceDifference:number) =>
    priceDifference < 0 ? formatCents(priceDifference) : `+${formatCents(priceDifference)}`

  getTariffMarkup = (tariffs:TariffModel[]) =>
    this.props.changeable ?
      <div>
        <strong>{formatCents(head(tariffs).priceDifference)}</strong>&nbsp;
        {t('tariffOptions.label.estimatedPrice')}
      </div>
      :
      <div className="lp-tariff-options">
        <Select
          options={tariffs.map((tariff) => ({
            label: `${this.getTariffPriceDifference(tariff.priceDifference)} - ${tariff.fareName}`,
            value: tariff.id,
            })
          )}
          value={this.props.selectedTariff.id}
          onChange={this.handleOptionChange}
          clearable={false}
          backspaceRemoves={false}
          deleteRemoves={false}
          searchable={false}
          disabled={this.props.env !== ENVIRONMENT.SEARCH}/>
        </div>

  getTariffMarkupLoader = () => <Spinner />;

  renderTariffs = ifElse(
    isNilOrEmpty,
    this.getTariffMarkupLoader,
    this.getTariffMarkup
  );

  render() {
    return this.renderTariffs(this.props.tariffs);
  }
}
