// Utils
import { sortWith, prop, ascend, descend } from '../util/general';
// Constants
// Actions
import { HOTEL_SORT_VALUES } from '../const/app';
// Models
import { HotelModel } from '../models';
// Interfaces
import { SortingFilterConstraint } from '../interfaces';
import IFilter, { ConstraintsOpts } from '../interfaces/IFilter';

export type HotelSortingFilterConstraint = {
  current: string;
};

export default class HotelSortingFilter implements IFilter<HotelModel, SortingFilterConstraint> {
  private constructor() {}

  private static instance: HotelSortingFilter;

  static getInstance = () => {
    if (!HotelSortingFilter.instance) {
      HotelSortingFilter.instance = new HotelSortingFilter();
    }
    return HotelSortingFilter.instance;
  };

  public id: string = 'HotelSortingFilter';

  public getConstraints = (
    opts: ConstraintsOpts<HotelModel, SortingFilterConstraint, SortingFilterConstraint>,
  ): SortingFilterConstraint => {
    if (opts.reinit && opts.onlyUpdateConstraint) {
      return opts.constraint;
    }

    return opts.userConstraint.current
      ? opts.userConstraint
      : { current: HOTEL_SORT_VALUES.RECOMMENDATION_SCORE };
  };

  private sortById = ascend(prop('id'));
  private sortByRecommendation = descend(prop('recommendation'));
  private sortByStars = descend(prop('comfortClass'));
  private sortByTotalPrice = ascend(prop('price'));
  private sortByRatings = descend(prop('userRating'));
  private sortByDistance = ascend(prop('distance'));
  private sortByFavorite = descend(prop('favorited'));
  private sortByNegotiatedRates = descend(prop('negotiatedRates'));
  private sortByOrgFavorite = descend(prop('orgFavorited'));

  public process = (constraint: HotelSortingFilterConstraint, hotels: HotelModel[]) => {
    let sortCriteria;
    switch (constraint.current) {
      case HOTEL_SORT_VALUES.RECOMMENDATION_SCORE:
        sortCriteria = [this.sortByRecommendation, this.sortByTotalPrice];
        break;
      case HOTEL_SORT_VALUES.STARS:
        sortCriteria = [this.sortByStars, this.sortByTotalPrice];
        break;
      case HOTEL_SORT_VALUES.TOTAL_PRICE:
        sortCriteria = [this.sortByTotalPrice, this.sortByRecommendation];
        break;
      case HOTEL_SORT_VALUES.RATING:
        sortCriteria = [this.sortByRatings, this.sortByTotalPrice];
        break;
      case HOTEL_SORT_VALUES.DISTANCE:
        sortCriteria = [this.sortByDistance, this.sortByTotalPrice];
        break;
      default:
        sortCriteria = [];
        break;
    }

    return sortWith([
      this.sortByFavorite,
      this.sortByNegotiatedRates,
      this.sortByOrgFavorite,
      ...sortCriteria,
      this.sortById,
    ])(hotels);
  };
}
