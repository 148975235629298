import { createSelector } from 'reselect';
import { ITripsState } from '../reducers';
import { TripModel } from '../models';
import {
  isNilOrEmpty,
  map,
  prop,
  findById,
  size,
  values,
} from '../util/general';

export const selectedOutbound = (state:ITripsState):TripModel =>
  findById(state.selectedOutboundId)(state.outward);

export const selectedInbound = (state:ITripsState):TripModel =>
  findById(state.selectedInboundId)(state.inbound);

export const cheapestInbound = (state:ITripsState):number => {
  if (!isNilOrEmpty(state.inbound)) {
    const inboundPrices = values(map((trip:TripModel) => prop('baseTotal', trip), state.inbound));
    return Math.min(...inboundPrices);
  }
  return 0;
};

export const outward = (state:ITripsState) => state.outward;

export const inbound = (state:ITripsState) => state.inbound;

export const outwardFilteredIds = (state:ITripsState) => state.outwardFilteredIds;

export const inboundFilteredIds = (state:ITripsState) => state.inboundFilteredIds;

export const outwardFilteredTrips = createSelector(
  outward, outwardFilteredIds,
  (outwardTrips, filteredIds) => map((id:string) => outwardTrips[id], filteredIds)
)

export const inboundFilteredTrips = createSelector(
  inbound, inboundFilteredIds,
  (inboundTrips, filteredIds) => map((id:string) => inboundTrips[id], filteredIds)
)

export const outwardTripsCount = createSelector(
  outward,
  (outwardTrips) => size(outwardTrips)
)

export const inboundTripsCount = createSelector(
  inbound,
  (inboundTrips) => size(inboundTrips)
)

export const outwardFilteredTripsCount = createSelector(
  outwardFilteredIds,
  (outwardFilteredTripIds) => outwardFilteredTripIds.length
)

export const inboundFilteredTripsCount = createSelector(
  inboundFilteredIds,
  (inboundFilteredTripIds) => inboundFilteredTripIds.length
)
