import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import { switchMap, filter, mapTo, catchError } from 'rxjs/operators';

// Utils
import { apiRequest, apiRequestError, apiURL } from '../util/api';
// Constants
import { API_METHOD_TYPE } from '../const/api';
// Actions
import { basketActions, generalActions } from '../actions';
// Models
// Interfaces
import { RootAction, RootState } from '../interfaces';
// Selectors

export const removeItemFromBasketEpic: Epic<RootAction, RootAction, RootState> = (
  action$,
  store$,
) =>
  action$.pipe(
    filter(isActionOf(basketActions.removeItemFromBasketAsync.request)),
    switchMap((action) =>
      apiRequest(
        apiURL.basket.removeItem(store$.value.search.currentSearch.basketId),
        API_METHOD_TYPE.DELETE,
        {
          direction: action.payload.itemType,
        },
      ).pipe(
        mapTo(
          generalActions.applyExtActionAsync.request({
            callback: action.payload.onSuccess,
            param: null,
          }),
        ),
        catchError((err) =>
          apiRequestError(action.payload.onError, 'RemoveItemFromBasketError', err),
        ),
      ),
    ),
  );

export const addTariffsToBasketEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(basketActions.addTripTariffsToBasketAsync.request)),
    switchMap((action) =>
      apiRequest(
        apiURL.basket.addTariffs(store$.value.search.currentSearch.basketId),
        API_METHOD_TYPE.PUT,
        {
          _method: API_METHOD_TYPE.PUT,
          basket: {
            assembly_tariff_ids: action.payload.tariffIds,
          },
        },
      ).pipe(
        mapTo(
          generalActions.applyExtActionAsync.request({
            callback: action.payload.onSuccess,
            param: null,
          }),
        ),
        catchError((err) => apiRequestError(action.payload.onError, 'AddTariffsToBasketError', err)),
      ),
    ),
  );

export const requestSeatReservationEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(basketActions.requestSeatReservationAsync.request)),
    switchMap((action) =>
      apiRequest(
        apiURL.basketItem.setSeatReservation(action.payload.basketItemId),
        API_METHOD_TYPE.PUT,
        {
          basket_item: {
            request_seat_reservation: action.payload.requestSeatReservation,
          },
        },
      ).pipe(
        mapTo(
          generalActions.applyExtActionAsync.request({
            callback: action.payload.onSuccess,
            param: null,
          }),
        ),
        catchError((err) =>
          apiRequestError(action.payload.onError, 'RequestSeatReservationError', err),
        ),
      ),
    ),
  );
