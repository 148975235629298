import { getType } from 'typesafe-actions';

// Utils
// Constants
// Actions
import { AdminUserActions, adminUserActions } from '../actions';
// Models
import { UserModel } from '../models';
// Interfaces
// Components
// Styles

export interface IAdminUserState {
  userId:number;
  token:string;
  profile:UserModel;
}

export const initialState: IAdminUserState = {
  userId: undefined,
  token: undefined,
  profile: undefined,
};

export default (state:IAdminUserState = initialState, action:AdminUserActions) => {
  switch (action.type) {
    case getType(adminUserActions.setUserProfile):
      return { ...state, profile: action.payload };
    case getType(adminUserActions.clearAdminUser):
      return initialState;
    default:
      return state;
  }
};