import * as React from 'react';
import { OverlayView } from '@react-google-maps/api';

// Utils
import { equals } from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
import { CoordinatesModel } from '@src/shared/src/models';
// Components
// Styles
import '../styles/MapMarker.scss';
import { PinIcon } from '@toolkit/ui/icons';

interface IProps {
  position:CoordinatesModel;
  locationLabel:string;
  dateLabel:string;
  isDep:boolean;
}

class MapMarker extends React.Component<IProps>{

  public shouldComponentUpdate(nextProps:IProps) {
    return !equals(nextProps, this.props);
  }

  public render() {

    return (
      <OverlayView position={this.props.position} mapPaneName={OverlayView.FLOAT_PANE}>
        <div className="map-marker">
          <PinIcon />
          <div className="map-marker-box">
            {this.props.dateLabel &&
              <div className="map-marker-date">
              {this.props.dateLabel}
              </div>
            }
            <div className="map-marker-location">{this.props.locationLabel}</div>
          </div>
        </div>
      </OverlayView>);
  }
}

export default MapMarker;
