import { createSelector } from 'reselect';

// Utils
import {
  any,
  equals,
  flatten,
  isNilOrEmpty,
  map,
  path,
  pathOr,
  pipe,
  pluck,
  reduce,
  sortBy,
  isNil,
  filter,
} from '../util/general';
import { getSelectedFares } from '../util/hotels';
import {
  getSelectedTariffsByMainLeg,
  getTripsVechiles,
} from '../util/trips';
// Constants
// Actions
// Models
import {
  BasketItemModel,
  BookingItemModel,
} from '../models';
// Interfaces
import {
  ICheckoutState,
} from '../reducers';
// Constants
import {
  STATUS,
} from '../const/app';
import { RootState } from '../interfaces';
// Selectors

// TODO: use lenses instead of direct access
const sortByDepDate = sortBy(path(['legs', 0, 'legOption', 'transportSegments', 0, 'depAt']));

export const trips = (state:ICheckoutState)  => state.trips;

export const hotels = (state:ICheckoutState) => state.hotels;

export const rentals = (state:ICheckoutState) => state.rentals;

const sortedTrips = createSelector(
  trips,
  (checkoutTrips) =>  isNilOrEmpty(checkoutTrips) ? null : sortByDepDate(checkoutTrips)
);

export const outwardTrip = createSelector(
  sortedTrips,
  (checkoutTrips) => {
    return isNilOrEmpty(checkoutTrips) ? null : checkoutTrips[0];
  }
);

export const inboundTrip = createSelector(
  sortedTrips,
  (checkoutTrips) => (isNilOrEmpty(checkoutTrips) || checkoutTrips.length === 1) ? null : checkoutTrips[1]
);

export const hotel = createSelector(
  hotels,
  (bookedHotels) => isNilOrEmpty(bookedHotels) ? null : bookedHotels[0]
);

export const rental = createSelector(
  rentals,
  (bookedRentals) => isNilOrEmpty(bookedRentals) ? null : bookedRentals[0]
);

export const preparedAt = (state:ICheckoutState):Date =>
  pathOr(0, ['createdAt'], state.travelBooking);

export const totalCost = (state:ICheckoutState):number => {
  const totalTrips = !isNilOrEmpty(state.trips) ?
    reduce(
      (acc:number, next:number) => acc + next, 0, pluck(
        'priceDifference', getSelectedTariffsByMainLeg(state.trips)))
    : 0;
  const totalHotels = !isNilOrEmpty(state.hotels) ?
    reduce(
      (acc:number, next:number) => acc + next, 0, pluck(
        'price', getSelectedFares(state.hotels)))
    : 0;

  return totalTrips + totalHotels;
};

export const costCenter = (state:ICheckoutState) => pathOr(null, ['costCenter'], state.travelBooking);

export const costUnit = (state:ICheckoutState) => pathOr(null, ['costUnit'], state.travelBooking);

export const referenceCode = (state:ICheckoutState) => pathOr(null, ['referenceCode'], state.travelBooking);

export const purposeOfTrip = (state:ICheckoutState) => pathOr(null, ['purposeOfTrip'], state.travelBooking);

export const remarks = (state:ICheckoutState) => pathOr(null, ['remarks'], state.travelBooking);

// --Basket--
export const basket = (state:ICheckoutState) => state.basket;

export const basketItems = (state:ICheckoutState):BasketItemModel[] => state.basketItems

export const currentBookingItems = (state:ICheckoutState):BookingItemModel[] => state.currentBookingItems

export const isBasketEditable = createSelector(basket, (currBasket) => currBasket ? currBasket.editable : false);

export const inboundShuttleAtArrival = createSelector(basket, (currBasket) => currBasket.inboundShuttleAtArrival);

export const inboundShuttleAtDeparture = createSelector(basket, (currBasket) => currBasket.inboundShuttleAtDeparture);

export const outwardShuttleAtArrival = createSelector(basket, (currBasket) => currBasket.outwardShuttleAtArrival);

export const outwardShuttleAtDeparture = createSelector(basket, (currBasket) => currBasket.outwardShuttleAtDeparture);

export const hasConfirmFailedItems = (state:ICheckoutState):boolean =>
  any(
    equals(STATUS.CONFIRM_FAILED),
    map(pathOr(null, ['travelBookingItem', 'status']))(state.basketItems)
  );

export const hasConfirmPendingItems = (state:ICheckoutState):boolean =>
  any(
    equals(STATUS.CONFIRM_PENDING),
    map(pathOr(null, ['travelBookingItem', 'status']))(state.basketItems)
  );

export const hasHardFailedItems = (state:ICheckoutState):boolean =>
  any(
    equals(true),
    map(pathOr(null, ['hardFail']))(state.basketItems)
  );

export const policyViolations = createSelector(currentBookingItems, basket, (bookingItems, basket) => {
  if (basket.editable) {
    return basket.travelPolicyViolations
  }
  return pipe
    (
      map((bookingItem:BookingItemModel) => bookingItem.policyViolations),
      filter((val:any) => !isNil(val)),
      flatten,
    )(bookingItems);
})

export const basketTripsVehicles = createSelector(
  trips,
  (basketTrips) => getTripsVechiles(basketTrips)
);

export const bookingItems = (state: RootState) => state.checkout.currentBookingItems;

export const booking = (state: RootState) => state.checkout.currentBooking;

export const basketStatus = (state: RootState) => state.checkout.basket?.status

export const isBookingConfirmFinished = createSelector(
  booking,
  (booking) => booking?.status === STATUS.CONFIRM_FINISHED
)

export const isAnyBookingItemAirbnb = createSelector(
  bookingItems,
  (bookingItems) => bookingItems.some(item => item.aggregator?.ident === 'airbnb')
)

