import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNilOrEmpty } from '@src/shared/src/util/general';
// Constants
// Actions
// Models
import { EventListingModel, EventModel } from '@src/shared/src/models';
import { formatDate } from '@src/shared/src/util/date';
// Interfaces
// Components
import { WarningBanner } from '@toolkit/ui';
// Styles

type Props = {
  eventListing: EventListingModel;
};

export const SearchEvents: React.FC<Props> = props => {
  if (props.eventListing?.events.length > 0) {
    return (
      <WarningBanner
        icon="lp-icon-event"
        isInfo={true}
        showCloseBtn={true}
        label={
          <div>
            <h3>{t('searchEvents.events.title')}</h3>
            <br/>
            {props.eventListing.events.map((event: EventModel) => {
              return (
                <div key={event.id}>
                  <p>
                    {formatDate(event.startOn)} - {formatDate(event.endOn)}: &nbsp;
                    <strong>{event.name}</strong>
                    {`${isNilOrEmpty(event.description) ? '' : ` - (${event.description}) `}`}
                  </p>
                </div>
              );
            })}
          </div>
        }
      />
    );
  } else {
    return null;
  }
};
