import { JsonConverter, JsonCustomConvert } from 'json2typescript';

@JsonConverter
export default class CeilConverter implements JsonCustomConvert<number> {
  public serialize(val:number): any {
    return val;
  }

  public deserialize(val:number): number {
    return Math.ceil(val);
  }
}