import * as React from 'react';
import classNames from 'classnames';
import { getTime } from 'date-fns';

// Utils
import { getUTCDate } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import '../styles/CheckoutTimer.scss';

interface IProps {
  isPreparing?:boolean;
  bookingCreatedAt:Date;
  onTimerExpired: () => void;
}
interface IState {
  currentTimeStr:string;
  currentTime:Date;
  timeDiff:number;
}
export default class CheckoutTimer extends React.Component<IProps, {}> {
  private timeInterval;
  public state:IState = {
    currentTimeStr: '09:59',
    currentTime: getUTCDate(),
    timeDiff: 1,
  };

  public componentDidMount() {
    this.timeInterval = setInterval(this.updateCurrentTimeStr, 1000);
  }

  public componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  private updateCurrentTimeStr = () => {
    const timeDiff = 600000 - (
      getTime(this.state.currentTime) - getTime(this.props.bookingCreatedAt)
    );
    if (timeDiff <= 0) {
      clearInterval(this.timeInterval);
      this.props.onTimerExpired();
    } else {
      this.setState({
        currentTimeStr: new Date(timeDiff).toISOString().substr(14, 5),
        currentTime: getUTCDate(),
        timeDiff,
      });
    }
  }

  private getTimerMarkup = (isPreparing: boolean) => {
    if (!isPreparing) {
      return (
        this.props.bookingCreatedAt ?
          (this.state.timeDiff > 0 ? this.state.currentTimeStr : '00:00')
          :
          '09:59'
      );
    } else {
      return '09:59';
    }
  }

  public render() {
    const mainClass = classNames('tcp-checkout-timer', {
      'tcp-checkout-timer-danger': (this.state.timeDiff < 60000),
    });
    return (
      <div className={mainClass}>
        {/* TODO: Refactor to <Interpolate> and/or <Trans> */}
        <span className="tcp-checkout-timer-caption">
          {t('checkoutTimer.text.selectionReserved')}:
        </span>
        <span className="tcp-checkout-timer-clock">
          {` ${this.getTimerMarkup(false)} `}
          {t('checkoutTimer.text.minutes')}
        </span>
      </div>
    );
  }
}
