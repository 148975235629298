import { JsonObject, JsonProperty } from 'json2typescript';
import SettingsModel from './SettingsModel';

@JsonObject('AppSettingsUserFlightSeatPreferencesModel')
export default class AppSettingsUserFlightSeatPreferencesModel {
  @JsonProperty('bulkhead_seat', [SettingsModel])
  public bulkHeadSeat:SettingsModel[] = [];
  @JsonProperty('priority_1', [SettingsModel])
  public priority1:SettingsModel[] = [];
  @JsonProperty('priority_2', [SettingsModel])
  public priority2:SettingsModel[] = [];
  @JsonProperty('proximity_to_emergency_exit', [SettingsModel])
  public proximityToEmergencyExit:SettingsModel[] = [];
  @JsonProperty('seat_close_to_rest_room', [SettingsModel])
  public seatCloseToRestroom:SettingsModel[] = [];
}