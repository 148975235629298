import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './converters';
import TransportSegmentModel from './TransportSegmentModel';

@JsonObject('BookingTransportFareModel')
export default class BookingTransportFareModel {
  @JsonProperty('id', Number) public id:number = undefined;
  @JsonProperty('created_at', DateConverter) public createdAt:Date = undefined;
  @JsonProperty('name', String) public name:string = undefined;
  @JsonProperty('price', Number) public price:number = undefined;
  @JsonProperty('previous_price', Number) public previousPrice:number = undefined;
  @JsonProperty('seat_reservation', String) public seatReservation:string = undefined;
  @JsonProperty('seat_reservation_price', Number) public seatReservationPrice:number = undefined;
  @JsonProperty('segment', TransportSegmentModel) public segment:TransportSegmentModel= undefined;
}