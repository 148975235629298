import { getType } from 'typesafe-actions';

// Utils
import {
  findIndexById,
  update,
  unionWith,
  eqBy,
  prop,
} from '../util/general';
// Constants
// Actions
import { HotelActions, hotelActions } from '../actions';
// Models
import { HotelModel } from '../models';
// Interfaces

export interface IHotelState {
  hotels: HotelModel[];
  filteredHotels: HotelModel[];
  selectedHotelId: string;
  lastWSUpdated: number;
  allowanceMatchingHotelIds: number[];
}

const initialState: IHotelState = {
  hotels: [],
  filteredHotels: [],
  selectedHotelId: undefined,
  lastWSUpdated: -1,
  allowanceMatchingHotelIds: []
};

export default (state: IHotelState = initialState, action: HotelActions) => {
  let hotelIndex;
  switch (action.type) {
    case getType(hotelActions.addHotel):
      hotelIndex = findIndexById(action.payload.id)(state.hotels);
      return {
        ...state,
        hotels: hotelIndex === -1 ?
          [...state.hotels, action.payload] : update(hotelIndex, action.payload, state.hotels),
      };
    case getType(hotelActions.addFilteredHotel):
      hotelIndex = findIndexById(action.payload.id)(state.filteredHotels);
      return {
        ...state,
        filteredHotels: hotelIndex === -1 ?
          [...state.filteredHotels, action.payload] : update(hotelIndex, action.payload, state.filteredHotels),
      };
    case getType(hotelActions.setFilteredHotels): return { ...state, filteredHotels: action.payload };
    case getType(hotelActions.setHotels): return { ...state, hotels: action.payload };
    case getType(hotelActions.appendHotels):
      return {
        ...state,
        hotels: unionWith(
          eqBy(prop('id')),
          action.payload,
          state.hotels
        ),
      };
    case getType(hotelActions.setExpanded):
      hotelIndex = findIndexById(action.payload.hotelId)(state.hotels);
      return {
        ...state,
        hotels: {
          ...state.hotels,
          [hotelIndex]: {
            ...state.hotels[hotelIndex],
            expanded: action.payload.expanded,
          },
        },
      };
    case getType(hotelActions.setSelectedHotelId): return { ...state, selectedHotelId: action.payload };
    case getType(hotelActions.setLastWSUpdated):
      // console.log(`lastWSUpdated Hotel: ${action.payload}`)
      return { ...state, lastWSUpdated: action.payload };
    case getType(hotelActions.setAllowanceMatchingHotelIds):
      return { ...state, allowanceMatchingHotelIds: action.payload }
    case getType(hotelActions.clearHotels): return initialState;
    default: return state;
  }
};
