import * as React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { isNotNil, formatCents } from '@src/shared/src/util/general';
import { formatDate } from '@src/shared/src/util/date';
import { t } from '@toolkit/util/i18n';
import { FARE_PAYMENT_TYPE } from '@src/util/const';
import { isEnvSearch } from '@src/util/app';
// Constants
import { ENVIRONMENT } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { HotelFareModel } from '@src/shared/src/models';
// Components
import { Button } from '@toolkit/ui';
import HotelRoomDetails from './HotelRoomDetails';
// Styles
import '../styles/HotelFareMobile.scss';
// Store
import { prepayHotelRatesFilterEnabled } from '@src/store/selectors';

type Props = {
  fares: HotelFareModel[];
  env: ENVIRONMENT;
  onSelectHotel?: (hotelFareIds: string[]) => void;
};

const HotelFareSM: React.FC<Props> = props => {
  const isPrepayHotelRatesFilterEnabled = useSelector(prepayHotelRatesFilterEnabled)
  const fares = isEnvSearch(props.env) ? props.fares : props.fares.filter(fare => fare.selected);

  const renderSelectionBtn = (fareId: string, farePaymentType: string) => {
    if (isPrepayHotelRatesFilterEnabled && farePaymentType === FARE_PAYMENT_TYPE.PREPAY) {
      return <Button
        className="button"
        data-rh={t('hotelFare.tooltip.prepayHotelRatesDisabled')}
        data-rh-at="right"
        disabled={true}>
        <i className="icon-info_outline" />&nbsp;{t('global.deactivated')}
      </Button>
    }
    else if (isEnvSearch(props.env)) {
      return <div className="tcp-hotel-fare-mobile-select">
        <Button
          className="button"
          onClick={() => props.onSelectHotel([fareId])}>
          {t('global.select')}
        </Button>
      </div>
    }
  }

  return (
    <div>
      <div className="tcp-hotel-details-title">{t('hotelFareSM.faresTitle')}</div>
      {fares.map((fare: HotelFareModel) => (
        <div key={fare.id} className="tcp-hotel-fare-mobile">
          <div className="tcp-hotel-fare-mobile-class">{fare.comfortClass}</div>
          <div className="tcp-hotel-fare-mobile-info">
            <div>
              <label className="tcp-hotel-fare-mobile-info-label">
                {t('hotelFare.thead.roomType')}
              </label>
              {fare.roomType}
            </div>
            <div>
              <label className="tcp-hotel-fare-mobile-info-label">&nbsp;</label>
              <strong
                data-rh={
                  fare.provider
                    ? `${t('hotelFare.cell.price.provider.via')} ${fare.provider}`
                    : undefined
                }>
                {formatCents(fare.price)}
              </strong>
            </div>
          </div>
          <span className="tcp-hotel-fare-mobile-label">{t('hotelFare.thead.breakfast')}</span>
          <span data-rh-at="right" data-rh={
            fare.breakfastSurcharge && !fare.breakfast
              ? t('hotelFare.tooltip.breakfastSurcharge', {
                breakfastSurcharge: formatCents(fare.breakfastSurcharge),
              })
              : undefined
          }>
            {fare.breakfast ? t('global.yes') : t('global.no')}
          </span>
          <br />
          <span className="tcp-hotel-fare-mobile-label">{t('hotelFare.thead.cancellable')}</span>
          {isNotNil(fare.refundableUntil)
            ? `${t('hotelFare.tbody.until')} ${formatDate(fare.refundableUntil)}`
            : '-'}
          <br />
          <HotelRoomDetails roomDetails={fare.roomDetails} />
          {renderSelectionBtn(fare.id, fare.paymentType)}
        </div>
      ))}
    </div>
  );
};
export default HotelFareSM;
