import * as React from 'react';
import Field from 'redux-form/es/Field';

// Utils
import { t } from '@toolkit/util/i18n';
import { settingsToSelectOptions } from '@src/shared/src/util/settings';
import {
  required,
  olderThan18,
  // mobilePhone,
  // minLength,
  // maxLength,
} from '@toolkit/util/formsValidation';
// Constants
// Services
// Models, Stores & Interfaces
import { SettingsModel } from '@src/shared/src/models';
// Components
import { FormInput, SelectInput, SelectDatePicker, Label } from '@toolkit/ui';
// Styles
import '@src/styles/modules/_form.scss';

type Props = {
  userTitles: SettingsModel[];
};

class CreateGuestForm extends React.Component<Props> {
  public render() {
    return (
      <React.Fragment>
        <div className="form-row">
          <div className="form-field">
            <Field
              name="gender"
              options={[
                { label: t('settingsForm.label.gender.female'), value: 'female' },
                { label: t('settingsForm.label.gender.male'), value: 'male' },
              ]}
              validate={[required]}
              component={SelectInput}
              placeholder={t('settingsForm.label.gender')}
            />
          </div>
          <div className="form-field">
            <Field
              name="title"
              simpleValue={true}
              editable={false}
              clearable={false}
              backspaceRemoves={false}
              deleteRemoves={false}
              validate={[required]}
              options={settingsToSelectOptions(this.props.userTitles, t)}
              component={SelectInput}
              placeholder={t('settingsForm.label.title')}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <Field
              name="firstName"
              className="form-textfield"
              validate={[required]}
              component={FormInput}
              placeholder={t('settingsForm.label.firstName')}
            />
          </div>
          <div className="form-field">
            <Field
              name="lastName"
              className="form-textfield"
              validate={[required]}
              component={FormInput}
              placeholder={t('settingsForm.label.lastName')}
            />
          </div>
        </div>
        <div className="form-field">
          <Label>{t('settingsForm.label.dateOfBirth')}</Label>
          <Field
            name="birthdateOn"
            validate={[required, olderThan18]}
            component={SelectDatePicker}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default CreateGuestForm;
