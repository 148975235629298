import { from, combineLatest, Observable } from 'rxjs';
import { mergeMap, map, filter } from 'rxjs/operators';

// Utils
import {
  isNotNil,
  filter as rFilter,
  pick,
  propSatisfies,
} from '@src/shared/src/util/general';
import { store } from '@src/store/store.config';
import { getStateStreamSlice } from '@toolkit/util/state-utils/state.util';
// Constants
import { STATUS } from '@src/shared/src/const/app';
// Actions
// Models
import { BookingItemModel } from '@src/shared/src/models';
import { BookingItemLastErrorStatusModel } from '@src/models';
// Interfaces
import { IRootState } from '@src/store';
import { ICheckoutState } from '@src/shared/src/reducers/checkoutReducer';
// Subscriptions
import * as userSubscriptions from '../user/user.subscriptions';
import * as searchSubscriptions from '../search/search.subscriptions';
// Queries

const _checkout$: Observable<ICheckoutState> = from(store as any).pipe(
  map((state: IRootState) => state.checkout));

const _getPassengerRequireProp = (prop: string) =>
  combineLatest(
    searchSubscriptions.isOnlyPassengerGuest(),
    searchSubscriptions.firstPassenger(),
    userSubscriptions.loggedInUser()).pipe(
      map(([isOnlyPassengerGuest, firstPassenger, loggedInUser]) =>
        isOnlyPassengerGuest
          ? loggedInUser[prop]
          : firstPassenger[prop]
      )
    );

const _bookingItems$ = getStateStreamSlice<ICheckoutState, BookingItemModel[]>(_checkout$, ['currentBookingItems']);

export const isCostCenterRequired = () => _getPassengerRequireProp('costCenterRequired');

export const isCostUnitRequired = () => _getPassengerRequireProp('costUnitRequired');

export const isReferenceCodeRequired = () => _getPassengerRequireProp('referenceCodeRequired');

export const isPurposeOfTripRequired = () => _getPassengerRequireProp('purposeOfTripRequired');

export const isPolicyViolationJustificationRequired = () =>
  _getPassengerRequireProp('requireJustificationForPolicyViolation');

export const redeemingAllowance = () => getStateStreamSlice(_checkout$, ['redeemingAllowance']);

export const bookingItemsErrorStatuses = (): Observable<BookingItemLastErrorStatusModel> =>
  _bookingItems$.pipe(
    mergeMap(rFilter(propSatisfies(isNotNil, 'lastErrorStatus'))),
    filter((bookingItem: BookingItemModel) => bookingItem.status !== STATUS.CONFIRM_FAILED),
    map(pick(['id', 'lastErrorStatus'])),
  );
