import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
import { findBySelected } from '@src/shared/src/util/general';
// Constants
import { ENVIRONMENT } from '@src/shared/src/const/app';
// Actions
import { selectors } from '@src/shared/src';
// Models
import {
  PassengerModel,
  RentalModel,
} from '@src/shared/src/models';
// Interfaces
import { IRootState, ConnectedRedux } from '@src/store';
// Interfaces
// Components
import RentalCarDetails from './RentalCarDetails';
import RentalCarExtras from './RentalCarExtras';
import RentalDriversInfo from './RentalDriversInfo';
import RentalTicketOptions from './RentalTicketOptions';
import BookedRentalItemInfo from './BookedRentalItemInfo';
// Styles
import '../styles/RentalDetails.scss';

type Props = ConnectedRedux<IRootState> & AppContextProp & {
  passengers:PassengerModel[]
  env:ENVIRONMENT;
  rental:RentalModel;
}

class RentalDetails extends React.Component<Props> {
  render() {
    const { rental, env, passengers } = this.props;

    return (
      <div className="lp-rental-details">
        {this.props.appContext.isMediaBPNotMobile &&
          <BookedRentalItemInfo rental={rental} env={env} />
        }
        <div className="lp-rental-details-inner">
          <div className="lp-rental-details-inner-car_fare_description">
            <RentalDriversInfo passengers={passengers} />
            <RentalCarDetails rentalVehicle={rental.vehicle} />
            <RentalCarExtras rentalExtras={findBySelected(rental.fares).extras} />
          </div>
          <RentalTicketOptions aggregatorName={rental.rentalSearch.aggregator.effectiveIdent} />
        </div>
      </div>
    );
  }
}

const mapState = (state:IRootState) => ({
  passengers: selectors.search.searchPassengers(state.search),
});
export default connect(mapState)(withAppContext(RentalDetails));
