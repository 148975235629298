import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
import { isNil } from '@src/shared/src/util/general';
// Constants
// Actions
import { searchActions } from '@src/shared/src/actions';
// Models
// Interfaces
import { IRootState } from '@src/store';
// Components
import SearchBarRouteInput from './SearchBarRouteInput';
import { Checkbox } from '@toolkit/ui';
// Styles
import '../styles/RentalOnlySearch.scss';

type Props = {
  searchArr: string;
  searchDep: string;
  onSetUiSearchSuggestDepOverlay: (val: string, type?: boolean) => void;
  onSetUiSearchSuggestArrOverlay: (val: string, type?: boolean) => void;
  onSetSearchDep: (val: string) => void;
  onSetSearchArr: (val: string) => void;
  isDepartureMissing?: boolean;
  isArrivalMissing?: boolean;
  uiSearchSuggestDepOverlay: boolean;
  uiSearchSuggestArrOverlay: boolean;
  slideOutType: string; // need this for mobile view
  returnAtPickup: boolean;
  setReturnAtPickup: (val: boolean) => void;
  inNavbar: boolean;
};

export const RentalOnlySearch: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const rentalReturnAtPickup = useSelector((state: IRootState) => state.search.currentSearch.rentalReturnAtPickup);

  return (
    <div className="tcp-search-bar-route tcp-search-bar-route-active lp-rental-only-search">
      {isNil(props.slideOutType) || props.slideOutType === 'outbound' ? (
        <SearchBarRouteInput
          isFrom={true}
          value={props.searchDep}
          onFocus={() => props.onSetUiSearchSuggestDepOverlay('depSuggest')}
          onChange={props.onSetSearchDep}
          placeholder={t('search.bar.form.input.placeholder.from')}
          displayErrorMsg={props.isDepartureMissing}
          errorMessage={t('searchBarConn.notify.text.departureMissing')}
          isVisible={props.uiSearchSuggestDepOverlay}
          inSlideout={props.slideOutType ? true : false}
        />
      ) : (
        ''
      )}
      {!rentalReturnAtPickup && (isNil(props.slideOutType) || props.slideOutType === 'inbound') ? (
        <SearchBarRouteInput
          isFrom={false}
          value={props.searchArr}
          onFocus={() => props.onSetUiSearchSuggestArrOverlay('arrSuggest')}
          onChange={props.onSetSearchArr}
          placeholder={t('search.bar.form.input.placeholder.to')}
          displayErrorMsg={props.isArrivalMissing}
          errorMessage={t('searchBarConn.notify.text.arrivalMissing')}
          isVisible={props.uiSearchSuggestArrOverlay}
          inSlideout={props.slideOutType ? true : false}
        />
      ) : (
        ''
      )}
      {!props.inNavbar ? (
        <div>
          <div className="checkbox-ret">
            <Checkbox
              isChecked={rentalReturnAtPickup}
              label={t('rentalOnlySearch.checkbox.label.returnAndPickup')}
              id="chk-ret"
              onChange={val => dispatch(searchActions.setSearchRentalReturnAtPickup(val))}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
