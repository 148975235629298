import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

// Utils
import { t } from '@toolkit/util/i18n';
import { isAnyBookingItemAirbnb } from '@src/shared/src/selectors/checkoutSelectors';
// Constants
import { STATUS } from '@src/shared/src/const/app';
import { API_URL } from '@src/shared/src/const/api';
// Actions
// Models & Interfaces
// Components
import { LabelButton } from '@toolkit/ui';
// Styles

type Props = {
  basketStatus: STATUS;
  bookingId: string;
};

export const CheckoutSidebarConfirmBody: React.FC<Props> = ({ basketStatus, bookingId }) => {
  const bookingHasAirbnb = useSelector(isAnyBookingItemAirbnb);

  const renderICal = () => {
    if ((window as any).__IS_WEBVIEW) {
      return (
        <p>
          <strong>{t('checkoutSideBarConfirmationInfo.text.ios.addToCalendar')}</strong>
        </p>
      );
    } else {
      return (
        <a className="tcp-link" href={`${API_URL.ORG_ADMIN_TRAVEL_BOOKINGS}/${bookingId}/ics`}>
          <i className="icon-add_calendar" />
          {t('checkoutSideBarConfirmationInfo.button.addToCalendar')}
        </a>
      );
    }
  };

  return (
    <span>
      <div className="sidebar-section is-open">
        <div className="sidebar-section-inner">
          <span className="sidebar-section-title">
            {t('checkoutSideBarConfirmationInfo.title.prepareTrip')}
          </span>
          {bookingHasAirbnb
            ? t('checkout-sidebar-confirm-body.text.description-start-airbnb')
            : t('checkout-sidebar-confirm-body.text.description-start')}
          <br/>
          <Trans i18nKey="checkout-sidebar-confirm-body.text.description">
            <br /><br /><br />
          </Trans>
        </div>
      </div>

      {basketStatus === STATUS.CONFIRM_FINISHED && (
        <div className="sidebar-section is-open">
          <div className="sidebar-section-inner">{renderICal()}</div>
        </div>
      )}

      <div className="sidebar-section is-open">
        <div className="sidebar-section-inner">
          <LabelButton
            onClick={() => window.location.assign(`${API_URL.DEFAULT}/org_admin/travel/bookings`)}>
            <i className="icon-tickets" />
            {t('checkoutSideBarConfirmationInfo.button.seeAllBookings')}
          </LabelButton>
        </div>
      </div>
    </span>
  );
};
