import * as React from 'react';
import { connect } from 'react-redux';
import Slider, { Range } from 'rc-slider';

// Utils
import {
  formatCents,
  isNil,
  partialRight,
  prop,
  roundToOneDigit,
  times,
} from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';

// Constants
import {
  CENTS_CONVERSION_TYPES,
} from '@src/shared/src/const/app';
// Actions
import { selectors } from '@src/shared/src';
import { appSettingsSelectors } from '@src/shared/src/selectors';
import {
  setHotelNameFilter,
  setHotelStarFilter,
  setHotelRatingFilter,
  setHotelPricePerNightFilter,
  setHotelPriceFilter,
  setHotelDistanceFilter,
  setHotelFeatureFilter,
  setHotelAccommodationFilter,
} from '@src/shared/src/actions/filterActions';
// Models
import {
  SearchModel,
  PassengerModel,
  SettingsModel,
  TripModel,
} from '@src/shared/src/models';
import { FilterOption, SelectionModel } from '@src/models';
// Interfaces
import { IConnectedRedux, IRootState } from '@src/store';
import {
  HotelNameFilterConstraint,
  HotelStarFilterConstraint,
  HotelRatingFilterConstraint,
  HotelPricePerNightFilterConstraint,
  HotelPriceFilterConstraint,
  HotelDistanceFilterConstraint,
} from '@src/shared/src/filters';
import { setUiMobileFilterOverlay, setUiMobileNoScrolling } from '@pod/ui/actions';
import { CurrentFilterConstraint } from '@src/shared/src/interfaces';

// Components
import { SearchSideBarInfo } from '@pod/search/components';
import {
  InputFilter,
  BaseFilter,
  CheckboxSelectionFilter,
} from '@pod/filters';
import { TripSideBarSelectionInfo } from '@pod/trips/components';
import { Navbar } from '@toolkit/ui';
import { Filter, FilterOptionList } from '@pod/filters/components';

// Styles
import '@src/styles/modules/sidebar/_search-field.scss';

type Props = IConnectedRedux<IRootState> & AppContextProp & {
  hotelNameFilter:HotelNameFilterConstraint;
  hotelStarFilter:HotelStarFilterConstraint;
  hotelRatingFilter:HotelRatingFilterConstraint;
  hotelPricePerNightFilter:HotelPricePerNightFilterConstraint;
  hotelPriceFilter:HotelPriceFilterConstraint;
  hotelDistanceFilter:HotelDistanceFilterConstraint;
  hotelFeatureFilter: CurrentFilterConstraint<SelectionModel[]>;
  hotelAccommodationFilter: CurrentFilterConstraint<FilterOption[]>;
  search:SearchModel;
  loyaltyCards:SettingsModel[];
  passengers:PassengerModel[];
  isSearchingForOnlyHotel:boolean;
  selectedOutward:TripModel;
  selectedInbound:TripModel;
  isOpen?:boolean;
  setHotelDetailsOpenedId:(val:string) => void;
  preventVacationRentals: boolean;
  totalHotelsCount: number;
};

class HotelSideBarConn extends React.Component<Props> {

  private starFilterFormat = (val:number) => times((idx) =>
    <span key={`stf-${idx}`} className={`stars-rating-icon ${idx + 1  > val ? 'is-empty' : ''}`}/>, 5)

  private closeMobileFilterOverlay = () =>  {
    this.props.dispatch(setUiMobileFilterOverlay(false));
    this.props.dispatch(setUiMobileNoScrolling(false));
  }

  resetHotelDetailsOpenedId = () => this.props.setHotelDetailsOpenedId('');

  public render() {
    const { hotelStarFilter, hotelRatingFilter, hotelDistanceFilter, hotelPriceFilter, dispatch,
      hotelPricePerNightFilter, search, loyaltyCards, selectedInbound, selectedOutward,
      isSearchingForOnlyHotel, appContext, isOpen, hotelAccommodationFilter, preventVacationRentals, totalHotelsCount } = this.props;
    
    return (
          <aside className={`sidebar sidebar-trip sidebar-hotels ${isOpen ? 'is--open' : ''}`}>
           { appContext.isMediaBPNotMobile ?
              <h2 className="sidebar-title">{t('hotelsSidebar.title.selectHotel')}</h2> :
              <Navbar actionRight={() => this.closeMobileFilterOverlay()} iconRight="icon-arrow_forward" labelRight={t('search.bar.slide.out.button.confirm')}>
                Filter
              </Navbar>
            }

              <SearchSideBarInfo
                from={prop('depName', search)}
                to={prop('arrName', search)}
                depAt={prop('depAt', search)}
                arrAt={prop('arrAt', search)}
                loyaltyCards={loyaltyCards}
                isSearchingForOnlyHotel={isSearchingForOnlyHotel}/>
              {!isNil(selectedOutward) &&
                <TripSideBarSelectionInfo
                  outward={selectedOutward}
                  inbound={selectedInbound}
                  searchId={search.id}/>
              }
              <InputFilter
                title={t('hotelSideBar.hotelNameFilter.title.searchForHotel')}
                currentValue={this.props.hotelNameFilter.current}
                onChange={(value) => {
                  this.props.setHotelDetailsOpenedId('');
                  dispatch(
                    setHotelNameFilter({
                      constraint: {
                        current: value,
                      },
                      onlyUpdateConstraint: false,
                    })
                  );
                }}/>

              {!preventVacationRentals &&
                <Filter
                  title={t('hotel-accommodation-filter.title')}
                  tooltip={t('hotel-accommodation-filter.tooltip')}
                  isHidden={totalHotelsCount === 0}>
                  <FilterOptionList
                    options={hotelAccommodationFilter.current}
                    filterType='switch'
                    onChange={value => {
                      this.resetHotelDetailsOpenedId();
                      dispatch(
                        setHotelAccommodationFilter({
                          constraint: {
                            current: value
                          },
                          onlyUpdateConstraint: false,
                        })
                      )
                    }}
                    labelTranslation={label => t(`hotel-accommodation-filter.label.${label}`)}
                  />
                </Filter>
              }
              <BaseFilter
                title={t('hotelSideBar.starsFilter.title.stars')}
                min={hotelStarFilter.min}
                max={hotelStarFilter.max}
                currentMin={hotelStarFilter.currentMin}
                currentMax={hotelStarFilter.currentMax}
                edgeFormatValue={this.starFilterFormat}
                render={({currentMax, currentMin, min, max, onChange }) => (
                  <Range
                    value={[currentMin, currentMax]}
                    min={min}
                    max={max}
                    onChange={onChange}
                  />
                )}
                onChange={(val) => {
                  this.props.setHotelDetailsOpenedId('');
                  dispatch(
                    setHotelStarFilter({
                      constraint: {
                        min: hotelStarFilter.min,
                        max: hotelStarFilter.max,
                        currentMin: val[0],
                        currentMax: val[1],
                      },
                      onlyUpdateConstraint: false,
                    })
                  );
                }}/>
              <BaseFilter
                title={t('hotelSideBar.ratingFilter.title.ratings')}
                min={hotelRatingFilter.min}
                max={hotelRatingFilter.max}
                currentMin={hotelRatingFilter.currentMin}
                currentMax={hotelRatingFilter.currentMax}
                edgeFormatValue={roundToOneDigit}
                midFormatValue={roundToOneDigit}
                render={({currentMax, currentMin, min, max, onChange }) => (
                  <Range
                    value={[currentMin, currentMax]}
                    min={min}
                    max={max}
                    step={0.1}
                    onChange={onChange}
                  />
                )}
                onChange={(value) => {
                  this.props.setHotelDetailsOpenedId('');
                  dispatch(
                    setHotelRatingFilter({
                      constraint: {
                        min: hotelRatingFilter.min,
                        max: hotelRatingFilter.max,
                        currentMin: value[0],
                        currentMax: value[1],
                      },
                      onlyUpdateConstraint: false,
                    })
                  );
                }}/>
              <BaseFilter
                title={t('hotelSideBar.pricePerNightFilter.title.pricePerNight')}
                min={hotelPricePerNightFilter.min}
                max={hotelPricePerNightFilter.max}
                currentMin={hotelPricePerNightFilter.currentMin}
                currentMax={hotelPricePerNightFilter.currentMax}
                edgeFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
                midFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
                render={({currentMax, currentMin, min, max, onChange }) => (
                  <Range
                    value={[currentMin, currentMax]}
                    min={min}
                    max={max}
                    onChange={onChange}
                  />
                )}
                onChange={(val) => {
                  this.props.setHotelDetailsOpenedId('');
                  dispatch(
                    setHotelPricePerNightFilter({
                      constraint: {
                        min: hotelPricePerNightFilter.min,
                        max: hotelPricePerNightFilter.max,
                        currentMin: val[0],
                        currentMax: val[1],
                      },
                      onlyUpdateConstraint: false,
                    })
                  );
                }}/>
              <BaseFilter
                title={t('hotelSideBar.totalPriceFilter.title.totalPrice')}
                min={hotelPriceFilter.min}
                max={hotelPriceFilter.max}
                currentMin={hotelPriceFilter.currentMin}
                currentMax={hotelPriceFilter.currentMax}
                edgeFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
                midFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
                render={({currentMax, currentMin, min, max, onChange }) => (
                  <Range
                    value={[currentMin, currentMax]}
                    min={min}
                    max={max}
                    onChange={onChange}
                  />
                )}
                onChange={(val) => {
                  this.props.setHotelDetailsOpenedId('');
                  dispatch(
                    setHotelPriceFilter({
                      constraint: {
                        min: hotelPriceFilter.min,
                        max: hotelPriceFilter.max,
                        currentMin: val[0],
                        currentMax: val[1],
                      },
                      onlyUpdateConstraint: false,
                    })
                  );
                }}/>
              <BaseFilter
                title={t('hotelSideBar.distanceFilter.title.distance')}
                min={hotelDistanceFilter.min}
                max={hotelDistanceFilter.max}
                currentMin={hotelDistanceFilter.min}
                currentMax={hotelDistanceFilter.current}
                edgeFormatValue={(val) => `${val} km`}
                midFormatValue={(val) => `${val} km`}
                render={({currentMax, currentMin, min, max, onChange }) => (
                  <Slider
                    value={currentMax}
                    min={min}
                    max={max}
                    step={0.01}
                    onChange={(val) => onChange([val])}
                  />
                )}
                onChange={(value) => {
                  this.props.setHotelDetailsOpenedId('');
                  dispatch(
                    setHotelDistanceFilter({
                      constraint: {
                        min: hotelDistanceFilter.min,
                        max: hotelDistanceFilter.max,
                        current: value[0],
                      },
                      onlyUpdateConstraint: false,
                    })
                  );
                }}/>
              <CheckboxSelectionFilter
                title={t('hotelSideBarConn.featureFilter.title')}
                options={this.props.hotelFeatureFilter.current}
                categoryTranslation={(category) => t(`hotelFeatureCategory.${category}`)}
                onChange={(newOptions) => {
                  this.props.setHotelDetailsOpenedId('');
                  dispatch(
                    setHotelFeatureFilter({
                      constraint: {
                        current: newOptions
                      },
                      onlyUpdateConstraint: false,
                    })
                  )
                }}/>
          </aside>
    );
  }
}

const mapState = (state:IRootState) => ({
  hotelNameFilter: state.filters.hotelNameFilter,
  hotelStarFilter: state.filters.hotelStarFilter,
  hotelRatingFilter: state.filters.hotelRatingFilter,
  hotelPricePerNightFilter: state.filters.hotelPricePerNightFilter,
  hotelPriceFilter: state.filters.hotelPriceFilter,
  hotelDistanceFilter: state.filters.hotelDistanceFilter,
  hotelFeatureFilter: state.filters.hotelFeatureFilter,
  hotelAccommodationFilter: state.filters.hotelAccommodationFilter,
  isSearchingForOnlyHotel: state.settings.isSearchingForOnlyHotel,
  search: selectors.search.search(state.search),
  passengers: selectors.search.searchPassengers(state.search),
  loyaltyCards: appSettingsSelectors.loyaltyCards(state),
  selectedOutward: selectors.checkout.outwardTrip(state.checkout),
  selectedInbound: selectors.checkout.inboundTrip(state.checkout),
  preventVacationRentals: state.organization.org.preventVacationRentals,
  totalHotelsCount: state.hotels.hotels.length,
});
export default connect(mapState)(withAppContext(HotelSideBarConn));
