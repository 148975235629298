import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// Utils
import { store } from '@src/store/store.config';
import { getStateStreamSlice } from '@toolkit/util/state-utils/state.util';
// Constants
// Actions
// Models
// Interfaces
import { IHotelState } from '@src/shared/src/reducers/hotelReducer';
import { IRootState } from '@src/store';
// Subscriptions
// Queries
import * as hotelQueries from './hotel.queries';

const _hotel$: Observable<IHotelState> =
  from(store as any).pipe(map((state: IRootState) => state.hotels));
const _allowanceMatchingHotelIds$ =
  getStateStreamSlice<IHotelState, number[]>(_hotel$, ['allowanceMatchingHotelIds']);

export const allowanceMatchingHotel = () => _allowanceMatchingHotelIds$.pipe(
  map(([allowanceMatchingHotelId]) => hotelQueries.findHotelById(allowanceMatchingHotelId))
);
