import { JsonObject, JsonProperty } from 'json2typescript';
import TravelBookingFareModel from './TravelBookingFareModel';
import { DateConverter } from './converters';
import DocumentModel from './DocumentModel';
import { DIRECTION } from '../const/app';

@JsonObject('TravelBookingItemModel')
export default class TravelBookingItemModel {
  @JsonProperty('booked_at', DateConverter) public bookedAt:Date = undefined;
  @JsonProperty('booking_id', Number) public bookingId:number = -1;
  @JsonProperty('cancelled_at', DateConverter) public cancelledAt:Date = undefined;
  @JsonProperty('created_at', DateConverter) public createdAt:Date = undefined;
  @JsonProperty('currency', String) public currency:string = '';
  @JsonProperty('fare', TravelBookingFareModel) public fare:TravelBookingFareModel = undefined;
  @JsonProperty('fare_id', Number) public fareId:Number = -1;
  @JsonProperty('fare_ids', [Number]) public fareIds:Number[] = [];
  @JsonProperty('fare_type', String) public fareType:string = '';
  @JsonProperty('id', Number) public id:Number = -1;
  @JsonProperty('last_error_status', String) public lastErrorStatus:string = '';
  @JsonProperty('order_number', String) public orderNumber:string = '';
  @JsonProperty('price', Number) public price:Number = -1;
  @JsonProperty('refundable_until', DateConverter) public refundableUntil:Date = undefined;
  @JsonProperty('remote_status', String) public remoteStatus:string = undefined;
  @JsonProperty('retracted_at', DateConverter) public retractedAt:Date = undefined;
  @JsonProperty('seat_reservation', String, true) public seatReservation:string = undefined;
  @JsonProperty('status', String) public status:string = '';
  @JsonProperty('updated_at', DateConverter) public updatedAt:Date = undefined;
  @JsonProperty('visible_documents', [DocumentModel], true)
  public visibleDocuments:DocumentModel[] = [];
  @JsonProperty('hard_fail', Boolean, true) hardFail: boolean = undefined;
  @JsonProperty('direction', String, true) public direction: DIRECTION = undefined;
  @JsonProperty('roundtrip', Boolean, true) public isRoundtrip: boolean = undefined;
}
