import * as React from 'react';

// Utils
import {
  formatDayDifference,
} from '@src/shared/src/util/general';
import { generateTripRoutes } from '@toolkit/util/app';
import { formatDate } from '@src/shared/src/util/date';
// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { LegModel } from '@src/shared/src/models';
// Components
import LegWaypoint from './LegWaypoint';
// Styles
import '../styles/TripRoutes.scss';

interface IProps {
  tripDepAt:Date;
  tripArrAt:Date;
  tripLegs:LegModel[];
  tripDuration:number;
  searchDate?:Date;
}
export default class TripRoutes extends React.PureComponent<IProps, {}> {

  private getTripTimeMarkup = (isDep:boolean, tripTime:Date, searchDate: Date) => {
    return (
      <span className={`tcp-trip-routes-time tcp-trip-routes-time-${isDep ? 'from' : 'to'}`}>
        {formatDate(tripTime, DATE_FORMAT_TYPES.SHORT_TIME)} <sup>{formatDayDifference(tripTime, searchDate)}</sup>
      </span>
    );
  }

  private getRoutesMarkup = (tripLegs: LegModel[], tripDuration:number) => {
    const markupSegments = generateTripRoutes(tripLegs, tripDuration);
    return markupSegments.map((mSegment) =>
      <li
        key={mSegment.key}
        className={`tcp-trip-routes-item ${mSegment.class}`}
        style={mSegment.style}>
        <LegWaypoint icon={mSegment.icon} />
      </li>
    );
  }

  public render() {
    const { tripArrAt, tripDepAt, tripLegs, tripDuration, searchDate  } = this.props;

    return (
      <ol className={`tcp-trip-routes`}>
        <li className="tcp-trip-routes-item">
          {this.getTripTimeMarkup(true, tripDepAt, searchDate)}
            <i className="tcp-trip-routes-icon icon-from is-large"/>
        </li>
        {this.getRoutesMarkup(tripLegs, tripDuration)}
        <li className="tcp-trip-routes-item">
          {this.getTripTimeMarkup(false, tripArrAt, searchDate)}
          <i className="tcp-trip-routes-icon icon-to is-large"/>
        </li>
      </ol>
    );
  }
}