import { JsonObject, JsonProperty } from 'json2typescript';
import { NumberToStringConverter, DateConverter } from './converters';

import RedeemingAllowanceModel from './RedeemingAllowanceModel';

@JsonObject('BookingModel')
export default class BookingModel {
  @JsonProperty('id', NumberToStringConverter) public id:string = undefined;
  @JsonProperty('accounting_invoice_profile_id', Number, true) public accountingInvoiceProfileId:number = undefined;
  @JsonProperty('approved_at', DateConverter, true) public approvedAt:Date = undefined;
  @JsonProperty('approved_by', Number, true) public approvedBy:number = undefined;
  @JsonProperty('cost_center', String, true) public costCenter:string = undefined;
  @JsonProperty('created_at', DateConverter) public createdAt:Date = undefined;
  @JsonProperty('creator_id', Number) public creatorId:number = undefined;
  @JsonProperty('external', Boolean) public external:boolean = undefined;
  @JsonProperty('purpose_of_trip', String, true) public purposeOfTrip:string = undefined;
  @JsonProperty('reference_code', String, true) public referenceCode:string = undefined;
  @JsonProperty('remarks', String, true) public remarks:string = undefined;
  @JsonProperty('status', String) public status:string = undefined;
  @JsonProperty('total_price', Number) public totalPrice:number = undefined;
  @JsonProperty('updated_at', DateConverter) public updatedAt:Date = undefined;
  @JsonProperty('ask_support_if_cheaper_ticket_possible', Boolean)
  public askSupportIfCheaperTicketPossible:boolean = false;
  @JsonProperty('requires_allowance', Boolean, true) public requiresAllowance:boolean = false;
  @JsonProperty('exceeds_allowance', Boolean, true) public exceedsAllowance:boolean = false;
  @JsonProperty('redeeming_allowance', RedeemingAllowanceModel, true) public redeemingAllowance:boolean = false;
}
