import classnames from 'classnames';
import * as React from 'react';
import './styles/Link.scss';

type Props = {
  children: React.ReactNode;
  icon?: JSX.Element;
  onClick?: any;
  small?: boolean;
  className?: string;
};

const Link: React.FC<Props> = (props) => {
  return (
    <button
      className={classnames('tcp-link', props.className, { 'is--small': props.small })}
      onClick={props.onClick}>
      {props.icon}
      {props.children}
    </button>
  );
};

export default Link;
