import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { errorLogger } from '@src/shared/src/util/errors';
import { t } from '@toolkit/util/i18n';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
import {
  findBySelected,
  verifyProperty,
  formatCents,
  isNil,
} from '@src/shared/src/util/general';
// Constants
import {
  ENVIRONMENT,
  CENTS_CONVERSION_TYPES,
  ERROR_SEVERITY,
} from '@src/shared/src/const/app';
// Actions
import { IRootState, ConnectedRedux } from '@src/store';
import { selectors } from '@src/shared/src';
// Models
import {
  RentalFareModel,
  RentalModel,
  SearchModel,
} from '@src/shared/src/models';
// Interfaces
// Components
import { Navbar } from '@toolkit/ui';
import RentalTitle from './RentalTitle';
import RentalDetails from './RentalDetails';
import RentalPickUpInfo from './RentalPickUpInfo';
import BookedRentalItemInfo from './BookedRentalItemInfo';
// Styles
import '../styles/Rental.scss';

type Props = ConnectedRedux<IRootState> & AppContextProp & {
  rental:RentalModel;
  env:ENVIRONMENT;
  search:SearchModel;
  detailsOpened?:boolean;
}
type State = {
  showDetails:boolean;
};

class Rental extends React.Component<Props, State> {
  readonly state:State = {
    showDetails: this.props.detailsOpened || false,
  };

  private toggleDetails = () => this.setState({ showDetails: !this.state.showDetails })

  private getSelectionMarkup = () => {
    if (this.props.appContext.isMediaBPNotMobile) {
      return (
        <div className="tcp-hotel-select">
          <button
            className={`button ${this.state.showDetails ? 'button-light' : ''}`}
            onClick={this.toggleDetails}>
            {this.state.showDetails
              ? <span>{t('global.close_details')}<i className="icon-arrow_upward" /></span>
              : <span>{t('global.view_details')}<i className="icon-arrow_downward" /></span>
            }
          </button>
        </div>
      );
    }

    return null;
  }

  public render() {
    const { rental, appContext, env } = this.props;
    const selectedRentalFare:RentalFareModel = findBySelected(rental.fares);
    if (isNil(selectedRentalFare)) {
      errorLogger('Rental.tsx', new Error('Could not find any selected rental fare'), ERROR_SEVERITY.ERROR);
    }

    return (
      <div className={`tcp-hotel tcp-hotel-rental ${this.state.showDetails ? 'tcp-hotel-is-open-details' : ''}`}
        onClick={!appContext.isMediaBPNotMobile && !this.state.showDetails ? () => this.toggleDetails() : null}>
        {!appContext.isMediaBPNotMobile && this.state.showDetails &&
          <Navbar actionRight={() => this.toggleDetails()} iconRight="icon-close2">
            {t('trip.button.details')}
          </Navbar>
        }
        <div className="tcp-hotel-inner">
          <div className="tcp-rental-image">
            <img className="tcp-hotel-main-image" src={selectedRentalFare.carImageUrl}/>
          </div>
          <div className="tcp-hotel-info">
            <RentalTitle
              vehicleExample={verifyProperty('', selectedRentalFare, ['vehicleExamples', '0'])}
              vehicleGroup={rental.vehicle.group} />
            <RentalPickUpInfo
              search={this.props.search}
              rental={this.props.rental} />
            {!appContext.isMediaBPNotMobile &&
              <div className="tcp-hotel-footer">
                  <div className="tcp-hotel-footer-price">
                    <span>
                      <strong>
                        {formatCents(selectedRentalFare.price, CENTS_CONVERSION_TYPES.FLOOR)}
                      </strong>
                    </span>
                  </div>
              </div>
            }
          </div>
          {this.getSelectionMarkup()}
        </div>
        {!appContext.isMediaBPNotMobile &&
          <BookedRentalItemInfo rental={rental} env={env} />
        }
        {this.state.showDetails &&
          <RentalDetails rental={rental} env={env} />
        }
      </div>
    );
  }
}

const mapState = (state:IRootState) => ({
  search: selectors.search.search(state.search),
})
export default connect(mapState)(withAppContext(Rental));
