export type ConfigBootstrapType = {
  releaseStage: string;
  commitHash: string;
  locale: string;
};
export default class ConfigBootstrap {
  private constructor() { }

  private static instance: ConfigBootstrap;
  private releaseStage: string;
  private commitHash: string;
  private locale: string;

  static setupInstance = (opts: ConfigBootstrapType) => {
    ConfigBootstrap.instance = new ConfigBootstrap();
    ConfigBootstrap.instance.releaseStage = opts.releaseStage;
    ConfigBootstrap.instance.commitHash = opts.commitHash;
    ConfigBootstrap.instance.locale = opts.locale;
  }

  static getInstance = () => {
    if (!ConfigBootstrap.instance) {
      throw new Error('Setup the ConfigBootstrap by calling setupInstance() first');
    }
    return ConfigBootstrap.instance;
  }

  getReleaseStage = () => this.releaseStage;

  getCommitHash = () => this.commitHash;

  getLocale = () => this.locale;

}
