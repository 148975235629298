import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
import { selectors } from '@src/shared/src';
import { isNilOrEmpty } from '@src/shared/src/util/general';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
// Constants
import {
  ENVIRONMENT,
  STATUS,
  VEHICLE_TYPES,
} from '@src/shared/src/const/app';
// Actions
// Models
import {
  BookingItemModel,
  PassengerModel,
  AccountingInvoiceProfileModel,
} from '@src/shared/src/models';
// Interfaces
import { ConnectedRedux, IRootState } from '@src/store';
// Components
import {
  CheckoutBookedItemsDetail,
  BookingInfo,
  CheckoutFooter,
  CheckoutFooterConfirm,
  JourneyPlanInformation,
} from '@pod/checkout/components';
import {
  LoaderOverlay,
  WarningBanner,
} from '@toolkit/ui';
import LoaderConfirm from '@toolkit/ui/LoaderConfirm';

// Styles

declare const window: any;

type Props = ConnectedRedux<IRootState> & AppContextProp & {
  env:ENVIRONMENT;
  totalPrice:number;
  bookingItems:BookingItemModel[];
  basketStatus:STATUS;
  searchId:number;
  referenceCode:string;
  costCenter:string;
  costUnit:string;
  purposeOfTrip:string;
  basketTripsVehicles:VEHICLE_TYPES[];
  guestPassengers:PassengerModel[];
  passengers:PassengerModel[];
  isJourney:boolean;
  accountingInvoiceProfile:AccountingInvoiceProfileModel;
  bookedBy: string;
  remarks: string;
};

class CheckoutConfirmConn extends React.PureComponent<Props> {

  renderGuestCheckinWarningBanner = () => {
    const { guestPassengers, basketTripsVehicles } = this.props;

    if (!isNilOrEmpty(guestPassengers) && basketTripsVehicles.includes(VEHICLE_TYPES.PLANE)) {
      return (
        <WarningBanner
          icon="lp-icon-help"
          isInfo={true}
          label={t('checkoutConfirmConn.noGuestCheckin.info.message')}/>
      );
    }
    return null;
  }

  renderObbDownloadTicketWarning() {
    if (this.props.bookingItems.some(item => item.aggregator?.ident === 'trainline_obb')) {
      return (
        <WarningBanner
          icon="lp-icon-warning"
          label={t('checkoutConfirmConn.obbDownloadTicket.info.message')}/>
      );
    }
  }

  renderLoader = () =>
    <LoaderOverlay
      showSpinner={true}
      title={t('global.processingTrip')}/>

  private sendCheckoutMessage = () => {
    if ((window as any).__IS_WEBVIEW) {
      window.isMobileWebView.postMessage("checkout");
    }
  }

  componentDidMount() {
    this.sendCheckoutMessage();
  }

  render() {
    const {
      env,
      bookingItems,
      totalPrice,
      basketStatus,
      referenceCode,
      costCenter,
      costUnit,
      appContext,
      purposeOfTrip,
      passengers,
      accountingInvoiceProfile,
      bookedBy,
      remarks,
    } = this.props;

    if (
      basketStatus !== STATUS.CONFIRM_FAILED &&
      basketStatus !== STATUS.CONFIRM_PENDING &&
      basketStatus !== STATUS.CONFIRM_FINISHED
    ) {
      return (
        <LoaderOverlay
          showSpinner={false}
          showInBox={true}
          body={
            <LoaderConfirm />
          } />
      );
    }
    return (
      <React.Fragment>
        {this.renderGuestCheckinWarningBanner()}
        {this.renderObbDownloadTicketWarning()}
        {this.props.isJourney &&
          <JourneyPlanInformation
            referenceCode={referenceCode}
            costCenter={costCenter}
            purposeOfTrip={purposeOfTrip}
            passengers={passengers}
            invoiceProfileName={accountingInvoiceProfile ? accountingInvoiceProfile.name : ''}
            bookedBy={bookedBy}/>
        }
        <CheckoutBookedItemsDetail
          isJourney={this.props.isJourney}
          env={env}
          showItemsDetails={appContext.isMediaBPNotMobile}/>
        <BookingInfo
          env={env}
          bookingItems={bookingItems}/>
        <CheckoutFooter
          totalPrice={totalPrice}>
          {!this.props.isJourney &&
            <CheckoutFooterConfirm
              remarks={remarks}
              costCenter={costCenter}
              costUnit={costUnit}
              referenceCode={referenceCode}/>
          }
        </CheckoutFooter>
      </React.Fragment>
    );
  }
}

const mapState = (state:IRootState) => ({
  costCenter: selectors.checkout.costCenter(state.checkout),
  costUnit: selectors.checkout.costUnit(state.checkout),
  referenceCode: selectors.checkout.referenceCode(state.checkout),
  purposeOfTrip: selectors.checkout.purposeOfTrip(state.checkout),
  remarks: selectors.checkout.remarks(state.checkout),
  basketTripsVehicles: selectors.checkout.basketTripsVehicles(state.checkout),
  guestPassengers: selectors.search.guestPassengers(state.search),
  passengers: selectors.search.searchPassengers(state.search),
  accountingInvoiceProfile: selectors.search.getInvoiceProfile(state.search, state.checkout),
  bookedBy: state.checkout.basket.createdBy,
});
export default connect(mapState)(withAppContext(CheckoutConfirmConn));
