import * as React from 'react';

// Utils
import { getPrimaryPictureURL } from '@src/shared/src/util/hotels';
// Constants
// Actions
// Models
// Interfaces
// Components
// Styles
import '../styles/HotelMainImage.scss';

type Props = {
  primaryImageURL:string;
  pictureURLs:string[];
};

const HotelMainImage: React.FC<Props> = (props) => {
  const primaryImgURL = getPrimaryPictureURL(props.primaryImageURL, props.pictureURLs);
  return (
    <img src={primaryImgURL} alt="" className="tcp-hotel-main-image"/>
  );
};

export default HotelMainImage;