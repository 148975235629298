import * as React from 'react';
import { useState } from 'react';

// Utils
// Constants
// Actions
// Models
// Interfaces
// Components
import { TextField } from '@toolkit/ui';
// Styles
import '../styles/SearchBarRouteInput.scss';
import { PinIcon } from '@toolkit/ui/icons';

type Props = {
  isFrom: boolean;
  value: string;
  onChange: (inputValue: string) => void;
  onFocus?: () => void;
  placeholder: string;
  isVisible?: boolean;
  inSlideout?: boolean;
  displayErrorMsg?: boolean;
  errorMessage?: string;
  disabled?: boolean;
};

const SearchBarRouteInput: React.FC<Props> = (props) => {
  const { isFrom, value, onChange, onFocus, placeholder, inSlideout, isVisible } = props;
  const [displayError, setDisplayError] = useState(false);

  return (
    <div>
      <TextField
        name={isFrom ? 'inputTextFrom' : 'inputTextTo'}
        label={placeholder}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={() => setDisplayError(props.displayErrorMsg)}
        isVisible={isVisible && inSlideout}
        errorMsg={displayError && props.displayErrorMsg ? props.errorMessage : ''}
        icon={!inSlideout ? <PinIcon /> : null}
        showClear={!props.disabled}
        forceFocus={isVisible}
        disabled={props.disabled}
      />
    </div>
  );
};
export default SearchBarRouteInput;
