import * as React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
const urlParse = require('url-parse');

// Utils
import { notify, navigate } from '@toolkit/util/app';
import { verifyProperty } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
import { NOTIF_TYPE } from '@src/shared/src/const/app';
// Actions
import { usersActions } from '@src/shared/src/actions';
// Models
// Interfaces
// Components
import { ResetPasswordForm } from '@pod/admin_user/components';

export const ResetPasswordPage: React.FC<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();

  let queryResetToken = urlParse(location.search, true);
  queryResetToken = verifyProperty('', queryResetToken.query, ['password_reset_token']);

  if (queryResetToken.length < 1) {
    notify('Wrong password reset token', NOTIF_TYPE.ERROR);
    navigate('/auth/login');
  }
  const handlePasswordReset = values => {
    if (values.password !== values.confirmPassword) {
      notify(t('resetPasswordPage.error.passwordDoNotMatch'), NOTIF_TYPE.ERROR);
      return;
    }
    dispatch(
      usersActions.doResetPasswordAsync.request({
        onSuccess: () => {
          notify('Your password has been successfully reset', NOTIF_TYPE.SUCCESS);
          navigate('/auth/login');
        },
        onError: () => {
          notify(t('resetPasswordPage.error.cannotReset'), NOTIF_TYPE.ERROR);
        },
        password: values.password,
        password_reset_token: queryResetToken,
      })
    );
  };

  return (
    <div>
      <ResetPasswordForm handlePasswordReset={handlePasswordReset} />
    </div>
  );
};
