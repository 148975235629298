import * as React from 'react';
import { useState } from 'react';
import Markdown from 'react-markdown';
import { useSelector } from 'react-redux';

// Utils
import { map, isNilOrEmpty } from '@src/shared/src/util/general';
import { appSettingsSelectors } from '@src/shared/src/selectors';
import classNames from 'classnames';

// Constants
// Actions
// Models
import { BENotificationMessageModel } from '@src/shared/src/models';
// Interfaces
import { IRootState } from '@src/store';
// Components
import { CloseIcon, InfoIcon } from './icons';
// Styles
import './styles/BENotificationBar.scss';

type Props = {
  currentPage: string;
};

const getLocaleMessage = (locale: string) => {
  switch (locale.slice(0, 2)) {
    case 'en':
      return 'enMessage';
    case 'de':
      return 'deMessage';
    case 'es':
      return 'esMessage';
  }
};

export const BENotificationBar: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const { currentPage } = props;

  const userLocale = useSelector(
    (state: IRootState) => state.adminUser.profile?.preference?.language || 'en',
  );

  const beNotifications = useSelector((state: IRootState) =>
    appSettingsSelectors.notifications(state),
  );

  const notificationsMarkup = map(
    (notification: BENotificationMessageModel) => {
      return (
        <div
          className="lp-be-notification-bar-inner"
          key={notification[getLocaleMessage(userLocale)]}>
          <InfoIcon />
          <div>
            <Markdown>{notification[getLocaleMessage(userLocale)]}</Markdown>
          </div>
        </div>
      );
    },
    beNotifications.filter(
      (notification: BENotificationMessageModel) => notification[`pageCategory${currentPage}`],
    ),
  );

  if (isNilOrEmpty(notificationsMarkup)) {
    return null;
  } else {
    return (
      <div className={classNames('lp-be-notification-bar', { 'is--open': isOpen })}>
        {notificationsMarkup}
        <CloseIcon className="lp-be-notification-bar-close" onClick={() => setIsOpen(false)} />
      </div>
    );
  }
};

export default BENotificationBar;
