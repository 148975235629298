import { getType } from 'typesafe-actions';

// Utils
import { findIndexById, update, findIndex, propEq } from '../util/general';
// Constants
// Actions
import { CheckoutActions, checkoutActions } from '../actions';
// Models
import {
  BookingModel,
  BasketModel,
  TripModel,
  HotelModel,
  TravelBookingModel,
  BasketItemModel,
  BookingItemModel,
  RentalModel,
  RedeemingAllowanceModel
} from '../models';
// Interfaces

export interface ICheckoutState {
  currentBooking:BookingModel;
  currentBookingItems:BookingItemModel[];
  basket:BasketModel;
  trips:TripModel[];
  hotels:HotelModel[];
  rentals:RentalModel[];
  travelBooking:TravelBookingModel;
  basketItems:BasketItemModel[];
  lastWSUpdated:number;
  seatMapSelectorUrl:string;
  redeemingAllowance:RedeemingAllowanceModel;
}

const initialState: ICheckoutState = {
  currentBooking: null,
  currentBookingItems: [],
  basket: null,
  trips: [],
  hotels: [],
  rentals: [],
  basketItems: [],
  travelBooking: null,
  lastWSUpdated: -1,
  seatMapSelectorUrl: null,
  redeemingAllowance: null
};

export default (state:ICheckoutState = initialState, action:CheckoutActions) => {
  switch (action.type) {
    case getType(checkoutActions.setBasket):
      return { ...state, basket: action.payload };
    case getType(checkoutActions.setTrips):
      return { ...state, trips: action.payload };
    case getType(checkoutActions.setHotels):
      return { ...state, hotels: action.payload };
    case getType(checkoutActions.setRentals):
      return { ...state, rentals: action.payload };
    case getType(checkoutActions.setLastWSUpdated):
      // console.log(`lastWSUpdated Checkout: ${action.payload}`)
      return { ...state, lastWSUpdated: action.payload };
    case getType(checkoutActions.setCurrentBooking):
      return { ...state, currentBooking: action.payload };
    case getType(checkoutActions.setTravelBooking):
      return { ...state, travelBooking: action.payload };
    case getType(checkoutActions.setSeatMapSelectorUrl):
      return { ...state, seatMapSelectorUrl: action.payload };
    case getType(checkoutActions.setRedeemingAllowance):
      return { ...state, redeemingAllowance: action.payload };
    case getType(checkoutActions.addBaksetItem):
      // if we add the same tariff to the basket
      // backend will remove the old item and replace it with a new one (same assemblyTariffId but different ids)
      // We need to use assemblyTariffId to find the old item and replace it
      const basketItemIdx = findIndex(propEq('assemblyTariffId', action.payload.assemblyTariffId))(state.basketItems);
      return {
        ...state,
        basketItems: basketItemIdx === -1
          ? [...state.basketItems, action.payload] : update(basketItemIdx, action.payload, state.basketItems),
      };
    case getType(checkoutActions.addBookingItem):
      const bookingItemIdx = findIndexById(action.payload.id)(state.currentBookingItems);
      return {
        ...state,
        currentBookingItems: bookingItemIdx === -1 ?
          [...state.currentBookingItems, action.payload]
          :
          update(bookingItemIdx, action.payload, state.currentBookingItems),
      };
    case getType(checkoutActions.clearBasket):
      return initialState;
    default:
      return state;
  }
};
